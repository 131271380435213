import React from "react";
import Routes from "./routes";
import store from "store";
import ProviderWrapper from "./ProviderWrapper";
import AddToHomescreen from "components/AddToHomescreen";
import { OutageModel } from "store/types";

type AppProps = {
  outageModel?: OutageModel;
};

const App = (props: AppProps) => {
  return (
    <ProviderWrapper store={store}>
      <>
        <Routes outageModel={props.outageModel} />
        <AddToHomescreen />
      </>
    </ProviderWrapper>
  );
};

export default App;
