import { Action } from "redux";
import { ErrorPayload } from "store/error/types";

export const LOAD_BILL_PAYER_REQUEST = "LOAD_BILL_PAYER_REQUEST";
export const LOAD_BILL_PAYER_SUCCESS = "LOAD_BILL_PAYER_SUCCESS";
export const LOAD_BILL_PAYER_FAILURE = "LOAD_BILL_PAYER_FAILURE";

export const ADD_BILL_PAYER_REQUEST = "ADD_BILL_PAYER_REQUEST";
export const ADD_BILL_PAYER_SUCCESS = "ADD_BILL_PAYER_SUCCESS";
export const ADD_BILL_PAYER_FAILURE = "ADD_BILL_PAYER_FAILURE";

export const CLEAR_BILL_PAYER_DETAIL = "CLEAR_BILL_PAYER_DETAIL";

export type ClearBillPayerDetailAction = Action<typeof CLEAR_BILL_PAYER_DETAIL>;

export type LoadBillPayerRequestAction = Action<
  typeof LOAD_BILL_PAYER_REQUEST
> & {
  id: number;
};

export type LoadBillPayerSuccessAction = Action<
  typeof LOAD_BILL_PAYER_SUCCESS
> & {
  billPayer: BillPayer;
};

export type LoadBillPayerFailureAction = Action<
  typeof LOAD_BILL_PAYER_FAILURE
> &
  ErrorPayload;

export type AddBillPayerRequestAction = Action<
  typeof ADD_BILL_PAYER_REQUEST
> & {
  billPayer: AddBillPayerModel;
};

export type AddBillPayerSuccessAction = Action<
  typeof ADD_BILL_PAYER_SUCCESS
> & {
  billPayer: BillPayer;
};

export type AddBillPayerFailureAction = Action<typeof ADD_BILL_PAYER_FAILURE> &
  ErrorPayload;

export type BillPayerDetailActionTypes =
  | ClearBillPayerDetailAction
  | LoadBillPayerRequestAction
  | LoadBillPayerSuccessAction
  | LoadBillPayerFailureAction
  | AddBillPayerRequestAction
  | AddBillPayerSuccessAction
  | AddBillPayerFailureAction;

export type BillPayer = {
  id: number;
  userId: number;
  rcaName: string;
  rcaId: number;
  rcaCustomerNumber: string;
  organisationName: string;
  organisationId: number;
  name: string;
  email: string;
  workPhone: string;
  mobilePhone: string;
  faxPhone: string;
  jobPosition: string;
  address: string;
};

export type AddBillPayerModel = {
  userId: number;
  rcaOrganisationIds: number[];
  rcaCustomerNumber: string;
};

export type BillPayerDetailState = {
  billPayerDetailData: BillPayer | undefined;
};
