import {
  LOAD_LOOKUP_REQUEST,
  LoadLookupRequestAction,
  LOAD_LOOKUP_SUCCESS
} from "./types";
import { loadLookupFailure, loadLookupSuccess } from "./actions";
import {
  put,
  fork,
  take,
  cancel,
  ActionPattern,
  call
} from "redux-saga/effects";
import { Lookup, SearchModel } from "store/types";
import { Task } from "redux-saga";
import axios from "axios";
import { Endpoint } from "common/endpoints";
import { API_ROUTE } from "common/constants";

const getLookupData = (
  endpoint: Endpoint,
  route: string,
  args: string[] = []
) => {
  const finalArgs = args.join("/");
  const uri = endpoint
    ? `${API_ROUTE}${endpoint}/${route}/lookups/${finalArgs}`
    : `${API_ROUTE}${route}/lookups/${finalArgs}`;
  return axios.get(uri);
};

const postLookupData = (
  route: string,
  args: SearchModel,
  endpoint?: Endpoint
) => {
  const uri = endpoint
    ? `${API_ROUTE}${endpoint}/${route}/lookups/`
    : `${API_ROUTE}${route}/lookups/`;
  return axios.post(uri, args);
};

export function* loadLookupRequest(
  action: LoadLookupRequestAction,
  cleanupTasks: () => void
) {
  try {
    yield put({ type: action.route + LOAD_LOOKUP_REQUEST });

    const { data }: { data: Lookup[] } =
      !action.args || action.args instanceof Array
        ? yield call(getLookupData, action.endpoint, action.route, action.args)
        : yield call(
            postLookupData,
            action.route,
            action.args,
            action.endpoint
          );

    if (data) {
      yield put(loadLookupSuccess(action.route, data));
      yield put({ type: action.route + LOAD_LOOKUP_SUCCESS });
    } else {
      yield put(
        loadLookupFailure(
          `Unable to load data for this lookup: ${action.route}.`
        )
      );
    }
  } catch (e) {
    yield put(
      loadLookupFailure("Something went wrong when trying to fetch lookup data")
    );
  } finally {
    cleanupTasks();
  }
}

export default function* watchLoadLookupRequest() {
  yield takeLatestByKey(LOAD_LOOKUP_REQUEST, loadLookupRequest);
}

/* this function is used to simulate the takeLatest function but uses a common action
 and a key(string) to check if a previous request needs to be cancelled */
const takeLatestByKey = <
  T extends ActionPattern,
  Fn extends (...args: any[]) => any
>(
  pattern: T,
  saga: Fn,
  ...args: any
) =>
  fork(function*() {
    const tasks: Map<string, Task> = new Map();
    while (true) {
      const action = yield take(pattern);
      if (tasks.has(action.route)) {
        yield cancel(tasks.get(action.route)!);
      }

      tasks.set(
        action.route,
        yield fork(
          saga,
          ...args.concat(action, () => {
            tasks.delete(action.route);
          })
        )
      );
    }
  });
