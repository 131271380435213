import {
  LOAD_APPLICATIONS_SUMMARY_REQUEST,
  LoadApplicationsSummaryRequestAction,
  LOAD_APPLICATIONS_SUMMARY_REQUEST_EXTRA,
  ApplicationsSummaryReceivedPayload,
  ApplicationsSummaryFilter,
  LOAD_RCA_LOGOS_REQUEST,
  LoadRcaLogosRequest,
  RcaLogo,
  LOAD_RCA_BOUNDARIES_REQUEST,
  LoadRcaBoundariesRequestAction,
  RcaBoundary,
  ApplicationExportColumn,
  LOAD_APPLICATION_EXPORT_COLUMNS_REQUEST,
  GENERATE_APPLICATION_GRID_LIST_EXPORT_REQUEST,
  GenerateApplicationGridListExportRequestAction,
  ApplicationsFilterRequest
} from "./types";
import { put, takeLatest, call } from "redux-saga/effects";
import {
  generateApplicationGridListExportFailure,
  generateApplicationGridListExportSuccess,
  loadApplicationExportColumnsSuccess,
  loadApplicationsSummaryFailure,
  loadApplicationsSummarySuccess,
  loadRcaBoundariesSuccess,
  loadRcaLogosSuccess
} from "./actions";
import axios from "axios";
import { API_ROUTE } from "common/constants";
import { APPLICATION_ENDPOINT, MEDIA_ENDPOINT } from "common/endpoints";
import {
  APPLICATIONS_CONTROLLER,
  IMAGES_CONTROLLER
} from "common/controllerConstants";
import { GetOffsetUtc } from "common/dateHelper";
import moment from "moment";

const mapFilterModel = (
  filters: ApplicationsSummaryFilter
): ApplicationsFilterRequest => ({
  searchTerm: filters.filterPreferences?.searchValue,
  rcaIds: filters.filterPreferences?.rcaSelection.map(x => x.id),
  dateFrom: filters.filterPreferences?.startDate,
  dateTo: filters.filterPreferences?.endDate,
  carStepIds: filters.filterPreferences?.stepSelection.map(x => x.id),
  carStatusIds: filters.filterPreferences?.statusSelection.map(x => x.id),
  carTypeIds: filters.filterPreferences?.typeSelection.map(x => x.id),
  applicantRoleIds: filters.filterPreferences?.roleSelection.map(x => x.id),
  userIds: filters.filterPreferences?.peopleSelection.map(x => x.id),
  bounds: filters.bounds,
  quickFilterId:
    (filters.filterPreferences?.stepSelection &&
      filters.filterPreferences?.stepSelection.length > 0) ||
      (filters.filterPreferences?.roleSelection &&
        filters.filterPreferences?.roleSelection.length > 0) ||
      (filters.filterPreferences?.statusSelection &&
        filters.filterPreferences?.statusSelection.length > 0)
      ? 0
      : filters.filterPreferences?.quickFilterSelection?.id,
  skip: filters.skip,
  take: 50,
  sorting: filters.sorting,
  loadCount: !!filters.loadCount,
  viewMode: filters.viewMode
});

const getApplications = (filters: ApplicationsSummaryFilter) => {
  const applicationFilter: ApplicationsFilterRequest = mapFilterModel(filters);

  return axios.post(
    `${API_ROUTE}${APPLICATION_ENDPOINT}/${APPLICATIONS_CONTROLLER}/summary`,
    {
      applicationsFilter: applicationFilter
    }
  );
};

export function* loadApplicationsSummaryRequest(
  action: LoadApplicationsSummaryRequestAction
) {
  try {
    const { data }: { data: ApplicationsSummaryReceivedPayload } = yield call(
      getApplications,
      action.applicationsSummaryOptions
    );

    if (data) {
      yield put(
        loadApplicationsSummarySuccess({
          receivedData: data.receivedData,
          totalCount: data.totalCount,
          isMapView: action.applicationsSummaryOptions.bounds !== undefined
        })
      );
    } else {
      yield put(
        loadApplicationsSummaryFailure(
          "Unable to load the application summary data"
        )
      );
    }
  } catch (e) {
    yield put(
      loadApplicationsSummaryFailure(
        "Something went wrong when trying to fetch the car summary data"
      )
    );
    throw e;
  }
}

export function* watchLoadApplicationsSummaryRequestExtra() {
  yield takeLatest(
    LOAD_APPLICATIONS_SUMMARY_REQUEST_EXTRA,
    loadApplicationsSummaryRequest
  );
}

export default function* watchLoadApplicationsSummaryRequest() {
  yield takeLatest(
    LOAD_APPLICATIONS_SUMMARY_REQUEST,
    loadApplicationsSummaryRequest
  );
}

const loadRcaLogosApi = (rcaIds: number[]) => {
  return axios.post(
    `${API_ROUTE}${MEDIA_ENDPOINT}/${IMAGES_CONTROLLER}/rcaLogos`,
    {
      rcaOrganisationIds: rcaIds
    }
  );
};

export function* loadRcaLogos(action: LoadRcaLogosRequest) {
  try {
    const { data }: { data: RcaLogo[] } = yield call(
      loadRcaLogosApi,
      action.rcaIds
    );

    const rcaLogos: RcaLogo[] = [];

    for (const rca of action.rcaIds) {
      if (!data.some(x => x.rcaOrganisationId === rca)) {
        rcaLogos.push({ rcaOrganisationId: rca });
      } else {
        const logo = data.find(x => x.rcaOrganisationId === rca);
        if (logo) {
          rcaLogos.push(logo);
        }
      }
    }

    if (rcaLogos) {
      yield put(loadRcaLogosSuccess(rcaLogos));
    }
  } catch (e) {
    throw e;
  }
}

export function* watchLoadRcaLogosRequest() {
  yield takeLatest(LOAD_RCA_LOGOS_REQUEST, loadRcaLogos);
}

const rcaBoundariesApi = (rcaIds: number[]) => {
  return axios.post(
    `${API_ROUTE}${APPLICATION_ENDPOINT}/${APPLICATIONS_CONTROLLER}/rcaBoundaries`,
    {
      rcaOrganisationIds: rcaIds
    }
  );
};

function* loadRcaBoundaries(action: LoadRcaBoundariesRequestAction) {
  try {
    const { data }: { data: RcaBoundary[] } = yield call(
      rcaBoundariesApi,
      action.rcaIds
    );

    yield put(loadRcaBoundariesSuccess(data));
  } catch (e) {
    throw e;
  }
}

export function* watchLoadRcaBoundariesRequest() {
  yield takeLatest(LOAD_RCA_BOUNDARIES_REQUEST, loadRcaBoundaries);
}

const applicationExportColumnsApi = () => {
  return axios.get(
    `${API_ROUTE}${APPLICATION_ENDPOINT}/${APPLICATIONS_CONTROLLER}/exportcolumns`
  );
};

function* loadApplicationExportColumns() {
  try {
    const { data }: { data: ApplicationExportColumn[] } = yield call(
      applicationExportColumnsApi
    );

    yield put(loadApplicationExportColumnsSuccess(data));
  } catch (e) {
    throw e;
  }
}

export function* watchLoadApplicationExportColumnsRequest() {
  yield takeLatest(
    LOAD_APPLICATION_EXPORT_COLUMNS_REQUEST,
    loadApplicationExportColumns
  );
}

const applicationGridListExportApi = (
  filters: ApplicationsFilterRequest,
  applicationExportColumns: ApplicationExportColumn[],
  fileName: string
) => {
  return axios.post(
    `${API_ROUTE}${APPLICATION_ENDPOINT}/${APPLICATIONS_CONTROLLER}/exportapplicationlist`,
    {
      filters: filters,
      applicationExportColumns: applicationExportColumns,
      fileName: fileName,
      offset: GetOffsetUtc()
    }
  );
};

function* generateApplicationGridListExport(
  action: GenerateApplicationGridListExportRequestAction
) {
  try {
    const fileName = `Generated Export on ${moment().format(
      "DD MMM YYYY HH_mm_ss"
    )}.csv`;

    const { data }: { data: any } = yield call(
      applicationGridListExportApi,
      mapFilterModel(action.filters),
      action.applicationExportColumns,
      fileName
    );

    if (data) {
      download(data, fileName);
      yield put(generateApplicationGridListExportSuccess());
    } else {
      yield put(generateApplicationGridListExportFailure("error"));
    }
  } catch (e) {
    yield put(generateApplicationGridListExportFailure(e));
  }
}

export function* watchGenerateApplicationGridListExportRequest() {
  yield takeLatest(
    GENERATE_APPLICATION_GRID_LIST_EXPORT_REQUEST,
    generateApplicationGridListExport
  );
}

const download = (data: any, name: string) => {
  const url = window.URL.createObjectURL(new Blob([data]));

  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", name);
  document.body.appendChild(link);
  link.click();
  link.remove();
};
