import { takeLatest, call, put } from "redux-saga/effects";
import {
  UserDetail,
  GRANT_USER_ACCESS,
  LOAD_USER_LINKS_REQUEST,
  LOAD_USER_DETAIL_REQUEST,
  SetLinkDuplicatesRequestAction,
  LOAD_DUPLICATE_ACCOUNT_REQUEST,
  SET_LINK_DUPLICATES_REQUEST,
  SET_GST_NUMBER_REQUEST,
  SetGstNumberRequestAction,
  LOAD_NEW_ORGANISATIONS_REQUEST,
  LoadNewOrganisationsRequestAction,
  RegisteredOrganisationModel,
  SET_NEW_ORGANISATION_REQUEST,
  SetNewOrganisationRequestAction,
  SET_NEW_ORGANISATION_SUCCESS,
  SET_NEW_ORGANISATION_FAILURE,
  LOAD_DUPLICATE_ACCOUNT_FAILURE,
  DuplicateAccountModel,
  UserAccount,
  LoadUserDetailRequestAction,
  SET_LINK_DUPLICATES_FAILURE,
  SetUserDetailRequestAction,
  SET_USER_DETAIL_FAILURE,
  SET_USER_DETAIL_REQUEST,
  SET_GST_NUMBER_FAILURE,
  SET_GST_NUMBER_SUCCESS,
  SET_LINK_DUPLICATES_SUCCESS,
  SET_NOTIFICATION_SETTINGS_REQUEST,
  SetNotificationSettingsRequestAction,
  NotificationSettings,
  SET_USER_REGIONS_REQUEST,
  UserRegion,
  SetUserRegionsRequestAction,
  SET_USER_REGIONS_FAILURE,
  SET_NOTIFICATION_SETTINGS_SUCCESS,
  SET_NOTIFICATION_SETTINGS_FAILURE,
  SET_USER_REGIONS_SUCCESS,
  SET_USER_LINK_REQUEST,
  SetUserLinkRequestAction,
  UserLinkDetail,
  SET_USER_LINK_SUCCESS,
  UserLinkButtonActionTypes,
  SET_USER_LINK_FAILURE,
  DELETE_USER_LINK_REQUEST,
  DeleteUserLinkRequestAction,
  DELETE_USER_LINK_SUCCESS,
  DELETE_USER_LINK_FAILURE,
  LoadUserLinksRequestAction,
  LOAD_PERMISSION_TO_VIEW_USER_REQUEST,
  LOAD_USER_NOTIFICATIONS_COUNT_REQUEST,
  LOAD_USER_NOTIFICATIONS_REQUEST,
  UserNotifications,
  LOAD_USER_NOTIFICATIONS_COUNT_FAILURE,
  LOAD_USER_NOTIFICATIONS_FAILURE,
  SET_USER_NOTIFICATIONS_INACTIVE_REQUEST,
  SetUserNotificationInactiveRequestAction,
  SET_USER_NOTIFICATIONS_INACTIVE_FAILURE,
  SET_USER_NOTIFICATIONS_INACTIVE_SUCCESS,
  SET_USER_NOTIFICATION_CONFIRM_DECLINE_REQUEST,
  SetUserNotificationConfirmRequestAction,
  SET_USER_NOTIFICATION_CONFIRM_DECLINE_SUCCESS,
  SET_USER_NOTIFICATION_CONFIRM_DECLINE_FAILURE,
  LOAD_SCALE_OF_WORK_QUESTIONNAIRE_REQUEST,
  LoadScaleOfWorkQuestionnaireRequestAction,
  LOAD_USER_REGIONS_REQUEST,
  LOAD_PARTICIPANT_ORGANISATION_REQUEST,
  LoadUserOrganisationRequestAction,
  LOAD_PARTICIPANT_ORGANISATION_FAILURE,
  DECLINE_USER_LINK_REQUEST,
  ConfirmUserLinkRequestAction,
  CONFIRM_USER_LINK_REQUEST,
  DeclineUserLinkRequestAction,
  USER_LINK_SUCCESS,
  DECLINE_USER_LINK_FAILURE,
  CONFIRM_USER_LINK_FAILURE,
  LoadNotificationsRequestAction,
  UserNotificationPaginationResponse,
  GET_CONTACT_REQUEST,
  GetContactRequestAction
} from "./types";
import {
  loadAccountManagementSuccess,
  loadUserDetailSuccess,
  loadDuplicateAccountSuccess,
  setLinkDuplicatesSuccess,
  setLinkDuplicatesFailure,
  loadDuplicateAccountFailure,
  setGstNumberSuccess,
  loadAccountManagementFailure,
  loadUserDetailFailure,
  setGstNumberFailure,
  loadNewOrganisationsFailure,
  loadNewOrganisationSuccess,
  setNewOrganisationFailure,
  setNewOrganisationSuccess,
  setUserDetailSuccess,
  setUserDetailFailure,
  setNotificationSettingsSuccess,
  setUserRegionsSuccess,
  setNotificationSettingsFailure,
  setUserRegionsFailure,
  setUserLinkFailure,
  setUserLinkSuccess,
  deleteUserLinkSuccess,
  deleteUserLinkFailure,
  loadPermissionToViewUserSuccess,
  loadPermissionToViewUserFailure,
  loadUserNotificationsCountSuccess,
  loadUserNotificationsSuccess,
  loadUserNotificationsCountFailure,
  loadUserNotificationsFailure,
  setUserNotificationInactiveFailure,
  setUserNotificationInactiveSuccess,
  setUserNotificationConfirmDeclineSuccess,
  setUserNotificationConfirmDeclineFailure,
  loadScaleOfWorkQuestionnaireSuccess,
  loadScaleOfWorkQuestionnaireFailure,
  loadUserRegionsSuccess,
  loadParticipantOrganisationSuccess,
  loadParticipantOrganisationFailure,
  declineUserLinkFailure,
  confirmUserLinkFailure,
  userLinkSuccess,
  getContactSuccess
} from "./actions";
import { Lookup, SearchModel } from "store/types";
import axios from "axios";
import { API_ROUTE } from "common/constants";
import { ADMINISTRATION_ENDPOINT, BILLING_ENDPOINT, Endpoint } from "common/endpoints";
import {
  USERS_CONTROLLER,
  ORGANISATIONS_CONTROLLER,
  RCA_ORGANISATIONS_CONTROLLER,
  NEW_ORGANIATIONS_CONTROLLER,
  ADD_ORGANIATIONS_CONTROLLER,
  ADD_CARUSER_CONTROLLER,
  GET_PARTICIPANT_ORGANISATION_CONTROLLER
} from "common/controllerConstants";
import { enqueueSnackbar } from "store/notistack/actions";
import { createNotification } from "common/addNotification";
import { UserLinkType, UserNotificationActionTypes } from "common/enums";
import { GetOffsetUtc } from "common/dateHelper";

const setUserDetailApi = (
  userModel: UserDetail,
  isEditingUserAccount: boolean
) => {
  const addUser = {
    isEditingUserAccount: isEditingUserAccount,
    email: userModel.email,
    firstName: userModel.firstName,
    lastName: userModel.lastName,
    address: userModel.address.description,
    mobilePhone: userModel.mobilePhone,
    workPhone: userModel.workPhone,
    fax: userModel.fax,
    jobPosition: userModel.jobPosition,
    organisationId: userModel.organisation.id,
    isTrafficManagementCoordinator: userModel.isTrafficManagementCoordinator,
    profileUpdateRequired: userModel.profileUpdateRequired,
    participantCertificationTypeLink: {
      participantCertificateTypeId: userModel.participantCertificateType?.id,
      certificationNumber: userModel.certificationNumber,
      dateOfExpiry: userModel.dateOfExpiry,
      dateOfIssue: userModel.dateOfIssue,
      placeOfIssue: userModel.placeOfIssue
    }
  };
  if (axios.defaults.headers.common["Authorization"] === undefined){
    return axios.post(
      `${API_ROUTE}${ADD_CARUSER_CONTROLLER}/lookups`,
      addUser
    );
  }
  return axios.post(
    `${API_ROUTE}${ADMINISTRATION_ENDPOINT}/${USERS_CONTROLLER}/createnewcaruser`,
    addUser
  );
};

function* setUserDetail(action: SetUserDetailRequestAction) {
  try {
    const { data }: { data: Lookup } = yield call(
      setUserDetailApi,
      action.newUserFormModel,
      action.isEditingUserAccount
    );

    yield put(
      setUserDetailSuccess(
        action.parentId,
        data,
        action.isEditingUserAccount,
        action.newUserFormModel
      )
    );

    yield put(
      enqueueSnackbar(
        createNotification(
          action.isEditingUserAccount ? "editedUserAccount" : "addedNewAccount",
          action.isEditingUserAccount
            ? "Successfully updated your account details"
            : "New Account added",
          "success"
        )
      )
    );
  } catch (e) {
    const message = e.response.data;
    if (message.Message) {
      yield put(
        enqueueSnackbar(
          createNotification(
            SET_USER_DETAIL_FAILURE,
            message.Message,
            message.MessageType
          )
        )
      );
    } else {
      yield put(
        setUserDetailFailure(
          "Something went wrong while trying to save new user"
        )
      );
    }
  }
}

export default function* watchSetUserDetailRequest() {
  yield takeLatest(SET_USER_DETAIL_REQUEST, setUserDetail);
}

const loadDuplicateAccountsApi = () => {
  return axios.get(
    `${API_ROUTE}${ADMINISTRATION_ENDPOINT}/${USERS_CONTROLLER}/duplicateusers`
  );
};

function* loadDuplicateAccounts() {
  try {
    const { data }: { data: DuplicateAccountModel[] } = yield call(
      loadDuplicateAccountsApi
    );

    if (data) {
      yield put(loadDuplicateAccountSuccess(data));
    } else {
      yield put(
        loadDuplicateAccountFailure("Failed to load duplicate accounts")
      );
    }
  } catch (e) {
    const message = e.response.data;

    if (message.Message) {
      yield put(
        enqueueSnackbar(
          createNotification(
            LOAD_DUPLICATE_ACCOUNT_FAILURE,
            message.Message,
            message.MessageType
          )
        )
      );
    } else {
      yield put(
        loadDuplicateAccountFailure(
          "Something went wrong when trying to fetch duplicate accounts data"
        )
      );
    }
  }
}

export function* watchLoadDuplicateAccountsRequest() {
  yield takeLatest(LOAD_DUPLICATE_ACCOUNT_REQUEST, loadDuplicateAccounts);
}

const setLinkSelectedDuplicatesApi = (
  selectedIds?: number[],
  emailToLink?: string
) => {
  const request = {
    email: emailToLink ?? null,
    UserIds: selectedIds ?? null
  };

  return axios.post(
    `${API_ROUTE}${ADMINISTRATION_ENDPOINT}/${USERS_CONTROLLER}/linkduplicates`,
    request
  );
};

export function* setLinkSelectedDuplicates(
  action: SetLinkDuplicatesRequestAction
) {
  try {
    if (action.selectedIds || action.emailToLink) {
      const data = yield call(
        setLinkSelectedDuplicatesApi,
        action.selectedIds,
        action.emailToLink
      );

      if (data) {
        yield put(
          setLinkDuplicatesSuccess(action.selectedIds, action.emailToLink)
        );

        yield put(
          enqueueSnackbar(
            createNotification(
              SET_LINK_DUPLICATES_SUCCESS,
              "An email has been sent with instructions on how to confirm to link.",
              "success"
            )
          )
        );
      }
    }
  } catch (e) {
    const message = e.response.data;

    if (message.Message) {
      yield put(
        enqueueSnackbar(
          createNotification(
            SET_LINK_DUPLICATES_FAILURE,
            message.Message,
            message.MessageType
          )
        )
      );
    } else {
      yield put(
        setLinkDuplicatesFailure(
          "Something went wrong when trying to link duplcate account"
        )
      );
    }
  }
}

export function* watchSetLinkSelectedDuplicatesRequest() {
  yield takeLatest(SET_LINK_DUPLICATES_REQUEST, setLinkSelectedDuplicates);
}

const setUserLinkApi = (
  user: Lookup,
  userLinkType: UserLinkType,
  actionType: UserLinkButtonActionTypes
) => {
  const model = {
    userId: user.id,
    userLinkTypeId: userLinkType,
    isBaseUser: actionType !== GRANT_USER_ACCESS
  };

  return axios.post(
    `${API_ROUTE}${ADMINISTRATION_ENDPOINT}/${USERS_CONTROLLER}/userlinks`,
    model
  );
};

function* setUserLink(action: SetUserLinkRequestAction) {
  try {
    const { data }: { data: UserLinkDetail } = yield call(
      setUserLinkApi,
      action.user,
      action.userLinkType,
      action.actionType
    );

    yield put(setUserLinkSuccess(data, action.actionType));

    yield put(
      enqueueSnackbar(
        createNotification(
          SET_USER_LINK_SUCCESS,
          "Successfully linked the user",
          "success"
        )
      )
    );
  } catch (e) {
    const message = e.response.data;

    if (message.Message) {
      yield put(
        enqueueSnackbar(
          createNotification(
            SET_USER_LINK_FAILURE,
            message.Message,
            message.MessageType
          )
        )
      );
    } else {
      yield put(
        setUserLinkFailure("Something went wrong when trying to link users")
      );
    }
  }
}

export function* watchSetUserLinkRequest() {
  yield takeLatest(SET_USER_LINK_REQUEST, setUserLink);
}

const deleteUserLinkApi = (userLinkId: number) => {
  return axios.delete(
    `${API_ROUTE}${ADMINISTRATION_ENDPOINT}/${USERS_CONTROLLER}/userlinks/${userLinkId}`
  );
};

function* deleteUserLink(action: DeleteUserLinkRequestAction) {
  try {
    yield call(deleteUserLinkApi, action.userLinkId);

    yield put(deleteUserLinkSuccess(action.userLinkId));

    yield put(
      enqueueSnackbar(
        createNotification(
          DELETE_USER_LINK_SUCCESS,
          "Successfully deleted the linked user",
          "success"
        )
      )
    );
  } catch (e) {
    const message = e.response.data;

    if (message.Message) {
      yield put(
        enqueueSnackbar(
          createNotification(
            DELETE_USER_LINK_FAILURE,
            message.Message,
            message.MessageType
          )
        )
      );
    } else {
      yield put(
        deleteUserLinkFailure(
          "Something went wrong when trying to delete the linked user"
        )
      );
    }
  }
}

export function* watchDeleteUserLinkRequest() {
  yield takeLatest(DELETE_USER_LINK_REQUEST, deleteUserLink);
}

const loadUserLinksApi = (userId: number) => {
  return axios.get(
    `${API_ROUTE}${ADMINISTRATION_ENDPOINT}/users/userlinks/${userId}`
  );
};

export function* loadUserLinks(action: LoadUserLinksRequestAction) {
  try {
    const { data }: { data: UserAccount } = yield call(
      loadUserLinksApi,
      action.userId
    );

    yield put(loadAccountManagementSuccess(data));
  } catch (e) {
    yield put(
      loadAccountManagementFailure(
        "Something went wong while fetching account data"
      )
    );
  }
}

export function* watchLoadUserLinksRequest() {
  yield takeLatest(LOAD_USER_LINKS_REQUEST, loadUserLinks);
}

const loadPermissionToViewUserApi = (userId: number) => {
  return axios.get(
    `${API_ROUTE}${ADMINISTRATION_ENDPOINT}/users/userpermission/${userId}`
  );
};

export function* loadPermissionToViewUser(action: LoadUserDetailRequestAction) {
  try {
    const { data }: { data: boolean } = yield call(
      loadPermissionToViewUserApi,
      action.userId
    );

    if (data) {
      yield put(loadPermissionToViewUserSuccess(data));
    } else {
      yield put(
        enqueueSnackbar(
          createNotification(
            LOAD_PERMISSION_TO_VIEW_USER_REQUEST,
            "You do not have permission to view this user",
            "error"
          )
        )
      );
    }
  } catch (e) {
    yield put(
      loadPermissionToViewUserFailure(
        "Something went wrong while fetching permissions"
      )
    );
  }
}

export function* watchLoadPermissionToViewUserRequest() {
  yield takeLatest(
    LOAD_PERMISSION_TO_VIEW_USER_REQUEST,
    loadPermissionToViewUser
  );
}

const loadUserDetailApi = (userId: number) => {
  return axios.get(
    `${API_ROUTE}${ADMINISTRATION_ENDPOINT}/users/user/${userId}`
  );
};

export function* loadUserDetail(action: LoadUserDetailRequestAction) {
  try {
    const { data }: { data: UserDetail } = yield call(
      loadUserDetailApi,
      action.userId
    );

    if (data) {
      yield put(loadUserDetailSuccess(data));
    }
  } catch (e) {
    yield put(
      loadUserDetailFailure("Something went wrong while fetching account data")
    );
  }
}

export function* watchLoadUserDetailRequest() {
  yield takeLatest(LOAD_USER_DETAIL_REQUEST, loadUserDetail);
}

const setGstNumberApi = (gstNumber: string) => {
  return axios.post(
    `${API_ROUTE}${ADMINISTRATION_ENDPOINT}/${ORGANISATIONS_CONTROLLER}/gstnumber`,
    { gstNumber: gstNumber }
  );
};

function* setGstNumber(action: SetGstNumberRequestAction) {
  try {
    const response = yield call(setGstNumberApi, action.gstNumber);

    if (response) {
      yield put(setGstNumberSuccess(action.gstNumber));

      yield put(
        enqueueSnackbar(
          createNotification(
            SET_GST_NUMBER_SUCCESS,
            "GST number updated",
            "success"
          )
        )
      );
    }
  } catch (e) {
    const message = e.response.data;

    if (message.Message) {
      yield put(
        enqueueSnackbar(
          createNotification(
            SET_GST_NUMBER_FAILURE,
            message.Message,
            message.MessageType
          )
        )
      );
    } else {
      yield put(
        setGstNumberFailure(
          "Something went wrong while updating the GST number"
        )
      );
    }
  }
}

export function* watchSetGstNumberRequest() {
  yield takeLatest(SET_GST_NUMBER_REQUEST, setGstNumber);
}

const getNewOrganisations = (
  endpoint: Endpoint,
  route: string,
  args: SearchModel | string[]
) => {
  if (axios.defaults.headers.common["Authorization"] === undefined){
    return axios.post(
      `${API_ROUTE}${NEW_ORGANIATIONS_CONTROLLER}/lookups`,
      args
    );
  }
  return axios.post(
    `${API_ROUTE}${endpoint}/${ORGANISATIONS_CONTROLLER}/${route}`,
    args
  );
};

function* loadNewOrganisationsRequest(
  action: LoadNewOrganisationsRequestAction
) {
  try {
    if (action.args) {
      const { data }: { data: RegisteredOrganisationModel[] } = yield call(
        getNewOrganisations,
        action.endpoint,
        action.route,
        action.args!
      );

      if (data) {
        yield put(loadNewOrganisationSuccess(action.route, data));
      } else {
        yield put(
          loadNewOrganisationsFailure(
            `Unable to load data for: ${action.route}`
          )
        );
      }
    }
  } catch (e) {
    yield put(
      loadNewOrganisationsFailure(
        "Something went wrong when trying to fetch lookup data"
      )
    );
  }
}

export function* watchLoadNewOrganisationsRequest() {
  yield takeLatest(LOAD_NEW_ORGANISATIONS_REQUEST, loadNewOrganisationsRequest);
}

const addNewOrganisation = (
  newOrganisation: RegisteredOrganisationModel
) => {
  if (axios.defaults.headers.common["Authorization"] === undefined){
    return axios.post(
      `${API_ROUTE}${ADD_ORGANIATIONS_CONTROLLER}/lookups`,
      newOrganisation
    );
  }
  return axios.post(`${API_ROUTE}${ADMINISTRATION_ENDPOINT}/${ORGANISATIONS_CONTROLLER}/neworganisation`, newOrganisation);
};

function* setNewOrganisationsRequest(action: SetNewOrganisationRequestAction) {
  try {
    const { data }: { data: Lookup } = yield call(
      addNewOrganisation,
      action.newOrganisation
    );

    yield put(setNewOrganisationSuccess(data, action.parentId));

    yield put(
      enqueueSnackbar(
        createNotification(
          SET_NEW_ORGANISATION_SUCCESS,
          "New Organisation added successfully",
          "success"
        )
      )
    );

    // TODO: data (the id of the new organisation) may be used. Waiting on advice
  } catch (e) {
    const message = e.response.data;

    if (message.Message) {
      enqueueSnackbar(
        createNotification(
          SET_NEW_ORGANISATION_FAILURE,
          message.Message,
          message.MessageType
        )
      );
    } else {
      yield put(
        setNewOrganisationFailure(
          "Something went wrong when trying to add this organisation"
        )
      );
    }
  }
}

export function* watchSetNewOrganisationRequest() {
  yield takeLatest(SET_NEW_ORGANISATION_REQUEST, setNewOrganisationsRequest);
}

const setNotificationSettingsApi = (
  notificationSettings: NotificationSettings
) => {
  const model = {
    notificationSummaryTypeId: notificationSettings.notificationSummaryType.id,
    sendDowntimeEmails: notificationSettings.sendDowntimeEmails,
    sendNotificationEmails: notificationSettings.sendNotificationEmails,
    organisationIds: notificationSettings.organisationWhitelist.map(x => x.id)
  };

  return axios.post(
    `${API_ROUTE}${ADMINISTRATION_ENDPOINT}/${USERS_CONTROLLER}/notificationsettings`,
    model
  );
};

function* setNotificationSettings(
  action: SetNotificationSettingsRequestAction
) {
  try {
    yield call(setNotificationSettingsApi, action.notificationSettings);

    yield put(setNotificationSettingsSuccess(action.notificationSettings));

    yield put(
      enqueueSnackbar(
        createNotification(
          SET_NOTIFICATION_SETTINGS_SUCCESS,
          "Successfully updated Notification Settings",
          "success"
        )
      )
    );
  } catch (e) {
    const message = e.response.data;
    if (message.Message) {
      yield put(
        enqueueSnackbar(
          createNotification(
            SET_NOTIFICATION_SETTINGS_FAILURE,
            message.Message,
            message.MessageType
          )
        )
      );
    } else {
      yield put(
        setNotificationSettingsFailure(
          "Something went wrong while trying to update Notification Settings"
        )
      );
    }
  }
}

export function* watchSetNotificationSettingsRequest() {
  yield takeLatest(SET_NOTIFICATION_SETTINGS_REQUEST, setNotificationSettings);
}

const setUserRegionsApi = (userRegions: UserRegion[]) => {
  const model = {
    userRegions: userRegions
  };

  return axios.post(
    `${API_ROUTE}${ADMINISTRATION_ENDPOINT}/${USERS_CONTROLLER}/userregions`,
    model
  );
};

function* setUserRegions(action: SetUserRegionsRequestAction) {
  try {
    yield call(setUserRegionsApi, action.userRegions);

    yield put(setUserRegionsSuccess(action.userRegions));

    yield put(
      enqueueSnackbar(
        createNotification(
          SET_USER_REGIONS_SUCCESS,
          "Successfully updated User Regions",
          "success"
        )
      )
    );
  } catch (e) {
    const message = e.response.data;
    if (message.Message) {
      yield put(
        enqueueSnackbar(
          createNotification(
            SET_USER_REGIONS_FAILURE,
            message.Message,
            message.MessageType
          )
        )
      );
    } else {
      yield put(
        setUserRegionsFailure(
          "Something went wrong while trying to update User Regions"
        )
      );
    }
  }
}

export function* watchSetUserRegionsRequest() {
  yield takeLatest(SET_USER_REGIONS_REQUEST, setUserRegions);
}

const loadUserNotificationsApi = (pageIndex: number) => {
  const request = {
    offset: GetOffsetUtc(),
    pageIndex: pageIndex,
    pageSize: 100
  };
  return axios.post(
    `${API_ROUTE}${ADMINISTRATION_ENDPOINT}/notifications`,
    request
  );
};

export function* loadUserNotifications(action: LoadNotificationsRequestAction) {
  try {
    const { data }: { data: UserNotificationPaginationResponse } = yield call(
      loadUserNotificationsApi, action.pageIndex
    );

    yield put(loadUserNotificationsSuccess(data));
  } catch (e) {
    const message = e.response.data;

    if (message.Message) {
      yield put(
        enqueueSnackbar(
          createNotification(
            LOAD_USER_NOTIFICATIONS_FAILURE,
            message.Message,
            message.MessageType
          )
        )
      );
    } else {
      yield put(
        loadUserNotificationsFailure(
          "Something went wrong when trying to fetch notifications"
        )
      );
    }
  }
}

export function* watchLoadUserNotificationsRequest() {
  yield takeLatest(LOAD_USER_NOTIFICATIONS_REQUEST, loadUserNotifications);
}

const loadUserNotificationsCountApi = () => {
  return axios.get(
    `${API_ROUTE}${ADMINISTRATION_ENDPOINT}/${USERS_CONTROLLER}/notificationcount`
  );
};

export function* loadUserNotificationsCount() {
  try {
    const { data }: { data: number } = yield call(
      loadUserNotificationsCountApi
    );
    yield put(loadUserNotificationsCountSuccess(data));
  } catch (e) {
    const message = e.response.data;

    if (message.Message) {
      yield put(
        enqueueSnackbar(
          createNotification(
            LOAD_USER_NOTIFICATIONS_COUNT_FAILURE,
            message.Message,
            message.MessageType
          )
        )
      );
    } else {
      yield put(
        loadUserNotificationsCountFailure(
          "Something went wrong when trying to fetch notification count"
        )
      );
    }
  }
}

export function* watchLoadUserNotificationCountRequest() {
  yield takeLatest(
    LOAD_USER_NOTIFICATIONS_COUNT_REQUEST,
    loadUserNotificationsCount
  );
}

const setUserNotificationInactiveApi = (notificationIds: string[]) => {
  return axios.post(
    `${API_ROUTE}${ADMINISTRATION_ENDPOINT}/${USERS_CONTROLLER}/hidenotification`,
    { notificationIds: notificationIds }
  );
};

export function* setUserNotificationInactive(
  action: SetUserNotificationInactiveRequestAction
) {
  try {
    yield call(setUserNotificationInactiveApi, action.request.notificationIds);
    yield put(setUserNotificationInactiveSuccess(action.request));
    yield put(
      enqueueSnackbar(
        createNotification(
          SET_USER_NOTIFICATIONS_INACTIVE_SUCCESS,
          "Notification is now hidden",
          "success"
        )
      )
    );
  } catch (e) {
    const message = e.response.data;

    if (message.Message) {
      yield put(
        enqueueSnackbar(
          createNotification(
            SET_USER_NOTIFICATIONS_INACTIVE_FAILURE,
            message.Message,
            message.MessageType
          )
        )
      );
    } else {
      yield put(
        setUserNotificationInactiveFailure(
          "Something went wrong when trying to set the notification to inactive"
        )
      );
    }
  }
}

export function* watchSetUserNotificationInactiveRequest() {
  yield takeLatest(
    SET_USER_NOTIFICATIONS_INACTIVE_REQUEST,
    setUserNotificationInactive
  );
}

const setUserNotificationConfirmApi = (notificationId: string) => {
  return axios.post(
    `${API_ROUTE}${ADMINISTRATION_ENDPOINT}/${USERS_CONTROLLER}/confirmnotification`,
    { notificationId: notificationId }
  );
};

const setUserNotificationDeclineApi = (notificationId: string) => {
  return axios.post(
    `${API_ROUTE}${ADMINISTRATION_ENDPOINT}/${USERS_CONTROLLER}/declinenotification`,
    { notificationId: notificationId }
  );
};

export function* setUserNotificationConfirmDecline(
  action: SetUserNotificationConfirmRequestAction
) {
  try {
    let actionDone;
    switch (action.notificationActionType) {
      case UserNotificationActionTypes.Confirm: {
        yield call(setUserNotificationConfirmApi, action.notificationId);
        actionDone = "confirmed";
        break;
      }
      case UserNotificationActionTypes.Decline: {
        yield call(setUserNotificationDeclineApi, action.notificationId);
        actionDone = "declined";
        break;
      }
    }

    yield put(setUserNotificationConfirmDeclineSuccess(action.notificationId));
    yield put(
      enqueueSnackbar(
        createNotification(
          SET_USER_NOTIFICATION_CONFIRM_DECLINE_SUCCESS,
          `Successfully ${actionDone} notification`,
          "success"
        )
      )
    );
  } catch (e) {
    const message = e.response.data;

    if (message.Message) {
      yield put(
        enqueueSnackbar(
          createNotification(
            SET_USER_NOTIFICATION_CONFIRM_DECLINE_FAILURE,
            message.Message,
            message.MessageType
          )
        )
      );
    } else {
      yield put(
        setUserNotificationConfirmDeclineFailure(
          `Something went wrong when trying to ${action.notificationActionType} your notification`
        )
      );
    }
  }
}

export function* watchSetUserNotificationConfirmDeclineRequest() {
  yield takeLatest(
    SET_USER_NOTIFICATION_CONFIRM_DECLINE_REQUEST,
    setUserNotificationConfirmDecline
  );
}

const loadScaleOfWorkQuestionnaireApi = (rcaId: number) => {
  return axios.get(
    `${API_ROUTE}${ADMINISTRATION_ENDPOINT}/${RCA_ORGANISATIONS_CONTROLLER}/scaleofworkquestionnaire/${rcaId}`
  );
};

function* loadScaleOfWorkQuestionnaire(
  action: LoadScaleOfWorkQuestionnaireRequestAction
) {
  try {
    const { data }: { data: string[] } = yield call(
      loadScaleOfWorkQuestionnaireApi,
      action.rcaId
    );

    yield put(loadScaleOfWorkQuestionnaireSuccess(data));
  } catch (e) {
    yield put(
      loadScaleOfWorkQuestionnaireFailure(
        "Something went wrong while fetching custom Scale Of Work questionnaires"
      )
    );
  }
}

export function* watchLoadScaleOfWorkQuestionnaireRequest() {
  yield takeLatest(
    LOAD_SCALE_OF_WORK_QUESTIONNAIRE_REQUEST,
    loadScaleOfWorkQuestionnaire
  );
}

const loadUserRegionsApi = () => {
  return axios.get(
    `${API_ROUTE}${ADMINISTRATION_ENDPOINT}/${USERS_CONTROLLER}/userregions`
  );
};

function* loadUserRegions() {
  const { data }: { data: UserRegion[] } = yield call(loadUserRegionsApi);

  yield put(loadUserRegionsSuccess(data));
}

export function* watchLoadUserRegionsRequest() {
  yield takeLatest(LOAD_USER_REGIONS_REQUEST, loadUserRegions);
}

const loadParticipantOrganisationApi = (userId: number) => {
  if (axios.defaults.headers.common["Authorization"] === undefined){
    return axios.get(`${API_ROUTE}${GET_PARTICIPANT_ORGANISATION_CONTROLLER}/lookups?${userId}`);
  }
  return axios.get(
    `${API_ROUTE}${ADMINISTRATION_ENDPOINT}/${USERS_CONTROLLER}/participantorganisation/${userId}`
  );
};

function* loadParticipantOrganisation(
  action: LoadUserOrganisationRequestAction
) {
  try {
    const { data }: { data: Lookup } = yield call(
      loadParticipantOrganisationApi,
      action.userId
    );

    yield put(loadParticipantOrganisationSuccess(data));
  } catch (e) {
    const message = e.response.data;

    if (message.Message) {
      yield put(
        enqueueSnackbar(
          createNotification(
            LOAD_PARTICIPANT_ORGANISATION_FAILURE,
            message.Message,
            message.MessageType
          )
        )
      );
    } else {
      yield put(
        loadParticipantOrganisationFailure(
          "Something went wrong when fetching participant organisation"
        )
      );
    }
  }
}

export function* watchLoadParticipantOrganisationRequest() {
  yield takeLatest(
    LOAD_PARTICIPANT_ORGANISATION_REQUEST,
    loadParticipantOrganisation
  );
}

const confirmUserLinkApi = (code: string) => {
  return axios.get(
    `${API_ROUTE}${ADMINISTRATION_ENDPOINT}/${USERS_CONTROLLER}/confirmuserlink/${code}`
  );
};

function* confirmUserLink(action: ConfirmUserLinkRequestAction) {
  try {
    const { data }: { data: string } = yield call(
      confirmUserLinkApi,
      action.code
    );
    yield put(
      enqueueSnackbar(
        createNotification(
          USER_LINK_SUCCESS,
          "Linking users completed",
          "success"
        )
      )
    );
    yield put(userLinkSuccess(data));
  } catch (e) {
    const message = e.response.data;
    if (message.Message) {
      yield put(
        enqueueSnackbar(
          createNotification(
            CONFIRM_USER_LINK_FAILURE,
            message.Message,
            message.MessageType
          )
        )
      );
    } else {
      yield put(
        confirmUserLinkFailure("Somethine went wrong when confirming user link")
      );
    }
  }
}

export function* watchConfirmUserLinkRequest() {
  yield takeLatest(CONFIRM_USER_LINK_REQUEST, confirmUserLink);
}

const declineUserLinkApi = (code: string) => {
  return axios.get(
    `${API_ROUTE}${ADMINISTRATION_ENDPOINT}/${USERS_CONTROLLER}/declineuserlink/${code}`
  );
};

function* declineUserLink(action: DeclineUserLinkRequestAction) {
  try {
    yield call(declineUserLinkApi, action.code);
    yield put(
      enqueueSnackbar(
        createNotification(
          USER_LINK_SUCCESS,
          "You have declined linking users",
          "success"
        )
      )
    );
    yield put(userLinkSuccess("decline"));
  } catch (e) {
    const message = e.response.data;

    if (message.Message) {
      yield put(
        enqueueSnackbar(
          createNotification(
            DECLINE_USER_LINK_FAILURE,
            message.Message,
            message.MessageType
          )
        )
      );
    } else {
      yield put(
        declineUserLinkFailure("Something went wrong when declining user link")
      );
    }
  }
}

export function* watchDeclineUserLinkRequest() {
  yield takeLatest(DECLINE_USER_LINK_REQUEST, declineUserLink);
}

const getDefaultBillPayerApi = (organisationId: number, rcaIds: number[], forDefaultBillPayer: boolean, userId?: number | null) => {
  return axios.post(`${API_ROUTE}${BILLING_ENDPOINT}/getDefaultContact`, {
    rcaIds: rcaIds,
    organisationId: organisationId,
    forDefaultBillPayer: forDefaultBillPayer,
    userId: userId
  });
};

function* GetContact(action: GetContactRequestAction) {
  try {
    const { data } = yield call(getDefaultBillPayerApi, action.organisationId, action.rcaIds, action.forDefaultBillPayer, action.userId);
    yield put(getContactSuccess(data));
  } catch (e) {
  }
}

export function* watchGetContactRequest() {
  yield takeLatest(GET_CONTACT_REQUEST, GetContact);
}
