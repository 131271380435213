import {
  LOAD_SINGLE_APPLICATION_REQUEST,
  LoadSingleApplicationRequestAction,
  ApplicationDetail,
  UPLOAD_DETAIL_FILE_REQUEST,
  UploadFileRequestAction,
  Attachment,
  NewAttachmentReturnType,
  SetApplicationNotesRequestAction,
  SET_APPLICATION_NOTES_REQUEST,
  SET_APPLICATION_NOTES_SUCCESS,
  SET_WORK_INFORMATION_REQUEST,
  SetWorkInformationRequestAction,
  SET_WORK_INFORMATION_SUCCESS,
  SET_APPLICATION_FEEDBACK_REQUEST,
  SetApplicationFeedbackRequestAction,
  ApplicationFeedback,
  SET_APPLICATION_FEEDBACK_SUCCESS,
  LOAD_EXISTING_APPLICATION_FEEDBACK_REQUEST,
  DOWNLOAD_CALLOUT_REPORT_REQUEST,
  DownloadCalloutReportRequestAction,
  Inspection,
  Defect,
  SET_WORK_INFORMATION_FAILURE,
  SET_PARTICIPANT_REQUEST,
  SetParticiantRequestAction,
  Participant,
  SET_NOTIFY_WORK_STATUS_REQUEST,
  SetNotifyWorkStatusRequestAction,
  SET_NOTIFY_WORK_STATUS_FAILURE,
  SET_NOTIFY_WORK_START_SUCCESS,
  NotifyWorkStartResponse,
  SET_APPLICATION_FEEDBACK_FAILURE,
  LoadAuditLogsRequestAction,
  LOAD_AUDIT_LOGS_REQUEST,
  AuditHistoryResponse,
  SET_PARTICIPANT_SUCCESS,
  SET_PARTICIPANT_FAILURE,
  REPLACE_PARTICIPANT_REQUEST,
  ReplaceParticipantRequestAction,
  REPLACE_PARTICIPANT_SUCCESS,
  REPLACE_PARTICIPANT_FAILURE,
  SET_NOTIFY_WORK_COMPLETION_SUCCESS,
  NotifyWorkCompletionResponse,
  REMOVE_PARTICIPANT_REQUEST,
  RemoveParticipantRequestAction,
  REMOVE_PARTICIPANT_SUCCESS,
  REMOVE_PARTICIPANT_FAILURE,
  SetLocationRequestAction,
  SET_LOCATION_SUCCESS,
  SET_LOCATION_FAILURE,
  SET_LOCATION_REQUEST,
  DELETE_ATTACHMENT_REQUEST,
  DeleteAttachmentRequestAction,
  DELETE_ATTACHMENT_SUCCESS,
  DELETE_ATTACHMENT_FAILURE,
  LocationInformationModel,
  SET_APPLICATION_NOTES_FAILURE,
  CANCEL_APPLICATION_REQUEST,
  CANCEL_APPLICATION_FAILURE,
  CANCEL_APPLICATION_SUCCESS,
  CancelApplicationRequestAction,
  MaintenanceCompletionRequest,
  SetMaintenanceCompletionRequestAction,
  SET_MAINTENANCE_COMPLETION_REQUEST,
  SET_MAINTENANCE_COMPLETION_SUCCESS,
  SET_MAINTENANCE_COMPLETION_FAILURE,
  MaintenanceCompletionResponse,
  ReplaceParticipantResponse,
  DECLINE_APPLICATION_PARTICIPANT_REQUEST,
  DeclineApplicationParticipantRequestAction,
  DECLINE_APPLICATION_PARTICIPANT_FAILURE
} from "./types";
import { put, takeLatest, call } from "redux-saga/effects";
import {
  loadSingleApplicationFailure,
  loadSingleApplicationSuccess,
  uploadFileFailure,
  uploadFileSuccess,
  setApplicationNotesSuccess,
  setApplicationNotesFailure,
  setWorkInformationSuccess,
  setWorkInformationFailure,
  setApplicationFeedbackSuccess,
  setApplicationFeedbackFailure,
  loadExistingApplicationFeedbackSuccess,
  downloadCalloutReportFailure,
  downloadInspectionReportSuccess,
  downloadDefectReportSuccess,
  setNotifyWorkStartSuccess,
  loadAuditLogsSuccess,
  loadAuditLogsFailure,
  setParticipantSuccess,
  setNotifyWorkStatusFailure,
  setParticipantFailure,
  replaceParticipantSuccess,
  replaceParticipantFailure,
  setNotifyWorkCompletionSuccess,
  removeParticipantSuccess,
  removeParticipantFailure,
  setLocationSuccess,
  setLocationFailure,
  deleteAttachmentSuccess,
  deleteAttachmentFailure,
  cancelApplicationFailure,
  cancelApplicationSuccess,
  setMaintenanceCompletionSuccess,
  declineApplicationParticipantFailure,
  declineApplicationParticipantSuccess
} from "./actions";
import axios from "axios";
import { API_ROUTE } from "common/constants";
import { APPLICATION_ENDPOINT, MEDIA_ENDPOINT } from "common/endpoints";
import { Lookup } from "store/types";
import { createNotification } from "common/addNotification";
import { enqueueSnackbar } from "store/notistack/actions";
import { WorkInformationModel } from "containers/ApplicationDetail/EditWorkInformationModal";
import { CalloutType } from "common/enums";
import { AddParticipant } from "store/types";
import featuresToWkt from "common/featuresToWkt";
import Wkt from "wicket";
import { GetOffsetUtc } from "common/dateHelper";

const getSingleApplication = (carId: number) => {
  return axios.get(`${API_ROUTE}${APPLICATION_ENDPOINT}/${carId}`);
};

export function* loadSingleApplicationRequest(
  action: LoadSingleApplicationRequestAction
) {
  try {
    let applicationDetail: ApplicationDetail | null = null;

    if (action.guid) {
      const { data }: { data: ApplicationDetail } = yield call(
        readOnlyApplicationDetailApi,
        action.guid
      );
      applicationDetail = data;
    } else {
      const { data }: { data: ApplicationDetail } = yield call(
        getSingleApplication,
        action.carId!
      );
      applicationDetail = data;
    }

    if (applicationDetail) {
      yield put(loadSingleApplicationSuccess(applicationDetail));
    } else {
      yield put(
        loadSingleApplicationFailure(
          `No data was found for CAR ID: ${action.carId}`
        )
      );
    }
  } catch (e) {
    const message = e.response.data;
    yield put(
      loadSingleApplicationFailure(
        message.Message ??
        "Something went wrong when trying to fetch the application detail data."
      )
    );
  }
}

export default function* watchLoadSingleApplicationRequest() {
  yield takeLatest(
    LOAD_SINGLE_APPLICATION_REQUEST,
    loadSingleApplicationRequest
  );
}

const uploadFile = (formData: FormData, reference?: string) => {
  const uri = reference
    ? `${API_ROUTE}media/attachment`
    : `${API_ROUTE}${MEDIA_ENDPOINT}/attachments/attachment`;
  return axios.post(uri, formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

function* uploadFileRequest(action: UploadFileRequestAction) {
  try {
    const formData = new FormData();
    const attachmentTypes: Lookup[] = [];

    formData.append(
      "childApplicationIds",
      action.childApplicationIds ? action.childApplicationIds.toString() : ""
    );
    formData.append("reference", action.reference ?? "");
    formData.append("permissionValue", action.permissionValue.toString());
    action.attachments.forEach(file => {
      const filedetails = `${action.applicationId
        }/${file.attachmentType.id.toString()}/${action.carStepId}`;

      formData.append(filedetails, file.file!);
      attachmentTypes.push(file.attachmentType);
    });
    const { data }: { data: NewAttachmentReturnType[] } = yield call(
      uploadFile,
      formData,
      action.reference
    );

    const result = data.map((x: NewAttachmentReturnType) => {
      return {
        attachmentType: attachmentTypes.find(y => y.id === x.attachmentTypeId),
        attachmentReference: x.attachmentReference,
        filename: x.filename,
        canDelete: x.canDelete,
        attachmentStatus: x.attachmentStatus
      } as Attachment;
    });

    yield put(
      enqueueSnackbar(
        createNotification(
          "addAttachment",
          "Successfully uploaded file(s)",
          "success"
        )
      )
    );

    yield put(uploadFileSuccess(result));
  } catch (e) {
    yield put(uploadFileFailure("Failed to upload the file."));
  }
}

export function* watchFileUploadRequest() {
  yield takeLatest(UPLOAD_DETAIL_FILE_REQUEST, uploadFileRequest);
}

const setApplicationNotesApi = (notes: string, applicationId: number) => {
  const notesModel = {
    notes: notes,
    applicationId: applicationId
  };
  return axios.post(`${API_ROUTE}${APPLICATION_ENDPOINT}/notes`, notesModel);
};

function* setApplicationNotes(action: SetApplicationNotesRequestAction) {
  try {
    yield call(setApplicationNotesApi, action.notes, action.applicationId);

    yield put(setApplicationNotesSuccess(action.notes));
    yield put(
      enqueueSnackbar(
        createNotification(
          SET_APPLICATION_NOTES_SUCCESS,
          "Application Notes updated successfully",
          "success"
        )
      )
    );
  } catch (e) {
    const message = e.response.data;

    if (message.Message) {
      enqueueSnackbar(
        createNotification(
          SET_APPLICATION_NOTES_FAILURE,
          message.Message,
          message.MessageType
        )
      );
    } else {
      yield put(
        setApplicationNotesFailure(
          "Something went wrong when trying to update Application Notes"
        )
      );
    }
  }
}

export function* watchSetApplicationNotesRequest() {
  yield takeLatest(SET_APPLICATION_NOTES_REQUEST, setApplicationNotes);
}

const setWorkInformationApi = (
  workInfo: WorkInformationModel,
  applicationId: number
) => {
  const workInfoModel = {
    workInfo: { ...workInfo, estimatedDuration: +workInfo.estimatedDuration },
    applicationId: applicationId
  };
  return axios.post(
    `${API_ROUTE}${APPLICATION_ENDPOINT}/workinformation`,
    workInfoModel
  );
};

function* setWorkInformation(action: SetWorkInformationRequestAction) {
  try {
    yield call(setWorkInformationApi, action.workInfo, action.applicationId);

    yield put(setWorkInformationSuccess(action.workInfo));
    yield put(
      enqueueSnackbar(
        createNotification(
          SET_WORK_INFORMATION_SUCCESS,
          "Work Information updated successfully",
          "success"
        )
      )
    );
  } catch (e) {
    const message = e.response.data;

    if (message.Message) {
      enqueueSnackbar(
        createNotification(
          SET_WORK_INFORMATION_FAILURE,
          message.Message,
          message.MessageType
        )
      );
    } else {
      yield put(
        setWorkInformationFailure(
          "Something went wrong when trying to update Work Information"
        )
      );
    }
  }
}

export function* watchSetWorkInformationRequest() {
  yield takeLatest(SET_WORK_INFORMATION_REQUEST, setWorkInformation);
}

const setApplicationFeedbackApi = (
  applicationId: number,
  rating: number,
  notes: string
) => {
  const applicationFeedbackModel = {
    applicationId: applicationId,
    rating: rating,
    notes: notes
  };
  return axios.post(
    `${API_ROUTE}${APPLICATION_ENDPOINT}/applications/applicationfeedback`,
    applicationFeedbackModel
  );
};

function* setApplicationFeedback(action: SetApplicationFeedbackRequestAction) {
  try {
    yield call(
      setApplicationFeedbackApi,
      action.applicationId,
      action.rating,
      action.notes
    );

    const feedback: ApplicationFeedback = {
      applicationId: action.applicationId,
      rating: action.rating,
      notes: action.notes
    };

    yield put(setApplicationFeedbackSuccess(feedback));
    yield put(
      enqueueSnackbar(
        createNotification(
          SET_APPLICATION_FEEDBACK_SUCCESS,
          "Application Feedback updated successfully",
          "success"
        )
      )
    );
  } catch (e) {
    const message = e.response.data;

    if (message.Message) {
      enqueueSnackbar(
        createNotification(
          SET_APPLICATION_FEEDBACK_FAILURE,
          message.Message,
          message.MessageType
        )
      );
    } else {
      yield put(
        setApplicationFeedbackFailure(
          "Something went wrong when trying to update Application Feedback"
        )
      );
    }
  }
}

export function* watchSetApplicationFeedbackRequest() {
  yield takeLatest(SET_APPLICATION_FEEDBACK_REQUEST, setApplicationFeedback);
}

const loadExistingApplicationFeedbackApi = (applicationId: number) => {
  return axios.get(
    `${API_ROUTE}${APPLICATION_ENDPOINT}/applications/applicationfeedback/${applicationId}`
  );
};

function* loadExistingApplicationFeedback(
  action: SetApplicationFeedbackRequestAction
) {
  try {
    const { data }: { data: ApplicationFeedback } = yield call(
      loadExistingApplicationFeedbackApi,
      action.applicationId
    );

    yield put(loadExistingApplicationFeedbackSuccess(data));
  } catch (e) {
    yield put(
      setApplicationFeedbackFailure(
        "Something went wrong when trying to load Application Feedback"
      )
    );
  }
}

export function* watchLoadExistingApplicationFeedbackRequest() {
  yield takeLatest(
    LOAD_EXISTING_APPLICATION_FEEDBACK_REQUEST,
    loadExistingApplicationFeedback
  );
}

const downloadInspectionReport = (inspectionId: number) => {
  return axios.get(
    `${API_ROUTE}${APPLICATION_ENDPOINT}/callout/report/inspection/${inspectionId}`
  );
};

const downloadDefectReport = (defectId: number) => {
  return axios.get(
    `${API_ROUTE}${APPLICATION_ENDPOINT}/callout/report/defect/${defectId}`
  );
};

function* downloadCalloutReport(action: DownloadCalloutReportRequestAction) {
  try {
    if (action.calloutType === CalloutType.Inspection) {
      const { data }: { data: Inspection } = yield call(
        downloadInspectionReport,
        action.calloutId
      );
      yield put(downloadInspectionReportSuccess(data));
    } else {
      const { data }: { data: Defect } = yield call(
        downloadDefectReport,
        action.calloutId
      );
      yield put(downloadDefectReportSuccess(data));
    }
  } catch (e) {
    yield put(
      downloadCalloutReportFailure(
        "Something went wrong when trying to download the report"
      )
    );
  }
}

export function* watchDownloadCalloutReportRequest() {
  yield takeLatest(DOWNLOAD_CALLOUT_REPORT_REQUEST, downloadCalloutReport);
}

const addParticipantApi = (
  carId: number,
  participant: AddParticipant,
  rcaId: number,
  reference?: string
) => {
  const participantRequest = {
    carId: carId,
    participant: participant,
    rcaId: rcaId,
    reference: reference,
    offset: GetOffsetUtc()
  };
  return axios.post(
    `${API_ROUTE}${APPLICATION_ENDPOINT}/addparticipant`,
    participantRequest
  );
};

export function* setParticipant(action: SetParticiantRequestAction) {
  try {
    const { data }: { data: Participant } = yield call(
      addParticipantApi,
      action.carId,
      action.participant,
      action.rcaId,
      action.reference
    );

    if (data) {
      yield put(setParticipantSuccess(data));
      yield put(
        enqueueSnackbar(
          createNotification(
            SET_PARTICIPANT_SUCCESS,
            "Participant added successfully",
            "success"
          )
        )
      );
    }
  } catch (e) {
    const message = e.response.data;

    if (message.Message) {
      yield put(
        enqueueSnackbar(
          createNotification(
            SET_PARTICIPANT_FAILURE,
            message.Message,
            message.MessageType
          )
        )
      );
    } else {
      yield put(
        setParticipantFailure(
          "Something went wrong when trying to add participants into the application"
        )
      );
    }
  }
}

export function* watchSetParticipantRequest() {
  yield takeLatest(SET_PARTICIPANT_REQUEST, setParticipant);
}

const replaceParticipantApi = (
  carId: number,
  participant: AddParticipant,
  rcaId: number
) => {
  const participantRequest = {
    carId: carId,
    participant: participant,
    rcaId: rcaId,
    offset: GetOffsetUtc()
  };
  return axios.post(
    `${API_ROUTE}${APPLICATION_ENDPOINT}/replaceparticipant`,
    participantRequest
  );
};

function* replaceParticipant(action: ReplaceParticipantRequestAction) {
  try {
    const { data }: { data: ReplaceParticipantResponse } = yield call(
      replaceParticipantApi,
      action.carId,
      action.participant,
      action.rcaId
    );

    if (data) {
      yield put(
        enqueueSnackbar(
          createNotification(
            REPLACE_PARTICIPANT_SUCCESS,
            "Participant successfully replaced",
            "success"
          )
        )
      );
      yield put(replaceParticipantSuccess(data));
    }
  } catch (e) {
    const message = e.response.data;

    if (message.Message) {
      yield put(
        enqueueSnackbar(
          createNotification(
            REPLACE_PARTICIPANT_FAILURE,
            message.Message,
            message.MessageType
          )
        )
      );
    } else {
      replaceParticipantFailure(
        "Something went wrong while trying to replace participant"
      );
    }
  }
}

export function* watchReplaceParticipantRequest() {
  yield takeLatest(REPLACE_PARTICIPANT_REQUEST, replaceParticipant);
}

const setNotifyWorkStartApi = (
  applicationId: number,
  newDate: Date,
  reference?: string,
  offset?: number
) => {
  return axios.post(`${API_ROUTE}${APPLICATION_ENDPOINT}/notifyworkstart`, {
    applicationId: applicationId,
    newDate: newDate,
    reference: reference,
    offset: offset
  });
};

const setNotifyWorkCompletionApi = (
  applicationId: number,
  newDate: Date,
  reference?: string,
  offset?: number
) => {
  return axios.post(
    `${API_ROUTE}${APPLICATION_ENDPOINT}/notifyworkcompletion`,
    {
      applicationId: applicationId,
      newDate: newDate,
      reference: reference,
      offset: offset
    }
  );
};

function* setNotifyWorkStatus(action: SetNotifyWorkStatusRequestAction) {
  try {
    if (action.isStart) {
      const { data }: { data: NotifyWorkStartResponse } = yield call(
        setNotifyWorkStartApi,
        action.applicationId,
        action.newDate,
        action.reference,
        action.offset
      );
      yield put(setNotifyWorkStartSuccess(data));
      yield put(
        enqueueSnackbar(
          createNotification(
            SET_NOTIFY_WORK_START_SUCCESS,
            "Notified Work Start date successfully",
            "success"
          )
        )
      );
    } else {
      const { data }: { data: NotifyWorkCompletionResponse } = yield call(
        setNotifyWorkCompletionApi,
        action.applicationId,
        action.newDate,
        action.reference,
        action.offset
      );
      yield put(setNotifyWorkCompletionSuccess(data));
      yield put(
        enqueueSnackbar(
          createNotification(
            SET_NOTIFY_WORK_COMPLETION_SUCCESS,
            "Notified Work Completion date successfully",
            "success"
          )
        )
      );
    }
  } catch (e) {
    const message = e.response.data;

    if (message.Message) {
      enqueueSnackbar(
        createNotification(
          SET_NOTIFY_WORK_STATUS_FAILURE,
          message.Message,
          message.MessageType
        )
      );
    } else {
      yield put(
        setNotifyWorkStatusFailure(
          `Something went wrong when trying to Notify Work ${action.isStart ? "Start" : "Completion"
          }`
        )
      );
    }
  }
}

export function* watchSetNotifyWorkStatusRequest() {
  yield takeLatest(SET_NOTIFY_WORK_STATUS_REQUEST, setNotifyWorkStatus);
}

const loadAuditLogsApi = (applicationId: number, sourceField: string) => {
  return axios.post(
    `${API_ROUTE}${APPLICATION_ENDPOINT}/applications/dateauditlogs`,
    {
      applicationId: applicationId,
      sourceField: sourceField
    }
  );
};

function* loadAuditLogs(action: LoadAuditLogsRequestAction) {
  try {
    const { data }: { data: AuditHistoryResponse } = yield call(
      loadAuditLogsApi,
      action.applicationId,
      action.sourceField
    );
    yield put(loadAuditLogsSuccess(data));
  } catch (e) {
    yield put(
      loadAuditLogsFailure(
        "Something went wrong when trying to load audit logs"
      )
    );
  }
}

export function* watchLoadAuditLogsRequest() {
  yield takeLatest(LOAD_AUDIT_LOGS_REQUEST, loadAuditLogs);
}

const removeParticipantApi = (participantLinkId: number) => {
  return axios.delete(
    `${API_ROUTE}${APPLICATION_ENDPOINT}/removeparticipant/${participantLinkId}`
  );
};

function* removeParticipant(action: RemoveParticipantRequestAction) {
  try {
    yield call(removeParticipantApi, action.participantLinkId);

    yield put(
      enqueueSnackbar(
        createNotification(
          REMOVE_PARTICIPANT_SUCCESS,
          "Participant successfully removed",
          "success"
        )
      )
    );
    yield put(removeParticipantSuccess(action.participantLinkId));
  } catch (e) {
    const message = e.response.data;

    if (message.Message) {
      yield put(
        enqueueSnackbar(
          createNotification(
            REMOVE_PARTICIPANT_FAILURE,
            message.Message,
            message.MessageType
          )
        )
      );
    } else {
      yield put(
        removeParticipantFailure(
          "Something went wrong when trying to remove participant"
        )
      );
    }
  }
}

export function* watchRemoveParticipantRequest() {
  yield takeLatest(REMOVE_PARTICIPANT_REQUEST, removeParticipant);
}

const setLocationApi = (
  locationInfo: LocationInformationModel,
  applicationId: number
) => {
  const wicket = new Wkt.Wkt();

  const locationModel = {
    ...locationInfo,
    features: featuresToWkt(wicket, locationInfo.features)
  };

  const model = {
    ...locationModel,
    applicationId: applicationId
  };
  return axios.post(`${API_ROUTE}${APPLICATION_ENDPOINT}/location`, model);
};

function* setLocation(action: SetLocationRequestAction) {
  try {
    yield call(setLocationApi, action.locationInfo, action.applicationId);

    yield put(setLocationSuccess(action.locationInfo));
    yield put(
      enqueueSnackbar(
        createNotification(
          SET_LOCATION_SUCCESS,
          "Location details updated successfully",
          "success"
        )
      )
    );
  } catch (e) {
    const message = e.response.data;

    if (message.Message) {
      yield put(
        enqueueSnackbar(
          createNotification(
            SET_LOCATION_FAILURE,
            message.Message,
            message.MessageType
          )
        )
      );
    } else {
      yield put(
        setLocationFailure(
          "Something went wrong when trying to update Location details"
        )
      );
    }
  }
}

export function* watchSetLocationRequest() {
  yield takeLatest(SET_LOCATION_REQUEST, setLocation);
}

const deleteAttachmentApi = (attachmentReference: string) => {
  return axios.delete(
    `${API_ROUTE}${MEDIA_ENDPOINT}/attachments/attachment/${attachmentReference}`
  );
};

function* deleteAttachment(action: DeleteAttachmentRequestAction) {
  try {
    yield call(deleteAttachmentApi, action.attachmentReference);

    yield put(
      enqueueSnackbar(
        createNotification(
          DELETE_ATTACHMENT_SUCCESS,
          "Successfully deleted the attachment",
          "success"
        )
      )
    );
    yield put(deleteAttachmentSuccess(action.attachmentReference));
  } catch (e) {
    const message = e.response.data;

    if (message.Message) {
      yield put(
        enqueueSnackbar(
          createNotification(
            DELETE_ATTACHMENT_FAILURE,
            message.Message,
            message.MessageType
          )
        )
      );
    } else {
      yield put(
        deleteAttachmentFailure(
          "Something went wrong when trying to delete the attachment"
        )
      );
    }
  }
}

export function* watchDeleteAttachmentRequest() {
  yield takeLatest(DELETE_ATTACHMENT_REQUEST, deleteAttachment);
}

const cancelApplicationApi = (applicationId: number) => {
  return axios.delete(`${API_ROUTE}${APPLICATION_ENDPOINT}/${applicationId}`);
};

function* cancelApplication(action: CancelApplicationRequestAction) {
  try {
    yield call(cancelApplicationApi, action.applicationId);

    yield put(
      enqueueSnackbar(
        createNotification(
          CANCEL_APPLICATION_SUCCESS,
          "Successfully cancelled the application",
          "success"
        )
      )
    );

    yield put(cancelApplicationSuccess());
  } catch (e) {
    const message = e.response.data;

    if (message.Message) {
      yield put(
        enqueueSnackbar(
          createNotification(
            CANCEL_APPLICATION_FAILURE,
            message.Message,
            message.MessageType
          )
        )
      );
    } else {
      yield put(
        cancelApplicationFailure(
          "Something went wrong when trying cancel the application"
        )
      );
    }
  }
}

export function* watchCancelApplicationRequest() {
  yield takeLatest(CANCEL_APPLICATION_REQUEST, cancelApplication);
}

const setMaintenanceCompletionApi = (model: MaintenanceCompletionRequest) => {
  return axios.post(
    `${API_ROUTE}${APPLICATION_ENDPOINT}/notifymaintenancecompletion`,
    model
  );
};

function* setMaintenanceCompletion(
  action: SetMaintenanceCompletionRequestAction
) {
  try {
    const { data }: { data: MaintenanceCompletionResponse } = yield call(
      setMaintenanceCompletionApi,
      action.model
    );

    yield put(setMaintenanceCompletionSuccess(data));

    yield put(
      enqueueSnackbar(
        createNotification(
          SET_MAINTENANCE_COMPLETION_SUCCESS,
          "Completion of Maintenance has been notified",
          "success"
        )
      )
    );
  } catch (e) {
    const message = e.response.data;

    if (message.Message) {
      yield put(
        enqueueSnackbar(
          createNotification(
            SET_MAINTENANCE_COMPLETION_FAILURE,
            message.Message,
            message.MessageType
          )
        )
      );
    } else {
      yield put(
        cancelApplicationFailure(
          "Something went wrong when trying to notify completion of maintenance"
        )
      );
    }
  }
}

export function* watchSetMaintenanceCompletionRequest() {
  yield takeLatest(
    SET_MAINTENANCE_COMPLETION_REQUEST,
    setMaintenanceCompletion
  );
}

const declineApplicationParticipantApi = (code: string) => {
  return axios.get(
    `${API_ROUTE}${APPLICATION_ENDPOINT}/declineparticipantrequest/${code}`
  );
};

function* declineApplicationParticipant(
  action: DeclineApplicationParticipantRequestAction
) {
  try {
    yield call(declineApplicationParticipantApi, action.code);
    yield put(declineApplicationParticipantSuccess(true));
  } catch (e) {
    const message = e.response.data;

    if (message.Message) {
      yield put(
        enqueueSnackbar(
          createNotification(
            DECLINE_APPLICATION_PARTICIPANT_FAILURE,
            message.Message,
            message.MessageType
          )
        )
      );
    } else {
      yield put(
        declineApplicationParticipantFailure(
          "Something went wrong when declining the participant request"
        )
      );
    }
  }
}

export function* watchDeclineApplicationParticipantRequest() {
  yield takeLatest(
    DECLINE_APPLICATION_PARTICIPANT_REQUEST,
    declineApplicationParticipant
  );
}

const readOnlyApplicationDetailApi = (guid: string) => {
  return axios.get(
    `${API_ROUTE}${APPLICATION_ENDPOINT}/readonlyapplication/${guid}`
  );
};
