import { Action } from "redux";
import { Lookup, SearchModel } from "store/types";
import { ErrorPayload } from "store/error/types";
import { Endpoint } from "common/endpoints";
import { UserLinkType, UserNotificationActionTypes } from "common/enums";

// Constants
export const GRANT_USER_ACCESS = "grantUserAccess";
export const REQUEST_SUPERVISOR_ACCESS = "requestSupervisorAccess";
export const REQUEST_ACCESS_TO_APPLICATION = "requestAccessToApplication";

// for existing accounts
export const LOAD_USER_DETAIL_REQUEST = "LOAD_USER_DETAIL_REQUEST";
export const LOAD_USER_DETAIL_FAILURE = "LOAD_USER_DETAIL_FAILURE";
export const LOAD_USER_DETAIL_SUCCESS = "LOAD_USER_DETAIL_SUCCESS";
export const LOAD_USER_LINKS_REQUEST = "LOAD_USER_LINKS_REQUEST";
export const LOAD_USER_LINKS_FAILURE = "LOAD_USER_LINKS_FAILURE";
export const LOAD_USER_LINKS_SUCCESS = "LOAD_USER_LINKS_SUCCESS";
export const RESET_ACCOUNT_MANAGEMENT = "RESET_ACCOUNT_MANAGEMENT";

// for user application access
export const SET_USER_LINK_REQUEST = "SET_USER_LINK_REQUEST";
export const SET_USER_LINK_SUCCESS = "SET_USER_LINK_SUCCESS";
export const SET_USER_LINK_FAILURE = "SET_USER_LINK_FAILURE";
export const DELETE_USER_LINK_REQUEST = "DELETE_USER_LINK_REQUEST";
export const DELETE_USER_LINK_SUCCESS = "DELETE_USER_LINK_SUCCESS";
export const DELETE_USER_LINK_FAILURE = "DELETE_USER_LINK_FAILURE";

export const RESET_CREATE_APPLICATION_NEW_USER_DATA =
  "RESET_CREATE_APPLICATION_NEW_USER_DATA";

// new account
export const SET_USER_DETAIL_REQUEST = "SET_USER_DETAIL_REQUEST";
export const SET_USER_DETAIL_SUCCESS = "SET_USER_DETAIL_SUCCESS";
export const SET_USER_DETAIL_FAILURE = "SET_USER_DETAIL_FAILURE";

// duplicate account management
export const LOAD_DUPLICATE_ACCOUNT_REQUEST = "LOAD_DUPLICATE_ACCOUNT_REQUEST";
export const LOAD_DUPLICATE_ACCOUNT_FAILURE = "LOAD_DUPLICATE_ACCOUNT_FAILURE";
export const LOAD_DUPLICATE_ACCOUNT_SUCCESS = "LOAD_DUPLICATE_ACCOUNT_SUCCESS";
export const SET_LINK_DUPLICATES_REQUEST = "SET_LINK_DUPLICATES_REQUEST";
export const SET_LINK_DUPLICATES_FAILURE = "SET_LINK_DUPLICATES_FAILURE";
export const SET_LINK_DUPLICATES_SUCCESS = "SET_LINK_DUPLICATES_SUCCESS";

// maintaining gst
export const SET_GST_NUMBER_REQUEST = "SET_GST_NUMBER_REQUEST";
export const SET_GST_NUMBER_FAILURE = "SET_GST_NUMBER_FAILURE";
export const SET_GST_NUMBER_SUCCESS = "SET_GST_NUMBER_SUCCESS";

//new organisations
export const LOAD_NEW_ORGANISATIONS_LOOKUP = "neworganisations";
export const LOAD_NEW_ORGANISATIONS_REQUEST = "LOAD_NEW_ORGANISATIONS_REQUEST";
export const LOAD_NEW_ORGANISATIONS_SUCCESS = "LOAD_NEW_ORGANISATIONS_SUCCESS";
export const LOAD_NEW_ORGANISATIONS_FAILURE = "LOAD_NEW_ORGANISATIONS_FAILURE";
export const RESET_NEW_ORGANISATIONS = "RESET_NEW_ORGANISATIONS";
export const RESET_AUTOFILL_ORGANISATION = "RESET_AUTOFILL_ORGANISATION";

export const SET_NEW_ORGANISATION_REQUEST = "SET_NEW_ORGANISATION_REQUEST";
export const SET_NEW_ORGANISATION_SUCCESS = "SET_NEW_ORGANISATION_SUCCESS";
export const SET_NEW_ORGANISATION_FAILURE = "SET_NEW_ORGANISATION_FAILURE";

export const LOAD_PARTICIPANT_ORGANISATION_REQUEST =
  "LOAD_PARTICIPANT_ORGANISATION_REQUEST";
export const LOAD_PARTICIPANT_ORGANISATION_SUCCESS =
  "LOAD_PARTICIPANT_ORGANISATION_SUCCESS";
export const LOAD_PARTICIPANT_ORGANISATION_FAILURE =
  "LOAD_PARTICIPANT_ORGANISATION_FAILURE";

//notification settings
export const SET_NOTIFICATION_SETTINGS_REQUEST =
  "SET_NOTIFICATION_SETTINGS_REQUEST";
export const SET_NOTIFICATION_SETTINGS_SUCCESS =
  "SET_NOTIFICATION_SETTINGS_SUCCESS";
export const SET_NOTIFICATION_SETTINGS_FAILURE =
  "SET_NOTIFICATION_SETTINGS_FAILURE";

//user notifications
export const LOAD_USER_NOTIFICATIONS_REQUEST =
  "LOAD_USER_NOTIFICATIONS_REQUEST";
export const LOAD_USER_NOTIFICATIONS_FAILURE =
  "LOAD_USER_NOTIFICATIONS_FAILURE";
export const LOAD_USER_NOTIFICATIONS_SUCCESS =
  "LOAD_USER_NOTIFICATIONS_SUCCESS";
export const RESET_USER_NOTIFICATIONS = "RESET_USER_NOTIFICATIONS";
export const LOAD_USER_NOTIFICATIONS_COUNT_REQUEST =
  "LOAD_USER_NOTIFICATIONS_COUNT_REQUEST";
export const LOAD_USER_NOTIFICATIONS_COUNT_SUCCESS =
  "LOAD_USER_NOTIFICATIONS_COUNT_SUCCESS";
export const LOAD_USER_NOTIFICATIONS_COUNT_FAILURE =
  "LOAD_USER_NOTIFICATIONS_COUNT_FAILURE";
export const SET_USER_NOTIFICATIONS_INACTIVE_REQUEST =
  "SET_USER_NOTIFICATIONS_INACTIVE_REQUEST";
export const SET_USER_NOTIFICATIONS_INACTIVE_SUCCESS =
  "SET_USER_NOTIFICATIONS_INACTIVE_SUCCESS";
export const SET_USER_NOTIFICATIONS_INACTIVE_FAILURE =
  "SET_USER_NOTIFICATIONS_INACTIVE_FAILURE";
export const SET_USER_NOTIFICATION_CONFIRM_DECLINE_REQUEST =
  "SET_USER_NOTIFICATION_CONFIRM_DECLINE_REQUEST";
export const SET_USER_NOTIFICATION_CONFIRM_DECLINE_SUCCESS =
  "SET_USER_NOTIFICATION_CONFIRM_DECLINE_SUCCESS";
export const SET_USER_NOTIFICATION_CONFIRM_DECLINE_FAILURE =
  "SET_USER_NOTIFICATION_CONFIRM_DECLINE_FAILURE";

//user regions
export const SET_USER_REGIONS_REQUEST = "SET_USER_REGIONS_REQUEST";
export const SET_USER_REGIONS_SUCCESS = "SET_USER_REGIONS_SUCCESS";
export const SET_USER_REGIONS_FAILURE = "SET_USER_REGIONS_FAILURE";
export const LOAD_USER_REGIONS_REQUEST = "LOAD_USER_REGIONS_REQUEST";
export const LOAD_USER_REGIONS_SUCCESS = "LOAD_USER_REGIONS_SUCCESS";
export const LOAD_USER_REGIONS_FAILURE = "LOAD_USER_REGIONS_FAILURE";

export const LOAD_PERMISSION_TO_VIEW_USER_REQUEST =
  "LOAD_PERMISSION_TO_VIEW_USER_REQUEST";
export const LOAD_PERMISSION_TO_VIEW_USER_SUCCESS =
  "LOAD_PERMISSION_TO_VIEW_USER_SUCCESS";
export const LOAD_PERMISSION_TO_VIEW_USER_FAILURE =
  "LOAD_PERMISSION_TO_VIEW_USER_FAILURE";

export const LOAD_SCALE_OF_WORK_QUESTIONNAIRE_REQUEST =
  "LOAD_SCALE_OF_WORK_QUESTIONNAIRE_REQUEST";
export const LOAD_SCALE_OF_WORK_QUESTIONNAIRE_SUCCESS =
  "LOAD_SCALE_OF_WORK_QUESTIONNAIRE_SUCCESS";
export const LOAD_SCALE_OF_WORK_QUESTIONNAIRE_FAILURE =
  "LOAD_SCALE_OF_WORK_QUESTIONNAIRE_FAILURE";
export const LOAD_SCALE_OF_WORK_QUESTIONNAIRE_RESET =
  "LOAD_SCALE_OF_WORK_QUESTIONNAIRE_RESET";

export const CONFIRM_USER_LINK_REQUEST = "CONFIRM_USER_LINK_REQUEST";
export const CONFIRM_USER_LINK_FAILURE = "CONFIRM_USER_LINK_FAILURE";
export const DECLINE_USER_LINK_REQUEST = "DECLINE_USER_LINK_REQUEST";
export const DECLINE_USER_LINK_FAILURE = "DECLINE_USER_LINK_FAILURE";
export const USER_LINK_SUCCESS = "USER_LINK_SUCCESS";
export const RESET_USER_LINK_PROCESSED = "RESET_USER_LINK_PROCESSED";

// success notification
export const RESET_SUCCESS_NOTIFICATION = "RESET_SUCCESS_NOTIFICATION";

export const GET_CONTACT_REQUEST = "GET_CONTACT_REQUEST";
export const GET_CONTACT_SUCCESS = "GET_CONTACT_SUCCESS";

// for existing accounts
// start {
export type LoadUserDetailRequestAction = Action<
  typeof LOAD_USER_DETAIL_REQUEST
> & {
  userId: number;
};

type LoadUserDetailFailureAction = Action<typeof LOAD_USER_DETAIL_FAILURE> &
  ErrorPayload;

type LoadUserDetailSuccessAction = Action<typeof LOAD_USER_DETAIL_SUCCESS> & {
  userDetail: UserDetail;
};

export type LoadUserLinksRequestAction = Action<
  typeof LOAD_USER_LINKS_REQUEST
> & {
  userId: number;
};

type LoadUserLinksFailureAction = Action<typeof LOAD_USER_LINKS_FAILURE> &
  ErrorPayload;

type LoadUserLinksSuccessAction = Action<typeof LOAD_USER_LINKS_SUCCESS> & {
  receivedData: UserAccount;
};

export type SetNotificationSettingsRequestAction = Action<
  typeof SET_NOTIFICATION_SETTINGS_REQUEST
> & {
  notificationSettings: NotificationSettings;
};

type SetNotificationSettingsSuccessAction = Action<
  typeof SET_NOTIFICATION_SETTINGS_SUCCESS
> & {
  notificationSettings: NotificationSettings;
};

type SetNotificationSettingsFailureAction = Action<
  typeof SET_NOTIFICATION_SETTINGS_FAILURE
> &
  ErrorPayload;

export type LoadNotificationsRequestAction = Action<
  typeof LOAD_USER_NOTIFICATIONS_REQUEST
> & {
  pageIndex: number;
};

type ResetAccountManagementAction = Action<typeof RESET_ACCOUNT_MANAGEMENT>;
// } end

// user application access
// start {
export type SetUserLinkRequestAction = Action<typeof SET_USER_LINK_REQUEST> & {
  user: Lookup;
  actionType: UserLinkButtonActionTypes;
  userLinkType: UserLinkType;
};

type SetUserLinkSuccessAction = Action<typeof SET_USER_LINK_SUCCESS> & {
  userLink: UserLinkDetail;
  actionType: UserLinkButtonActionTypes;
};

type SetUserLinkFailureAction = Action<typeof SET_USER_LINK_FAILURE> &
  ErrorPayload;

export type DeleteUserLinkRequestAction = Action<
  typeof DELETE_USER_LINK_REQUEST
> & {
  userLinkId: number;
};

type DeleteUserLinkFailureAction = Action<typeof DELETE_USER_LINK_FAILURE> &
  ErrorPayload;

type DeleteUserLinkSuccessAction = Action<typeof DELETE_USER_LINK_SUCCESS> & {
  userLinkId: number;
};

export type UserLinkButtonActionTypes =
  | typeof GRANT_USER_ACCESS
  | typeof REQUEST_SUPERVISOR_ACCESS
  | typeof REQUEST_ACCESS_TO_APPLICATION;

// } end

// creating new user/organisation records
// start {
export type SetUserDetailRequestAction = Action<
  typeof SET_USER_DETAIL_REQUEST
> & {
  newUserFormModel: UserDetail;
  parentId: string;
  isEditingUserAccount: boolean;
};

type SetUserDetailFailureAction = Action<typeof SET_USER_DETAIL_FAILURE> &
  ErrorPayload;

export type SetUserDetailSuccessAction = Action<
  typeof SET_USER_DETAIL_SUCCESS
> & {
  newUser: Lookup;
  parentId: string;
  isEditingUserAccount: boolean;
  userDetail?: UserDetail;
};

export type SetNewOrganisationRequestAction = Action<
  typeof SET_NEW_ORGANISATION_REQUEST
> & {
  newOrganisation: RegisteredOrganisationModel;
  parentId: string;
};

export type GetContactRequestAction = Action<
  typeof GET_CONTACT_REQUEST
> & {
  organisationId: number,
  rcaIds: number[],
  forDefaultBillPayer: boolean,
  userId?: number | null
};

type SetNewOrganisationSuccessAction = Action<
  typeof SET_NEW_ORGANISATION_SUCCESS
> & {
  newOrg: Lookup;
  parentId: string;
};

type SetNewOrganisationFailureAction = Action<
  typeof SET_NEW_ORGANISATION_FAILURE
> &
  ErrorPayload;

type GetContactSuccessAction = Action<
  typeof GET_CONTACT_SUCCESS
> & {
  contact: Lookup | null;
};

type ResetCreateApplicationNewUserDataAction = Action<
  typeof RESET_CREATE_APPLICATION_NEW_USER_DATA
>;

// maintain gst
export type SetGstNumberRequestAction = Action<
  typeof SET_GST_NUMBER_REQUEST
> & {
  gstNumber: string;
};

type SetGstNumberFailureAction = Action<typeof SET_GST_NUMBER_FAILURE> &
  ErrorPayload;

type SetGstNumberSuccessAction = Action<typeof SET_GST_NUMBER_SUCCESS> & {
  gstNumber: string;
};

// maintain user regions
export type SetUserRegionsRequestAction = Action<
  typeof SET_USER_REGIONS_REQUEST
> & {
  userRegions: UserRegion[];
};

type SetUserRegionsSuccessAction = Action<typeof SET_USER_REGIONS_SUCCESS> & {
  userRegions: UserRegion[];
};

type LoadUserRegionsSuccessAction = Action<typeof LOAD_USER_REGIONS_SUCCESS> & {
  userRegions: UserRegion[];
};

// duplicate account management
// start {
export type LoadDuplicateAccountRequestAction = Action<
  typeof LOAD_DUPLICATE_ACCOUNT_REQUEST
>;

type LoadDuplicateAccountFailureAction = Action<
  typeof LOAD_DUPLICATE_ACCOUNT_FAILURE
> &
  ErrorPayload;

type LoadDuplicateAccountSuccessAction = Action<
  typeof LOAD_DUPLICATE_ACCOUNT_SUCCESS
> & { duplicateAccounts: DuplicateAccountModel[] };

export type SetLinkDuplicatesRequestAction = Action<
  typeof SET_LINK_DUPLICATES_REQUEST
> & { selectedIds?: number[]; emailToLink?: string };

type SetLinkDuplicatesFailureAction = Action<
  typeof SET_LINK_DUPLICATES_FAILURE
> &
  ErrorPayload;

type SetLinkDuplicatesSuccessAction = Action<
  typeof SET_LINK_DUPLICATES_SUCCESS
> & { selectedIds?: number[]; emailToLink?: string };
// } end

type ResetSuccessNotificationAction = Action<
  typeof RESET_SUCCESS_NOTIFICATION
> & { key: string };

// new organisations

export type LoadNewOrganisationsRequestAction = Action<
  typeof LOAD_NEW_ORGANISATIONS_REQUEST
> & {
  endpoint: Endpoint;
  route: string;
  args?: string[] | SearchModel;
};

type LoadNewOrganisationsSuccessAction = Action<
  typeof LOAD_NEW_ORGANISATIONS_SUCCESS
> & {
  route: string;
  data: RegisteredOrganisationModel[];
};

type LoadNewOrganisationsFailureAction = Action<
  typeof LOAD_NEW_ORGANISATIONS_FAILURE
> &
  ErrorPayload;

export type LoadPermissionToViewUserRequestAction = Action<
  typeof LOAD_PERMISSION_TO_VIEW_USER_REQUEST
> & { userId: number };

type LoadPermissionToViewUserSuccessAction = Action<
  typeof LOAD_PERMISSION_TO_VIEW_USER_SUCCESS
> & { hasPermission: boolean };

type LoadPermissionToViewUserFailureAction = Action<
  typeof LOAD_PERMISSION_TO_VIEW_USER_FAILURE
> &
  ErrorPayload;

type ResetNewOrganisationsAction = Action<typeof RESET_NEW_ORGANISATIONS>;

type ResetAutofillOrganisation = Action<typeof RESET_AUTOFILL_ORGANISATION>;

export type LoadUserOrganisationRequestAction = Action<
  typeof LOAD_PARTICIPANT_ORGANISATION_REQUEST
> & {
  userId: number;
};

type LoadUserOrganisationSuccessAction = Action<
  typeof LOAD_PARTICIPANT_ORGANISATION_SUCCESS
> & {
  organisation: Lookup;
};

// user notifications
export type LoadUserNotificationsSuccessAction = Action<
  typeof LOAD_USER_NOTIFICATIONS_SUCCESS
> & {
  userNotificationPagination: UserNotificationPaginationResponse;
};

export type ResetUserNotificationsAction = Action<
  typeof RESET_USER_NOTIFICATIONS
>;

type LoadUserNotificationsCountSuccessAction = Action<
  typeof LOAD_USER_NOTIFICATIONS_COUNT_SUCCESS
> & {
  notificationCount: number;
};

export type SetUserNotificationInactiveRequestAction = Action<
  typeof SET_USER_NOTIFICATIONS_INACTIVE_REQUEST
> & {
  request: HideNotificationRequest;
};

type SetUserNotificationInactiveSuccessAction = Action<
  typeof SET_USER_NOTIFICATIONS_INACTIVE_SUCCESS
> & {
  request: HideNotificationRequest;
};

export type SetUserNotificationConfirmRequestAction = Action<
  typeof SET_USER_NOTIFICATION_CONFIRM_DECLINE_REQUEST
> & {
  notificationId: string;
  notificationActionType: UserNotificationActionTypes;
};

type SetUserNotificationConfirmDeclineSuccessAction = Action<
  typeof SET_USER_NOTIFICATION_CONFIRM_DECLINE_SUCCESS
> & {
  notificationId: string;
};

export type LoadScaleOfWorkQuestionnaireRequestAction = Action<
  typeof LOAD_SCALE_OF_WORK_QUESTIONNAIRE_REQUEST
> & {
  rcaId: number;
};

type LoadScaleOfWorkQuestionnaireSuccessAction = Action<
  typeof LOAD_SCALE_OF_WORK_QUESTIONNAIRE_SUCCESS
> & {
  questions: string[];
};

type LoadScaleOfWorkQuestionnaireResetAction = Action<
  typeof LOAD_SCALE_OF_WORK_QUESTIONNAIRE_RESET
>;

export type ConfirmUserLinkRequestAction = Action<
  typeof CONFIRM_USER_LINK_REQUEST
> & {
  code: string;
};

export type DeclineUserLinkRequestAction = Action<
  typeof DECLINE_USER_LINK_REQUEST
> & {
  code: string;
};

type UserLinkSuccessAction = Action<typeof USER_LINK_SUCCESS> & {
  userLinkAction: string;
};

type ResetUserLinkProcessedAction = Action<typeof RESET_USER_LINK_PROCESSED>;

export type AdministrationStateActionTypes =
  | LoadUserDetailRequestAction
  | LoadUserDetailFailureAction
  | LoadUserDetailSuccessAction
  | LoadUserLinksRequestAction
  | LoadUserLinksFailureAction
  | LoadUserLinksSuccessAction
  | ResetAccountManagementAction
  | SetUserLinkRequestAction
  | SetUserLinkSuccessAction
  | SetUserLinkFailureAction
  | SetNewOrganisationRequestAction
  | SetNewOrganisationSuccessAction
  | SetNewOrganisationFailureAction
  | GetContactRequestAction
  | GetContactSuccessAction
  | ResetCreateApplicationNewUserDataAction
  | LoadDuplicateAccountRequestAction
  | LoadDuplicateAccountFailureAction
  | LoadDuplicateAccountSuccessAction
  | SetLinkDuplicatesRequestAction
  | SetLinkDuplicatesFailureAction
  | SetLinkDuplicatesSuccessAction
  | ResetSuccessNotificationAction
  | SetGstNumberRequestAction
  | SetGstNumberFailureAction
  | SetGstNumberSuccessAction
  | LoadNewOrganisationsSuccessAction
  | LoadNewOrganisationsRequestAction
  | LoadNewOrganisationsFailureAction
  | ResetNewOrganisationsAction
  | SetUserDetailRequestAction
  | SetUserDetailSuccessAction
  | SetUserDetailFailureAction
  | SetNotificationSettingsRequestAction
  | SetNotificationSettingsSuccessAction
  | SetNotificationSettingsFailureAction
  | LoadNotificationsRequestAction
  | SetUserRegionsRequestAction
  | SetUserRegionsSuccessAction
  | DeleteUserLinkRequestAction
  | DeleteUserLinkSuccessAction
  | DeleteUserLinkFailureAction
  | LoadPermissionToViewUserRequestAction
  | LoadPermissionToViewUserSuccessAction
  | LoadPermissionToViewUserFailureAction
  | LoadUserNotificationsSuccessAction
  | ResetUserNotificationsAction
  | LoadUserNotificationsCountSuccessAction
  | SetUserNotificationInactiveSuccessAction
  | SetUserNotificationConfirmDeclineSuccessAction
  | LoadScaleOfWorkQuestionnaireSuccessAction
  | LoadScaleOfWorkQuestionnaireResetAction
  | LoadUserRegionsSuccessAction
  | LoadUserOrganisationSuccessAction
  | ResetAutofillOrganisation
  | UserLinkSuccessAction
  | ResetUserLinkProcessedAction;

export type UserAccount = {
  supervisors: UserLinkDetail[];
  authorisedParents: UserLinkDetail[];
  supervisorOf: UserLinkDetail[];
  authorisedChildren: UserLinkDetail[];
  userDetail: UserDetail;
};

export type UserLinkDetail = {
  userLinkId: number;
  linkedUserId: number;
  userName: string;
  organisationName: string;
  isConfirmed: boolean;
  userLinkTypeId: number;
};

export type UserDetail = User & {
  userId?: number;
  mobilePhone: string;
  workPhone: string;
  fax: string;
  jobPosition: string;
  address: Lookup;
  isTrafficManagementCoordinator: boolean;
  participantCertificateType?: Lookup;
  certificationNumber?: string;
  placeOfIssue?: string;
  dateOfIssue?: Date;
  dateOfExpiry?: Date;
  userRegions?: UserRegion[];
  notificationSettings?: NotificationSettings;
  gstNumber?: string;
  profileUpdateRequired?: boolean;
  privateOrganisation?: Lookup;
  isOrganisationManager?: boolean;
};

export type User = {
  firstName: string;
  lastName: string;
  organisation: Lookup;
  email: string;
};

export type DuplicateAccountModel = {
  userId: number;
  displayName: string;
  email: string;
  addedOn: Date;
  addedBy: string;
};

export type RegisteredOrganisationModel = {
  organisationTypeId?: number;
  gstNumber: string;
  state: string;

  nzbn: string;
  companyId: string;
  companyType: string;
  companyStatus: string;
  companyFormerName: string;

  organisationId: number;
  name: string;
  categoryId?: number;
};

export type NewEntity = {
  data: Lookup;
  parentControl: string;
};

export type UserRegion = {
  userAreaLinkId?: number;
  isDefault: boolean;
  region: Lookup;
  area: Lookup;
};

export type NotificationSettings = {
  sendNotificationEmails: boolean;
  sendDowntimeEmails: boolean;
  notificationSummaryType: Lookup;
  organisationWhitelist: Lookup[];
};

export type UserNotificationItem = {
  notificationId: string;
  description: string;
  canDecline: boolean;
  notificationTypeId: number;
  showDeclineButton?: boolean;
  showConfirmButton?: boolean;
};

export type UserNotificationPaginationResponse = {
  hasPrevious: boolean;
  hasNext: boolean;
  notifications: UserNotifications[];
}

export type UserNotifications = {
  date: Date;
  notificationItems: UserNotificationItem[];
};

export type HideNotificationRequest = {
  notificationIds: string[];
  date: Date;
};

//This part was modified for S1 confirm/decline link backwards compatibility. commitID: 3a282eca3764b0c04ace9d3d89041299038b9cd0
export type AdministrationState = {
  newUserData: UserDetail;
  newOrganisations: RegisteredOrganisationModel[];
  userAccount: UserAccount;
  duplicateAccountData: DuplicateAccountModel[];
  selectedDuplicateIds: number[];
  lastAddedEntity?: NewEntity;
  hasPermissionToViewUser: boolean;
  notifications: UserNotifications[];
  notificationCount: number;
  scaleOfWorkQuestionnaire: string[];
  participantOrganisation: Lookup;
  defaultContact?: Lookup | null;
  userLinkActionResponse?: string;
  hasNext: boolean;
  hasPrevious: boolean;
};
