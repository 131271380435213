import { Provider } from "react-redux";
import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import store from "store";

type ProviderWrapperProps = {
  children: JSX.Element;
  store: typeof store;
};

const ProviderWrapper = (props: ProviderWrapperProps) => (
  <Provider store={props.store}>
    <CssBaseline />
    {props.children}
  </Provider>
);

export default ProviderWrapper;
