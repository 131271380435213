import moment from "moment";

export const ToStartOfDay = (date: Date | undefined) => {
  const output: Date = date
    ? moment(date)
        .startOf("day")
        .toDate()
    : moment()
        .startOf("day")
        .toDate();
  return output;
};

export const ToEndOfDay = (date: Date) => {
  return moment(date)
    .endOf("day")
    .toDate();
};

export const GetOffsetUtc = () => moment().utcOffset() + 60;

export const GetOffset = (date: Date) => moment(date).utcOffset();
