import produce from "immer";
import { Action } from "redux";
import { LoadingState } from "./types";

const initialState: LoadingState = new Set();

const loadingReducer = produce(
  (draft: LoadingState, action: Action<string>) => {
    const matches = /.*_(REQUEST|SUCCESS|FAILURE)/.exec(action.type);

    if (!matches) {
      return draft;
    }

    const requestName = action.type.replace(/_(SUCCESS|FAILURE)/, "_REQUEST");
    const [, requestState] = matches;

    requestState === "REQUEST"
      ? draft.add(requestName)
      : draft.delete(requestName);
  },
  initialState
);

export default loadingReducer;
