import React, { useState } from "react";
import moment from "moment";
import {
  Dialog,
  DialogTitle,
  IconButton,
  Typography,
  DialogContent
} from "@material-ui/core";
import SubmiticaTooltip from "./SubmiticaTooltip";
import CloseIcon from "@material-ui/icons/Close";
import SubmiticaLogo from "assets/img/submitica-s.png";
import AppleShareIcon from "assets/img/apple_share_icon.png";
import Spacer from "./Spacer";

const isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent);
};

const isInStandaloneMode = () => {
  return window.matchMedia("(display-mode: standalone)").matches;
};

const hasVisitedPreviously = () => {
  return localStorage.getItem("lastVisit") !== null;
};

const showModalBasedOnDate = () => {
  if (hasVisitedPreviously()) {
    // get last visit
    const prevVisit = localStorage.getItem("lastVisit");
    // set now as last visit
    localStorage.setItem("lastVisit", moment().toISOString());
    //compare if it is now a day later to last visit
    return moment().isAfter(moment(prevVisit!), "day");
  } else {
    localStorage.setItem("lastVisit", moment().toISOString());
    return true;
  }
};

const AddToHomescreen = () => {
  const [isOpen, setIsOpen] = useState(
    isIos() && !isInStandaloneMode() && showModalBasedOnDate()
  ); // put logic for viewing in here

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>
        <div style={{ justifyContent: "center", display: "flex" }}>
          <img style={{ height: 50, width: 50 }} src={SubmiticaLogo} />
        </div>
        <div style={{ position: "absolute", top: "4px", right: "4px" }}>
          <SubmiticaTooltip placement="bottom" title="Close">
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </SubmiticaTooltip>
        </div>
        <Spacer />
        <Typography display="inline" variant="h5">
          Install Submitica
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Install this application on your home screen for quick and easy acess
          on mobile
        </Typography>

        <Spacer />

        <Typography display="inline" variant="body2">
          Tap{" "}
          <img
            style={{ verticalAlign: "bottom", padding: "4px" }}
            src={AppleShareIcon}
          />{" "}
          then 'Add to Home Screen' to get started.
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default AddToHomescreen;
