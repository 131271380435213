import {
  ENQUEUE_SNACKBAR,
  REMOVE_SNACKBAR,
  NotistackNotification,
  REMOVE_ALL_SNACKBAR,
  DELETE_SNACKBAR
} from "./types";

export const enqueueSnackbar = (notification: NotistackNotification) => {
  return {
    type: ENQUEUE_SNACKBAR,
    notification: notification
  };
};

export const removeSnackbar = (key: string) => ({
  type: REMOVE_SNACKBAR,
  key: key
});

export const deleteSnackbar = (key: string) => ({
  type: DELETE_SNACKBAR,
  key: key
});

export const removeAllSnackbar = () => ({
  type: REMOVE_ALL_SNACKBAR
});
