import {
  LOAD_TTM_SITES_REQUEST,
  LoadTTMSitesRequestAction,
  SET_TTM_SITE_DB_REQUEST,
  SetTTMSiteDbRequestAction,
  TTMSite,
  SET_TTM_DETOUR_DB_REQUEST,
  SetTTMDetourDbRequestAction,
  TTMDetour,
  TTMClosure,
  SET_TTM_CLOSURE_DB_REQUEST,
  SetTTMClosureDbRequestAction,
  DELETE_TTM_SITE_DB_REQUEST,
  DeleteTTMSiteDbRequestAction,
  DELETE_TTM_SITE_DB_SUCCESS,
  DeleteTTMDetourDbRequestAction,
  DELETE_TTM_DETOUR_DB_SUCCESS,
  DELETE_TTM_DETOUR_DB_REQUEST,
  DELETE_TTM_CLOSURE_DB_REQUEST,
  DELETE_TTM_CLOSURE_DB_SUCCESS,
  DeleteTTMClosureDbRequestAction
} from "./types";
import {
  loadTTMSitesSuccess,
  loadTTMSitesFailure,
  setTTMSiteDbSuccess,
  setTTMSiteDbFailure,
  setTTMDetourDbFailure,
  setTTMDetourDbSuccess,
  setTTMClosureDbSuccess,
  setTTMClosureDbFailure,
  deleteTTMSiteDbFailure,
  deleteTTMSiteDbSuccess,
  deleteTTMDetourDbFailure,
  deleteTTMDetourDbSuccess,
  deleteTTMClosureDbFailure,
  deleteTTMClosureDbSuccess
} from "./actions";
import { put, takeLatest, call } from "redux-saga/effects";
import axios from "axios";
import { API_ROUTE } from "common/constants";
import { TTM_ENDPOINT, APPLICATION_ENDPOINT } from "common/endpoints";
import { enqueueSnackbar } from "store/notistack/actions";
import { createNotification } from "common/addNotification";
import featuresToWkt from "common/featuresToWkt";
import Wkt from "wicket";

const getTTMSites = (applicationId: number) => {
  return axios.get(
    `${API_ROUTE}${TTM_ENDPOINT}/sites/application/${applicationId}`
  );
};

function* loadTTMSitesRequest(action: LoadTTMSitesRequestAction) {
  try {
    let ttmSiteDetails: TTMSite[] | null = null;

    if (action.guid) {
      const { data }: { data: TTMSite[] } = yield call(
        readOnlyTtmDetailsApi,
        action.guid
      );
      ttmSiteDetails = data;
    } else {
      const { data }: { data: TTMSite[] } = yield call(
        getTTMSites,
        action.applicationId!
      );
      ttmSiteDetails = data;
    }

    if (ttmSiteDetails) {
      yield put(loadTTMSitesSuccess(ttmSiteDetails));
    } else {
      yield put(
        loadTTMSitesFailure(
          `No TTM Sites were found for CAR ID: ${action.applicationId}`
        )
      );
    }
  } catch (e) {
    yield put(
      loadTTMSitesFailure("Something went wrong when trying to fetch TTM data")
    );
  }
}

export default function* watchLoadTTMSitesRequest() {
  yield takeLatest(LOAD_TTM_SITES_REQUEST, loadTTMSitesRequest);
}

const setTTMSiteApiCall = (
  newSite: TTMSite,
  applicationId: number,
  isEditing: boolean
) => {
  const wicket = new Wkt.Wkt();

  const model = {
    applicationId: applicationId,
    isEditing: isEditing,
    site: {
      siteId: newSite.siteId,
      fromStreetNumber: newSite.fromStreetNumber,
      toStreetNumber: newSite.toStreetNumber,
      streetAddress: newSite.streetAddress,
      roadClassId: newSite.roadClass.id,
      roadLevelId: newSite.roadLevel.id,
      ttmTypeId: newSite.ttmType.id,
      ttmTypeExplanation: newSite.ttmTypeExplanation,
      ttmImpactId: newSite.ttmImpact.id,
      ttmImpactExplanation: newSite.ttmImpactExplanation,
      workActivityId: newSite.workActivity.id,
      workActivityExplanation: newSite.workActivityExplanation,
      speedLimitId: newSite.speedLimit.id === 0 ? null : newSite.speedLimit.id,
      affectedAccessIds: newSite.affectedAccess.map(y => y.id),
      location: newSite.location,
      affectedAreaDescription: newSite.affectedAreaDescription,
      isTrafficDelayLikely: newSite.isTrafficDelayLikely,
      isPublicNotificationRequired: newSite.isPublicNotificationRequired,
      notes: newSite.notes,
      shapes: featuresToWkt(wicket, newSite.features),
      detours: newSite.detours.map(y => {
        return { description: y.description, notes: y.notes };
      }),
      closures: newSite.closures.map(y => {
        return {
          proposedStart: y.proposedStart,
          proposedEnd: y.proposedEnd,
          actualStart: y.actualStart,
          actualEnd: y.actualEnd,
          notes: y.notes
        };
      })
    }
  };

  return axios.post(`${API_ROUTE}${APPLICATION_ENDPOINT}/site/add`, model);
};

function* setTTMSiteRequest(action: SetTTMSiteDbRequestAction) {
  try {
    const { data }: { data: number } = yield setTTMSiteApiCall(
      action.newSite,
      action.applicationId,
      action.isEditing
    );

    action.newSite.siteId = data;

    yield put(
      enqueueSnackbar(
        createNotification(
          action.isEditing ? "editSite" : "addSite",
          `Successfully ${
            action.isEditing ? "edited the" : "added a new"
          } Traffic Management Site`,
          "success"
        )
      )
    );

    yield put(setTTMSiteDbSuccess(action.newSite, action.isEditing));
  } catch (e) {
    yield put(
      setTTMSiteDbFailure(
        "Something went wrong when trying to save the Traffic Management Site"
      )
    );
  }
}

export function* watchSetTTMSiteDbRequest() {
  yield takeLatest(SET_TTM_SITE_DB_REQUEST, setTTMSiteRequest);
}

const setTTMDetourApiCall = (newDetour: TTMDetour, isEditing: boolean) => {
  const wicket = new Wkt.Wkt();

  const model = {
    siteId: newDetour.siteId,
    isEditing: isEditing,
    detour: {
      detourId: newDetour.detourId,
      description: newDetour.description,
      notes: newDetour.notes,
      shapes: featuresToWkt(wicket, newDetour.features)
    }
  };

  return axios.post(`${API_ROUTE}${TTM_ENDPOINT}/detours/add`, model);
};

function* setTTMDetourRequest(action: SetTTMDetourDbRequestAction) {
  try {
    const { data }: { data: number } = yield setTTMDetourApiCall(
      action.newDetour,
      action.isEditing
    );

    action.newDetour.detourId = data;

    yield put(
      enqueueSnackbar(
        createNotification(
          action.isEditing ? "editDetour" : "addDetour",
          `Successfully ${
            action.isEditing ? "edited the" : "added a new"
          } Traffic Management Detour`,
          "success"
        )
      )
    );

    yield put(setTTMDetourDbSuccess(action.newDetour, action.isEditing));
  } catch (e) {
    yield put(
      setTTMDetourDbFailure(
        "Something went wrong when trying to save the Traffic Management Detour"
      )
    );
  }
}

export function* watchSetTTMDetourDbRequest() {
  yield takeLatest(SET_TTM_DETOUR_DB_REQUEST, setTTMDetourRequest);
}

const setTTMClosureApiCall = (newClosure: TTMClosure, isEditing: boolean) => {
  const model = {
    siteId: newClosure.siteId,
    isEditing: isEditing,
    closure: {
      closureId: newClosure.closureId,
      proposedStart: newClosure.proposedStart,
      proposedEnd: newClosure.proposedEnd,
      actualStart: newClosure.actualStart,
      actualEnd: newClosure.actualEnd,
      notes: newClosure.notes
    }
  };

  return axios.post(`${API_ROUTE}${TTM_ENDPOINT}/closures/add`, model);
};

function* setTTMClosureRequest(action: SetTTMClosureDbRequestAction) {
  try {
    const { data }: { data: number } = yield setTTMClosureApiCall(
      action.newClosure,
      action.isEditing
    );

    action.newClosure.closureId = data;

    yield put(
      enqueueSnackbar(
        createNotification(
          action.isEditing ? "editClosure" : "addClosure",
          `Successfully ${
            action.isEditing ? "edited the" : "added a new"
          } Traffic Management Closure`,
          "success"
        )
      )
    );

    yield put(setTTMClosureDbSuccess(action.newClosure, action.isEditing));
  } catch (e) {
    yield put(
      setTTMClosureDbFailure(
        "Something went wrong when trying to save the Traffic Management Closure"
      )
    );
  }
}

export function* watchSetTTMClosureDbRequest() {
  yield takeLatest(SET_TTM_CLOSURE_DB_REQUEST, setTTMClosureRequest);
}

const deleteTTMSiteApi = (siteId: number) => {
  return axios.delete(`${API_ROUTE}${TTM_ENDPOINT}/sites/${siteId}`);
};

function* deleteTTMSite(action: DeleteTTMSiteDbRequestAction) {
  try {
    yield call(deleteTTMSiteApi, action.siteId);

    yield put(deleteTTMSiteDbSuccess(action.siteId));
    yield put(
      enqueueSnackbar(
        createNotification(
          DELETE_TTM_SITE_DB_SUCCESS,
          "Successfully deleted the TTM Site",
          "success"
        )
      )
    );
  } catch (e) {
    yield put(
      deleteTTMSiteDbFailure(
        "Something went wrong when trying to delete the Traffic Management Site"
      )
    );
  }
}

export function* watchDeleteTTMSiteDbRequest() {
  yield takeLatest(DELETE_TTM_SITE_DB_REQUEST, deleteTTMSite);
}

const deleteTTMDetourApi = (detourId: number) => {
  return axios.delete(`${API_ROUTE}${TTM_ENDPOINT}/detours/${detourId}`);
};

function* deleteTTMDetour(action: DeleteTTMDetourDbRequestAction) {
  try {
    yield call(deleteTTMDetourApi, action.detourId);

    yield put(deleteTTMDetourDbSuccess(action.siteId, action.detourId));
    yield put(
      enqueueSnackbar(
        createNotification(
          DELETE_TTM_DETOUR_DB_SUCCESS,
          "Successfully deleted the TTM Detour",
          "success"
        )
      )
    );
  } catch (e) {
    yield put(
      deleteTTMDetourDbFailure(
        "Something went wrong when trying to delete the Traffic Management Detour"
      )
    );
  }
}

export function* watchDeleteTTMDetourDbRequest() {
  yield takeLatest(DELETE_TTM_DETOUR_DB_REQUEST, deleteTTMDetour);
}

const deleteTTMClosureApi = (closureId: number) => {
  return axios.delete(`${API_ROUTE}${TTM_ENDPOINT}/closures/${closureId}`);
};

function* deleteTTMClosure(action: DeleteTTMClosureDbRequestAction) {
  try {
    yield call(deleteTTMClosureApi, action.closureId);

    yield put(deleteTTMClosureDbSuccess(action.siteId, action.closureId));
    yield put(
      enqueueSnackbar(
        createNotification(
          DELETE_TTM_CLOSURE_DB_SUCCESS,
          "Successfully deleted the TTM Closure",
          "success"
        )
      )
    );
  } catch (e) {
    yield put(
      deleteTTMClosureDbFailure(
        "Something went wrong when trying to delete the Traffic Management Closure"
      )
    );
  }
}

export function* watchDeleteTTMClosureDbRequest() {
  yield takeLatest(DELETE_TTM_CLOSURE_DB_REQUEST, deleteTTMClosure);
}

const readOnlyTtmDetailsApi = (guid: string) => {
  return axios.get(
    `${API_ROUTE}${APPLICATION_ENDPOINT}/readonlyttmdetails/${guid}`
  );
};
