import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import React from "react";
import { SubmiticaGreen } from "styles/colours";

type SubmiticaOutageProps = {
  outageMessage: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    outageContainer: {
      backgroundColor: SubmiticaGreen,
      height: "100vh"
    },
    outageDiv: {
      padding: "30px",
      alignItems: "center",
      display: "flex"
    }
  })
);

const SubmiticaOutage = (props: SubmiticaOutageProps) => {
  const isScreenSmall = useMediaQuery(useTheme().breakpoints.down("sm"));

  const classes = useStyles();

  return (
    <Grid container className={classes.outageContainer}>
      <Grid item xs={12} md={6} className={classes.outageDiv}>
        <Grid>
          <Typography
            variant={isScreenSmall ? "h4" : "h2"}
            style={{ clear: "both" }}
          >
            Sorry
          </Typography>
          <Typography variant={isScreenSmall ? "h5" : "h3"}>
            {props.outageMessage}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SubmiticaOutage;
