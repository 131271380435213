import {
  LOAD_SINGLE_APPLICATION_REQUEST,
  ApplicationDetail,
  LOAD_SINGLE_APPLICATION_SUCCESS,
  LOAD_SINGLE_APPLICATION_FAILURE,
  RESET_SINGLE_APPLICATION,
  UPLOAD_DETAIL_FILE_REQUEST,
  UPLOAD_DETAIL_FILE_SUCCESS,
  UPLOAD_DETAIL_FILE_FAILURE,
  NewAttachment,
  Attachment,
  SET_APPLICATION_NOTES_REQUEST,
  SET_APPLICATION_NOTES_SUCCESS,
  SET_APPLICATION_NOTES_FAILURE,
  SET_WORK_INFORMATION_REQUEST,
  SET_WORK_INFORMATION_SUCCESS,
  SET_WORK_INFORMATION_FAILURE,
  DOWNLOAD_CALLOUT_REPORT_REQUEST,
  DOWNLOAD_CALLOUT_REPORT_FAILURE,
  Inspection,
  DOWNLOAD_INSPECTION_REPORT_SUCCESS,
  Defect,
  DOWNLOAD_DEFECT_REPORT_SUCCESS,
  SET_PARTICIPANT_REQUEST,
  Participant,
  SET_PARTICIPANT_SUCCESS,
  SET_PARTICIPANT_FAILURE,
  SET_NOTIFY_WORK_STATUS_REQUEST,
  SET_NOTIFY_WORK_START_SUCCESS,
  SET_NOTIFY_WORK_STATUS_FAILURE,
  NotifyWorkStartResponse,
  SET_APPLICATION_FEEDBACK_REQUEST,
  SET_APPLICATION_FEEDBACK_SUCCESS,
  SET_APPLICATION_FEEDBACK_FAILURE,
  LOAD_EXISTING_APPLICATION_FEEDBACK_REQUEST,
  LOAD_EXISTING_APPLICATION_FEEDBACK_SUCCESS,
  LOAD_EXISTING_APPLICATION_FEEDBACK_FAILURE,
  ApplicationFeedback,
  REPLACE_PARTICIPANT_REQUEST,
  REPLACE_PARTICIPANT_SUCCESS,
  REPLACE_PARTICIPANT_FAILURE,
  ReplaceParticipant,
  LOAD_AUDIT_LOGS_REQUEST,
  LOAD_AUDIT_LOGS_FAILURE,
  AuditHistoryResponse,
  LOAD_AUDIT_LOGS_SUCCESS,
  RESET_AUDIT_LOGS,
  SET_NOTIFY_WORK_COMPLETION_SUCCESS,
  NotifyWorkCompletionResponse,
  REMOVE_PARTICIPANT_REQUEST,
  REMOVE_PARTICIPANT_SUCCESS,
  REMOVE_PARTICIPANT_FAILURE,
  SET_LOCATION_REQUEST,
  SET_LOCATION_SUCCESS,
  SET_LOCATION_FAILURE,
  DELETE_ATTACHMENT_REQUEST,
  DELETE_ATTACHMENT_FAILURE,
  DELETE_ATTACHMENT_SUCCESS,
  LocationInformationModel,
  CANCEL_APPLICATION_REQUEST,
  CANCEL_APPLICATION_FAILURE,
  CANCEL_APPLICATION_SUCCESS,
  MaintenanceCompletionRequest,
  SET_MAINTENANCE_COMPLETION_REQUEST,
  SET_MAINTENANCE_COMPLETION_SUCCESS,
  SET_MAINTENANCE_COMPLETION_FAILURE,
  MaintenanceCompletionResponse,
  ReplaceParticipantResponse,
  DECLINE_APPLICATION_PARTICIPANT_REQUEST,
  DECLINE_APPLICATION_PARTICIPANT_FAILURE,
  DECLINE_APPLICATION_PARTICIPANT_SUCCESS
} from "./types";
import { WorkInformationModel } from "containers/ApplicationDetail/EditWorkInformationModal";
import { CalloutType } from "common/enums";
import { AddParticipant } from "store/types";

export const loadSingleApplicationRequest = (
  carId?: number,
  guid?: string
) => ({
  type: LOAD_SINGLE_APPLICATION_REQUEST,
  carId: carId,
  guid: guid
});

export const loadSingleApplicationSuccess = (payload: ApplicationDetail) => ({
  type: LOAD_SINGLE_APPLICATION_SUCCESS,
  applicationDetailData: payload
});

export const loadSingleApplicationFailure = (errorMessage: string) => ({
  type: LOAD_SINGLE_APPLICATION_FAILURE,
  errorMessage: errorMessage
});

export const resetSingleApplication = () => ({
  type: RESET_SINGLE_APPLICATION
});

export const uploadFileRequest = (
  applicationId: number,
  carStepId: number,
  attachments: NewAttachment[],
  permissionValue: number,
  childApplicationIds: number[],
  reference?: string
) => ({
  type: UPLOAD_DETAIL_FILE_REQUEST,
  applicationId: applicationId,
  attachments: attachments,
  carStepId: carStepId,
  permissionValue: permissionValue,
  childApplicationIds: childApplicationIds,
  reference
});

export const uploadFileSuccess = (attachments: Attachment[]) => ({
  type: UPLOAD_DETAIL_FILE_SUCCESS,
  attachments: attachments
});

export const uploadFileFailure = (errorMessage: string) => ({
  type: UPLOAD_DETAIL_FILE_FAILURE,
  errorMessage: errorMessage
});

export const setApplicationNotesRequest = (
  notes: string,
  applicationId: number
) => ({
  type: SET_APPLICATION_NOTES_REQUEST,
  notes: notes,
  applicationId: applicationId
});

export const setApplicationNotesSuccess = (notes: string) => ({
  type: SET_APPLICATION_NOTES_SUCCESS,
  notes: notes
});

export const setApplicationNotesFailure = (errorMessage: string) => ({
  type: SET_APPLICATION_NOTES_FAILURE,
  errorMessage: errorMessage
});

export const setWorkInformationRequest = (
  workInfo: WorkInformationModel,
  applicationId: number
) => ({
  type: SET_WORK_INFORMATION_REQUEST,
  workInfo: workInfo,
  applicationId: applicationId
});

export const setWorkInformationSuccess = (workInfo: WorkInformationModel) => ({
  type: SET_WORK_INFORMATION_SUCCESS,
  workInfo: workInfo
});

export const setWorkInformationFailure = (errorMessage: string) => ({
  type: SET_WORK_INFORMATION_FAILURE,
  errorMessage: errorMessage
});

export const setApplicationFeedbackRequest = (
  applicationId: number,
  rating: number,
  notes: string
) => ({
  type: SET_APPLICATION_FEEDBACK_REQUEST,
  applicationId: applicationId,
  rating: rating,
  notes: notes
});

export const setApplicationFeedbackSuccess = (
  feedback: ApplicationFeedback
) => ({
  type: SET_APPLICATION_FEEDBACK_SUCCESS,
  feedback: feedback
});

export const setApplicationFeedbackFailure = (errorMessage: string) => ({
  type: SET_APPLICATION_FEEDBACK_FAILURE,
  errorMessage: errorMessage
});

export const loadExistingApplicationFeedbackRequest = (
  applicationId: number
) => ({
  type: LOAD_EXISTING_APPLICATION_FEEDBACK_REQUEST,
  applicationId: applicationId
});

export const loadExistingApplicationFeedbackSuccess = (
  feedback: ApplicationFeedback
) => ({
  type: LOAD_EXISTING_APPLICATION_FEEDBACK_SUCCESS,
  feedback: feedback
});

export const loadExistingApplicationFeedbackFailure = (
  errorMessage: string
) => ({
  type: LOAD_EXISTING_APPLICATION_FEEDBACK_FAILURE,
  errorMessage: errorMessage
});
export const downloadCalloutReportRequest = (
  calloutId: number,
  calloutType: CalloutType
) => ({
  type: DOWNLOAD_CALLOUT_REPORT_REQUEST,
  calloutId: calloutId,
  calloutType: calloutType
});

export const downloadInspectionReportSuccess = (inspection: Inspection) => ({
  type: DOWNLOAD_INSPECTION_REPORT_SUCCESS,
  inspection: inspection
});

export const downloadDefectReportSuccess = (defect: Defect) => ({
  type: DOWNLOAD_DEFECT_REPORT_SUCCESS,
  defect: defect
});

export const downloadCalloutReportFailure = (errorMessage: string) => ({
  type: DOWNLOAD_CALLOUT_REPORT_FAILURE,
  errorMessage: errorMessage
});

export const setParticipantRequest = (
  participant: AddParticipant,
  carId: number,
  rcaId: number,
  reference?: string
) => ({
  type: SET_PARTICIPANT_REQUEST,
  participant: participant,
  carId: carId,
  rcaId: rcaId,
  reference: reference
});

export const setParticipantSuccess = (participant: Participant) => ({
  type: SET_PARTICIPANT_SUCCESS,
  participant: participant
});

export const setParticipantFailure = (errorMessage: string) => ({
  type: SET_PARTICIPANT_FAILURE,
  errorMessage: errorMessage
});

export const setNotifyWorkStatusRequest = (
  applicationId: number,
  newDate: Date,
  isStart: boolean,
  reference?: string,
  offset?: number
) => ({
  type: SET_NOTIFY_WORK_STATUS_REQUEST,
  applicationId: applicationId,
  newDate: newDate,
  isStart: isStart,
  reference: reference,
  offset: offset
});

export const setNotifyWorkStartSuccess = (
  updatedInfo: NotifyWorkStartResponse
) => ({
  type: SET_NOTIFY_WORK_START_SUCCESS,
  updatedInfo: updatedInfo
});

export const setNotifyWorkCompletionSuccess = (
  updatedInfo: NotifyWorkCompletionResponse
) => ({
  type: SET_NOTIFY_WORK_COMPLETION_SUCCESS,
  updatedInfo: updatedInfo
});

export const setNotifyWorkStatusFailure = (errorMessage: string) => ({
  type: SET_NOTIFY_WORK_STATUS_FAILURE,
  errorMessage: errorMessage
});

export const replaceParticipantRequest = (
  participant: ReplaceParticipant,
  carId: number,
  rcaId: number
) => ({
  type: REPLACE_PARTICIPANT_REQUEST,
  participant: participant,
  carId: carId,
  rcaId: rcaId
});

export const replaceParticipantSuccess = (
  participantResponse: ReplaceParticipantResponse
) => ({
  type: REPLACE_PARTICIPANT_SUCCESS,
  participantResponse: participantResponse
});

export const replaceParticipantFailure = (errorMessage: string) => ({
  type: REPLACE_PARTICIPANT_FAILURE,
  errorMessage: errorMessage
});

export const loadAuditLogsRequest = (
  applicationId: number,
  sourceField: string
) => ({
  type: LOAD_AUDIT_LOGS_REQUEST,
  applicationId: applicationId,
  sourceField: sourceField
});

export const loadAuditLogsSuccess = (auditLog: AuditHistoryResponse) => ({
  type: LOAD_AUDIT_LOGS_SUCCESS,
  auditLog: auditLog
});

export const loadAuditLogsFailure = (errorMessage: string) => ({
  type: LOAD_AUDIT_LOGS_FAILURE,
  errorMessage: errorMessage
});

export const resetAuditLogs = () => ({ type: RESET_AUDIT_LOGS });

export const removeParticipantRequest = (participantLinkId: number) => ({
  type: REMOVE_PARTICIPANT_REQUEST,
  participantLinkId: participantLinkId
});

export const removeParticipantSuccess = (participantLinkId: number) => ({
  type: REMOVE_PARTICIPANT_SUCCESS,
  participantLinkId: participantLinkId
});

export const removeParticipantFailure = (errorMessage: string) => ({
  type: REMOVE_PARTICIPANT_FAILURE,
  errorMessage: errorMessage
});

export const setLocationRequest = (
  locationInfo: LocationInformationModel,
  applicationId: number
) => ({
  type: SET_LOCATION_REQUEST,
  locationInfo: locationInfo,
  applicationId: applicationId
});

export const setLocationSuccess = (locationInfo: LocationInformationModel) => ({
  type: SET_LOCATION_SUCCESS,
  locationInfo: locationInfo
});

export const setLocationFailure = (errorMessage: string) => ({
  type: SET_LOCATION_FAILURE,
  errorMessage: errorMessage
});

export const deleteAttachmentRequest = (attachmentReference: string) => ({
  type: DELETE_ATTACHMENT_REQUEST,
  attachmentReference: attachmentReference
});

export const deleteAttachmentSuccess = (attachmentReference: string) => ({
  type: DELETE_ATTACHMENT_SUCCESS,
  attachmentReference: attachmentReference
});

export const deleteAttachmentFailure = (errorMessage: string) => ({
  type: DELETE_ATTACHMENT_FAILURE,
  errorMessage: errorMessage
});

export const cancelApplicationRequest = (applicationId: number) => ({
  type: CANCEL_APPLICATION_REQUEST,
  applicationId: applicationId
});

export const cancelApplicationSuccess = () => ({
  type: CANCEL_APPLICATION_SUCCESS
});

export const cancelApplicationFailure = (errorMessage: string) => ({
  type: CANCEL_APPLICATION_FAILURE,
  errorMessage: errorMessage
});

export const setMaintenanceCompletionRequest = (
  model: MaintenanceCompletionRequest
) => ({
  type: SET_MAINTENANCE_COMPLETION_REQUEST,
  model: model
});

export const setMaintenanceCompletionSuccess = (
  model: MaintenanceCompletionResponse
) => ({
  type: SET_MAINTENANCE_COMPLETION_SUCCESS,
  model: model
});

export const setMaintenanceCompletionFailure = (errorMessage: string) => ({
  type: SET_MAINTENANCE_COMPLETION_FAILURE,
  errorMessage: errorMessage
});

export const declineApplicationParticipantRequest = (code: string) => ({
  type: DECLINE_APPLICATION_PARTICIPANT_REQUEST,
  code: code
});

export const declineApplicationParticipantSuccess = (
  participantDeclined: boolean
) => ({
  type: DECLINE_APPLICATION_PARTICIPANT_SUCCESS,
  participantDeclined: participantDeclined
});

export const declineApplicationParticipantFailure = (errorMessage: string) => ({
  type: DECLINE_APPLICATION_PARTICIPANT_FAILURE,
  errorMessage: errorMessage
});
