import { combineReducers } from "redux";
import authReducer from "./auth/reducer";
import preferencesReducer from "./preferences/reducer";
import applicationsSummaryReducer from "./applicationsSummary/reducer";
import applicationDetailReducer from "./applicationDetail/reducer";
import createApplicationReducer from "./createApplication/reducers";
import administrationReducer from "./administration/reducer";
import loadingReducer from "./loading/reducer";
import lookupReducer from "./lookup/reducer";
import ttmReducer from "./trafficManagement/reducer";
import errorReducer from "./error/reducer";
import notistackReducer from "./notistack/reducers";
import mapReducer from "./map/reducer";
import billPayerDetailReducer from "./billPayerDetail/reducer";
import billPayersSummaryReducer from "./billPayersSummary/reducer";
import invoicesSummaryReducer from "./invoiceSummary/reducer";
import invoiceDetailReducer from "./invoiceDetail/reducer";
import mediaReducer from "./media/reducer";
import { enableMapSet } from "immer";

enableMapSet();

const rootReducer = combineReducers({
  auth: authReducer,
  preferences: preferencesReducer,
  applicationsSummary: applicationsSummaryReducer,
  applicationDetail: applicationDetailReducer,
  createApplication: createApplicationReducer,
  administration: administrationReducer,
  lookup: lookupReducer,
  trafficManagement: ttmReducer,
  loading: loadingReducer,
  error: errorReducer,
  notistack: notistackReducer,
  map: mapReducer,
  billPayerDetail: billPayerDetailReducer,
  billPayersSummary: billPayersSummaryReducer,
  invoicesSummary: invoicesSummaryReducer,
  invoiceDetail: invoiceDetailReducer,
  media: mediaReducer
});

export default rootReducer;
