import {
  LOAD_APPLICATIONS_SUMMARY_REQUEST,
  LOAD_APPLICATIONS_SUMMARY_SUCCESS,
  LOAD_APPLICATIONS_SUMMARY_FAILURE,
  RESET_APPLICATIONS_SUMMARY,
  ApplicationsSummaryFilter,
  ApplicationsSummaryReceivedPayload,
  LOAD_APPLICATIONS_SUMMARY_REQUEST_EXTRA,
  LOAD_MAP_APPLICATIONS_SUMMARY_REQUEST,
  LOAD_MAP_APPLICATIONS_SUMMARY_SUCCESS,
  LOAD_RCA_LOGOS_REQUEST,
  RcaLogo,
  LOAD_RCA_LOGOS_SUCCESS,
  RcaBoundary,
  LOAD_RCA_BOUNDARIES_REQUEST,
  LOAD_RCA_BOUNDARIES_SUCCESS,
  LOAD_APPLICATION_EXPORT_COLUMNS_REQUEST,
  ApplicationExportColumn,
  LOAD_APPLICATION_EXPORT_COLUMNS_SUCCESS,
  LOAD_APPLICATION_EXPORT_COLUMNS_FAILURE,
  GENERATE_APPLICATION_GRID_LIST_EXPORT_REQUEST,
  GENERATE_APPLICATION_GRID_LIST_EXPORT_FAILURE,
  GENERATE_APPLICATION_GRID_LIST_EXPORT_SUCCESS
} from "./types";

export const loadApplicationsSummaryRequest = (
  payload: ApplicationsSummaryFilter
) => ({
  type: LOAD_APPLICATIONS_SUMMARY_REQUEST,
  applicationsSummaryOptions: payload
});

export const loadApplicationsSummaryRequestExtra = (
  payload: ApplicationsSummaryFilter
) => ({
  type: LOAD_APPLICATIONS_SUMMARY_REQUEST_EXTRA,
  applicationsSummaryOptions: payload
});

export const loadApplicationsSummarySuccess = (
  payload: ApplicationsSummaryReceivedPayload
) => ({
  type: LOAD_APPLICATIONS_SUMMARY_SUCCESS,
  receivedData: payload.receivedData,
  totalCount: payload.totalCount,
  isMapView: payload.isMapView
});

export const loadApplicationsSummaryFailure = (errorMessage: string) => ({
  type: LOAD_APPLICATIONS_SUMMARY_FAILURE,
  errorMessage: errorMessage
});

export const loadMapApplicationsSummaryRequest = (
  payload: ApplicationsSummaryFilter
) => ({
  type: LOAD_MAP_APPLICATIONS_SUMMARY_REQUEST,
  applicationsSummaryOptions: payload
});

export const loadMapApplicationsSummarySuccess = (
  payload: ApplicationsSummaryReceivedPayload
) => ({
  type: LOAD_MAP_APPLICATIONS_SUMMARY_SUCCESS,
  receivedData: payload.receivedData,
  totalCount: payload.totalCount
});

export const resetApplicationsSummary = () => ({
  type: RESET_APPLICATIONS_SUMMARY
});

export const loadRcaLogosRequest = (rcaIds: number[]) => ({
  type: LOAD_RCA_LOGOS_REQUEST,
  rcaIds: rcaIds
});

export const loadRcaLogosSuccess = (rcaLogos: RcaLogo[]) => ({
  type: LOAD_RCA_LOGOS_SUCCESS,
  rcaLogos: rcaLogos
});

export const loadRcaBoundariesRequest = (rcaIds: number[]) => ({
  type: LOAD_RCA_BOUNDARIES_REQUEST,
  rcaIds: rcaIds
});

export const loadRcaBoundariesSuccess = (rcaBoundaries: RcaBoundary[]) => ({
  type: LOAD_RCA_BOUNDARIES_SUCCESS,
  rcaBoundaries: rcaBoundaries
});

export const loadApplicationExportColumnsRequest = () => ({
  type: LOAD_APPLICATION_EXPORT_COLUMNS_REQUEST
});

export const loadApplicationExportColumnsSuccess = (
  applicationExportColumns: ApplicationExportColumn[]
) => ({
  type: LOAD_APPLICATION_EXPORT_COLUMNS_SUCCESS,
  applicationExportColumns: applicationExportColumns
});

export const loadApplicationExportColumnsFailure = (errorMessage: string) => ({
  type: LOAD_APPLICATION_EXPORT_COLUMNS_FAILURE,
  errorMessage: errorMessage
});

export const generateApplicationGridListExportRequest = (
  filters: ApplicationsSummaryFilter,
  applicationExportColumns: ApplicationExportColumn[]
) => ({
  type: GENERATE_APPLICATION_GRID_LIST_EXPORT_REQUEST,
  filters: filters,
  applicationExportColumns: applicationExportColumns
});

export const generateApplicationGridListExportSuccess = () => ({
  type: GENERATE_APPLICATION_GRID_LIST_EXPORT_SUCCESS
});

export const generateApplicationGridListExportFailure = (
  errorMessage: string
) => ({
  type: GENERATE_APPLICATION_GRID_LIST_EXPORT_FAILURE,
  errorMessage: errorMessage
});
