import {
  LoadBillPayerRequestAction,
  LOAD_BILL_PAYER_REQUEST,
  BillPayer,
  ADD_BILL_PAYER_REQUEST,
  AddBillPayerRequestAction,
  AddBillPayerModel,
  ADD_BILL_PAYER_SUCCESS,
  ADD_BILL_PAYER_FAILURE
} from "./types";
import { takeLatest, call, put } from "redux-saga/effects";
import { API_ROUTE } from "common/constants";
import { BILLING_ENDPOINT } from "common/endpoints";
import axios from "axios";
import {
  loadBillPayerFailure,
  loadBillPayerSuccess,
  addBillPayerFailure,
  addBillPayerSuccess
} from "./actions";
import { enqueueSnackbar } from "store/notistack/actions";
import { createNotification } from "common/addNotification";

const loadBillPayerApi = (id: number) => {
  return axios.get(`${API_ROUTE}${BILLING_ENDPOINT}/billPayer/${id}`);
};

export function* loadBillPayer(action: LoadBillPayerRequestAction) {
  try {
    const { data }: { data: BillPayer } = yield call(
      loadBillPayerApi,
      action.id
    );

    yield put(loadBillPayerSuccess(data));
  } catch (e) {
    const message = e.response.data;
    yield put(
      loadBillPayerFailure(
        message.Message ??
          "Something went wrong when trying to fetch the Bill Payer"
      )
    );
  }
}

const addBillPayerApi = (billPayer: AddBillPayerModel) => {
  return axios.post(`${API_ROUTE}${BILLING_ENDPOINT}/billpayer`, billPayer);
};

export function* addBillPayer(action: AddBillPayerRequestAction) {
  try {
    const { data }: { data: BillPayer } = yield call(
      addBillPayerApi,
      action.billPayer
    );

    yield put(addBillPayerSuccess(data));
    yield put(
      enqueueSnackbar(
        createNotification(
          ADD_BILL_PAYER_SUCCESS,
          "Bill Payer Added Successfully",
          "success"
        )
      )
    );
  } catch (e) {
    const message = e.response.data;

    if (message.Message) {
      enqueueSnackbar(
        createNotification(
          ADD_BILL_PAYER_FAILURE,
          message.Message,
          message.MessageType
        )
      );
    }
    yield put(
      addBillPayerFailure(
        "Something went wrong when trying to add the Bill Payer"
      )
    );
  }
}

export function* watchLoadBillPayerRequest() {
  yield takeLatest(LOAD_BILL_PAYER_REQUEST, loadBillPayer);
}

export function* watchAddBillPayerRequest() {
  yield takeLatest(ADD_BILL_PAYER_REQUEST, addBillPayer);
}
