import { LatLng } from "leaflet";
import { Action } from "redux";
import { ErrorPayload } from "store/error/types";
import { BoundingBox, Feature, Lookup } from "store/types";

/* saga action names start */
export const LOAD_SHAPES_REQUEST = "LOAD_SHAPES_REQUEST";
export const LOAD_SHAPES_SUCCESS = "LOAD_SHAPES_SUCCESS";
export const LOAD_SHAPES_FAILURE = "LOAD_SHAPES_FAILURE";
export const RESET_SHAPES = "RESET_SHAPES";

export const LOAD_APPLICATION_CLASH_REQUEST = "LOAD_APPLICATION_CLASH_REQUEST";
export const LOAD_APPLICATION_CLASH_SUCCESS = "LOAD_APPLICATION_CLASH_SUCCESS";
export const LOAD_APPLICATION_CLASH_FAILURE = "LOAD_APPLICATION_CLASH_FAILURE";

export const LOAD_DISPATCHES_REQUEST = "LOAD_DISPATCHES_REQUEST";
export const LOAD_DISPATCHES_SUCCESS = "LOAD_DISPATCHES_SUCCESS";
export const LOAD_DISPATCHES_FAILURE = "LOAD_DISPATCHES_FAILURE";
export const RESET_DISPATCHES_SHAPES = "RESET_DISPATCHES_SHAPES";

export const SET_MAP_INPUT_REQUEST = "SET_MAP_INPUT_REQUEST";
/* saga action names end */

export type LoadShapesRequestAction = Action<typeof LOAD_SHAPES_REQUEST> & {
  request: LoadShapesRequest;
};

type LoadShapesSuccessAction = Action<typeof LOAD_SHAPES_SUCCESS> & {
  shapeDetails: ShapeDetail[];
};

type LoadShapesFailureAction = Action<typeof LOAD_SHAPES_FAILURE> &
  ErrorPayload;

type ResetShapesAction = Action<typeof RESET_SHAPES>;

export type LoadApplicationClashRequestAction = Action<
  typeof LOAD_APPLICATION_CLASH_REQUEST
> & {
  applicationClashRequest: ApplicationClashRequest;
};

type LoadApplicationClashSuccessAction = Action<
  typeof LOAD_APPLICATION_CLASH_SUCCESS
> & {
  applicationClash: ApplicationClash;
};

export type LoadDispatchesRequestAction = Action<
  typeof LOAD_DISPATCHES_REQUEST
> & {
  request: LoadDispatchesRequest;
};

export type SetMapInputRequestAction = Action<typeof SET_MAP_INPUT_REQUEST> & {
  mapInputData: MapInputData;
};

type LoadDispatchesSuccessAction = Action<typeof LOAD_DISPATCHES_SUCCESS> & {
  dispatchDetails: string[];
};

type ResetDispatchShapesAction = Action<typeof RESET_DISPATCHES_SHAPES>;

export type MapActionTypes =
  | LoadShapesRequestAction
  | LoadShapesSuccessAction
  | LoadShapesFailureAction
  | ResetShapesAction
  | LoadApplicationClashSuccessAction
  | LoadDispatchesSuccessAction
  | ResetDispatchShapesAction
  | SetMapInputRequestAction;

export type MapDetail = {
  carShapes: Feature[];
  dispatches?: Feature[];
};

export type MapState = {
  mapData: MapDetail;
  hasClash?: boolean;
  mapInputData?: MapInputData;
};

export type LoadShapesRequest = {
  startDate: Date;
  endDate: Date;
  boundingBox: BoundingBox;
  includeGlobalCars: boolean;
};

export type LoadDispatchesRequest = {
  startDate: Date;
  endDate: Date;
  startOffset: number;
  endOffset: number;
  boundingBox: BoundingBox;
};

export type CarInformation = {
  carShape: string;
  carNumber: string;
  carStatus: string;
  activity: string;
  applicant: string;
  workStartedDescription: string;
  workCompleteDescription: string;
  duration: number;
  estimatedStartDate: Date;
  estimatedCompletionDate: Date;
  workStart?: Date;
  workComplete?: Date;
  rcaOrganisationName: string;
  contact: string;
  utility: string;
  utilityContact: string;
  applicationType: Lookup;
};

export type DispatchInformation = {
  id: number;
  contractId: string;
  assetType: string;
  fault: string;
  priority: string;
  status: string;
  callType: string;
};

export type ShapeDetail = {
  carShape: string;
  carDetail: CarInformation;
};

export type PopupDisplay = {
  carNumber: string;
  carStatus: string;
  activity: string;
  applicant: string;
  contact: string;
  utility: string;
  utilityContact: string;
  duration: string;
  startDate: string | null;
  endDate: string | null;
  rca: string;
};

export type ApplicationClashRequest = {
  applicationShape: string;
  estimatedStartDate?: Date;
  estimatedCompletionDate?: Date;
  includeGlobalCars: boolean;
};

export type ApplicationClash = {
  applicationShape: string;
  shapeDetails: ShapeDetail[];
};

export type MapInputData = {
  startDate: Date;
  endDate: Date;
  includeGlobalCars: boolean;
  recentValidZoomValue: number;
  mapCenter?: LatLng;
};
