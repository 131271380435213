import produce from "immer";
import {
  NotificationActionTypes,
  ENQUEUE_SNACKBAR,
  NotistackState,
  REMOVE_SNACKBAR,
  REMOVE_ALL_SNACKBAR,
  DELETE_SNACKBAR
} from "./types";

const initialState: NotistackState = {
  notifications: new Map()
};

const notistackReducer = produce(
  (state: NotistackState, action: NotificationActionTypes) => {
    switch (action.type) {
      case ENQUEUE_SNACKBAR: {
        state.notifications.set(
          action.notification.options.key as string,
          action.notification
        );
        return;
      }
      case REMOVE_SNACKBAR: {
        state.notifications.set(action.key, {
          dismissed: true,
          message: "",
          options: { key: action.key }
        });
        return;
      }
      case REMOVE_ALL_SNACKBAR: {
        state.notifications.clear();
        return;
      }
      case DELETE_SNACKBAR: {
        state.notifications.delete(action.key);
        return;
      }
    }
  },
  initialState
);

export default notistackReducer;
