import { put, takeLatest, call } from "redux-saga/effects";
import axios from "axios";
import {
  LOAD_CAN_VIEW_INVOICE_REQUEST,
  LOAD_DEFAULT_REGION_REQUEST,
  LOAD_SEARCHABLE_REGION_REQUEST
} from "./types";
import {
  loadDefaultRegionSuccess,
  loadDefaultRegionFailure,
  loadSearchableRegionSuccess,
  loadSearchableRegionFailure,
  loadCanViewInvoiceSuccess,
  loadCanViewInvoiceFailure
} from "./actions";
import { API_ROUTE } from "common/constants";
import { ADMINISTRATION_ENDPOINT } from "common/endpoints";
import { USERS_CONTROLLER } from "common/controllerConstants";

const loadDefaultRegionApi = () => {
  return axios.get(
    `${API_ROUTE}${ADMINISTRATION_ENDPOINT}/${USERS_CONTROLLER}/defaultregion`
  );
};

function* loadDefaultRegion() {
  try {
    const { data }: { data: string } = yield call(loadDefaultRegionApi);

    yield put(loadDefaultRegionSuccess(data));
  } catch (e) {
    yield put(
      loadDefaultRegionFailure(
        "Something went wrong when trying load the default user region"
      )
    );
  }
}

export function* watchLoadDefaultRegionRequest() {
  yield takeLatest(LOAD_DEFAULT_REGION_REQUEST, loadDefaultRegion);
}

const loadSearchableRegionApi = () => {
  return axios.get(
    `${API_ROUTE}${ADMINISTRATION_ENDPOINT}/${USERS_CONTROLLER}/searchableregion`
  );
};

function* loadSearchableRegion() {
  try {
    const { data }: { data: string } = yield call(loadSearchableRegionApi);

    yield put(loadSearchableRegionSuccess(data));
  } catch (e) {
    yield put(
      loadSearchableRegionFailure(
        "Something went wrong when trying load the permitted searchable region"
      )
    );
  }
}

export function* watchLoadSearchableRegionRequest() {
  yield takeLatest(LOAD_SEARCHABLE_REGION_REQUEST, loadSearchableRegion);
}

const loadCanViewInvoiceApi = () => {
  return axios.get(
    `${API_ROUTE}${ADMINISTRATION_ENDPOINT}/${USERS_CONTROLLER}/canviewinvoice`
  );
};

function* loadCanViewInvoice() {
  try {
    const { data }: { data: boolean } = yield call(loadCanViewInvoiceApi);

    yield put(loadCanViewInvoiceSuccess(data));
  } catch (e) {
    yield put(
      loadCanViewInvoiceFailure(
        "Something went wrong when trying to load permission to view invoices"
      )
    );
  }
}

export function* watchLoadCanViewInvoiceRequest() {
  yield takeLatest(LOAD_CAN_VIEW_INVOICE_REQUEST, loadCanViewInvoice);
}
