import { Lookup, Feature } from "store/types";
import { Action } from "redux";

export const LOAD_TTM_SITES_REQUEST = "LOAD_TTM_SITES_REQUEST";
export const LOAD_TTM_SITES_SUCCESS = "LOAD_TTM_SITES_SUCCESS";
export const LOAD_TTM_SITES_FAILURE = "LOAD_TTM_SITES_FAILURE";
export const SET_TTM_SITE_STORE_DATA = "SET_TTM_SITE_STORE_DATA";
export const SET_TTM_DETOUR_STORE_DATA = "SET_TTM_DETOUR_STORE_DATA";
export const SET_TTM_CLOSURE_STORE_DATA = "SET_TTM_CLOSURE_STORE_DATA";
export const DELETE_TTM_SITE_STORE = "DELETE_TTM_SITE_STORE";
export const DELETE_TTM_DETOUR_STORE = "DELETE_TTM_DETOUR_STORE";
export const DELETE_TTM_CLOSURE_STORE = "DELETE_TTM_CLOSURE_STORE";
// Save to database
export const SET_TTM_SITE_DB_REQUEST = "SET_TTM_SITE_DB_REQUEST";
export const SET_TTM_SITE_DB_SUCCESS = "SET_TTM_SITE_DB_SUCCESS";
export const SET_TTM_SITE_DB_FAILURE = "SET_TTM_SITE_DB_FAILURE";

export const SET_TTM_DETOUR_DB_REQUEST = "SET_TTM_DETOUR_DB_REQUEST";
export const SET_TTM_DETOUR_DB_SUCCESS = "SET_TTM_DETOUR_DB_SUCCESS";
export const SET_TTM_DETOUR_DB_FAILURE = "SET_TTM_DETOUR_DB_FAILURE";

export const SET_TTM_CLOSURE_DB_REQUEST = "SET_TTM_CLOSURE_DB_REQUEST";
export const SET_TTM_CLOSURE_DB_SUCCESS = "SET_TTM_CLOSURE_DB_SUCCESS";
export const SET_TTM_CLOSURE_DB_FAILURE = "SET_TTM_CLOSURE_DB_FAILURE";

export const DELETE_TTM_SITE_DB_REQUEST = "DELETE_TTM_SITE_DB_REQUEST";
export const DELETE_TTM_SITE_DB_SUCCESS = "DELETE_TTM_SITE_DB_SUCCESS";
export const DELETE_TTM_SITE_DB_FAILURE = "DELETE_TTM_SITE_DB_FAILURE";
export const DELETE_TTM_DETOUR_DB_REQUEST = "DELETE_TTM_DETOUR_DB_REQUEST";
export const DELETE_TTM_DETOUR_DB_SUCCESS = "DELETE_TTM_DETOUR_DB_SUCCESS";
export const DELETE_TTM_DETOUR_DB_FAILURE = "DELETE_TTM_DETOUR_DB_FAILURE";
export const DELETE_TTM_CLOSURE_DB_REQUEST = "DELETE_TTM_CLOSURE_DB_REQUEST";
export const DELETE_TTM_CLOSURE_DB_SUCCESS = "DELETE_TTM_CLOSURE_DB_SUCCESS";
export const DELETE_TTM_CLOSURE_DB_FAILURE = "DELETE_TTM_CLOSURE_DB_FAILURE";

export const DUPLICATE_TTM = "DUPLICATE_TTM";
export const RESET_TTM = "RESET_TTM";
export const RESET_ADDED_SITE_ID = "RESET_ADDED_SITE_ID";

export type TTMState = {
  TTMSites: TTMSite[];
  addedSiteId?: number;
};

export type LoadTTMSitesRequestAction = Action<
  typeof LOAD_TTM_SITES_REQUEST
> & {
  applicationId?: number;
  guid?: string;
};

export type SetTTMSiteDbRequestAction = Action<
  typeof SET_TTM_SITE_DB_REQUEST
> & {
  newSite: TTMSite;
  isEditing: boolean;
  applicationId: number;
};

type SetTTMSiteDbSuccessAction = Action<typeof SET_TTM_SITE_DB_SUCCESS> & {
  newSite: TTMSite;
  isEditing: boolean;
};

export type SetTTMDetourDbRequestAction = Action<
  typeof SET_TTM_DETOUR_DB_REQUEST
> & {
  newDetour: TTMDetour;
  isEditing: boolean;
};

type SetTTMDetourDbSuccessAction = Action<typeof SET_TTM_DETOUR_DB_SUCCESS> & {
  newDetour: TTMDetour;
  isEditing: boolean;
};

export type SetTTMClosureDbRequestAction = Action<
  typeof SET_TTM_CLOSURE_DB_REQUEST
> & {
  newClosure: TTMClosure;
  isEditing: boolean;
};

type SetTTMClosureDbSuccessAction = Action<
  typeof SET_TTM_CLOSURE_DB_SUCCESS
> & {
  newClosure: TTMClosure;
  isEditing: boolean;
};

type SetTTMSiteDataAction = Action<typeof SET_TTM_SITE_STORE_DATA> & {
  newSite: TTMSite;
  isEditing: boolean;
};

type SetTTMDetourDataAction = Action<typeof SET_TTM_DETOUR_STORE_DATA> & {
  newDetour: TTMDetour;
  isEditing: boolean;
};

type SetTTMClosureDataAction = Action<typeof SET_TTM_CLOSURE_STORE_DATA> & {
  newClosure: TTMClosure;
  isEditing: boolean;
};

type LoadTTMSitesSuccessAction = Action<typeof LOAD_TTM_SITES_SUCCESS> & {
  sites: TTMSite[];
};

type DeleteTTMSiteStoreAction = Action<typeof DELETE_TTM_SITE_STORE> & {
  siteId: number;
};

export type DeleteTTMSiteDbRequestAction = Action<
  typeof DELETE_TTM_SITE_DB_REQUEST
> & {
  siteId: number;
};

type DeleteTTMSiteDbSuccessAction = Action<
  typeof DELETE_TTM_SITE_DB_SUCCESS
> & { siteId: number };

type DeleteTTMDetourStoreAction = Action<typeof DELETE_TTM_DETOUR_STORE> & {
  siteId: number;
  detourId: number;
};

export type DeleteTTMDetourDbRequestAction = Action<
  typeof DELETE_TTM_DETOUR_DB_REQUEST
> & {
  siteId: number;
  detourId: number;
};

type DeleteTTMDetourDbSuccessAction = Action<
  typeof DELETE_TTM_DETOUR_DB_SUCCESS
> & { siteId: number; detourId: number };

type DeleteTTMClosureStoreAction = Action<typeof DELETE_TTM_CLOSURE_STORE> & {
  siteId: number;
  closureId: number;
};

export type DeleteTTMClosureDbRequestAction = Action<
  typeof DELETE_TTM_CLOSURE_DB_REQUEST
> & {
  siteId: number;
  closureId: number;
};

type DeleteTTMClosureDbSuccessAction = Action<
  typeof DELETE_TTM_CLOSURE_DB_SUCCESS
> & { siteId: number; closureId: number };

type DuplicateTTMAction = Action<typeof DUPLICATE_TTM> & {
  ttmSites: TTMSite[];
};

type ResetTTMAction = Action<typeof RESET_TTM>;

type ResetAddedSiteIdAction = Action<typeof RESET_ADDED_SITE_ID>;

export type TTMStateActionTypes =
  | LoadTTMSitesSuccessAction
  | SetTTMSiteDataAction
  | SetTTMDetourDataAction
  | SetTTMClosureDataAction
  | SetTTMSiteDbSuccessAction
  | SetTTMDetourDbSuccessAction
  | SetTTMClosureDbSuccessAction
  | DeleteTTMSiteStoreAction
  | DeleteTTMSiteDbSuccessAction
  | DeleteTTMDetourStoreAction
  | DeleteTTMDetourDbSuccessAction
  | DeleteTTMClosureStoreAction
  | DeleteTTMClosureDbSuccessAction
  | DuplicateTTMAction
  | ResetTTMAction
  | ResetAddedSiteIdAction;

export type TTMSite = {
  siteId: number;
  detours: TTMDetour[];
  closures: TTMClosure[];
  status: string;
  fromStreetNumber: string;
  toStreetNumber: string;
  streetAddress: string;
  roadClass: Lookup;
  roadLevel: Lookup;
  ttmType: Lookup;
  ttmTypeExplanation?: string;
  ttmImpact: Lookup;
  ttmImpactExplanation: string;
  workActivity: Lookup;
  workActivityExplanation: string;
  speedLimit: Lookup;
  affectedAccess: Lookup[];
  location: string;
  affectedAreaDescription: string;
  isTrafficDelayLikely: boolean;
  isPublicNotificationRequired: boolean;
  notes?: string;
  shapes?: string;
  features: Feature[];
};

export type TTMDetour = {
  detourId: number;
  siteId: number;
  description: string;
  notes?: string;
  shapes?: string;
  features: Feature[];
};

export type TTMClosure = {
  closureId: number;
  siteId: number;
  proposedStart?: Date;
  proposedEnd?: Date;
  actualStart?: Date;
  actualEnd?: Date;
  notes?: string;
};
