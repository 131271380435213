import { Action } from "redux";
import { Lookup, Feature, AdditionalParticipant } from "store/types";
import { ErrorPayload } from "store/error/types";
import { Guid } from "guid-typescript";
import { ApplicationType } from "common/enums";
import { TTMSite } from "store/trafficManagement/types";
import { ApplicationDetail } from "store/applicationDetail/types";

/* saga action names start */
export const UPLOAD_CREATE_APPLICATION_TEMPORARY_FILE_REQUEST =
  "UPLOAD_CREATE_APPLICATION_TEMPORARY_FILE_REQUEST";
export const UPLOAD_CREATE_APPLICATION_TEMPORARY_FILE_SUCCESS =
  "UPLOAD_CREATE_APPLICATION_TEMPORARY_FILE_SUCCESS";
export const UPLOAD_CREATE_APPLICATION_TEMPORARY_FILE_FAILURE =
  "UPLOAD_CREATE_APPLICATION_TEMPORARY_FILE_FAILURE";
export const DELETE_CREATE_APPLICATION_TEMPORARY_FILE_REQUEST =
  "DELETE_CREATE_APPLICATION_TEMPORARY_FILE_REQUEST";
export const DELETE_CREATE_APPLICATION_TEMPORARY_FILE_SUCCESS =
  "DELETE_CREATE_APPLICATION_TEMPORARY_FILE_SUCCESS";
export const DELETE_CREATE_APPLICATION_TEMPORARY_FILE_FAILURE =
  "DELETE_CREATE_APPLICATION_TEMPORARY_FILE_FAILURE";
export const SUBMIT_APPLICATION_REQUEST = "SUBMIT_APPLICATION_REQUEST";
export const SUBMIT_APPLICATION_SUCCESS = "SUBMIT_APPLICATION_SUCCESS";
export const SUBMIT_APPLICATION_FAILURE = "SUBMIT_APPLICATION_FAILURE";

export const LOAD_BILLING_ITEMS_PREVIEW_REQUEST =
  "LOAD_BILLING_ITEMS_PREVIEW_REQUEST";
export const LOAD_BILLING_ITEMS_PREVIEW_SUCCESS =
  "LOAD_BILLING_ITEMS_PREVIEW_SUCCESS";
export const LOAD_BILLING_ITEMS_PREVIEW_FAILURE =
  "LOAD_BILLING_ITEMS_PREVIEW_FAILURE";

export const LOAD_RCA_IDS_FROM_SHAPES_REQUEST =
  "LOAD_RCA_IDS_FROM_SHAPES_REQUEST";
export const LOAD_RCA_IDS_FROM_SHAPES_SUCCESS =
  "LOAD_RCA_IDS_FROM_SHAPES_SUCCESS";
export const LOAD_RCA_IDS_FROM_SHAPES_FAILURE =
  "LOAD_RCA_IDS_FROM_SHAPES_FAILURE";

export const LOAD_RCA_OPTION_FROM_ID_REQUEST =
  "LOAD_RCA_OPTION_FROM_ID_REQUEST";
export const LOAD_RCA_OPTION_FROM_ID_SUCCESS =
  "LOAD_RCA_OPTION_FROM_ID_SUCCESS";
export const LOAD_RCA_OPTION_FROM_ID_FAILURE =
  "LOAD_RCA_OPTION_FROM_ID_FAILURE";

export const LOAD_PRIMARY_APPLICANT_INFO_REQUEST =
  "LOAD_PRIMARY_APPLICANT_INFO_REQUEST";
export const LOAD_PRIMARY_APPLICANT_INFO_SUCCESS =
  "LOAD_PRIMARY_APPLICANT_INFO_SUCCESS";
export const LOAD_PRIMARY_APPLICANT_INFO_FAILURE =
  "LOAD_PRIMARY_APPLICANT_INFO_FAILURE";

export const SET_TEMPORARY_SAVED_APPLICATION_REQUEST =
  "SET_TEMPORARY_SAVED_APPLICATION_REQUEST";

export const CHECK_REGISTERED_ORGANISATION_REQUEST =
  "CHECK_REGISTERED_ORGANISATION_REQUEST";
export const CHECK_REGISTERED_ORGANISATION_SUCCESS =
  "CHECK_REGISTERED_ORGANISATION_SUCCESS";
export const CHECK_REGISTERED_ORGANISATION_FAILURE =
  "CHECK_REGISTERED_ORGANISATION_FAILURE";
export const LOAD_SAVED_APPLICATION_LIST_REQUEST =
  "LOAD_SAVED_APPLICATION_LIST_REQUEST";
export const LOAD_SAVED_APPLICATION_LIST_SUCCESS =
  "LOAD_SAVED_APPLICATION_LIST_SUCCESS";
export const LOAD_SAVED_APPLICATION_LIST_FAILURE =
  "LOAD_SAVED_APPLICATION_LIST_FAILURE";

export const DELETE_SAVED_APPLICATION_REQUEST =
  "DELETE_SAVED_APPLICATION_REQUEST";
export const DELETE_SAVED_APPLICATION_SUCCESS =
  "DELETE_SAVED_APPLICATION_SUCCESS";
export const DELETE_SAVED_APPLICATION_FAILURE =
  "DELETE_SAVED_APPLICATION_FAILURE";

export const LOAD_SAVED_APPLICATION_REQUEST = "LOAD_SAVED_APPLICATION_REQUEST";
export const LOAD_SAVED_APPLICATION_SUCCESS = "LOAD_SAVED_APPLICATION_SUCCESS";
export const LOAD_SAVED_APPLICATION_FAILURE = "LOAD_SAVED_APPLICATION_FAILURE";

export const RESET_HAS_REQUEST_FAILURE = "RESET_HAS_REQUEST_FAILURE";
/* saga action names end */

/* redux action names start */
export const RESET_CREATE_APPLICATION = "RESET_CREATE_APPLICATION";
export const SET_CREATE_APPLICATION_NEXT_STEP =
  "SET_CREATE_APPLICATION_NEXT_STEP";
export const SET_CREATE_APPLICATION_SETUP_DATA =
  "SET_CREATE_APPLICATION_SETUP_DATA";
export const SET_CREATE_APPLICATION_WHAT_DATA =
  "SET_CREATE_APPLICATION_WHAT_DATA";
export const SET_CREATE_APPLICATION_WHERE_DATA =
  "SET_CREATE_APPLICATION_WHERE_DATA";
export const SET_CREATE_APPLICATION_TRAFFIC_DATA =
  "SET_CREATE_APPLICATION_TRAFFIC_DATA";
export const SET_CREATE_APPLICATION_WHEN_DATA =
  "SET_CREATE_APPLICATION_WHEN_DATA";
export const SET_CREATE_APPLICATION_WHO_DATA =
  "SET_CREATE_APPLICATION_WHO_DATA";
export const SET_CREATE_APPLICATION_EXTRA_DATA =
  "SET_CREATE_APPLICATION_EXTRA_DATA";
export const RESET_APPLICATION_TYPE_DEPENDENT_DATA =
  "RESET_APPLICATION_TYPE_DEPENDENT_DATA";
export const LOAD_APPLICATION_SETTINGS_DATA_REQUEST =
  "LOAD_APPLICATION_SETTINGS_DATA_REQUEST";
export const LOAD_APPLICATION_SETTINGS_DATA_SUCCESS =
  "LOAD_APPLICATION_SETTINGS_DATA_SUCCESS";
export const LOAD_APPLICATION_SETTINGS_DATA_FAILURE =
  "LOAD_APPLICATION_SETTINGS_DATA_FAILURE";
export const LOAD_SIBLING_APPLICATION_SETTINGS_DATA_REQUEST =
  "LOAD_SIBLING_APPLICATION_SETTINGS_DATA_REQUEST";
export const LOAD_SIBLING_APPLICATION_SETTINGS_DATA_SUCCESS =
  "LOAD_SIBLING_APPLICATION_SETTINGS_DATA_SUCCESS";
export const LOAD_SIBLING_APPLICATION_SETTINGS_DATA_FAILURE =
  "LOAD_SIBLING_APPLICATION_SETTINGS_DATA_FAILURE";
export const LOAD_SIBLING_APPLICATION_SETTINGS_DATA_RESET =
  "LOAD_SIBLING_APPLICATION_SETTINGS_DATA_RESET";

export const RESET_STORE_FORCE_RCA_SELECTION =
  "RESET_STORE_FORCE_RCA_SELECTION";

export const DUPLICATE_APPLICATION = "DUPLICATE_APPLICATION";

export const RESET_DUPLICATING_STATUS = "RESET_DUPLICATING_STATUS";
export const GET_DEFAULT_BILLPAYER_REQUEST = "GET_DEFAULT_BILLPAYER_REQUEST";
export const GET_DEFAULT_BILLPAYER_SUCCESS = "GET_DEFAULT_BILLPAYER_SUCCESS";
/* redux action names end */

export type SubmitApplicationRequestAction = Action<
  typeof SUBMIT_APPLICATION_REQUEST
> & {
  newApplication: NewApplication;
  ttmSites: TTMSite[];
};

type SubmitApplicationSuccessAction = Action<
  typeof SUBMIT_APPLICATION_SUCCESS
> & { applicationList: ApplicationList[]; hasRequestFailure: boolean };

type SubmitApplicationFailureAction = Action<
  typeof SUBMIT_APPLICATION_FAILURE
> &
  ErrorPayload;

export type UploadTemporaryFileRequestAction = Action<
  typeof UPLOAD_CREATE_APPLICATION_TEMPORARY_FILE_REQUEST
> & {
  applicationId: Guid;
  attachments: TemporaryAttachment[];
};

type UploadTemporaryFileSuccessAction = Action<
  typeof UPLOAD_CREATE_APPLICATION_TEMPORARY_FILE_SUCCESS
> & {
  fileIdentifiers: Guid[];
};

type UploadTemporaryFileFailureAction = Action<
  typeof UPLOAD_CREATE_APPLICATION_TEMPORARY_FILE_FAILURE
> &
  ErrorPayload;

export type DeleteTemporaryFileRequestAction = Action<
  typeof DELETE_CREATE_APPLICATION_TEMPORARY_FILE_REQUEST
> & {
  applicationId: Guid;
  attachmentDetails: TemporaryAttachment;
};

type DeleteTemporaryFileSuccessAction = Action<
  typeof DELETE_CREATE_APPLICATION_TEMPORARY_FILE_SUCCESS
> & {
  fileIdentifier: Guid;
};

type DeleteTemporaryFileFailureAction = Action<
  typeof DELETE_CREATE_APPLICATION_TEMPORARY_FILE_FAILURE
> &
  ErrorPayload;

type ResetCreateApplicationAction = Action<typeof RESET_CREATE_APPLICATION>;

type SetCreateApplicationNextStepAction = Action<
  typeof SET_CREATE_APPLICATION_NEXT_STEP
>;

type SetCreateApplicationSetupaDataAction = Action<
  typeof SET_CREATE_APPLICATION_SETUP_DATA
> & {
  setupData: SetupData;
};

type SetCreateApplicationWhatDataAction = Action<
  typeof SET_CREATE_APPLICATION_WHAT_DATA
> & {
  whatData: WhatData;
};

type SetCreateApplicationWhereDataAction = Action<
  typeof SET_CREATE_APPLICATION_WHERE_DATA
> & {
  whereData: WhereData;
};

type SetCreateApplicationTrafficDataAction = Action<
  typeof SET_CREATE_APPLICATION_TRAFFIC_DATA
> & {
  trafficData: TrafficData;
};

type SetCreateApplicationWhenDataAction = Action<
  typeof SET_CREATE_APPLICATION_WHEN_DATA
> & {
  whenData: WhenData;
};

type SetCreateApplicationWhoDataAction = Action<
  typeof SET_CREATE_APPLICATION_WHO_DATA
> & {
  whoData: WhoData;
};

type SetCreateApplicationExtraDataAction = Action<
  typeof SET_CREATE_APPLICATION_EXTRA_DATA
> & {
  extraData: ExtraData;
};

type ResetApplicationTypeDependentDataAction = Action<
  typeof RESET_APPLICATION_TYPE_DEPENDENT_DATA
>;

export type LoadApplicationSettingsDataRequestAction = Action<
  typeof LOAD_APPLICATION_SETTINGS_DATA_REQUEST
> & { rcaIds: number[] };

type LoadApplicationSettingsSuccessAction = Action<
  typeof LOAD_APPLICATION_SETTINGS_DATA_SUCCESS
> & { applicationSettings: ApplicationSettingsData };

export type LoadSiblingApplicationSettingsRequestAction = Action<
  typeof LOAD_SIBLING_APPLICATION_SETTINGS_DATA_REQUEST
> & { rcaIds: number[] };

type LoadSiblingApplicationSettingsSuccessAction = Action<
  typeof LOAD_SIBLING_APPLICATION_SETTINGS_DATA_SUCCESS
> & { siblingApplicationSettings: ApplicationSettingsData };

type LoadSiblingApplicationSettingsResetAction = Action<
  typeof LOAD_SIBLING_APPLICATION_SETTINGS_DATA_RESET
>;

export type LoadBillingItemsPreviewRequestAction = Action<
  typeof LOAD_BILLING_ITEMS_PREVIEW_REQUEST
> & {
  billingPreviewRequest: BillingPreviewRequestModel;
};

type LoadBillingItemsPreviewSuccessAction = Action<
  typeof LOAD_BILLING_ITEMS_PREVIEW_SUCCESS
> & {
  data: RcaBillableItemGroupingModel[];
};

type LoadBillingItemsPreviewFailureAction = Action<
  typeof LOAD_BILLING_ITEMS_PREVIEW_FAILURE
> &
  ErrorPayload;

export type LoadRcaIdsFromShapesRequestAction = Action<
  typeof LOAD_RCA_IDS_FROM_SHAPES_REQUEST
> & {
  shapes: Feature[];
};

type LoadRcaIdsFromShapesSuccessAction = Action<
  typeof LOAD_RCA_IDS_FROM_SHAPES_SUCCESS
> & {
  rcaOptions: RcaApplicationModel[];
};

type LoadRcaIdsFromShapesFailureAction = Action<
  typeof LOAD_RCA_IDS_FROM_SHAPES_FAILURE
> &
  ErrorPayload;

type ResetForceRcaSelectionAction = Action<
  typeof RESET_STORE_FORCE_RCA_SELECTION
>;

export type LoadRcaOptionFromIdRequestAction = Action<
  typeof LOAD_RCA_OPTION_FROM_ID_REQUEST
> & {
  rcaId: number;
};

type LoadRcaOptionFromIdSuccessAction = Action<
  typeof LOAD_RCA_OPTION_FROM_ID_SUCCESS
> & {
  rcaOption: RcaApplicationModel;
};

export type LoadPrimaryApplicantInfoRequestAction = Action<
  typeof LOAD_PRIMARY_APPLICANT_INFO_REQUEST
> & {
  userId: number;
  rcaIds?: number[];
};

type LoadPrimaryApplicantInfoSuccessAction = Action<
  typeof LOAD_PRIMARY_APPLICANT_INFO_SUCCESS
> & {
  billPayer: Lookup;
  organisation: Lookup;
};

type DuplicateApplicationAction = Action<typeof DUPLICATE_APPLICATION> & {
  applicationData: ApplicationDetail;
};

type ResetDuplicatingStatusAction = Action<typeof RESET_DUPLICATING_STATUS>;

export type SetTemporarySavedApplicationRequestAction = Action<
  typeof SET_TEMPORARY_SAVED_APPLICATION_REQUEST
> & {
  newApplication: NewApplication;
  ttmSites: TTMSite[];
};

export type CheckRegisteredOrganisationRequestAction = Action<
  typeof CHECK_REGISTERED_ORGANISATION_REQUEST
> & {
  organisationId: number;
  rcaIds: number[];
};

type CheckRegisteredOrganisationSuccessAction = Action<
  typeof CHECK_REGISTERED_ORGANISATION_SUCCESS
> & {
  organisationRegisters: OrganisationRegisters;
};

export type LoadSavedApplicationsListRequestAction = Action<
  typeof LOAD_SAVED_APPLICATION_LIST_REQUEST
>;

type LoadSavedApplicationsListSuccessAction = Action<
  typeof LOAD_SAVED_APPLICATION_LIST_SUCCESS
> & {
  savedApplications: TemporaryDisplaySavedApplication[];
};

export type DeleteSavedApplicationRequestAction = Action<
  typeof DELETE_SAVED_APPLICATION_REQUEST
> & {
  id: Guid;
};

type DeleteSavedApplicationSuccessAction = Action<
  typeof DELETE_SAVED_APPLICATION_SUCCESS
> & {
  id: Guid;
};

export type LoadSavedApplicationRequestAction = Action<
  typeof LOAD_SAVED_APPLICATION_REQUEST
> & {
  id: Guid;
};

type LoadSavedApplicationSuccessAction = Action<
  typeof LOAD_SAVED_APPLICATION_SUCCESS
> & {
  newApplication: NewApplication;
};

type ResetHasRequestFailureAction = Action<typeof RESET_HAS_REQUEST_FAILURE>;

export type GetDefaultBillPayerRequestAction = Action<
  typeof GET_DEFAULT_BILLPAYER_REQUEST
> & {
  organisationId: number;
  rcaIds?: number[];
  userId?: number | null;
};

type GetDefaultBillPayerSuccessAction = Action<
  typeof GET_DEFAULT_BILLPAYER_SUCCESS
> & {
  billPayer: Lookup
};

export type CreateApplicationActionTypes =
  | ResetCreateApplicationAction
  | SetCreateApplicationNextStepAction
  | SetCreateApplicationSetupaDataAction
  | SetCreateApplicationWhatDataAction
  | SetCreateApplicationWhereDataAction
  | SetCreateApplicationWhenDataAction
  | SetCreateApplicationTrafficDataAction
  | SetCreateApplicationWhoDataAction
  | SetCreateApplicationExtraDataAction
  | UploadTemporaryFileRequestAction
  | UploadTemporaryFileSuccessAction
  | UploadTemporaryFileFailureAction
  | DeleteTemporaryFileRequestAction
  | DeleteTemporaryFileSuccessAction
  | DeleteTemporaryFileFailureAction
  | ResetApplicationTypeDependentDataAction
  | SubmitApplicationRequestAction
  | SubmitApplicationSuccessAction
  | SubmitApplicationFailureAction
  | LoadApplicationSettingsDataRequestAction
  | LoadApplicationSettingsSuccessAction
  | LoadBillingItemsPreviewRequestAction
  | LoadBillingItemsPreviewSuccessAction
  | LoadBillingItemsPreviewFailureAction
  | LoadRcaIdsFromShapesRequestAction
  | LoadRcaIdsFromShapesSuccessAction
  | LoadRcaIdsFromShapesFailureAction
  | ResetForceRcaSelectionAction
  | LoadRcaOptionFromIdSuccessAction
  | LoadPrimaryApplicantInfoSuccessAction
  | DuplicateApplicationAction
  | ResetDuplicatingStatusAction
  | CheckRegisteredOrganisationSuccessAction
  | LoadSavedApplicationsListSuccessAction
  | DeleteSavedApplicationSuccessAction
  | LoadSavedApplicationSuccessAction
  | LoadSiblingApplicationSettingsSuccessAction
  | LoadSiblingApplicationSettingsResetAction
  | ResetHasRequestFailureAction
  | GetDefaultBillPayerSuccessAction;

export type NewApplication = {
  id: Guid;
  currentMaxStep: number;
  rcaOptions: RcaApplicationModel[];
  billingPreview: RcaBillableItemGroupingModel[];
  setupData: SetupData;
  whereData: WhereData;
  whatData: WhatData;
  trafficData: TrafficData;
  whenData: WhenData;
  whoData: WhoData;
  extraData: ExtraData;
  organisationRegisters?: OrganisationRegisters;
};

export type RcaBillableItemModel = {
  rcaId: number;
  rcaName: string;
  rcaWebsite: string;
  carId: number;
  templateId: number;
  quantity: number;
  rate: number;
  unitId: number;
  rebatedItemId?: number;
  description: string;
  amount: number;
};

export type RcaBillableItemGroupingModel = {
  rcaName: string;
  billableItems: RcaBillableItemModel[];
};

export type BillingPreviewRequestModel = {
  rcaOrganisationIds: number[];
  shapeArea: number;
  shapeLength: number;
  applicationTypeId: number;
  estimatedDuration: number;
  roadClosureRequired?: boolean;
  roadLevelId?: number;
  workTypeId: number;
  tmpCount: number;
  estimatedCompletionDate?: Date;
  locationInRoadIds: number[];
  speedLimitIds: number[];
};

export type SetupData = {
  hasNoConflicts: boolean;
  applicationType?: ApplicationType;
  hasTMPForUpload: boolean;
  hasServicePlans: boolean;
  isDuplicating: boolean;
};

export type WhatData = {
  description: string;
  isGlobalCar: boolean;
  activities: Lookup[];
};

export type WhereData = {
  streetNumber: string;
  streetName: string;
  suburb: string;
  townCity: string;
  postCode: string;
  locationsInRoad: Lookup[];
  addressNotes?: string;
  shapes: Feature[];
  forceRcaSelection: boolean;
};

export type WhenData = {
  workType: Lookup;
  majorActivities: Lookup[];
  duration?: string;
  startDate?: Date;
  endDate?: Date;
  normalWorkingHours: string;
};

export type WhoData = {
  primaryApplicant: Lookup;
  primaryApplicantOrganisation: Lookup;
  principalRole: Lookup | null;
  isOnBehalfOfUtilityOperator: boolean;
  isPrincipal: boolean;
  isPrincipalAnOrganisation: boolean;
  principalOrganisation: Lookup;
  principalContactPerson: Lookup;
  billPayerOrganisation: Lookup;
  billPayer: Lookup;
  utilityOperatorOrganisation: Lookup;
  utilityOperatorContact: Lookup;
  additionalParticipants: AdditionalParticipant[];
  showUtilityOperatorOption?: boolean;
  showPrincipalFillOutForm?: boolean;
};

export type CreateApplicationState = {
  newApplication: NewApplication;
  savedApplications: TemporaryDisplaySavedApplication[];
  newApplicationList?: ApplicationList[];
  applicationSettingsData?: ApplicationSettingsData;
  siblingApplicationSettings?: ApplicationSettingsData;
  hasRequestFailure: boolean;
};

export type TrafficData = {
  roadLevel: Lookup | null;
  isFullRoadClosureRequired: boolean;
  tmp?: TemporaryAttachment[];
};

export type TemporaryAttachment = {
  filename: string;
  identifier: Guid;
  attachmentType: Lookup;
  isUploaded: boolean;
  file?: File;
};

export type ExtraData = {
  consentNumber: string;
  utilityReferenceNumber: string;
  purchaseOrderNumber: string;
  extraInformation: string;
  extraAttachments?: TemporaryAttachment[];
};

export type ApplicationSettingsData = {
  organisationId: number;
  isBillPayerRequired: boolean;
  isUoRegisterEnabled: boolean;
  isTtmRegisterEnabled: boolean;
  isBillPayerRegisterEnabled: boolean;
  workingDaysToSubmitMajorExcavation: number;
  workingDaysToSubmitMinorExcavation: number;
  workingDaysToSubmitPreliminary: number;
  workingDaysToSubmitEvent: number;
  workingDaysToSubmitNonExcavation: number;
  enforceTtmUpload: boolean;
  enforcePoNumber: boolean;
  isBillingModuleEnabled: boolean;
  rcaNames: string;
  rcaWithEnforcedPdfAttachment: string;
  enableMajorProcessingHistory: boolean;
};

export type RcaApplicationModel = {
  rcaId: number;
  rcaCustomisationTypeIds: number[];
  rcaName: string;
  rcaWebsite: string;
  isActive: boolean;
  isSubmiticaEnabled: boolean;
};

export type TemporarySavedApplication = {
  applicationDetail: string;
  ttmDetail: string;
};

export type TemporaryDisplaySavedApplication = {
  id: Guid;
  description: string;
};

export const BILL_PAYER = "billPayer";
export const PRINCIPAL_CONTACT_PERSON = "principalContactPerson";

export type ApplicationList = {
  carId: number;
  rcaId: number;
  carNumber: string;
};

export type OrganisationRegisters = {
  isRegisteredUtilityOperator: boolean;
  isRegisteredTrafficManager: boolean;
};
