import {
  NewApplication,
  RESET_CREATE_APPLICATION,
  SET_CREATE_APPLICATION_NEXT_STEP,
  SET_CREATE_APPLICATION_WHAT_DATA,
  SET_CREATE_APPLICATION_WHEN_DATA,
  SET_CREATE_APPLICATION_WHERE_DATA,
  SET_CREATE_APPLICATION_TRAFFIC_DATA,
  WhereData,
  WhatData,
  TrafficData,
  SET_CREATE_APPLICATION_WHO_DATA,
  WhoData,
  WhenData,
  SET_CREATE_APPLICATION_EXTRA_DATA,
  ExtraData,
  SET_CREATE_APPLICATION_SETUP_DATA,
  SetupData,
  UPLOAD_CREATE_APPLICATION_TEMPORARY_FILE_REQUEST,
  UPLOAD_CREATE_APPLICATION_TEMPORARY_FILE_SUCCESS,
  UPLOAD_CREATE_APPLICATION_TEMPORARY_FILE_FAILURE,
  TemporaryAttachment,
  DELETE_CREATE_APPLICATION_TEMPORARY_FILE_FAILURE,
  DELETE_CREATE_APPLICATION_TEMPORARY_FILE_SUCCESS,
  DELETE_CREATE_APPLICATION_TEMPORARY_FILE_REQUEST,
  RESET_APPLICATION_TYPE_DEPENDENT_DATA,
  SUBMIT_APPLICATION_REQUEST,
  SUBMIT_APPLICATION_SUCCESS,
  SUBMIT_APPLICATION_FAILURE,
  LOAD_APPLICATION_SETTINGS_DATA_REQUEST,
  LOAD_APPLICATION_SETTINGS_DATA_FAILURE,
  ApplicationSettingsData,
  LOAD_APPLICATION_SETTINGS_DATA_SUCCESS,
  LOAD_BILLING_ITEMS_PREVIEW_REQUEST,
  BillingPreviewRequestModel,
  RcaBillableItemGroupingModel,
  LOAD_BILLING_ITEMS_PREVIEW_SUCCESS,
  LOAD_BILLING_ITEMS_PREVIEW_FAILURE,
  LOAD_RCA_IDS_FROM_SHAPES_REQUEST,
  LOAD_RCA_IDS_FROM_SHAPES_SUCCESS,
  LOAD_RCA_IDS_FROM_SHAPES_FAILURE,
  RcaApplicationModel,
  RESET_STORE_FORCE_RCA_SELECTION,
  LOAD_RCA_OPTION_FROM_ID_REQUEST,
  LOAD_RCA_OPTION_FROM_ID_SUCCESS,
  LOAD_RCA_OPTION_FROM_ID_FAILURE,
  LOAD_PRIMARY_APPLICANT_INFO_REQUEST,
  LOAD_PRIMARY_APPLICANT_INFO_SUCCESS,
  LOAD_PRIMARY_APPLICANT_INFO_FAILURE,
  DUPLICATE_APPLICATION,
  RESET_DUPLICATING_STATUS,
  SET_TEMPORARY_SAVED_APPLICATION_REQUEST,
  CHECK_REGISTERED_ORGANISATION_REQUEST,
  CHECK_REGISTERED_ORGANISATION_SUCCESS,
  CHECK_REGISTERED_ORGANISATION_FAILURE,
  LOAD_SAVED_APPLICATION_LIST_REQUEST,
  LOAD_SAVED_APPLICATION_LIST_SUCCESS,
  LOAD_SAVED_APPLICATION_LIST_FAILURE,
  DELETE_SAVED_APPLICATION_REQUEST,
  DELETE_SAVED_APPLICATION_SUCCESS,
  DELETE_SAVED_APPLICATION_FAILURE,
  LOAD_SAVED_APPLICATION_REQUEST,
  LOAD_SAVED_APPLICATION_SUCCESS,
  LOAD_SAVED_APPLICATION_FAILURE,
  TemporaryDisplaySavedApplication,
  LOAD_SIBLING_APPLICATION_SETTINGS_DATA_SUCCESS,
  LOAD_SIBLING_APPLICATION_SETTINGS_DATA_REQUEST,
  LOAD_SIBLING_APPLICATION_SETTINGS_DATA_FAILURE,
  LOAD_SIBLING_APPLICATION_SETTINGS_DATA_RESET,
  RESET_HAS_REQUEST_FAILURE,
  ApplicationList,
  OrganisationRegisters,
  GET_DEFAULT_BILLPAYER_REQUEST,
  GET_DEFAULT_BILLPAYER_SUCCESS
} from "./types";
import { Lookup, Feature } from "store/types";
import { Guid } from "guid-typescript";
import { TTMSite } from "store/trafficManagement/types";
import { ApplicationDetail } from "store/applicationDetail/types";

export const submitApplicationRequest = (
  newApplication: NewApplication,
  ttmSites: TTMSite[]
) => ({
  type: SUBMIT_APPLICATION_REQUEST,
  newApplication: newApplication,
  ttmSites: ttmSites
});

export const submitApplicationSuccess = (
  applicationList: ApplicationList[],
  hasRequestFailure: boolean
) => ({
  type: SUBMIT_APPLICATION_SUCCESS,
  applicationList: applicationList,
  hasRequestFailure: hasRequestFailure
});

export const submitApplicationFailure = (errorMessage: string) => ({
  type: SUBMIT_APPLICATION_FAILURE,
  errorMessage: errorMessage
});

export const uploadTemporaryFileRequest = (
  applicationId: Guid,
  attachments: TemporaryAttachment[]
) => ({
  type: UPLOAD_CREATE_APPLICATION_TEMPORARY_FILE_REQUEST,
  applicationId: applicationId,
  attachments: attachments
});

export const uploadTemporaryFileSuccess = (fileIdentifiers: Guid[]) => ({
  type: UPLOAD_CREATE_APPLICATION_TEMPORARY_FILE_SUCCESS,
  fileIdentifiers: fileIdentifiers
});

export const uploadTemporaryFileFailure = (errorMessage: string) => ({
  type: UPLOAD_CREATE_APPLICATION_TEMPORARY_FILE_FAILURE,
  errorMessage: errorMessage
});

export const deleteTemporaryFileRequest = (
  applicationId: Guid,
  attachmentDetails: TemporaryAttachment
) => ({
  type: DELETE_CREATE_APPLICATION_TEMPORARY_FILE_REQUEST,
  applicationId: applicationId,
  attachmentDetails: attachmentDetails
});

export const deleteTemporaryFileSuccess = (fileIdentifier: Guid) => ({
  type: DELETE_CREATE_APPLICATION_TEMPORARY_FILE_SUCCESS,
  fileIdentifier: fileIdentifier
});

export const deleteTemporaryFileFailure = (errorMessage: string) => ({
  type: DELETE_CREATE_APPLICATION_TEMPORARY_FILE_FAILURE,
  errorMessage: errorMessage
});

export const resetCreateApplication = () => ({
  type: RESET_CREATE_APPLICATION
});

export const setCreateApplicationMaxStep = () => ({
  type: SET_CREATE_APPLICATION_NEXT_STEP
});

export const setCreateApplicationSetupData = (setupData: SetupData) => ({
  type: SET_CREATE_APPLICATION_SETUP_DATA,
  setupData: setupData
});

export const setCreateApplicationWhatData = (whatData: WhatData) => ({
  type: SET_CREATE_APPLICATION_WHAT_DATA,
  whatData: whatData
});

export const setCreateApplicationWhereData = (whereData: WhereData) => ({
  type: SET_CREATE_APPLICATION_WHERE_DATA,
  whereData: whereData
});

export const setCreateApplicationTrafficData = (trafficData: TrafficData) => ({
  type: SET_CREATE_APPLICATION_TRAFFIC_DATA,
  trafficData: trafficData
});

export const setCreateApplicationWhenData = (whenData: WhenData) => ({
  type: SET_CREATE_APPLICATION_WHEN_DATA,
  whenData: whenData
});

export const setCreateApplicationWhoData = (whoData: WhoData) => ({
  type: SET_CREATE_APPLICATION_WHO_DATA,
  whoData: whoData
});

export const setCreateApplicationExtraData = (extraData: ExtraData) => ({
  type: SET_CREATE_APPLICATION_EXTRA_DATA,
  extraData: extraData
});

export const resetApplicationDependentData = () => ({
  type: RESET_APPLICATION_TYPE_DEPENDENT_DATA
});

export const loadApplicationSettingsDataRequest = (rcaIds: number[]) => ({
  type: LOAD_APPLICATION_SETTINGS_DATA_REQUEST,
  rcaIds: rcaIds
});

export const loadApplicationSettingsDataFailure = (errorMessage: string) => ({
  type: LOAD_APPLICATION_SETTINGS_DATA_FAILURE,
  errorMessage: errorMessage
});

export const loadApplicationSettingsDataSuccess = (
  applicationSettings: ApplicationSettingsData
) => ({
  type: LOAD_APPLICATION_SETTINGS_DATA_SUCCESS,
  applicationSettings: applicationSettings
});

export const loadSiblingApplicationSettingsRequest = (rcaIds: number[]) => ({
  type: LOAD_SIBLING_APPLICATION_SETTINGS_DATA_REQUEST,
  rcaIds: rcaIds
});

export const loadSiblingApplicationSettingsFailure = (
  errorMessage: string
) => ({
  type: LOAD_SIBLING_APPLICATION_SETTINGS_DATA_FAILURE,
  errorMessage: errorMessage
});

export const loadSiblingApplicationSettingsDataSuccess = (
  siblingApplicationSettings: ApplicationSettingsData
) => ({
  type: LOAD_SIBLING_APPLICATION_SETTINGS_DATA_SUCCESS,
  siblingApplicationSettings: siblingApplicationSettings
});

export const loadSiblingApplicationSettingsDataReset = () => ({
  type: LOAD_SIBLING_APPLICATION_SETTINGS_DATA_RESET
});

export const loadBillingItemPreviewRequest = (
  billingPreviewRequestModel: BillingPreviewRequestModel
) => ({
  type: LOAD_BILLING_ITEMS_PREVIEW_REQUEST,
  billingPreviewRequest: billingPreviewRequestModel
});

export const loadBillingItemPreviewSuccess = (
  data: RcaBillableItemGroupingModel[]
) => ({
  type: LOAD_BILLING_ITEMS_PREVIEW_SUCCESS,
  data: data
});

export const loadBillingItemPreviewFailure = (errorMessage: string) => ({
  type: LOAD_BILLING_ITEMS_PREVIEW_FAILURE,
  errorMessage: errorMessage
});

export const loadRcaIdsFromShapesRequest = (shapes: Feature[]) => ({
  type: LOAD_RCA_IDS_FROM_SHAPES_REQUEST,
  shapes: shapes
});

export const loadRcaIdsFromShapesSuccess = (
  rcaOptions: RcaApplicationModel[]
) => ({
  type: LOAD_RCA_IDS_FROM_SHAPES_SUCCESS,
  rcaOptions: rcaOptions
});

export const loadRcaIdsFromShapesFailure = (errorMessage: string) => ({
  type: LOAD_RCA_IDS_FROM_SHAPES_FAILURE,
  errorMessage: errorMessage
});

export const resetForceRcaSelection = () => ({
  type: RESET_STORE_FORCE_RCA_SELECTION
});

export const loadRcaOptionFromIdRequest = (rcaId: number) => ({
  type: LOAD_RCA_OPTION_FROM_ID_REQUEST,
  rcaId: rcaId
});

export const loadRcaOptionsFromIdSuccess = (
  rcaOption: RcaApplicationModel
) => ({
  type: LOAD_RCA_OPTION_FROM_ID_SUCCESS,
  rcaOption: rcaOption
});

export const loadRcaOptionsFromIdFailure = (errorMessage: string) => ({
  type: LOAD_RCA_OPTION_FROM_ID_FAILURE,
  errorMessage: errorMessage
});

export const loadPrimaryApplicantInfoRequest = (
  userId: number,
  rcaIds?: number[]
) => ({
  type: LOAD_PRIMARY_APPLICANT_INFO_REQUEST,
  userId: userId,
  rcaIds: rcaIds
});

export const loadPrimaryApplicantInfoSuccess = (
  billPayer: Lookup,
  organisation: Lookup
) => ({
  type: LOAD_PRIMARY_APPLICANT_INFO_SUCCESS,
  billPayer: billPayer,
  organisation: organisation
});

export const loadPrimaryApplicantInfoFailure = (errorMessage: string) => ({
  type: LOAD_PRIMARY_APPLICANT_INFO_FAILURE,
  errorMessage: errorMessage
});

export const duplicateApplication = (applicationData: ApplicationDetail) => ({
  type: DUPLICATE_APPLICATION,
  applicationData: applicationData
});

export const resetDuplicatingStatusAction = () => ({
  type: RESET_DUPLICATING_STATUS
});

export const setTemporarySavedApplicationRequest = (
  newApplication: NewApplication,
  ttmSites: TTMSite[]
) => ({
  type: SET_TEMPORARY_SAVED_APPLICATION_REQUEST,
  newApplication: newApplication,
  ttmSites: ttmSites
});

export const checkRegisteredOrganisationRequest = (
  organisationId: number,
  rcaIds: number[]
) => ({
  type: CHECK_REGISTERED_ORGANISATION_REQUEST,
  organisationId: organisationId,
  rcaIds: rcaIds
});

export const checkRegisteredOrganisationSuccess = (
  organisationRegisters: OrganisationRegisters
) => ({
  type: CHECK_REGISTERED_ORGANISATION_SUCCESS,
  organisationRegisters: organisationRegisters
});

export const checkRegisteredOrganisationFailure = (errorMessage: string) => ({
  type: CHECK_REGISTERED_ORGANISATION_FAILURE,
  errorMessage: errorMessage
});
export const loadSavedApplicationsRequest = () => ({
  type: LOAD_SAVED_APPLICATION_LIST_REQUEST
});

export const loadSavedApplicationsSuccess = (
  savedApplications: TemporaryDisplaySavedApplication[]
) => ({
  type: LOAD_SAVED_APPLICATION_LIST_SUCCESS,
  savedApplications: savedApplications
});

export const loadSavedApplicationsFailure = (errorMessage: string) => ({
  type: LOAD_SAVED_APPLICATION_LIST_FAILURE,
  errorMessage: errorMessage
});

export const deleteSavedApplicationRequest = (id: Guid) => ({
  type: DELETE_SAVED_APPLICATION_REQUEST,
  id: id
});

export const deleteSavedApplicationSuccess = (id: Guid) => ({
  type: DELETE_SAVED_APPLICATION_SUCCESS,
  id: id
});

export const deleteSavedApplicationFailure = (errorMessage: string) => ({
  type: DELETE_SAVED_APPLICATION_FAILURE,
  errorMessage: errorMessage
});

export const loadSavedApplicationRequest = (id: Guid) => ({
  type: LOAD_SAVED_APPLICATION_REQUEST,
  id: id
});

export const loadSavedApplicationSuccess = (
  newApplication: NewApplication
) => ({
  type: LOAD_SAVED_APPLICATION_SUCCESS,
  newApplication: newApplication
});

export const loadSavedApplicationFailure = (errorMessage: string) => ({
  type: LOAD_SAVED_APPLICATION_FAILURE,
  errorMessage: errorMessage
});

export const resetHasRequestFailure = () => ({
  type: RESET_HAS_REQUEST_FAILURE
});

export const getDefaultBillPayerRequest = (organisationId: number, rcaIds?: number[], userId?: number | null) => ({
  type: GET_DEFAULT_BILLPAYER_REQUEST,
  organisationId: organisationId,
  rcaIds: rcaIds,
  userId: userId
});

export const getDefaultBillPayerSuccess = (billPayer: Lookup) => ({
  type: GET_DEFAULT_BILLPAYER_SUCCESS,
  billPayer: billPayer
});
