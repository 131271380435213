import {
  LOAD_BILL_PAYER_REQUEST,
  BillPayer,
  LOAD_BILL_PAYER_SUCCESS,
  LOAD_BILL_PAYER_FAILURE,
  ADD_BILL_PAYER_REQUEST,
  AddBillPayerModel,
  ADD_BILL_PAYER_SUCCESS,
  ADD_BILL_PAYER_FAILURE,
  CLEAR_BILL_PAYER_DETAIL
} from "./types";

export const clearBillPayerDetail = () => ({
  type: CLEAR_BILL_PAYER_DETAIL
});

export const loadBillPayerRequest = (id: number) => ({
  type: LOAD_BILL_PAYER_REQUEST,
  id: id
});

export const loadBillPayerSuccess = (billPayer: BillPayer) => ({
  type: LOAD_BILL_PAYER_SUCCESS,
  billPayer: billPayer
});

export const loadBillPayerFailure = (errorMessage: string) => ({
  type: LOAD_BILL_PAYER_FAILURE,
  errorMessage: errorMessage
});

export const addBillPayerRequest = (billPayer: AddBillPayerModel) => ({
  type: ADD_BILL_PAYER_REQUEST,
  billPayer: billPayer
});

export const addBillPayerSuccess = (billPayer: BillPayer) => ({
  type: ADD_BILL_PAYER_SUCCESS,
  billPayer: billPayer
});

export const addBillPayerFailure = (errorMessage: string) => ({
  type: ADD_BILL_PAYER_FAILURE,
  errorMesage: errorMessage
});
