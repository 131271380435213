import produce from "immer";
import {
  MapActionTypes,
  LOAD_SHAPES_SUCCESS,
  RESET_SHAPES,
  MapState,
  ShapeDetail,
  LOAD_APPLICATION_CLASH_SUCCESS,
  LOAD_DISPATCHES_SUCCESS,
  RESET_DISPATCHES_SHAPES,
  SET_MAP_INPUT_REQUEST
} from "./types";
import Wkt from "wicket";
import { wktToFeatures, getDispatchFeature } from "common/wktToFeatures";
import { Feature, DispatchDetails } from "store/types";

const initialState: MapState = {
  mapData: { carShapes: [] }
};

const getShapes = (shapes: Feature[][]) => {
  const features: Feature[] = [];
  shapes.map(x => {
    features.push(...x);
  });

  return features;
};

const getDispatches = (details: string[]) => {
  let features: Feature[] = [];
  if (!details || details.length === 0) {
    return features;
  }

  details.forEach(x => {
    const dispatches: DispatchDetails = JSON.parse(x);
    dispatches.features.forEach(dispatch => {
      const dispatchFeatures: Feature[] | null = getDispatchFeature(dispatch);
      if (!dispatchFeatures) {
        return true;
      }
      features = features.concat(dispatchFeatures);
    });
  });
  return features;
};

const mapReducer = produce((draft: MapState, action: MapActionTypes) => {
  switch (action.type) {
    case LOAD_SHAPES_SUCCESS: {
      const wicket = new Wkt.Wkt();
      const shapes: Feature[][] = action.shapeDetails.map((x: ShapeDetail) => {
        return wktToFeatures(wicket, x.carShape, x.carDetail);
      });
      draft.mapData.carShapes = getShapes(shapes);
      return;
    }
    case RESET_SHAPES: {
      draft.mapData.carShapes = [];
      draft.hasClash = undefined;
      return;
    }
    case RESET_DISPATCHES_SHAPES: {
      draft.mapData.dispatches = [];
      return;
    }
    case LOAD_APPLICATION_CLASH_SUCCESS: {
      const wicket = new Wkt.Wkt();
      let shapes: Feature[][] = [];
      draft.hasClash = false;

      if (action.applicationClash.applicationShape) {
        if (
          action.applicationClash.shapeDetails &&
          action.applicationClash.shapeDetails.length > 0
        ) {
          shapes = action.applicationClash.shapeDetails.map(
            (x: ShapeDetail) => {
              return wktToFeatures(wicket, x.carShape, x.carDetail);
            }
          );
          draft.hasClash = true;
        }

        shapes.push(
          wktToFeatures(wicket, action.applicationClash.applicationShape)
        );
      }

      draft.mapData.carShapes = getShapes(shapes);
      return;
    }
    case LOAD_DISPATCHES_SUCCESS: {
      draft.mapData.dispatches = getDispatches(action.dispatchDetails);
      return;
    }
    case SET_MAP_INPUT_REQUEST: {
      draft.mapInputData = action.mapInputData;
      return;
    }
  }
}, initialState);

export default mapReducer;
