import { takeLatest, put, call } from "redux-saga/effects";
import { ToStartOfDay } from "common/dateHelper";
import {
  UPLOAD_CREATE_APPLICATION_TEMPORARY_FILE_REQUEST,
  UploadTemporaryFileRequestAction,
  DeleteTemporaryFileRequestAction,
  DELETE_CREATE_APPLICATION_TEMPORARY_FILE_REQUEST,
  SUBMIT_APPLICATION_REQUEST,
  SubmitApplicationRequestAction,
  NewApplication,
  LOAD_APPLICATION_SETTINGS_DATA_REQUEST,
  LoadApplicationSettingsDataRequestAction,
  LOAD_BILLING_ITEMS_PREVIEW_REQUEST,
  LoadBillingItemsPreviewRequestAction,
  RcaBillableItemGroupingModel,
  BillingPreviewRequestModel,
  LOAD_RCA_IDS_FROM_SHAPES_REQUEST,
  LoadRcaIdsFromShapesRequestAction,
  LOAD_RCA_IDS_FROM_SHAPES_FAILURE,
  SUBMIT_APPLICATION_FAILURE,
  RcaApplicationModel,
  LoadRcaOptionFromIdRequestAction,
  LOAD_RCA_OPTION_FROM_ID_FAILURE,
  LOAD_RCA_OPTION_FROM_ID_REQUEST,
  LOAD_PRIMARY_APPLICANT_INFO_REQUEST,
  LOAD_PRIMARY_APPLICANT_INFO_FAILURE,
  LoadPrimaryApplicantInfoRequestAction,
  SetTemporarySavedApplicationRequestAction,
  SET_TEMPORARY_SAVED_APPLICATION_REQUEST,
  CHECK_REGISTERED_ORGANISATION_REQUEST,
  CheckRegisteredOrganisationRequestAction,
  CHECK_REGISTERED_ORGANISATION_FAILURE,
  LOAD_SAVED_APPLICATION_LIST_FAILURE,
  LOAD_SAVED_APPLICATION_LIST_REQUEST,
  DeleteSavedApplicationRequestAction,
  DELETE_SAVED_APPLICATION_FAILURE,
  DELETE_SAVED_APPLICATION_REQUEST,
  LoadSavedApplicationRequestAction,
  LOAD_SAVED_APPLICATION_REQUEST,
  TemporarySavedApplication,
  LOAD_SAVED_APPLICATION_FAILURE,
  TemporaryDisplaySavedApplication,
  TemporaryAttachment,
  LOAD_SIBLING_APPLICATION_SETTINGS_DATA_REQUEST,
  LoadSiblingApplicationSettingsRequestAction,
  ApplicationSettingsData,
  ApplicationList,
  OrganisationRegisters,
  GET_DEFAULT_BILLPAYER_REQUEST,
  GetDefaultBillPayerRequestAction
} from "./types";
import {
  uploadTemporaryFileFailure,
  uploadTemporaryFileSuccess,
  deleteTemporaryFileSuccess,
  deleteTemporaryFileFailure,
  submitApplicationSuccess,
  submitApplicationFailure,
  loadApplicationSettingsDataSuccess,
  loadApplicationSettingsDataFailure,
  loadBillingItemPreviewFailure,
  loadBillingItemPreviewSuccess,
  loadRcaIdsFromShapesFailure,
  loadRcaIdsFromShapesSuccess,
  loadRcaOptionsFromIdFailure,
  loadRcaOptionsFromIdSuccess,
  loadPrimaryApplicantInfoFailure,
  loadPrimaryApplicantInfoSuccess,
  checkRegisteredOrganisationSuccess,
  checkRegisteredOrganisationFailure,
  loadSavedApplicationsSuccess,
  loadSavedApplicationsFailure,
  deleteSavedApplicationSuccess,
  loadSavedApplicationSuccess,
  loadSavedApplicationFailure,
  loadSiblingApplicationSettingsDataSuccess,
  loadSiblingApplicationSettingsFailure,
  getDefaultBillPayerSuccess
} from "./actions";
import axios from "axios";
import { ParticipantRoleEnum, ApplicantRoleEnum } from "common/enums";
import {
  APPLICATION_ENDPOINT,
  MEDIA_ENDPOINT,
  ADMINISTRATION_ENDPOINT,
  BILLING_ENDPOINT
} from "common/endpoints";
import { API_ROUTE } from "common/constants";
import { TTMSite, TTMDetour, TTMClosure } from "store/trafficManagement/types";
import {
  ORGANISATIONS_CONTROLLER,
  ATTACHMENTS_CONTROLLER,
  TEMPORARY_CONTROLLER
} from "common/controllerConstants";
import Wkt from "wicket";
import featuresToWkt, { featureToWkt } from "common/featuresToWkt";
import { Guid } from "guid-typescript";
import { createNotification } from "common/addNotification";
import { enqueueSnackbar } from "store/notistack/actions";
import { AddParticipant, Lookup } from "store/types";
import { GetApplicationTypeString } from "common/EnumUtils";
import moment from "moment";
import { duplicateTTM } from "store/trafficManagement/actions";
import wktToFeatures from "common/wktToFeatures";
import { GetOffsetUtc } from "common/dateHelper";
import { ApplicationType } from "common/enums";

const uploadTemporaryFile = (formData: FormData) => {
  return axios.post(
    `${API_ROUTE}${MEDIA_ENDPOINT}/${ATTACHMENTS_CONTROLLER}/temporaryattachment`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
};

const deleteTemporaryFile = (filepath: string) => {
  return axios.delete(
    `${API_ROUTE}${MEDIA_ENDPOINT}/${ATTACHMENTS_CONTROLLER}/temporaryattachment/${filepath}`
  );
};

function* uploadTemporaryFileRequest(action: UploadTemporaryFileRequestAction) {
  try {
    const formData = new FormData();
    const ids: Guid[] = [];
    action.attachments.forEach(file => {
      const filepath = `${action.applicationId}/${file.attachmentType.id}/${file.identifier}_${file.filename}`;
      formData.append(filepath, file.file!);

      ids.push(file.identifier);
    });

    yield call(uploadTemporaryFile, formData);

    yield put(
      enqueueSnackbar(
        createNotification(
          "addTemporaryAttachment",
          "Successfully uploaded file(s)",
          "success"
        )
      )
    );

    yield put(uploadTemporaryFileSuccess(ids));
  } catch (e) {
    yield put(uploadTemporaryFileFailure("Failed to upload the file."));
  }
}

function* deleteTemporaryFileRequest(action: DeleteTemporaryFileRequestAction) {
  try {
    const filepath = `${action.applicationId}/${action.attachmentDetails.attachmentType.id}/${action.attachmentDetails.identifier}_${action.attachmentDetails.filename}`;

    yield call(deleteTemporaryFile, filepath);

    yield put(deleteTemporaryFileSuccess(action.attachmentDetails.identifier));
  } catch (e) {
    yield put(deleteTemporaryFileFailure("Failed to delete the file."));
  }
}

const submitApplication = (app: NewApplication, ttmSites: TTMSite[]) => {
  let participants: AddParticipant[] = [
    {
      //Primary Applicant
      userId: app.whoData.primaryApplicant.id,
      participantRole: {
        id: ParticipantRoleEnum.ApplicantPrimaryContact,
        description: "Applicant Primary Contact"
      }
    }
  ];

  if (app.whoData.billPayer && app.whoData.billPayer.id !== 0) {
    participants.push({
      // Bill payer
      userId: app.whoData.billPayer.id,
      participantRole: {
        id: ParticipantRoleEnum.BillPayer,
        description: "Bill Payer"
      }
    });
  }

  if (
    app.whoData.utilityOperatorContact &&
    app.whoData.utilityOperatorContact.id !== 0
  ) {
    participants.push({
      // Bill payer
      userId: app.whoData.utilityOperatorContact.id,
      participantRole: {
        id: ParticipantRoleEnum.UtilityOperatorPrimaryContact,
        description: "Utility Operator Primary Contact"
      }
    });
    if (
      app.whoData.principalRole &&
      app.whoData.principalRole.id !== ApplicantRoleEnum.UtilityOperator
    ) {
      participants.push({
        //Other
        userId: app.whoData.isPrincipal
          ? app.whoData.primaryApplicant.id
          : app.whoData.principalContactPerson.id,
        participantRole: {
          id: ParticipantRoleEnum.OtherPartyConsultant,
          description: "Other Party Consultant"
        }
      });
    }
  } else {
    participants.push({
      //Principal
      userId: app.whoData.isPrincipal
        ? app.whoData.primaryApplicant.id
        : app.whoData.principalContactPerson.id,
      participantRole: {
        id: ParticipantRoleEnum.PrincipalPrimaryContact,
        description: "Principal Primary Contact"
      }
    });
  }

  participants = participants.concat(
    app.whoData.additionalParticipants.map(x => {
      return {
        participantRole: x.participantRole,
        userId: x.participantContact.id
      };
    })
  );

  const wicket = new Wkt.Wkt();

  const sites = ttmSites.map(x => {
    return {
      fromStreetNumber: x.fromStreetNumber,
      toStreetNumber: x.toStreetNumber,
      streetAddress: x.streetAddress,
      roadClassId: x.roadClass.id,
      roadLevelId: x.roadLevel.id,
      ttmTypeId: x.ttmType.id,
      ttmTypeExplanation: x.ttmTypeExplanation,
      ttmImpactId: x.ttmImpact.id,
      ttmImpactExplanation: x.ttmImpactExplanation,
      workActivityId: x.workActivity.id,
      workActivityExplanation: x.workActivityExplanation,
      speedLimitId: x.speedLimit.id === 0 ? null : x.speedLimit.id,
      affectedAccessIds: x.affectedAccess.map(y => y.id),
      location: x.location,
      affectedAreaDescription: x.affectedAreaDescription,
      isTrafficDelayLikely: x.isTrafficDelayLikely,
      isPublicNotificationRequired: x.isPublicNotificationRequired,
      notes: x.notes,
      shapes: featuresToWkt(wicket, x.features),
      detours: x.detours.map(y => {
        return {
          description: y.description,
          notes: y.notes,
          shapes: featuresToWkt(wicket, y.features)
        };
      }),
      closures: x.closures.map(y => {
        return {
          proposedStart: y.proposedStart,
          proposedEnd: y.proposedEnd,
          actualStart: y.actualStart,
          actualEnd: y.actualEnd,
          notes: y.notes
        };
      })
    };
  });

  const applicationDetail = {
    //setup
    applicationId: app.id.toString(),
    applicationTypeId: app.setupData.applicationType,
    //where
    streetNumber: app.whereData.streetNumber,
    streetName: app.whereData.streetName,
    suburb: app.whereData.suburb,
    townCity: app.whereData.townCity,
    postCode: app.whereData.postCode,
    addressNotes: app.whereData.addressNotes,
    locationsInRoad: app.whereData.locationsInRoad.map(x => x.id),
    rcaIdList: app.rcaOptions
      .filter(x => x.isSubmiticaEnabled && x.isActive)
      .map(x => x.rcaId),
    shapes: featuresToWkt(wicket, app.whereData.shapes),
    //when
    workTypeId: app.whenData.workType.id,
    majorActivityIds: app.whenData.majorActivities.map(x => x.id),
    duration: app.whenData.duration,
    startDate: ToStartOfDay(app.whenData.startDate),
    endDate: ToStartOfDay(app.whenData.endDate),
    normalWorkingHours: app.whenData.normalWorkingHours,
    //who
    principalRoleId: app.whoData.principalRole?.id,
    participants: participants,
    //what
    description: app.whatData.description,
    isGlobalCar: app.whatData.isGlobalCar,
    activityIds: app.whatData.activities.map(x => x.id),
    //traffic
    roadLevelId: app.trafficData.roadLevel?.id,
    isRoadClosureRequired: app.trafficData.isFullRoadClosureRequired,
    //extra
    consentNumber: app.extraData.consentNumber,
    utilityReferenceNumber: app.extraData.utilityReferenceNumber,
    purchaseOrderNumber: app.extraData.purchaseOrderNumber,
    extraInformation: app.extraData.extraInformation,
    billingPreview: app.billingPreview,
    noTMP: (app.setupData.applicationType === ApplicationType.Preliminary && (!app.trafficData.tmp || app.trafficData.tmp?.length === 0)) ? true : false
  };

  const attachments = app.trafficData.tmp?.map(x => {
    return {
      identifier: x.identifier.toString(),
      fileName: x.filename,
      attachmentTypeId: x.attachmentType.id
    };
  });

  if (app.extraData.extraAttachments) {
    attachments?.push(
      ...app.extraData.extraAttachments.map(x => {
        return {
          identifier: x.identifier.toString(),
          fileName: x.filename,
          attachmentTypeId: x.attachmentType.id
        };
      })
    );
  }

  return axios.post(`${API_ROUTE}${APPLICATION_ENDPOINT}/add`, {
    applicationDetail: applicationDetail,
    attachments: attachments,
    sites: sites,
    offset: GetOffsetUtc()
  });
};

function* submitApplicationRequest(action: SubmitApplicationRequestAction) {
  try {
    const { data }: { data: ApplicationList[] } = yield call(
      submitApplication,
      action.newApplication,
      action.ttmSites
    );

    yield put(submitApplicationSuccess(data, false));
  } catch (e) {
    const message = e.response ? e.response.data : e.message;

    if (message.Message) {
      yield put(
        enqueueSnackbar(
          createNotification(
            SUBMIT_APPLICATION_FAILURE,
            message.Message,
            message.MessageType
          )
        )
      );

      yield put(submitApplicationSuccess([], true));
    } else {
      yield put(submitApplicationFailure("Failed to submit the application"));
    }
  }
}

const getApplicationSettings = (rcaIds: number[]) => {
  return axios.post(
    `${API_ROUTE}${ADMINISTRATION_ENDPOINT}/applicationsettings`,
    { organisationIds: rcaIds }
  );
};

function* applicationSettingsDataRequest(
  action: LoadApplicationSettingsDataRequestAction
) {
  try {
    const { data }: { data: ApplicationSettingsData } = yield call(
      getApplicationSettings,
      action.rcaIds
    );
    yield put(loadApplicationSettingsDataSuccess(data));
  } catch (e) {
    yield put(
      loadApplicationSettingsDataFailure("Failed to fetch application settings")
    );
  }
}

export function* watchApplicationSettingsDataRequest() {
  yield takeLatest(
    LOAD_APPLICATION_SETTINGS_DATA_REQUEST,
    applicationSettingsDataRequest
  );
}

function* childApplicationSettingsDataRequest(
  action: LoadSiblingApplicationSettingsRequestAction
) {
  try {
    const { data }: { data: ApplicationSettingsData } = yield call(
      getApplicationSettings,
      action.rcaIds
    );

    yield put(loadSiblingApplicationSettingsDataSuccess(data));
  } catch (e) {
    yield put(
      loadSiblingApplicationSettingsFailure(
        "Failed to fetch child application settings"
      )
    );
  }
}

export function* watchChildApplicationSettingsDataRequest() {
  yield takeLatest(
    LOAD_SIBLING_APPLICATION_SETTINGS_DATA_REQUEST,
    childApplicationSettingsDataRequest
  );
}

const loadBillingPreview = (
  billingPreviewRequestModel: BillingPreviewRequestModel
) => {
  return axios.post(
    `${API_ROUTE}${BILLING_ENDPOINT}/billingpreview`,
    billingPreviewRequestModel
  );
};

function* loadBillingItemPreviewRequest(
  action: LoadBillingItemsPreviewRequestAction
) {
  try {
    const { data }: { data: RcaBillableItemGroupingModel[] } = yield call(
      loadBillingPreview,
      action.billingPreviewRequest
    );

    yield put(loadBillingItemPreviewSuccess(data));
  } catch (e) {
    yield put(
      loadBillingItemPreviewFailure(
        "Failed to fetch Billing Items for this application"
      )
    );
  }
}

const loadRcaIdsFromShapes = (shapes: string[]) => {
  return axios.post(
    `${API_ROUTE}${ADMINISTRATION_ENDPOINT}/rcaorganisations/getRcaIdsFromShapes`,
    {
      wktShapes: shapes
    }
  );
};

function* loadRcaIdsFromShapesRequest(
  action: LoadRcaIdsFromShapesRequestAction
) {
  try {
    if (action.shapes.length > 0) {
      const wicket = new Wkt.Wkt();

      const wktStrings = action.shapes.map(shape => {
        return featureToWkt(wicket, shape);
      });

      const { data }: { data: RcaApplicationModel[] } = yield call(
        loadRcaIdsFromShapes,
        wktStrings
      );

      yield put(loadRcaIdsFromShapesSuccess(data));
    } else {
      yield put(loadRcaIdsFromShapesSuccess([]));
    }
  } catch (e) {
    const message = e.response.data;

    if (message.Message) {
      yield put(
        enqueueSnackbar(
          createNotification(
            LOAD_RCA_IDS_FROM_SHAPES_FAILURE,
            message.Message,
            message.MessageType
          )
        )
      );
    } else {
      yield put(
        loadRcaIdsFromShapesFailure(
          "Failed to load RCA matches for the drawn shapes"
        )
      );
    }
  }
}

const loadRcaOptionsFromIdApi = (rcaId: number) => {
  return axios.get(
    `${API_ROUTE}${ADMINISTRATION_ENDPOINT}/rcaorganisations/getRcaOptionFromId/${rcaId}`
  );
};

function* loadRcaOptionFromId(action: LoadRcaOptionFromIdRequestAction) {
  try {
    const { data }: { data: RcaApplicationModel } = yield call(
      loadRcaOptionsFromIdApi,
      action.rcaId
    );

    yield put(loadRcaOptionsFromIdSuccess(data));
  } catch (e) {
    const message = e.response.data;

    if (message.Message) {
      yield put(
        enqueueSnackbar(
          createNotification(
            LOAD_RCA_OPTION_FROM_ID_FAILURE,
            message.Message,
            message.MessageType
          )
        )
      );
    } else {
      yield put(loadRcaOptionsFromIdFailure("Failed to load an RCA match"));
    }
  }
}

export function* watchLoadRcaOptionsFromIdRequest() {
  yield takeLatest(LOAD_RCA_OPTION_FROM_ID_REQUEST, loadRcaOptionFromId);
}

export function* watchTemporaryFileUploadRequest() {
  yield takeLatest(
    UPLOAD_CREATE_APPLICATION_TEMPORARY_FILE_REQUEST,
    uploadTemporaryFileRequest
  );
}

export function* watchTemporaryFileDeleteRequest() {
  yield takeLatest(
    DELETE_CREATE_APPLICATION_TEMPORARY_FILE_REQUEST,
    deleteTemporaryFileRequest
  );
}

export function* watchSubmitApplicationRequest() {
  yield takeLatest(SUBMIT_APPLICATION_REQUEST, submitApplicationRequest);
}

export function* watchLoadBillingItemPreviewRequest() {
  yield takeLatest(
    LOAD_BILLING_ITEMS_PREVIEW_REQUEST,
    loadBillingItemPreviewRequest
  );
}

export function* watchLoadRcaIdsFromShapesRequest() {
  yield takeLatest(
    LOAD_RCA_IDS_FROM_SHAPES_REQUEST,
    loadRcaIdsFromShapesRequest
  );
}

const loadPrimaryApplicantInfoApi = (userId: number, rcaIds?: number[]) => {
  return axios.post(`${API_ROUTE}${BILLING_ENDPOINT}/primaryApplicantInfo`, {
    rcaIds: rcaIds,
    userId: userId
  });
};

export function* loadPrimaryApplicantInfo(
  action: LoadPrimaryApplicantInfoRequestAction
) {
  try {
    const {
      data
    }: { data: { billPayer: Lookup; organisation: Lookup } } = yield call(
      loadPrimaryApplicantInfoApi,
      action.userId,
      action.rcaIds
    );

    yield put(
      loadPrimaryApplicantInfoSuccess(data.billPayer, data.organisation)
    );
  } catch (e) {
    const message = e.response.data;

    if (message.Message) {
      enqueueSnackbar(
        createNotification(
          LOAD_PRIMARY_APPLICANT_INFO_FAILURE,
          message.Message,
          message.MessageType
        )
      );
    }
    yield put(
      loadPrimaryApplicantInfoFailure(
        "Something went wrong when trying to load the primary applicant information"
      )
    );
  }
}

export function* watchLoadPrimaryApplicantInfoRequest() {
  yield takeLatest(
    LOAD_PRIMARY_APPLICANT_INFO_REQUEST,
    loadPrimaryApplicantInfo
  );
}

const setTemporarySavedApplicationApi = (
  newApplication: NewApplication,
  ttmSites: TTMSite[]
) => {
  const wicket = new Wkt.Wkt();

  const temporaryCarModel = {
    storageId: newApplication.id.toString(),
    description: `${GetApplicationTypeString(
      newApplication.setupData.applicationType!
    )} application saved on ${moment().format("Do MMMM YYYY, h:mm:ss a")}`,
    applicationDetail: JSON.stringify({
      ...newApplication,
      whereData: {
        ...newApplication.whereData,
        shapes: featuresToWkt(wicket, newApplication.whereData.shapes)
      }
    }),
    ttmDetail: JSON.stringify(
      ttmSites.map(site => ({
        ...site,
        features: featuresToWkt(wicket, site.features),
        detours: site.detours.map(detour => ({
          ...detour,
          features: featuresToWkt(wicket, detour.features)
        }))
      }))
    )
  };

  return axios.post(
    `${API_ROUTE}${APPLICATION_ENDPOINT}/${TEMPORARY_CONTROLLER}`,
    temporaryCarModel
  );
};

export function* setTemporarySavedApplication(
  action: SetTemporarySavedApplicationRequestAction
) {
  try {
    yield call(
      setTemporarySavedApplicationApi,
      action.newApplication,
      action.ttmSites
    );
    // TODO potentially coming in a future PR
  } catch (e) {
    // TODO as above
  }
}

export function* watchSetTemporarySavedApplicationRequest() {
  yield takeLatest(
    SET_TEMPORARY_SAVED_APPLICATION_REQUEST,
    setTemporarySavedApplication
  );
}

const registeredOrganisationApi = (
  organisationId: number,
  rcaIds: number[]
) => {
  return axios.post(
    `${API_ROUTE}${ADMINISTRATION_ENDPOINT}/${ORGANISATIONS_CONTROLLER}/registeredorganisation`,
    {
      organisationId: organisationId,
      rcaIds: rcaIds
    }
  );
};

export function* checkRegisteredOrganisation(
  action: CheckRegisteredOrganisationRequestAction
) {
  try {
    const { data }: { data: OrganisationRegisters } = yield call(
      registeredOrganisationApi,
      action.organisationId,
      action.rcaIds
    );

    yield put(checkRegisteredOrganisationSuccess(data));
  } catch (e) {
    const message = e.response.data;

    if (message.Message) {
      enqueueSnackbar(
        createNotification(
          CHECK_REGISTERED_ORGANISATION_FAILURE,
          message.Message,
          message.MessageType
        )
      );
    }
    yield put(
      checkRegisteredOrganisationFailure(
        "Something went wrong when trying to check if current organisation is a registered utility operator"
      )
    );
  }
}

export function* watchCheckRegisteredOrganisationRequest() {
  yield takeLatest(
    CHECK_REGISTERED_ORGANISATION_REQUEST,
    checkRegisteredOrganisation
  );
}

const loadSavedApplicationsApi = () => {
  return axios.get(
    `${API_ROUTE}${APPLICATION_ENDPOINT}/${TEMPORARY_CONTROLLER}`
  );
};

export function* loadSavedApplications() {
  try {
    const { data }: { data: TemporaryDisplaySavedApplication[] } = yield call(
      loadSavedApplicationsApi
    );

    yield put(loadSavedApplicationsSuccess(data));
  } catch (e) {
    const message = e.response.data;

    if (message.Message) {
      enqueueSnackbar(
        createNotification(
          LOAD_SAVED_APPLICATION_LIST_FAILURE,
          message.Message,
          message.MessageType
        )
      );
    }
    yield put(
      loadSavedApplicationsFailure(
        "Something went wrong when trying to load the list of saved applications"
      )
    );
  }
}

export function* watchLoadSavedApplicationsRequest() {
  yield takeLatest(LOAD_SAVED_APPLICATION_LIST_REQUEST, loadSavedApplications);
}

const deleteSavedApplicationApi = (id: Guid) => {
  return axios.delete(
    `${API_ROUTE}${APPLICATION_ENDPOINT}/${TEMPORARY_CONTROLLER}/${id}`
  );
};

export function* deleteSavedApplication(
  action: DeleteSavedApplicationRequestAction
) {
  try {
    yield call(deleteSavedApplicationApi, action.id);

    yield put(deleteSavedApplicationSuccess(action.id));
  } catch (e) {
    const message = e.response.data;

    if (message.Message) {
      enqueueSnackbar(
        createNotification(
          DELETE_SAVED_APPLICATION_FAILURE,
          message.Message,
          message.MessageType
        )
      );
    }
    yield put(
      loadSavedApplicationsFailure(
        "Something went wrong when trying to delete the saved application"
      )
    );
  }
}

export function* watchDeleteSavedApplicationRequest() {
  yield takeLatest(DELETE_SAVED_APPLICATION_REQUEST, deleteSavedApplication);
}

const loadSavedApplicationApi = (id: Guid) => {
  return axios.get(
    `${API_ROUTE}${APPLICATION_ENDPOINT}/${TEMPORARY_CONTROLLER}/${id}`
  );
};

export function* loadSavedApplication(
  action: LoadSavedApplicationRequestAction
) {
  try {
    const wicket = new Wkt.Wkt();

    const { data }: { data: TemporarySavedApplication } = yield call(
      loadSavedApplicationApi,
      action.id
    );

    // manipulate data into correct format

    let application = JSON.parse(data.applicationDetail);

    application = {
      ...application,
      currentMaxStep: application.currentMaxStep,
      id: Guid.parse(application.id.value),
      whereData: {
        ...application.whereData,
        shapes: wktToFeatures(wicket, application.whereData.shapes)
      },
      whenData: {
        ...application.whenData,
        startDate: application.whenData.startDate
          ? new Date(application.whenData.startDate)
          : undefined,
        endDate: application.whenData.endDate
          ? new Date(application.whenData.endDate)
          : undefined
      },
      trafficData: {
        ...application.trafficData,
        tmp: application.trafficData.tmp
          ? application.trafficData.tmp.map((tmp: TemporaryAttachment) => ({
            ...tmp,
            identifier: Guid.parse((tmp.identifier as any).value)
          }))
          : undefined
      },
      extraData: {
        ...application.extraData,
        extraAttachments: application.extraData.extraAttachments
          ? application.extraData.extraAttachments.map(
            (attachment: TemporaryAttachment) => ({
              ...attachment,
              identifier: Guid.parse((attachment.identifier as any).value)
            })
          )
          : undefined
      }
    };

    let ttm = JSON.parse(data.ttmDetail);

    ttm = ttm.map((site: TTMSite) => ({
      ...site,
      features: site.features
        ? wktToFeatures(wicket, site.features as any)
        : [],
      detours:
        site && site.detours
          ? site.detours.map((detour: TTMDetour) => ({
            ...detour,
            features: wktToFeatures(wicket, detour.features as any)
          }))
          : undefined,
      closures:
        site && site.closures
          ? site.closures.map((closure: TTMClosure) => ({
            ...closure,
            proposedStart: closure.proposedStart
              ? new Date(closure.proposedStart)
              : undefined,
            proposedEnd: closure.proposedEnd
              ? new Date(closure.proposedEnd)
              : undefined,
            actualStart: closure.actualStart
              ? new Date(closure.actualStart)
              : undefined,
            actualEnd: closure.actualEnd
              ? new Date(closure.actualEnd)
              : undefined
          }))
          : undefined
    }));

    // send data off
    yield put(loadSavedApplicationSuccess(application));
    yield put(duplicateTTM(ttm));
  } catch (e) {
    if (e.response) {
      const message = e.response.data;

      if (message.Message) {
        enqueueSnackbar(
          createNotification(
            LOAD_SAVED_APPLICATION_FAILURE,
            message.Message,
            message.MessageType
          )
        );
      }
    } else {
      yield put(
        loadSavedApplicationFailure(
          "Something went wrong when trying to load the saved application"
        )
      );
    }
  }
}

export function* watchLoadSavedApplicationRequest() {
  yield takeLatest(LOAD_SAVED_APPLICATION_REQUEST, loadSavedApplication);
}

const getDefaultBillPayerApi = (organisationId: number, rcaIds?: number[], userId?: number | null) => {
  return axios.post(`${API_ROUTE}${BILLING_ENDPOINT}/getDefaultBillPayer`, {
    rcaIds: rcaIds,
    organisationId: organisationId,
    userId: userId
  });
};

export function* getDefaultBillPayer(
  action: GetDefaultBillPayerRequestAction
) {
  try {
    const {
      data
    }: { data: Lookup } = yield call(
      getDefaultBillPayerApi,
      action.organisationId,
      action.rcaIds,
      action.userId
    );

    yield put(
      getDefaultBillPayerSuccess(data)
    );
  } catch (e) {
  }
}

export function* watchGetDefaultBillPayerRequest() {
  yield takeLatest(
    GET_DEFAULT_BILLPAYER_REQUEST,
    getDefaultBillPayer
  );
}