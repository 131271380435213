import { Action } from "redux";
import { ErrorPayload } from "store/error/types";

export const LOAD_INVOICES_SUMMARY_REQUEST = "LOAD_INVOICES_SUMMARY_REQUEST";
export const LOAD_INVOICES_SUMMARY_SUCCESS = "LOAD_INVOICES_SUMMARY_SUCCESS";
export const LOAD_INVOICES_SUMMARY_FAILURE = "LOAD_INVOICES_SUMMARY_FAILURE";
export const LOAD_INVOICES_SUMMARY_REQUEST_EXTRA =
  "LOAD_INVOICES_SUMMARY_REQUEST_EXTRA";

export const RESET_INVOICES_SUMMARY = "RESET_INVOICES_SUMMARY";

export type InvoicesSummaryOptions = {
  skip: number;
};

export type LoadInvoicesSummaryRequestAction = Action<
  typeof LOAD_INVOICES_SUMMARY_REQUEST
> & {
  invoicesSummaryOptions: InvoicesSummaryOptions;
};

export type LoadInvoicesSummaryRequestExtraAction = Action<
  typeof LOAD_INVOICES_SUMMARY_REQUEST_EXTRA
> & {
  invoicesSummaryOptions: InvoicesSummaryOptions;
};

type LoadInvoicesSummarySuccessAction = Action<
  typeof LOAD_INVOICES_SUMMARY_SUCCESS
> &
  InvoicesSummarySuccessPayload;

type LoadInvoicesSummaryFailureAction = Action<
  typeof LOAD_INVOICES_SUMMARY_FAILURE
> &
  ErrorPayload;

type ResetBillPayersSummaryAction = Action<typeof RESET_INVOICES_SUMMARY>;

export type InvoicesSummaryActionTypes =
  | LoadInvoicesSummaryRequestAction
  | LoadInvoicesSummaryRequestExtraAction
  | LoadInvoicesSummarySuccessAction
  | LoadInvoicesSummaryFailureAction
  | ResetBillPayersSummaryAction;

export type InvoiceSummaryState = {
  invoiceSummaryData: InvoiceSummary[];
  totalInvoices: number;
};

export type InvoicesSummarySuccessPayload = {
  receivedData: InvoiceSummary[];
  totalCount: number;
};

export type InvoiceSummary = {
  invoiceId: number;
  organisation: string;
  rcaName: string;
  totalAmount: number;
  description: string;
};
