import { Action } from "redux";
import { ErrorPayload } from "store/error/types";

export const LOAD_INVOICE_DETAIL_REQUEST = "LOAD_INVOICE_DETAIL_REQUEST";
export const LOAD_INVOICE_DETAIL_SUCCESS = "LOAD_INVOICE_DETAIL_SUCCESS";
export const LOAD_INVOICE_DETAIL_FAILURE = "LOAD_INVOICE_DETAIL_FAILURE";
export const RESET_INVOICE_DETAIL = "RESET_INVOICE_DETAIL";

export type InvoiceDetailOptions = {
  invoiceId: number;
};

export type LoadInvoiceDetailRequestAction = Action<
  typeof LOAD_INVOICE_DETAIL_REQUEST
> & {
  invoiceDetailOptions: InvoiceDetailOptions;
};

export type LoadInvoiceDetailSuccessAction = Action<
  typeof LOAD_INVOICE_DETAIL_SUCCESS
> &
  InvoiceDetailSuccessPayload;

type LoadInvoiceDetailFailureAction = Action<
  typeof LOAD_INVOICE_DETAIL_FAILURE
> &
  ErrorPayload;

type ResetInvoiceDetailAction = Action<typeof RESET_INVOICE_DETAIL>;

export type InvoiceDetailActionTypes =
  | LoadInvoiceDetailRequestAction
  | LoadInvoiceDetailSuccessAction
  | LoadInvoiceDetailFailureAction
  | ResetInvoiceDetailAction;

export type InvoiceDetailSuccessPayload = {
  invoiceHeaderData: InvoiceHeaderData;
  receivedBillingItems: InvoiceBillingItem[];
  totalCount: number;
};

export type InvoiceHeaderData = {
  description: string;
  notes: string;
  organisationName: string;
  rcaName: string;
  addedOn: Date;
  totalAmount: number;
};

export type InvoiceBillingItem = {
  applicationId: number;
  applicationNumber: string;
  itemNumber: string;
  description: string;
  purchaseOrder: string;
  unit: string;
  quantity: number;
  rate: number;
  amount: number;
  rebatedItem: boolean;
};

export type ExportInvoiceItem = {
  rcaName?: string;
  applicationNumber: string;
  itemNumber: string;
  description: string;
  purchaseOrder: string;
  unit: string;
  quantity: number;
  rate: number;
  amount: number;
  rebatedItem: string;
};

export type InvoiceDetailState = {
  invoiceBillingItems: InvoiceBillingItem[];
  totalBillingItems: number;
  invoiceDetailData?: InvoiceHeaderData;
};
