import React from "react";
import SubmiticaLogo from "assets/img/submitica-s.png";
import styled from "styled-components";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Theme,
  makeStyles,
  createStyles,
  useTheme
} from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import { SubmiticaGreen } from "styles/colours";

const Logo = styled.img`
  height: 75px;
  margin-left: 45px;
  margin-top: 45px;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonProgress: {
      color: SubmiticaGreen,
      position: "absolute"
    }
  })
);

type LoaderProps = {
  isPartial?: boolean;
};

const Loader = (props: LoaderProps) => {
  const classes = useStyles();

  const isScreenSmall = useMediaQuery(useTheme().breakpoints.down("sm"));

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        minHeight: props.isPartial ? "250px" : "",
        height: props.isPartial
          ? ""
          : isScreenSmall
          ? "calc(100vh - 140px)"
          : "calc(100vh - 70px)",
        width: "100%",
        alignItems: "center"
      }}
    >
      <CircularProgress
        className={`${classes.buttonProgress}`}
        size={160}
        thickness={1}
      ></CircularProgress>
      <Logo style={{ marginTop: "0", marginLeft: "0" }} src={SubmiticaLogo} />
    </div>
  );
};

export default Loader;
