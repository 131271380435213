import produce from "immer";
import {
  CreateApplicationState,
  CreateApplicationActionTypes,
  RESET_CREATE_APPLICATION,
  SET_CREATE_APPLICATION_NEXT_STEP,
  SET_CREATE_APPLICATION_WHAT_DATA,
  SET_CREATE_APPLICATION_WHERE_DATA,
  SET_CREATE_APPLICATION_TRAFFIC_DATA,
  SET_CREATE_APPLICATION_WHEN_DATA,
  SET_CREATE_APPLICATION_EXTRA_DATA,
  SET_CREATE_APPLICATION_SETUP_DATA,
  SET_CREATE_APPLICATION_WHO_DATA,
  RESET_APPLICATION_TYPE_DEPENDENT_DATA,
  UPLOAD_CREATE_APPLICATION_TEMPORARY_FILE_SUCCESS,
  DELETE_CREATE_APPLICATION_TEMPORARY_FILE_SUCCESS,
  SUBMIT_APPLICATION_SUCCESS,
  LOAD_APPLICATION_SETTINGS_DATA_SUCCESS,
  LOAD_BILLING_ITEMS_PREVIEW_SUCCESS,
  LOAD_RCA_IDS_FROM_SHAPES_SUCCESS,
  RESET_STORE_FORCE_RCA_SELECTION,
  LOAD_RCA_OPTION_FROM_ID_SUCCESS,
  LOAD_PRIMARY_APPLICANT_INFO_SUCCESS,
  DUPLICATE_APPLICATION,
  RESET_DUPLICATING_STATUS,
  CHECK_REGISTERED_ORGANISATION_SUCCESS,
  LOAD_SAVED_APPLICATION_LIST_SUCCESS,
  DELETE_SAVED_APPLICATION_SUCCESS,
  LOAD_SAVED_APPLICATION_SUCCESS,
  LOAD_SIBLING_APPLICATION_SETTINGS_DATA_SUCCESS,
  LOAD_SIBLING_APPLICATION_SETTINGS_DATA_RESET,
  RESET_HAS_REQUEST_FAILURE,
  GET_DEFAULT_BILLPAYER_SUCCESS
} from "./types";
import { Guid } from "guid-typescript";
import { ApplicationType, ParticipantRoleEnum } from "common/enums";
import { AdditionalParticipant } from "store/types";

const initialState: CreateApplicationState = {
  newApplicationList: undefined,
  savedApplications: [],
  hasRequestFailure: false,
  newApplication: {
    id: Guid.create(),
    currentMaxStep: 0,
    rcaOptions: [],
    billingPreview: [],
    setupData: {
      hasNoConflicts: false,
      hasTMPForUpload: false,
      hasServicePlans: false,
      isDuplicating: false
    },
    whatData: {
      isGlobalCar: false,
      description: "",
      activities: []
    },
    whereData: {
      streetNumber: "",
      streetName: "",
      suburb: "",
      townCity: "",
      postCode: "",
      locationsInRoad: [],
      addressNotes: "",
      shapes: [],
      forceRcaSelection: false
    },
    trafficData: {
      roadLevel: null,
      isFullRoadClosureRequired: false
    },
    whenData: {
      workType: { id: 0, description: "" },
      majorActivities: [],
      duration: "0",
      startDate: undefined,
      endDate: undefined,
      normalWorkingHours: ""
    },
    whoData: {
      isPrincipal: true,
      isPrincipalAnOrganisation: true,
      billPayer: { id: 0, description: "" },
      billPayerOrganisation: { id: 0, description: "" },
      isOnBehalfOfUtilityOperator: true,
      primaryApplicant: { id: 0, description: "" },
      primaryApplicantOrganisation: { id: 0, description: "" },
      principalContactPerson: { id: 0, description: "" },
      principalOrganisation: { id: 0, description: "" },
      utilityOperatorContact: { id: 0, description: "" },
      utilityOperatorOrganisation: { id: 0, description: "" },
      principalRole: null,
      additionalParticipants: []
    },
    extraData: {
      consentNumber: "",
      utilityReferenceNumber: "",
      purchaseOrderNumber: "",
      extraInformation: ""
    }
  }
};

const createApplicationReducer = produce(
  (draft: CreateApplicationState, action: CreateApplicationActionTypes) => {
    switch (action.type) {
      case RESET_CREATE_APPLICATION: {
        draft.newApplicationList = undefined;
        draft.newApplication = {
          ...initialState.newApplication,
          id: Guid.create()
        };
        draft.savedApplications = [];
        draft.hasRequestFailure = false;
        return;
      }
      case SET_CREATE_APPLICATION_NEXT_STEP: {
        draft.newApplication.currentMaxStep++;
        return;
      }
      case SET_CREATE_APPLICATION_SETUP_DATA: {
        draft.newApplication.setupData = action.setupData;
        switch (action.setupData.applicationType) {
          case ApplicationType.Excavation:
          case ApplicationType.Preliminary:
          case ApplicationType.Retrospective:
            draft.newApplication.whoData.showUtilityOperatorOption = true;
            draft.newApplication.whoData.showPrincipalFillOutForm = false;
            return;
          case ApplicationType.Event:
          case ApplicationType.NonExcavation:
            draft.newApplication.whoData.showUtilityOperatorOption = false;
            draft.newApplication.whoData.showPrincipalFillOutForm = true;
            return;
        }
        return;
      }
      case SET_CREATE_APPLICATION_WHAT_DATA: {
        draft.newApplication.whatData = action.whatData;
        return;
      }
      case SET_CREATE_APPLICATION_WHERE_DATA: {
        draft.newApplication.whereData = action.whereData;
        return;
      }
      case SET_CREATE_APPLICATION_TRAFFIC_DATA: {
        draft.newApplication.trafficData = action.trafficData;
        return;
      }
      case SET_CREATE_APPLICATION_WHEN_DATA: {
        draft.newApplication.whenData = action.whenData;
        return;
      }
      case SET_CREATE_APPLICATION_WHO_DATA: {
        draft.newApplication.whoData = action.whoData;
        return;
      }
      case SET_CREATE_APPLICATION_EXTRA_DATA: {
        draft.newApplication.extraData = action.extraData;
        return;
      }
      case UPLOAD_CREATE_APPLICATION_TEMPORARY_FILE_SUCCESS: {
        draft.newApplication.trafficData.tmp = draft.newApplication.trafficData.tmp?.map(
          file => {
            if (action.fileIdentifiers.includes(file.identifier)) {
              file.isUploaded = true;
            }
            return file;
          }
        );
        draft.newApplication.extraData.extraAttachments = draft.newApplication.extraData.extraAttachments?.map(
          file => {
            if (action.fileIdentifiers.includes(file.identifier)) {
              file.isUploaded = true;
            }
            return file;
          }
        );
        return;
      }
      case DELETE_CREATE_APPLICATION_TEMPORARY_FILE_SUCCESS: {
        draft.newApplication.trafficData.tmp = draft.newApplication.trafficData.tmp?.filter(
          x => x.identifier !== action.fileIdentifier
        );
        draft.newApplication.extraData.extraAttachments = draft.newApplication.extraData.extraAttachments?.filter(
          x => x.identifier !== action.fileIdentifier
        );
        return;
      }
      case SUBMIT_APPLICATION_SUCCESS: {
        if (action.hasRequestFailure) {
          draft.hasRequestFailure = action.hasRequestFailure;
          return;
        }

        draft.newApplicationList = action.applicationList;
        return;
      }
      case RESET_APPLICATION_TYPE_DEPENDENT_DATA: {
        if (!draft.newApplication.setupData.isDuplicating) {
          draft.newApplication.whereData.locationsInRoad = [];
          draft.newApplication.whenData.workType = { id: 0, description: "" };
          draft.newApplication.whenData.majorActivities = [];
          draft.newApplication.whoData.isOnBehalfOfUtilityOperator = true;
          draft.newApplication.whoData.principalRole = null;
          draft.newApplication.trafficData.isFullRoadClosureRequired = false;
          draft.newApplication.whatData.activities = [];
          draft.newApplication.whoData.utilityOperatorContact = {
            id: 0,
            description: ""
          };
          draft.newApplication.whoData.utilityOperatorOrganisation = {
            id: 0,
            description: ""
          };
          draft.newApplication.organisationRegisters = undefined;
          draft.newApplication.whenData.startDate = undefined;
          draft.newApplication.whenData.endDate = undefined;
          draft.newApplication.whenData.duration = "";
        }
        return;
      }
      case LOAD_APPLICATION_SETTINGS_DATA_SUCCESS: {
        draft.applicationSettingsData = action.applicationSettings;
        return;
      }
      case LOAD_BILLING_ITEMS_PREVIEW_SUCCESS: {
        draft.newApplication.billingPreview = action.data;
        return;
      }
      case LOAD_RCA_IDS_FROM_SHAPES_SUCCESS: {
        if (
          action.rcaOptions.length === 0 &&
          draft.newApplication.whereData.shapes &&
          draft.newApplication.whereData.shapes.length > 0
        ) {
          draft.newApplication.whereData.forceRcaSelection = true;
        } else {
          draft.newApplication.rcaOptions = action.rcaOptions;
        }
        return;
      }
      case RESET_STORE_FORCE_RCA_SELECTION: {
        draft.newApplication.whereData.forceRcaSelection = false;
        return;
      }
      case LOAD_RCA_OPTION_FROM_ID_SUCCESS: {
        draft.newApplication.rcaOptions = [action.rcaOption];
        return;
      }
      case LOAD_PRIMARY_APPLICANT_INFO_SUCCESS: {
        if (action.billPayer) {
          draft.newApplication.whoData.billPayer = action.billPayer;
        }
        if (
          draft.newApplication.whoData.billPayer &&
          draft.newApplication.whoData.billPayer.id !== 0
        ) {
          draft.newApplication.whoData.billPayerOrganisation =
            action.organisation;
        }

        draft.newApplication.whoData.primaryApplicantOrganisation =
          action.organisation;
        return;
      }
      case GET_DEFAULT_BILLPAYER_SUCCESS: {
        draft.newApplication.whoData.billPayer = action.billPayer;
        return;
      }
      case DUPLICATE_APPLICATION: {
        if (action.applicationData) {
          const { applicationData: data } = action;

          draft.newApplication.setupData.isDuplicating = true;

          if (data.participants) {
            const principal = data.participants.find(x => {
              return (
                x.participantRole.id ==
                ParticipantRoleEnum.PrincipalPrimaryContact
              );
            });
            if (principal) {
              draft.newApplication.whoData.principalOrganisation = principal.participantOrganisation ?? {
                id: 0,
                description: ""
              };
              draft.newApplication.whoData.principalContactPerson =
                principal.user;
            }

            const billPayer = data.participants.find(x => {
              return x.participantRole.id == ParticipantRoleEnum.BillPayer;
            });
            if (billPayer) {
              draft.newApplication.whoData.billPayerOrganisation = billPayer.participantOrganisation ?? {
                id: 0,
                description: ""
              };
              draft.newApplication.whoData.billPayer = billPayer.user;
            }

            const utilityOperator = data.participants.find(x => {
              return (
                x.participantRole.id ==
                ParticipantRoleEnum.UtilityOperatorPrimaryContact
              );
            });
            if (utilityOperator) {
              draft.newApplication.whoData.utilityOperatorOrganisation = utilityOperator.participantOrganisation ?? {
                id: 0,
                description: ""
              };
              draft.newApplication.whoData.utilityOperatorContact =
                utilityOperator.user;
            } else {
              draft.newApplication.whoData.isOnBehalfOfUtilityOperator = false;
            }

            const applicant = data.participants.find(x => {
              return (
                x.participantRole.id ==
                ParticipantRoleEnum.ApplicantPrimaryContact
              );
            });
            if (applicant) {
              draft.newApplication.whoData.primaryApplicantOrganisation = applicant.participantOrganisation ?? {
                id: 0,
                description: ""
              };
              draft.newApplication.whoData.primaryApplicant = applicant.user;
            }

            const additionalParticipants = data.participants.filter(x => {
              return (
                x.participantRole.id !==
                ParticipantRoleEnum.PrincipalPrimaryContact &&
                x.participantRole.id !== ParticipantRoleEnum.BillPayer &&
                x.participantRole.id !==
                ParticipantRoleEnum.UtilityOperatorPrimaryContact &&
                x.participantRole.id !==
                ParticipantRoleEnum.ApplicantPrimaryContact
              );
            });

            draft.newApplication.whoData.additionalParticipants = additionalParticipants.map(
              x => {
                {
                  return {
                    participantCategory: {
                      id: x.participantCategoryId,
                      description: ""
                    },
                    participantRole: x.participantRole,
                    participantOrganisation: x.participantOrganisation,
                    participantContact: x.user
                  } as AdditionalParticipant;
                }
              }
            );

            draft.newApplication.whoData.principalRole = data.applicantRole;
          }

          draft.newApplication.whereData.shapes = data.features;
          draft.newApplication.setupData.applicationType = data.carType.id;

          draft.newApplication.whereData.streetName = data.streetName;
          draft.newApplication.whereData.streetNumber = data.streetNumber;
          draft.newApplication.whereData.suburb = data.suburb;
          draft.newApplication.whereData.townCity = data.townCity;
          draft.newApplication.whereData.postCode = data.postcode;
          draft.newApplication.whereData.locationsInRoad = data.locationsInRoad;
          draft.newApplication.whereData.addressNotes = data.addressNotes;

          draft.newApplication.whenData.normalWorkingHours =
            data.hoursOfWork ?? "";
          draft.newApplication.whenData.workType = data.workType;
          draft.newApplication.whenData.majorActivities = data.majorActivities;

          draft.newApplication.whatData.description = data.description ?? "";
          draft.newApplication.whatData.activities = data.activities;
          draft.newApplication.whatData.isGlobalCar = data.globalCar;

          draft.newApplication.trafficData.roadLevel = data.roadLevel;
          draft.newApplication.trafficData.isFullRoadClosureRequired =
            data.isRoadClosureRequired;

          draft.newApplication.extraData.consentNumber =
            data.consentNumber ?? "";
          draft.newApplication.extraData.utilityReferenceNumber =
            data.utilityRefNumber ?? "";
          draft.newApplication.extraData.purchaseOrderNumber =
            data.purchaseOrderNumber ?? "";
          draft.newApplication.extraData.extraInformation = data.notes;
        }
        return;
      }
      case RESET_DUPLICATING_STATUS: {
        draft.newApplication.setupData.isDuplicating = false;
        return;
      }
      case CHECK_REGISTERED_ORGANISATION_SUCCESS: {
        draft.newApplication.organisationRegisters =
          action.organisationRegisters;
        return;
      }
      case LOAD_SAVED_APPLICATION_LIST_SUCCESS: {
        draft.savedApplications = action.savedApplications;
        return;
      }
      case DELETE_SAVED_APPLICATION_SUCCESS: {
        draft.savedApplications = draft.savedApplications.filter(
          x => x.id !== action.id
        );
        return;
      }
      case LOAD_SAVED_APPLICATION_SUCCESS: {
        draft.newApplication.setupData.isDuplicating = true;
        draft.newApplication = action.newApplication;
        return;
      }
      case LOAD_SIBLING_APPLICATION_SETTINGS_DATA_SUCCESS: {
        draft.siblingApplicationSettings = action.siblingApplicationSettings;
        return;
      }
      case LOAD_SIBLING_APPLICATION_SETTINGS_DATA_RESET: {
        draft.siblingApplicationSettings = undefined;
        return;
      }
      case RESET_HAS_REQUEST_FAILURE: {
        draft.hasRequestFailure = false;
        return;
      }
    }
  },
  initialState
);

export default createApplicationReducer;
