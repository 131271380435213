import {
  InvoiceSummaryState,
  InvoicesSummaryActionTypes,
  LOAD_INVOICES_SUMMARY_SUCCESS,
  RESET_INVOICES_SUMMARY
} from "./types";
import produce from "immer";

const initialState: InvoiceSummaryState = {
  invoiceSummaryData: [],
  totalInvoices: 0
};

const invoicesSummaryReducer = produce(
  (draft: InvoiceSummaryState, action: InvoicesSummaryActionTypes) => {
    switch (action.type) {
      case LOAD_INVOICES_SUMMARY_SUCCESS: {
        draft.invoiceSummaryData.push(...action.receivedData);
        draft.totalInvoices = action.totalCount;
        return;
      }
      case RESET_INVOICES_SUMMARY: {
        draft.invoiceSummaryData = [];
        return;
      }
    }
  },
  initialState
);

export default invoicesSummaryReducer;
