import produce from "immer";
import {
  LookupState,
  LookupActionTypes,
  LOAD_LOOKUP_SUCCESS,
  RESET_LOOKUP_DATA
} from "./types";

const initialState: LookupState = new Map();

const lookupReducer = produce(
  (draft: LookupState, action: LookupActionTypes) => {
    switch (action.type) {
      case LOAD_LOOKUP_SUCCESS: {
        draft.set(action.route, action.data);
        return;
      }
      case RESET_LOOKUP_DATA: {
        draft.delete(action.route);
        return;
      }
    }
  },
  initialState
);

export default lookupReducer;
