import {
  PreferencesState,
  PreferencesStateActionTypes,
  SETLIGHTTHEME,
  SETDARKTHEME,
  SETPREFERENCES,
  LOADPREFERENCES,
  CHANGE_VIEW_MODE,
  TABLE_VIEW,
  SET_COLUMN_SORTING,
  GET_FILTER_PREFERENCE_SUCCESS,
  SET_FILTER_PREFERENCE_SUCCESS
} from "./types";
import produce from "immer";
import { LIGHT, DARK } from "styles/themes/types";
import { FilterPreferences } from "store/types";

const getThemeFromStorage = () => {
  const themeFromStorage = localStorage.getItem("theme");

  switch (themeFromStorage) {
    case LIGHT: {
      return LIGHT;
    }
    case DARK: {
      return DARK;
    }
    default: {
      return LIGHT;
    }
  }
};

const initialState: PreferencesState = {
  theme: getThemeFromStorage(),
  isLoading: false,
  applicationsViewMode: TABLE_VIEW,
  filterPreferences: {
    searchValue: "",
    rcaSelection: [],
    statusSelection: [],
    stepSelection: [],
    typeSelection: [],
    roleSelection: [],
    peopleSelection: [],
    endDate: undefined,
    startDate: undefined,
    quickFilterSelection: undefined
  },
  columnSorting: []
};

const preferencesReducer = produce(
  (draft: PreferencesState, action: PreferencesStateActionTypes) => {
    switch (action.type) {
      case SETLIGHTTHEME:
        localStorage.setItem("theme", LIGHT);
        draft.theme = LIGHT;
        return;
      case LOADPREFERENCES:
        draft.isLoading = true;
        return;
      case SETDARKTHEME:
        localStorage.setItem("theme", DARK);
        draft.theme = DARK;
        return;
      case SETPREFERENCES:
        draft.isLoading = false;
        draft = action.preferences;
        return;
      case CHANGE_VIEW_MODE:
        draft.applicationsViewMode = action.viewMode;
        return;
      case SET_COLUMN_SORTING: {
        draft.columnSorting = action.columnSorting;
        return;
      }
      case GET_FILTER_PREFERENCE_SUCCESS: {
        if (action.filterPreference) {
          // Assigning "undefined" value for those that were saved as null into the database for comparing changes later on
          draft.filterPreferences = Object.keys(action.filterPreference).reduce(
            (acc, current) => {
              const currentData =
                action.filterPreference[
                `${current}` as keyof FilterPreferences
                ];

              if (current.toUpperCase().match(/DATE/g)) {
                acc = {
                  ...acc,
                  [current]:
                    currentData === null
                      ? undefined
                      : new Date(currentData as string)
                };
              } else {
                acc = {
                  ...acc,
                  [current]: currentData === null ? undefined : currentData
                };
              }

              return acc;
            },
            {} as FilterPreferences
          );
        } else {
          draft.filterPreferences = {
            ...initialState.filterPreferences,
            peopleSelection: [{ id: action.loggedInUserId, description: "Me" }]
          };
        }

        return;
      }
      case SET_FILTER_PREFERENCE_SUCCESS: {
        draft.filterPreferences = action.filterPreference;
        return;
      }
    }
  },
  initialState
);

export default preferencesReducer;
