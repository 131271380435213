import { Action } from "redux";
import { DARK, LIGHT } from "styles/themes/types";
import { FilterPreferences, SortModel } from "store/types";

export const SETDARKTHEME = "SETDARKTHEME";
export const SETLIGHTTHEME = "SETLIGHTTHEME";
export const SETPREFERENCES = "SETPREFERENCES";
export const RESETTHEME = "RESETTHEME";
export const LOADPREFERENCES = "LOADPREFERENCES";
export const MAP_VIEW = "MAP_VIEW";
export const TABLE_VIEW = "TABLE_VIEW";
export const DETAILED_VIEW = "DETAILED_VIEW";
export const TILE_VIEW = "TILE_VIEW";
export const CHANGE_VIEW_MODE = "CHANGE_VIEW_MODE";
export const SET_COLUMN_SORTING = "SET_COLUMN_SORTING";

// SAGA Constants START
export const GET_FILTER_PREFERENCE_REQUEST = "GET_FILTER_PREFERENCE_REQUEST";
export const GET_FILTER_PREFERENCE_SUCCESS = "GET_FILTER_PREFERENCE_SUCCESS";
export const GET_FILTER_PREFERENCE_FAILURE = "GET_FILTER_PREFERENCE_FAILURE";

export const SET_FILTER_PREFERENCE_REQUEST = "SET_FILTER_PREFERENCE_REQUEST";
export const SET_FILTER_PREFERENCE_SUCCESS = "SET_FILTER_PREFERENCE_SUCCESS";
export const SET_FILTER_PREFERENCE_FAILURE = "SET_FILTER_PREFERENCE_FAILURE";

// SAGA Constants END

export type PreferencesState = {
  isLoading: boolean;
  theme: typeof LIGHT | typeof DARK;
  applicationsViewMode: SummariesViewMode;
  filterPreferences: FilterPreferences;
  columnSorting: SortModel[];
};

type SetPreferencesActionType = Action<typeof SETPREFERENCES> & {
  preferences: PreferencesState;
};

type ChangeViewModeActionType = Action<typeof CHANGE_VIEW_MODE> & {
  viewMode: SummariesViewMode;
};

type SetColumnSortingActionType = Action<typeof SET_COLUMN_SORTING> & {
  columnSorting: SortModel[];
};

export type GetFilterPreferenceRequestAction = Action<
  typeof GET_FILTER_PREFERENCE_REQUEST
> & {
  loggedInUserId: number;
};

type GetFilterPreferenceSuccessAction = Action<
  typeof GET_FILTER_PREFERENCE_SUCCESS
> & {
  filterPreference: FilterPreferences;
  loggedInUserId: number;
};

export type SetFilterPreferenceRequestAction = Action<
  typeof SET_FILTER_PREFERENCE_REQUEST
> & {
  filterPreference: FilterPreferences;
};

type SetFilterPreferenceSuccessAction = Action<
  typeof SET_FILTER_PREFERENCE_SUCCESS
> & {
  filterPreference: FilterPreferences;
};

export type SummariesViewMode =
  | typeof MAP_VIEW
  | typeof TABLE_VIEW
  | typeof DETAILED_VIEW
  | typeof TILE_VIEW;

export type PreferencesStateActionTypes =
  | Action<typeof SETDARKTHEME>
  | Action<typeof SETLIGHTTHEME>
  | Action<typeof RESETTHEME>
  | Action<typeof LOADPREFERENCES>
  | SetPreferencesActionType
  | ChangeViewModeActionType
  | SetColumnSortingActionType
  | GetFilterPreferenceSuccessAction
  | SetFilterPreferenceSuccessAction;
