import * as Msal from "msal";
import React from "react";
import jwt_decode from "jwt-decode";
import axios from "axios";
import store from "store";

const LOCAL_STORAGE = "localStorage";
const SESSION_STORAGE = "sessionStorage";
const AUTHORIZATION_KEY = "Authorization";

const state = {
  stopLoopingRedirect: false,
  launchApp: null,
  accessToken: null,
  scopes: [],
  cacheLocation: LOCAL_STORAGE, // default value
  policy: null,
  auth: null,
  timeOutHandle: null,
  msalConfig: {
    auth: {
      clientId: null,
      authority: null,
      validateAuthority: false,
      redirectUri: null,
      postLogoutRedirectUri: null,
    },
    cache: {
      cacheLocation: LOCAL_STORAGE,
      storeAuthStateInCookie: false,
    },
  },
  apiConfig: {
    b2cScopes: [],
    webApi: null,
  },
  b2cPolicies: {
    names: {
      signUpSignIn: null,
      forgotPassword: null,
    },
    authorities: {
      signUpSignIn: { authority: null },
      forgotPassword: { authority: null },
    },
  },
  loginRequest: {
    scopes: ["openid", "profile"],
    forceRefresh: true,
  },
};

const isForgotPasswordError = (message) => message?.includes("AADB2C90118");

const authCallback = (error) => {
  if (error) {
    if (isForgotPasswordError(error.errorMessage)) {
      window.msal.loginRedirect(state.b2cPolicies.authorities.forgotPassword);
    } else if (error.errorMessage.includes("AADB2C90091") || error.errorMessage.includes("AADB2C90075")) {
      acquireToken();
    } else {
      state.stopLoopingRedirect = true;
    }
  } else {
    acquireToken();
  }
};

const cleanUpStorage = (cacheLocation) => {
  const storage = cacheLocation === LOCAL_STORAGE ? localStorage : sessionStorage;
  storage.removeItem(AUTHORIZATION_KEY);
};

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (originalRequest && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      return new Promise((resolve) => {
        acquireToken(() => {
          originalRequest.headers[AUTHORIZATION_KEY] = state.auth;
          resolve(axios(originalRequest));
        });
      });
    }
    return Promise.reject(error);
  }
);

const applyToken = (jwt) => {
  state.auth = `Bearer ${jwt}`;
  axios.defaults.headers.common[AUTHORIZATION_KEY] = state.auth;

  const decoded = jwt_decode(jwt);
  store.dispatch(getAuthorization(decoded));

  clearTimeout(state.timeOutHandle);
  const expirationTime = new Date(decoded.exp * 1000) - Date.now() - 3000;

  state.timeOutHandle = setTimeout(acquireToken, expirationTime);
};

const getAuthorization = (decoded) => ({
  type: "LOGIN_USER_SUCCESS",
  loginSuccessData: {
    userId: decoded.submiticaUserId,
    firstName: decoded.submiticaFirstName,
    lastName: decoded.submiticaLastName,
    organisationId: decoded.submiticaOrganisationId,
    organisationName: decoded.submiticaOrganisationName,
    isOrganisationManager: decoded.isOrganisationManager,
    profileUpdateRequired: decoded.profileUpdateRequired,
    isAuthenticated: true,
  },
});

const acquireToken = (successCallback) => {
  window.msal.acquireTokenSilent(state).then(
    (result) => {
      const token = `Bearer ${result.accessToken}`;
      if (state.cacheLocation === LOCAL_STORAGE) {
        localStorage.setItem(AUTHORIZATION_KEY, token);
      } else {
        sessionStorage.setItem(AUTHORIZATION_KEY, token);
      }

      state.accessToken = result.accessToken;
      applyToken(result.accessToken);
      if (successCallback) successCallback();
    },
    () => {
      window.msal.loginRedirect(state.scopes);
    }
  );
};

const authentication = {
  initialize: (config) => {
    cleanUpStorage(config.cacheLocation);
    state.b2cPolicies.names.signUpSignIn = config.signInPolicy;
    state.b2cPolicies.names.forgotPassword = config.resetPolicy;

    const { tenantName, tenant, redirectUri, postLogoutRedirectUri } = config;
    state.b2cPolicies.authorities.signUpSignIn.authority = `https://${tenantName}.b2clogin.com/${tenant}/${config.signInPolicy}`;
    state.b2cPolicies.authorities.forgotPassword.authority = `https://${tenantName}.b2clogin.com/${tenant}/${config.resetPolicy}`;

    state.apiConfig.b2cScopes = config.scopes;
    state.apiConfig.webApi = `https://${tenant}/api`;
    state.msalConfig.auth = {
      clientId: config.clientId,
      authority: state.b2cPolicies.authorities.signUpSignIn.authority,
      validateAuthority: false,
      redirectUri,
      postLogoutRedirectUri,
    };
    state.scopes = config.scopes;
    state.cacheLocation = config.cacheLocation;

    new Msal.UserAgentApplication(state.msalConfig);
    window.msal.handleRedirectCallback(authCallback);
  },
  run: (launchApp) => {
    state.launchApp = launchApp;
    if (!window.msal.isCallback(window.location.hash) && window.parent === window) {
      if (!state.stopLoopingRedirect) {
        acquireToken();
      }
    }
  },
  required: (WrappedComponent, renderLoading) => {
    // eslint-disable-next-line react/display-name
    return class extends React.Component {
      constructor(props) {
        super(props);
        this.state = { signedIn: !!state.accessToken };
      }

      componentDidMount() {
        if (!this.state.signedIn) {
          const redirectError = window.msal.redirectError?.errorMessage;
          if (isForgotPasswordError(redirectError) || isForgotPasswordError(window.location.hash)) {
            window.msal.loginRedirect(state.b2cPolicies.authorities.forgotPassword);
          } else {
            acquireToken(() => this.setState({ signedIn: true }));
          }
        }
      }

      render() {
        return this.state.signedIn ? <WrappedComponent {...this.props} /> : (typeof renderLoading === "function" ? renderLoading() : null);
      }
    };
  },
  signOut: () => window.msal.logout(),
  getAccessToken: () => state.accessToken,
  forceRefresh: () => {
    localStorage.clear();
    window.msal.loginRedirect(state.scopes);
  },
};

export default authentication;
