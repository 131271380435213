import {
  InvoiceDetailState,
  InvoiceDetailActionTypes,
  LOAD_INVOICE_DETAIL_SUCCESS,
  RESET_INVOICE_DETAIL
} from "./types";
import produce from "immer";

const initialState: InvoiceDetailState = {
  invoiceBillingItems: [],
  totalBillingItems: 0,
  invoiceDetailData: undefined
};

const invoiceDetailReducer = produce(
  (draft: InvoiceDetailState, action: InvoiceDetailActionTypes) => {
    switch (action.type) {
      case LOAD_INVOICE_DETAIL_SUCCESS:
        draft.invoiceDetailData = action.invoiceHeaderData;
        draft.totalBillingItems = action.totalCount;
        draft.invoiceBillingItems = action.receivedBillingItems;
        return;
      case RESET_INVOICE_DETAIL:
        draft = initialState;
        return;
    }
  },
  initialState
);

export default invoiceDetailReducer;
