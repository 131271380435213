import {
  LOAD_SHAPES_REQUEST,
  LOAD_SHAPES_SUCCESS,
  LOAD_SHAPES_FAILURE,
  RESET_SHAPES,
  LoadShapesRequest,
  ShapeDetail,
  LOAD_APPLICATION_CLASH_REQUEST,
  ApplicationClash,
  LOAD_APPLICATION_CLASH_SUCCESS,
  LOAD_APPLICATION_CLASH_FAILURE,
  ApplicationClashRequest,
  LOAD_DISPATCHES_REQUEST,
  LOAD_DISPATCHES_SUCCESS,
  LOAD_DISPATCHES_FAILURE,
  RESET_DISPATCHES_SHAPES,
  LoadDispatchesRequest,
  MapInputData,
  SET_MAP_INPUT_REQUEST
} from "./types";

export const loadShapesRequest = (request: LoadShapesRequest) => ({
  type: LOAD_SHAPES_REQUEST,
  request: request
});

export const loadShapesSuccess = (payload: ShapeDetail[]) => ({
  type: LOAD_SHAPES_SUCCESS,
  shapeDetails: payload
});

export const loadShapesFailure = (errorMessage: string) => ({
  type: LOAD_SHAPES_FAILURE,
  errorMessage: errorMessage
});

export const resetShapes = () => ({
  type: RESET_SHAPES
});

export const loadApplicationClashRequest = (
  applicationClashRequest: ApplicationClashRequest
) => ({
  type: LOAD_APPLICATION_CLASH_REQUEST,
  applicationClashRequest: applicationClashRequest
});

export const loadApplicationClashSuccess = (
  applicationClash: ApplicationClash
) => ({
  type: LOAD_APPLICATION_CLASH_SUCCESS,
  applicationClash: applicationClash
});

export const loadApplicationClashFailure = (errorMessage: string) => ({
  type: LOAD_APPLICATION_CLASH_FAILURE,
  errorMessage: errorMessage
});

export const loadDispatchesRequest = (request: LoadDispatchesRequest) => ({
  type: LOAD_DISPATCHES_REQUEST,
  request: request
});

export const loadDispatchesSuccess = (payload: string[]) => ({
  type: LOAD_DISPATCHES_SUCCESS,
  dispatchDetails: payload
});

export const loadDispatchesFailure = (errorMessage: string) => ({
  type: LOAD_DISPATCHES_FAILURE,
  errorMessage: errorMessage
});

export const resetDispatchesShapes = () => ({
  type: RESET_DISPATCHES_SHAPES
});

export const setMapInputData = (mapInputData: MapInputData | null) => ({
  type: SET_MAP_INPUT_REQUEST,
  mapInputData: mapInputData
});
