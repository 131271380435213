import {
  BillPayerDetailState,
  BillPayerDetailActionTypes,
  LOAD_BILL_PAYER_SUCCESS,
  ADD_BILL_PAYER_SUCCESS,
  CLEAR_BILL_PAYER_DETAIL
} from "./types";
import produce from "immer";

const initialState: BillPayerDetailState = {
  billPayerDetailData: undefined
};

const billPayerDetailReducer = produce(
  (draft: BillPayerDetailState, action: BillPayerDetailActionTypes) => {
    switch (action.type) {
      case CLEAR_BILL_PAYER_DETAIL:
        draft.billPayerDetailData = undefined;
        return;
      case ADD_BILL_PAYER_SUCCESS:
      case LOAD_BILL_PAYER_SUCCESS:
        draft.billPayerDetailData = action.billPayer;
        return;
    }
  },
  initialState
);

export default billPayerDetailReducer;
