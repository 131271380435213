import {
  LOAD_SHAPES_REQUEST,
  LoadShapesRequestAction,
  LoadShapesRequest,
  ShapeDetail,
  LOAD_APPLICATION_CLASH_REQUEST,
  LoadApplicationClashRequestAction,
  ApplicationClash,
  LOAD_APPLICATION_CLASH_FAILURE,
  ApplicationClashRequest,
  LOAD_DISPATCHES_REQUEST,
  LoadDispatchesRequestAction,
  LoadDispatchesRequest
} from "./types";
import { put, takeLatest, call } from "redux-saga/effects";
import {
  loadApplicationClashFailure,
  loadApplicationClashSuccess,
  loadDispatchesSuccess,
  loadDispatchesFailure,
  loadShapesFailure,
  loadShapesSuccess
} from "./actions";
import axios from "axios";
import { API_ROUTE } from "common/constants";
import { APPLICATION_ENDPOINT } from "common/endpoints";
import { MAP_CONTROLLER } from "common/controllerConstants";
import { enqueueSnackbar } from "store/notistack/actions";
import { createNotification } from "common/addNotification";

const getShapes = (request: LoadShapesRequest) => {
  return axios.post(
    `${API_ROUTE}${APPLICATION_ENDPOINT}/${MAP_CONTROLLER}/planningmap/carshapes`,
    request
  );
};

function* loadShapeRequest(action: LoadShapesRequestAction) {
  try {
    const { data }: { data: ShapeDetail[] } = yield call(
      getShapes,
      action.request
    );

    yield put(loadShapesSuccess(data));
  } catch (e) {
    yield put(loadShapesFailure("Failed to load car shapes."));
  }
}

export default function* watchLoadShapesRequest() {
  yield takeLatest(LOAD_SHAPES_REQUEST, loadShapeRequest);
}

const applicationClashApi = (request: ApplicationClashRequest) => {
  return axios.post(
    `${API_ROUTE}${APPLICATION_ENDPOINT}/${MAP_CONTROLLER}/applicationclashes`,
    request
  );
};

function* loadApplicationClash(action: LoadApplicationClashRequestAction) {
  try {
    const { data }: { data: ApplicationClash } = yield call(
      applicationClashApi,
      action.applicationClashRequest
    );

    yield put(loadApplicationClashSuccess(data));
  } catch (e) {
    const message = e.response.data;

    if (message.Message) {
      yield put(
        enqueueSnackbar(
          createNotification(
            LOAD_APPLICATION_CLASH_FAILURE,
            message.Message,
            message.MessageType
          )
        )
      );
    } else {
      yield put(
        loadApplicationClashFailure(
          "Something went wrong when fetching application clashes"
        )
      );
    }
  }
}

export function* watchLoadApplicationClash() {
  yield takeLatest(LOAD_APPLICATION_CLASH_REQUEST, loadApplicationClash);
}

const getDispatches = (request: LoadDispatchesRequest) => {
  return axios.post(
    `${API_ROUTE}${APPLICATION_ENDPOINT}/${MAP_CONTROLLER}/planningmap/getDispatch`,
    request
  );
};

function* loadDispatchRequest(action: LoadDispatchesRequestAction) {
  try {
    const { data }: { data: string[] } = yield call(
      getDispatches,
      action.request
    );
    yield put(loadDispatchesSuccess(data));
  } catch (e) {
    yield put(loadDispatchesFailure("Failed to load dispatches."));
  }
}

export function* watchLoadDispatchRequest() {
  yield takeLatest(LOAD_DISPATCHES_REQUEST, loadDispatchRequest);
}
