import React, { Suspense } from "react";
import { Router } from "react-router-dom";
import * as history from "history";
import ProtectedRoute from "components/ProtectedRoute";
import Loader from "components/Loader";
import authentication from "components/react-azure-b2c";
import { AppState, OutageModel } from "store/types";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import SubmiticaOutage from "containers/OutagePage";

const Application = React.lazy(() => import("containers/Application"));

type RoutesProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> & {
    outageModel?: OutageModel;
  };

const ApplicationLoader = () => (
  <Suspense fallback={<Loader />}>
    <Application />
  </Suspense>
);

const Routes = (props: RoutesProps) => {
  const allowBypass =
    window.location.pathname.toLowerCase().indexOf("response") > -1;

  return (
    <>
      {props.outageModel && props.outageModel!.isSubmiticaOffline && (
        <SubmiticaOutage outageMessage={props.outageModel!.outageMessage} />
      )}

      {!props.outageModel && (
        <Router history={history.createBrowserHistory()}>
          <ProtectedRoute
            component={
              !props.isLoggedIn && !!allowBypass
                ? ApplicationLoader
                : authentication.required(ApplicationLoader)
            }
          ></ProtectedRoute>
        </Router>
      )}
    </>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    isLoggedIn: state.auth.isAuthenticated
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(Routes);
