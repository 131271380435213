export const USERS_CONTROLLER = "users";
export const ORGANISATIONS_CONTROLLER = "organisations";
export const ATTACHMENTS_CONTROLLER = "attachments";
export const MAP_CONTROLLER = "map";
export const APPLICATIONS_CONTROLLER = "applications";
export const TEMPORARY_CONTROLLER = "applications/temporary";
export const RCA_ORGANISATIONS_CONTROLLER = "rcaorganisations";
export const IMAGES_CONTROLLER = "images";
export const NEW_ORGANIATIONS_CONTROLLER = "Neworganisations";
export const ADD_ORGANIATIONS_CONTROLLER = "AddNewOrganisation";
export const ADD_CARUSER_CONTROLLER = "AddNewCarUser";
export const GET_PARTICIPANT_ORGANISATION_CONTROLLER = "GetParticipantOrganisation";
