import {
  LOAD_INVOICES_SUMMARY_REQUEST,
  InvoicesSummaryOptions,
  InvoicesSummarySuccessPayload,
  LOAD_INVOICES_SUMMARY_SUCCESS,
  LOAD_INVOICES_SUMMARY_FAILURE,
  RESET_INVOICES_SUMMARY,
  LOAD_INVOICES_SUMMARY_REQUEST_EXTRA
} from "./types";

export const loadInvoicesSummaryRequest = (
  payload: InvoicesSummaryOptions
) => ({
  type: LOAD_INVOICES_SUMMARY_REQUEST,
  invoicesSummaryOptions: payload
});

export const loadInvoicesSummaryRequestExtra = (
  payload: InvoicesSummaryOptions
) => ({
  type: LOAD_INVOICES_SUMMARY_REQUEST_EXTRA,
  invoicesSummaryOptions: payload
});

export const loadInvoicesSummarySuccess = (
  payload: InvoicesSummarySuccessPayload
) => ({
  type: LOAD_INVOICES_SUMMARY_SUCCESS,
  receivedData: payload.receivedData,
  totalCount: payload.totalCount
});

export const loadInvoicesSummaryFailure = (errorMessage: string) => ({
  type: LOAD_INVOICES_SUMMARY_FAILURE,
  errorMessage: errorMessage
});

export const resetInvoicesSummary = () => ({
  type: RESET_INVOICES_SUMMARY
});
