import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import b2cauth from "components/react-azure-b2c";
import axios from "axios";
import { API_ROUTE } from "common/constants";
import { ADMINISTRATION_ENDPOINT } from "common/endpoints";

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

axios.get(`${API_ROUTE}${ADMINISTRATION_ENDPOINT}/getPreAuth`).then(x => {
  const data = x.data.adB2CInformation;
  const isSubmiticaDown = x.data.isSubmiticaDown;

  if (!isSubmiticaDown) {
    b2cauth.initialize({
      instance: data.instance,
      tenant: data.domain,
      signInPolicy: data.signUpSignInPolicyId,
      resetPolicy: data.resetPasswordPolicyId,
      clientId: data.clientId,
      cacheLocation: "localStorage",
      scopes: [
        `https://${data.domain}/api/read`,
        `https://${data.domain}/api/write`
      ],
      postLogoutRedirectUri: window.location.origin,
      redirectUri: window.location.origin,
      tenantName: data.tenant
    });
  }

  ReactDOM.render(
    isSubmiticaDown ? (
      <App
        outageModel={{
          isSubmiticaOffline: isSubmiticaDown,
          outageMessage: x.data.outageMessage
        }}
      />
    ) : (
      <App />
    ),
    document.getElementById("root")
  );
});
