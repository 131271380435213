import produce from "immer";
import {
  ApplicationsSummaryActionTypes,
  ApplicationsSummaryState,
  LOAD_APPLICATIONS_SUMMARY_SUCCESS,
  LOAD_APPLICATION_EXPORT_COLUMNS_SUCCESS,
  LOAD_RCA_BOUNDARIES_SUCCESS,
  LOAD_RCA_LOGOS_SUCCESS,
  RcaBoundary,
  RESET_APPLICATIONS_SUMMARY
} from "./types";
import Wkt from "wicket";
import wktToFeatures from "common/wktToFeatures";

const initialState: ApplicationsSummaryState = {
  applicationsSummaryData: [],
  totalSummaries: 0,
  rcaLogos: [],
  rcaBoundaries: [],
  applicationExportColumns: []
};

const applicationsSummaryReducer = produce(
  (draft: ApplicationsSummaryState, action: ApplicationsSummaryActionTypes) => {
    switch (action.type) {
      case LOAD_APPLICATIONS_SUMMARY_SUCCESS: {
        const wicket = new Wkt.Wkt();
        action.receivedData = action.receivedData.map(x => {
          return {
            ...x,
            ["dateSubmitted"]: new Date(x.dateSubmitted),
            ["estimatedStartDate"]: new Date(x.estimatedStartDate),
            ["estimatedCompletionDate"]: new Date(x.estimatedCompletionDate),
            ["features"]: x.shapes ? wktToFeatures(wicket, x.shapes) : []
          };
        });

        if (action.isMapView) {
          draft.applicationsSummaryData = action.receivedData;
        } else {
          draft.applicationsSummaryData.push(...action.receivedData);
        }

        if (action.totalCount >= 0) {
          draft.totalSummaries = action.totalCount;
        }

        return;
      }
      case RESET_APPLICATIONS_SUMMARY: {
        draft.applicationsSummaryData = [];
        return;
      }
      case LOAD_RCA_LOGOS_SUCCESS: {
        draft.rcaLogos.push(...action.rcaLogos);
        return;
      }
      case LOAD_RCA_BOUNDARIES_SUCCESS: {
        if (action.rcaBoundaries) {
          const wkt = new Wkt.Wkt();

          const newBoundaries: RcaBoundary[] = action.rcaBoundaries.map(x => {
            const singleItem: RcaBoundary = {
              rcaBoundaryFeatures: wktToFeatures(wkt, x.rcaBoundaryShape),
              rcaBoundaryShape: x.rcaBoundaryShape,
              rcaOrganisationId: x.rcaOrganisationId
            };
            return singleItem;
          });

          draft.rcaBoundaries.push(...newBoundaries);
          return;
        }
        return;
      }
      case LOAD_APPLICATION_EXPORT_COLUMNS_SUCCESS: {
        draft.applicationExportColumns = action.applicationExportColumns;
        return;
      }
    }
  },
  initialState
);

export default applicationsSummaryReducer;
