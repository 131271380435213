import {
  AdministrationStateActionTypes,
  RESET_CREATE_APPLICATION_NEW_USER_DATA,
  AdministrationState,
  SET_USER_DETAIL_SUCCESS,
  RESET_ACCOUNT_MANAGEMENT,
  LOAD_USER_LINKS_SUCCESS,
  LOAD_USER_DETAIL_SUCCESS,
  LOAD_DUPLICATE_ACCOUNT_SUCCESS,
  SET_LINK_DUPLICATES_SUCCESS,
  SET_GST_NUMBER_SUCCESS,
  LOAD_NEW_ORGANISATIONS_SUCCESS,
  RESET_NEW_ORGANISATIONS,
  SET_NEW_ORGANISATION_SUCCESS,
  GET_CONTACT_SUCCESS,
  UserDetail,
  SET_NOTIFICATION_SETTINGS_SUCCESS,
  SET_USER_REGIONS_SUCCESS,
  SET_USER_LINK_SUCCESS,
  GRANT_USER_ACCESS,
  REQUEST_SUPERVISOR_ACCESS,
  REQUEST_ACCESS_TO_APPLICATION,
  DELETE_USER_LINK_SUCCESS,
  LOAD_PERMISSION_TO_VIEW_USER_SUCCESS,
  LOAD_USER_NOTIFICATIONS_COUNT_SUCCESS,
  LOAD_USER_NOTIFICATIONS_SUCCESS,
  RESET_USER_NOTIFICATIONS,
  SET_USER_NOTIFICATIONS_INACTIVE_SUCCESS,
  SET_USER_NOTIFICATION_CONFIRM_DECLINE_SUCCESS,
  LOAD_SCALE_OF_WORK_QUESTIONNAIRE_SUCCESS,
  LOAD_SCALE_OF_WORK_QUESTIONNAIRE_RESET,
  LOAD_USER_REGIONS_SUCCESS,
  LOAD_PARTICIPANT_ORGANISATION_SUCCESS,
  RESET_AUTOFILL_ORGANISATION,
  USER_LINK_SUCCESS,
  RESET_USER_LINK_PROCESSED
} from "./types";
import produce from "immer";
import { UserNotificationTypes } from "common/enums";
import b2cauth from "components/react-azure-b2c";

const initialUserData: UserDetail = {
  email: "",
  firstName: "",
  lastName: "",
  address: { id: 0, description: "" },
  mobilePhone: "",
  workPhone: "",
  fax: "",
  jobPosition: "",
  organisation: { id: 0, description: "" },
  isTrafficManagementCoordinator: false,
  participantCertificateType: { id: 0, description: "" },
  certificationNumber: undefined,
  dateOfExpiry: undefined,
  dateOfIssue: undefined,
  placeOfIssue: undefined,
  notificationSettings: {
    sendDowntimeEmails: false,
    sendNotificationEmails: false,
    notificationSummaryType: { id: 0, description: "" },
    organisationWhitelist: []
  },
  gstNumber: undefined
};

const initialState: AdministrationState = {
  newUserData: initialUserData,
  newOrganisations: [],
  userAccount: {
    supervisors: [],
    authorisedParents: [],
    supervisorOf: [],
    authorisedChildren: [],
    userDetail: initialUserData
  },
  duplicateAccountData: [],
  selectedDuplicateIds: [],
  hasPermissionToViewUser: false,
  notifications: [],
  notificationCount: 0,
  scaleOfWorkQuestionnaire: [],
  participantOrganisation: { id: 0, description: "" },
  defaultContact: null,
  hasNext: false,
  hasPrevious: false
};

const newUserReducer = produce(
  (draft: AdministrationState, action: AdministrationStateActionTypes) => {
    switch (action.type) {
      case LOAD_USER_LINKS_SUCCESS:
        draft.userAccount.supervisors = action.receivedData.supervisors;
        draft.userAccount.supervisorOf = action.receivedData.supervisorOf;
        draft.userAccount.authorisedChildren =
          action.receivedData.authorisedChildren;
        draft.userAccount.authorisedParents =
          action.receivedData.authorisedParents;
        return;
      case RESET_CREATE_APPLICATION_NEW_USER_DATA:
        draft.newUserData = initialUserData;
        return;
      case RESET_NEW_ORGANISATIONS:
        draft.newOrganisations = [];
        return;
      case RESET_AUTOFILL_ORGANISATION:
        draft.participantOrganisation = { id: 0, description: "" };
        return;
      case LOAD_NEW_ORGANISATIONS_SUCCESS:
        draft.newOrganisations = action.data;
        return;
      case RESET_ACCOUNT_MANAGEMENT:
        draft.userAccount = {
          supervisors: [],
          authorisedParents: [],
          supervisorOf: [],
          authorisedChildren: [],
          userDetail: initialUserData
        };
        return;
      case SET_USER_DETAIL_SUCCESS:
        if (action.isEditingUserAccount) {
          if (action.userDetail) {
            action.userDetail.notificationSettings =
              draft.userAccount.userDetail.notificationSettings;
            action.userDetail.userRegions =
              draft.userAccount.userDetail.userRegions;
            action.userDetail.gstNumber =
              draft.userAccount.userDetail.gstNumber;
            draft.userAccount.userDetail = action.userDetail;
          }
          if (action.userDetail && action.userDetail.profileUpdateRequired) {
            b2cauth.forceRefresh();
          }
        } else {
          draft.lastAddedEntity = {
            data: action.newUser,
            parentControl: action.parentId
          };
        }
        return;
      case SET_USER_LINK_SUCCESS: {
        switch (action.actionType) {
          case GRANT_USER_ACCESS: {
            draft.userAccount.authorisedParents = [
              ...draft.userAccount.authorisedParents,
              action.userLink
            ];
            break;
          }
          case REQUEST_SUPERVISOR_ACCESS: {
            draft.userAccount.supervisorOf = [
              ...draft.userAccount.supervisorOf,
              action.userLink
            ];
            break;
          }
          case REQUEST_ACCESS_TO_APPLICATION: {
            draft.userAccount.authorisedChildren = [
              ...draft.userAccount.authorisedChildren,
              action.userLink
            ];
            break;
          }
        }
        return;
      }
      case LOAD_USER_DETAIL_SUCCESS:
        draft.userAccount.userDetail = action.userDetail;

        draft.userAccount.userDetail.dateOfExpiry = draft.userAccount.userDetail
          .dateOfExpiry
          ? new Date(draft.userAccount.userDetail.dateOfExpiry)
          : undefined;

        draft.userAccount.userDetail.dateOfIssue = draft.userAccount.userDetail
          .dateOfIssue
          ? new Date(draft.userAccount.userDetail.dateOfIssue)
          : undefined;

        return;
      case LOAD_DUPLICATE_ACCOUNT_SUCCESS:
        draft.duplicateAccountData = action.duplicateAccounts;
        return;
      case SET_LINK_DUPLICATES_SUCCESS:
        draft.duplicateAccountData = draft.duplicateAccountData.filter(
          obj => !action.selectedIds?.includes(obj.userId)
        );
        return;
      case SET_GST_NUMBER_SUCCESS:
        draft.userAccount.userDetail.gstNumber = action.gstNumber;
        return;
      case SET_NEW_ORGANISATION_SUCCESS:
        draft.lastAddedEntity = {
          data: action.newOrg,
          parentControl: action.parentId
        };
        return;
      case GET_CONTACT_SUCCESS:
        if (!action.contact) {
          return;
        }
        draft.defaultContact = action.contact;
        return;
      case SET_NOTIFICATION_SETTINGS_SUCCESS: {
        draft.userAccount.userDetail.notificationSettings =
          action.notificationSettings;
        return;
      }
      case SET_USER_REGIONS_SUCCESS: {
        draft.userAccount.userDetail.userRegions = action.userRegions;
        return;
      }
      case DELETE_USER_LINK_SUCCESS: {
        draft.userAccount.authorisedChildren = draft.userAccount.authorisedChildren.filter(
          x => x.userLinkId != action.userLinkId
        );
        draft.userAccount.authorisedParents = draft.userAccount.authorisedParents.filter(
          x => x.userLinkId != action.userLinkId
        );
        draft.userAccount.supervisorOf = draft.userAccount.supervisorOf.filter(
          x => x.userLinkId != action.userLinkId
        );
        return;
      }
      case LOAD_PERMISSION_TO_VIEW_USER_SUCCESS: {
        draft.hasPermissionToViewUser = action.hasPermission;
        return;
      }
      case LOAD_USER_NOTIFICATIONS_COUNT_SUCCESS: {
        draft.notificationCount = action.notificationCount;
        return;
      }
      case RESET_USER_NOTIFICATIONS: {
        draft.notifications = [];
        return;
      }
      case LOAD_USER_NOTIFICATIONS_SUCCESS: {
        draft.hasNext = action.userNotificationPagination.hasNext;
        draft.hasPrevious = action.userNotificationPagination.hasPrevious;
        draft.notifications = action.userNotificationPagination.notifications;

        draft.notifications.map(x => {
          x.date = new Date(x.date);
          x.notificationItems.map(y => {
            y.showConfirmButton =
              y.notificationTypeId === UserNotificationTypes.UserLinkRequest ||
              y.notificationTypeId ===
                UserNotificationTypes.ParticipantLinkRequest;

            y.showDeclineButton =
              y.notificationTypeId === UserNotificationTypes.UserLinkRequest ||
              y.notificationTypeId ===
                UserNotificationTypes.ParticipantLinkRequest ||
              ((y.notificationTypeId ===
                UserNotificationTypes.ParticipantLinkResponse ||
                y.notificationTypeId ===
                  UserNotificationTypes.ParticipantLinkReplaceResponse ||
                y.notificationTypeId ===
                  UserNotificationTypes.ParticipantLinkDeclineResponse) &&
                y.canDecline);
          });
        });
        return;
      }
      case SET_USER_NOTIFICATIONS_INACTIVE_SUCCESS: {
        const updatedGuidList = action.request.notificationIds;
        const index = draft.notifications.findIndex(
          x => x.date === action.request.date
        );

        draft.notificationCount =
          draft.notificationCount - updatedGuidList.length;

        updatedGuidList.map(x => {
          draft.notifications[index].notificationItems = draft.notifications[
            index
          ].notificationItems.filter(y => y.notificationId !== x);
        });

        if (draft.notifications[index].notificationItems.length === 0) {
          draft.notifications = draft.notifications.filter(
            x => x.date !== action.request.date
          );
        }

        return;
      }
      case SET_USER_NOTIFICATION_CONFIRM_DECLINE_SUCCESS: {
        draft.notificationCount--;
        draft.notifications = draft.notifications.filter(x => {
          if (x.notificationItems.length > 1) {
            return (x.notificationItems = x.notificationItems.filter(
              y => y.notificationId !== action.notificationId
            ));
          } else {
            return !x.notificationItems.some(
              y => y.notificationId == action.notificationId
            );
          }
        });
        return;
      }
      case LOAD_SCALE_OF_WORK_QUESTIONNAIRE_SUCCESS: {
        draft.scaleOfWorkQuestionnaire = action.questions;
        return;
      }
      case LOAD_SCALE_OF_WORK_QUESTIONNAIRE_RESET: {
        draft.scaleOfWorkQuestionnaire = [];
        return;
      }
      case LOAD_USER_REGIONS_SUCCESS: {
        draft.userAccount.userDetail.userRegions = action.userRegions;
        return;
      }
      case LOAD_PARTICIPANT_ORGANISATION_SUCCESS: {
        draft.participantOrganisation = action.organisation;
        return;
      }
      case USER_LINK_SUCCESS: {
        draft.userLinkActionResponse = action.userLinkAction;
        return;
      }
      case RESET_USER_LINK_PROCESSED: {
        draft.userLinkActionResponse = undefined;
        return;
      }
    }
  },
  initialState
);

export default newUserReducer;
