import {
  LOGIN_USER_REQUEST,
  LOAD_DEFAULT_REGION_REQUEST,
  LOAD_DEFAULT_REGION_SUCCESS,
  LOAD_DEFAULT_REGION_FAILURE,
  UPDATE_STORE_DEFAULT_REGION,
  LOAD_SEARCHABLE_REGION_REQUEST,
  LOAD_SEARCHABLE_REGION_SUCCESS,
  LOAD_SEARCHABLE_REGION_FAILURE,
  RESET_STORE_SEARCHABLE_REGION,
  LOGIN_USER_SUCCESS,
  LoginSuccessData,
  LOAD_CAN_VIEW_INVOICE_FAILURE,
  LOAD_CAN_VIEW_INVOICE_REQUEST,
  LOAD_CAN_VIEW_INVOICE_SUCCESS
} from "./types";

export const loginUserRequest = () => ({
  type: LOGIN_USER_REQUEST
});

export const loginUserSuccess = (loginSuccessData: LoginSuccessData) => ({
  type: LOGIN_USER_SUCCESS,
  loginSuccessData: loginSuccessData
});

export const loadDefaultRegionRequest = () => ({
  type: LOAD_DEFAULT_REGION_REQUEST
});

export const loadDefaultRegionSuccess = (defaultRegion: string) => ({
  type: LOAD_DEFAULT_REGION_SUCCESS,
  defaultRegion: defaultRegion
});

export const loadDefaultRegionFailure = (errorMessage: string) => ({
  type: LOAD_DEFAULT_REGION_FAILURE,
  errorMessage: errorMessage
});

export const updateStoreDefaultRegion = (defaultRegion: string) => ({
  type: UPDATE_STORE_DEFAULT_REGION,
  defaultRegion: defaultRegion
});

export const loadSearchableRegionRequest = () => ({
  type: LOAD_SEARCHABLE_REGION_REQUEST
});

export const loadSearchableRegionSuccess = (searchableRegion: string) => ({
  type: LOAD_SEARCHABLE_REGION_SUCCESS,
  searchableRegion: searchableRegion
});

export const loadSearchableRegionFailure = (errorMessage: string) => ({
  type: LOAD_SEARCHABLE_REGION_FAILURE,
  errorMessage: errorMessage
});

export const resetStoreSearchableRegion = () => ({
  type: RESET_STORE_SEARCHABLE_REGION
});

export const loadCanViewInvoiceRequest = () => ({
  type: LOAD_CAN_VIEW_INVOICE_REQUEST
});

export const loadCanViewInvoiceSuccess = (canViewInvoice: boolean) => ({
  type: LOAD_CAN_VIEW_INVOICE_SUCCESS,
  canViewInvoice: canViewInvoice
});

export const loadCanViewInvoiceFailure = (errorMessage: string) => ({
  type: LOAD_CAN_VIEW_INVOICE_FAILURE,
  errorMessage: errorMessage
});
