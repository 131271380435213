import produce from "immer";
import {
  SingleApplicationActionTypes,
  ApplicationDetailState,
  LOAD_SINGLE_APPLICATION_SUCCESS,
  RESET_SINGLE_APPLICATION,
  UPLOAD_DETAIL_FILE_SUCCESS,
  SET_APPLICATION_NOTES_SUCCESS,
  SET_WORK_INFORMATION_SUCCESS,
  SET_APPLICATION_FEEDBACK_SUCCESS,
  LOAD_EXISTING_APPLICATION_FEEDBACK_SUCCESS,
  DOWNLOAD_INSPECTION_REPORT_SUCCESS,
  DOWNLOAD_DEFECT_REPORT_SUCCESS,
  LOAD_AUDIT_LOGS_SUCCESS,
  RESET_AUDIT_LOGS,
  SET_PARTICIPANT_SUCCESS,
  SET_NOTIFY_WORK_START_SUCCESS,
  REPLACE_PARTICIPANT_SUCCESS,
  SET_NOTIFY_WORK_COMPLETION_SUCCESS,
  REMOVE_PARTICIPANT_SUCCESS,
  SET_LOCATION_SUCCESS,
  DELETE_ATTACHMENT_SUCCESS,
  CANCEL_APPLICATION_SUCCESS,
  SET_MAINTENANCE_COMPLETION_SUCCESS,
  DECLINE_APPLICATION_PARTICIPANT_SUCCESS
} from "./types";
import Wkt from "wicket";
import wktToFeatures from "common/wktToFeatures";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

const initialState: ApplicationDetailState = {
  applicationDetailData: undefined
};

const applicationDetailReducer = produce(
  (draft: ApplicationDetailState, action: SingleApplicationActionTypes) => {
    switch (action.type) {
      case LOAD_SINGLE_APPLICATION_SUCCESS: {
        draft.applicationDetailData = action.applicationDetailData;

        draft.applicationDetailData.isApplicationCancelled = false;

        if (action.applicationDetailData.shapes) {
          const wicket = new Wkt.Wkt();
          const features = wktToFeatures(
            wicket,
            action.applicationDetailData.shapes
          );

          draft.applicationDetailData.features = features;
        }

        if (action.applicationDetailData.rcaBoundaries) {
          const wicket = new Wkt.Wkt();
          const features = wktToFeatures(
            wicket,
            action.applicationDetailData.rcaBoundaries
          );
          draft.applicationDetailData.rcaBoundaryFeatures = features;
        }

        draft.applicationDetailData.dateSubmitted = draft.applicationDetailData
          .dateSubmitted
          ? new Date(draft.applicationDetailData.dateSubmitted)
          : undefined;

        draft.applicationDetailData.estimatedStartDate = new Date(
          draft.applicationDetailData.estimatedStartDate
        );

        draft.applicationDetailData.estimatedCompletionDate = new Date(
          draft.applicationDetailData.estimatedCompletionDate
        );

        draft.applicationDetailData.dateTmpLodged = draft.applicationDetailData
          .dateTmpLodged
          ? new Date(draft.applicationDetailData.dateTmpLodged)
          : undefined;

        draft.applicationDetailData.dateReceived = new Date(
          draft.applicationDetailData.dateReceived
        );

        draft.applicationDetailData.dateApproved = draft.applicationDetailData
          .dateApproved
          ? new Date(draft.applicationDetailData.dateApproved)
          : undefined;

        draft.applicationDetailData.dateWorkSignedOff = draft
          .applicationDetailData.dateWorkSignedOff
          ? new Date(draft.applicationDetailData.dateWorkSignedOff)
          : undefined;

        draft.applicationDetailData.dateWarrantyStarted = draft
          .applicationDetailData.dateWarrantyStarted
          ? new Date(draft.applicationDetailData.dateWarrantyStarted)
          : undefined;

        draft.applicationDetailData.dateWarrantyCompletionNotified = draft
          .applicationDetailData.dateWarrantyCompletionNotified
          ? new Date(draft.applicationDetailData.dateWarrantyCompletionNotified)
          : undefined;

        draft.applicationDetailData.dateWarrantyEnded = draft
          .applicationDetailData.dateWarrantyEnded
          ? new Date(draft.applicationDetailData.dateWarrantyEnded)
          : undefined;

        draft.applicationDetailData.dateClosed = draft.applicationDetailData
          .dateClosed
          ? new Date(draft.applicationDetailData.dateClosed)
          : undefined;

        draft.applicationDetailData.dateWorkStarted = draft
          .applicationDetailData.dateWorkStarted
          ? new Date(draft.applicationDetailData.dateWorkStarted)
          : undefined;

        draft.applicationDetailData.dateWorkCompleted = draft
          .applicationDetailData.dateWorkCompleted
          ? new Date(draft.applicationDetailData.dateWorkCompleted)
          : undefined;

        draft.applicationDetailData.inspectionsAndDefects = draft.applicationDetailData.inspectionsAndDefects?.map(
          x => {
            return { ...x, addedOn: new Date(x.addedOn) };
          }
        );

        draft.applicationDetailData.applicationFees = draft.applicationDetailData.applicationFees?.map(
          x => {
            return { ...x, dateAdded: new Date(x.dateAdded) };
          }
        );

        draft.applicationDetailData.participants = draft.applicationDetailData.participants?.map(
          x => {
            return x.certificateDetails
              ? {
                ...x,
                certificateDetails: {
                  ...x.certificateDetails,
                  certificateExpiry: x.certificateDetails.certificateExpiry
                    ? new Date(x.certificateDetails.certificateExpiry)
                    : undefined
                }
              }
              : x;
          }
        );

        return;
      }
      case RESET_SINGLE_APPLICATION: {
        draft.applicationDetailData = initialState.applicationDetailData;
        return;
      }
      case UPLOAD_DETAIL_FILE_SUCCESS: {
        if (draft.applicationDetailData) {
          draft.applicationDetailData.attachments = action.attachments.concat(
            draft.applicationDetailData.attachments ?? []
          );
        }
        return;
      }
      case SET_APPLICATION_NOTES_SUCCESS: {
        if (draft.applicationDetailData) {
          draft.applicationDetailData.notes = action.notes;
        }
        return;
      }
      case SET_WORK_INFORMATION_SUCCESS: {
        if (draft.applicationDetailData) {
          draft.applicationDetailData.applicantRole =
            action.workInfo.applicantRole;
          draft.applicationDetailData.activities = action.workInfo.activities;
          draft.applicationDetailData.majorActivities =
            action.workInfo.majorActivities;
          draft.applicationDetailData.roadLevel = action.workInfo.roadLevel;
          draft.applicationDetailData.globalCar = action.workInfo.isGlobalCar;
          draft.applicationDetailData.isRoadClosureRequired =
            action.workInfo.isRoadClosureRequired;
          draft.applicationDetailData.estimatedDuration = Number(
            action.workInfo.estimatedDuration
          );
          draft.applicationDetailData.estimatedStartDate = action.workInfo.estimatedStartDate!;
          draft.applicationDetailData.estimatedCompletionDate = action.workInfo.estimatedCompletionDate!;
          draft.applicationDetailData.description = action.workInfo.description;
          draft.applicationDetailData.hoursOfWork = action.workInfo.hoursOfWork;
          draft.applicationDetailData.utilityRefNumber =
            action.workInfo.utilityRefNumber;
          draft.applicationDetailData.consentNumber =
            action.workInfo.consentNumber;
          draft.applicationDetailData.purchaseOrderNumber =
            action.workInfo.purchaseOrderNumber;
        }
        return;
      }
      case SET_APPLICATION_FEEDBACK_SUCCESS: {
        draft.feedback = action.feedback;
        return;
      }
      case LOAD_EXISTING_APPLICATION_FEEDBACK_SUCCESS: {
        if (!action.feedback) {
          draft.feedback = undefined;
        } else {
          draft.feedback = action.feedback;
        }
        return;
      }
      case DOWNLOAD_INSPECTION_REPORT_SUCCESS: {
        const data = action.inspection;
        const doc = new jsPDF();
        doc.text("INSPECTION", 90, 10);
        autoTable(doc, {
          body: [
            [{ content: "CAR Number" }, { content: data.carNumber }],
            [{ content: "Inspection Id" }, { content: data.inspectionId }],
            [
              { content: "Date" },
              {
                content: data.date
                  ? new Date(data.date).toLocaleDateString("en-GB")
                  : ""
              }
            ],
            [{ content: "Auditor" }, { content: data.auditor }],
            [{ content: "Type" }, { content: data.type }],
            [{ content: "Category" }, { content: data.category }],
            [{ content: "Rating" }, { content: data.rating }],
            [{ content: "Notes" }, { content: data.notes }],
            [
              { content: "Remedial work required" },
              { content: data.remedialWorkRequired }
            ]
          ],
          columnStyles: {
            0: { cellWidth: 50 },
            1: { cellWidth: 132 }
          },
          tableWidth: 182,
          theme: "grid"
        });

        const newDate = new Date();
        const month = newDate.toLocaleString("default", { month: "long" });
        const dateString = `${newDate.getDate()} ${month} ${newDate.getFullYear()} ${newDate.getHours()}-${newDate.getMinutes()}-${newDate.getSeconds()}`;

        doc.save(`Inspection Report generated on ${dateString}.pdf`);
        return;
      }
      case DOWNLOAD_DEFECT_REPORT_SUCCESS: {
        const data = action.defect;
        const doc = new jsPDF();
        doc.text("DEFECT", 90, 10);
        autoTable(doc, {
          body: [
            [{ content: "CAR Number" }, { content: data.carNumber }],
            [{ content: "Defect Id" }, { content: data.defectId }],
            [
              { content: "Date Found" },
              {
                content: data.dateFound
                  ? new Date(data.dateFound).toLocaleDateString("en-GB")
                  : ""
              }
            ],
            [{ content: "Source" }, { content: data.source }],
            [{ content: "Status" }, { content: data.status }],
            [{ content: "Description" }, { content: data.description }],
            [{ content: "Explanation" }, { content: data.explanation }],
            [
              { content: "Date Fixed" },
              {
                content: data.dateFixed
                  ? new Date(data.dateFixed).toLocaleDateString("en-GB")
                  : ""
              }
            ],
            [{ content: "Resolution" }, { content: data.resolutionNote }],
            [{ content: "Rating" }, { content: data.rating }]
          ],
          columnStyles: {
            0: { cellWidth: 50 },
            1: { cellWidth: 132 }
          },
          tableWidth: 182,
          theme: "grid"
        });

        const newDate = new Date();
        const month = newDate.toLocaleString("default", { month: "long" });
        const dateString = `${newDate.getDate()} ${month} ${newDate.getFullYear()} ${newDate.getHours()}-${newDate.getMinutes()}-${newDate.getSeconds()}`;

        doc.save(`Defect Report generated on ${dateString}.pdf`);
        return;
      }
      case SET_PARTICIPANT_SUCCESS: {
        if (
          action.participant.certificateDetails &&
          action.participant.certificateDetails.certificateExpiry
        ) {
          action.participant.certificateDetails.certificateExpiry = new Date(
            action.participant.certificateDetails.certificateExpiry
          );
        }

        draft.applicationDetailData!.participants = [
          ...draft.applicationDetailData!.participants!,
          action.participant
        ];
        return;
      }
      case REPLACE_PARTICIPANT_SUCCESS: {
        if (
          action.participantResponse.participant.certificateDetails &&
          action.participantResponse.participant.certificateDetails
            .certificateExpiry
        ) {
          action.participantResponse.participant.certificateDetails.certificateExpiry = new Date(
            action.participantResponse.participant.certificateDetails.certificateExpiry
          );
        }

        draft.applicationDetailData!.participants = draft.applicationDetailData!.participants!.map(
          x => {
            if (
              x.participantLinkId ===
              action.participantResponse.participant.participantLinkId
            ) {
              return action.participantResponse.participant;
            }

            return x;
          }
        );
        draft.applicationDetailData!.permissionValue =
          action.participantResponse.permissionValue;
        return;
      }
      case SET_NOTIFY_WORK_START_SUCCESS: {
        if (draft.applicationDetailData) {
          draft.applicationDetailData.dateWorkStarted = action.updatedInfo
            .dateWorkStarted
            ? new Date(action.updatedInfo.dateWorkStarted)
            : undefined;

          draft.applicationDetailData.dateWorkCompleted = action.updatedInfo
            .dateWorkCompleted
            ? new Date(action.updatedInfo.dateWorkCompleted)
            : undefined;

          draft.applicationDetailData.estimatedDuration =
            action.updatedInfo.duration;

          draft.applicationDetailData.carStatus = action.updatedInfo.carStatus;

          draft.applicationDetailData.dateWorkStartedStatus =
            action.updatedInfo.dateWorkStartedStatus;
        }
        return;
      }
      case LOAD_AUDIT_LOGS_SUCCESS: {
        if (draft.applicationDetailData) {
          if (!action.auditLog) {
            draft.applicationDetailData.dateAuditLog = null;
          } else {
            draft.applicationDetailData.dateAuditLog = {
              ...action.auditLog,
              addedOn: new Date(action.auditLog.addedOn)
            };
          }
        }
        return;
      }
      case RESET_AUDIT_LOGS: {
        if (draft.applicationDetailData) {
          draft.applicationDetailData.dateAuditLog = undefined;
        }
        return;
      }
      case SET_NOTIFY_WORK_COMPLETION_SUCCESS: {
        if (draft.applicationDetailData) {
          draft.applicationDetailData.dateWorkCompleted = action.updatedInfo
            .dateWorkCompleted
            ? new Date(action.updatedInfo.dateWorkCompleted)
            : undefined;

          draft.applicationDetailData.dateWorkCompletedStatus =
            action.updatedInfo.dateWorkCompletedStatus;

          draft.applicationDetailData.notes = action.updatedInfo.notes;
          draft.applicationDetailData.carStep = action.updatedInfo.carStep;
          draft.applicationDetailData.carStatus = action.updatedInfo.carStatus;
        }
        return;
      }
      case REMOVE_PARTICIPANT_SUCCESS: {
        draft.applicationDetailData!.participants = draft.applicationDetailData!.participants!.filter(
          x => x.participantLinkId !== action.participantLinkId
        );
        return;
      }
      case SET_LOCATION_SUCCESS: {
        if (draft.applicationDetailData) {
          draft.applicationDetailData = {
            ...draft.applicationDetailData,
            features: action.locationInfo.features,
            streetName: action.locationInfo.streetName,
            streetNumber: action.locationInfo.streetNumber,
            suburb: action.locationInfo.suburb,
            townCity: action.locationInfo.townCity,
            postcode: action.locationInfo.postCode,
            addressNotes: action.locationInfo.addressNotes,
            locationsInRoad: action.locationInfo.locationsInRoad
          };
        }
        return;
      }
      case DELETE_ATTACHMENT_SUCCESS: {
        if (draft.applicationDetailData) {
          draft.applicationDetailData.attachments = draft.applicationDetailData.attachments?.filter(
            x => x.attachmentReference !== action.attachmentReference
          );
        }
        return;
      }
      case CANCEL_APPLICATION_SUCCESS: {
        if (draft.applicationDetailData) {
          draft.applicationDetailData.isApplicationCancelled = true;
        }
        return;
      }
      case SET_MAINTENANCE_COMPLETION_SUCCESS: {
        if (draft.applicationDetailData) {
          draft.applicationDetailData.dateWarrantyCompletionNotified = action
            .model.dateWarrantyCompletionNotified
            ? new Date(action.model.dateWarrantyCompletionNotified)
            : undefined;
          draft.applicationDetailData.carStatus = action.model.carStatus;
          draft.applicationDetailData.notes = action.model.notes;
        }
        return;
      }
      case DECLINE_APPLICATION_PARTICIPANT_SUCCESS: {
        draft.participantDeclined = true;
        return;
      }
    }
  },
  initialState
);

export default applicationDetailReducer;
