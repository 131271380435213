import produce from "immer";
import {
  GET_ATTACHMENT_VALIDATION_SUCCESS,
  MediaActionTypes,
  MediaState
} from "./types";

const initialState: MediaState = {
  attachmentValidation: { maxFileSize: undefined, whiteList: [] }
};

const mediaReducer = produce((draft: MediaState, action: MediaActionTypes) => {
  switch (action.type) {
    case GET_ATTACHMENT_VALIDATION_SUCCESS:
      draft.attachmentValidation = action.attachmentValidation;
      return;
  }
}, initialState);

export default mediaReducer;
