//map colours
export const EventBorderColour = "#0a7707";
export const EventFillColour = "#24ac20";
export const ExcavationBorderColour = "#015782";
export const ExcavationFillColour = "#0078B4";
export const NonExcavationBorderColour = "#7A037C";
export const NonExcavationFillColour = "#F50AF9";
export const PreliminaryBorderColour = "#4B4B4B";
export const PreliminaryFillColour = "#8C8885";
export const RetrospectiveBorderColour = "#d0aa15";
export const RetrospectiveFillColour = "#ffd326";
export const DispatchBorderColour = "#800000";
export const DispatchFillColour = "#BF0000";

//base theme colours
export const SubmiticaGreen = "#96d100"; // this is the original submitica colour
export const SubmiticaDarkGreen = "hsl(77, 100%, 36%)"; // this is the colours for icons and text
export const SubmiticaGrey = "#2e3841";
export const SubmiticaLightGrey = "hsl(0, 0%, 99%)";
export const SubmiticaWhite = "#fff";
export const SubmiticaBlack = "#000";
export const SubmiticaWhiteOffset = "#f2f2f2";
export const SubmiticaGreenOffset = "rgba(150, 209, 0, 0.08)";
export const SubmiticaYellow = "#ffa000";
export const SubmiticaOrange = "#ff7600";

//light theme colours
export const LightSubmiticaLabel = "rgba(0, 0, 0, 0.6)";
export const LightSubmiticaRed = "#cc0000";

//dark theme colours
export const DarkSubmiticaWhite = "#e6e6e6";
export const DarkSubmiticaLabel = "rgba(255, 255, 255, 0.6)";
export const DarkSubmiticaRed = "#ff0000";
