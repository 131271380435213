import {
  TTMState,
  TTMStateActionTypes,
  LOAD_TTM_SITES_SUCCESS,
  SET_TTM_SITE_STORE_DATA,
  SET_TTM_DETOUR_STORE_DATA,
  SET_TTM_CLOSURE_STORE_DATA,
  SET_TTM_SITE_DB_SUCCESS,
  SET_TTM_DETOUR_DB_SUCCESS,
  SET_TTM_CLOSURE_DB_SUCCESS,
  DELETE_TTM_SITE_STORE,
  DELETE_TTM_SITE_DB_SUCCESS,
  DELETE_TTM_DETOUR_STORE,
  DELETE_TTM_DETOUR_DB_SUCCESS,
  DELETE_TTM_CLOSURE_STORE,
  DELETE_TTM_CLOSURE_DB_SUCCESS,
  DUPLICATE_TTM,
  RESET_TTM,
  RESET_ADDED_SITE_ID
} from "./types";
import produce from "immer";
import wktToFeatures from "common/wktToFeatures";
import Wkt from "wicket";

const initialState: TTMState = {
  TTMSites: []
};

const ttmReducer = produce((draft: TTMState, action: TTMStateActionTypes) => {
  switch (action.type) {
    case SET_TTM_SITE_STORE_DATA:
    case SET_TTM_SITE_DB_SUCCESS: {
      if (action.isEditing) {
        draft.TTMSites = draft.TTMSites.map(site => {
          return action.newSite.siteId !== site.siteId ? site : action.newSite;
        });
        return;
      }
      draft.TTMSites.push(action.newSite);
      draft.addedSiteId = action.newSite.siteId;
      return;
    }
    case SET_TTM_DETOUR_STORE_DATA:
    case SET_TTM_DETOUR_DB_SUCCESS: {
      const index = draft.TTMSites.findIndex(
        x => x.siteId === action.newDetour.siteId
      );

      if (action.isEditing) {
        draft.TTMSites[index].detours = draft.TTMSites[index]?.detours.map(
          detour => {
            return action.newDetour.detourId !== detour.detourId
              ? detour
              : action.newDetour;
          }
        );
        return;
      }

      draft.TTMSites[index].detours.push(action.newDetour);
      return;
    }
    case SET_TTM_CLOSURE_STORE_DATA:
    case SET_TTM_CLOSURE_DB_SUCCESS: {
      const index = draft.TTMSites.findIndex(
        x => x.siteId === action.newClosure.siteId
      );
      if (action.isEditing) {
        draft.TTMSites[index].closures = draft.TTMSites[index].closures.map(
          closure => {
            return action.newClosure.closureId !== closure.closureId
              ? closure
              : action.newClosure;
          }
        );
        return;
      }
      draft.TTMSites[index].closures.push(action.newClosure);
      return;
    }
    case LOAD_TTM_SITES_SUCCESS: {
      const wicket = new Wkt.Wkt();
      action.sites = action.sites.map(x => {
        return {
          ...x,
          features: x.shapes ? wktToFeatures(wicket, x.shapes) : [],
          detours: x.detours.map(y => {
            return {
              ...y,
              features: y.shapes ? wktToFeatures(wicket, y.shapes) : []
            };
          }),
          closures: x.closures.map(y => {
            return {
              ...y,
              proposedStart: y.proposedStart
                ? new Date(y.proposedStart)
                : y.proposedStart,
              proposedEnd: y.proposedEnd
                ? new Date(y.proposedEnd)
                : y.proposedEnd
            };
          })
        };
      });

      draft.TTMSites = action.sites;
      return;
    }
    case DELETE_TTM_SITE_STORE:
    case DELETE_TTM_SITE_DB_SUCCESS: {
      draft.TTMSites = draft.TTMSites.filter(x => x.siteId !== action.siteId);
      return;
    }
    case DELETE_TTM_DETOUR_STORE:
    case DELETE_TTM_DETOUR_DB_SUCCESS: {
      const index = draft.TTMSites.findIndex(x => x.siteId === action.siteId);
      draft.TTMSites[index].detours = draft.TTMSites[index].detours.filter(
        x => x.detourId !== action.detourId
      );
      return;
    }
    case DELETE_TTM_CLOSURE_STORE:
    case DELETE_TTM_CLOSURE_DB_SUCCESS: {
      const index = draft.TTMSites.findIndex(x => x.siteId === action.siteId);
      draft.TTMSites[index].closures = draft.TTMSites[index].closures.filter(
        x => x.closureId !== action.closureId
      );
      return;
    }
    case DUPLICATE_TTM: {
      draft.TTMSites = action.ttmSites;
      return;
    }
    case RESET_TTM: {
      draft.TTMSites = [];
      return;
    }
    case RESET_ADDED_SITE_ID: {
      draft.addedSiteId = undefined;
      return;
    }
  }
}, initialState);

export default ttmReducer;
