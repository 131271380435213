import {
  RESET_INVOICE_DETAIL,
  LOAD_INVOICE_DETAIL_REQUEST,
  InvoiceDetailOptions,
  InvoiceDetailSuccessPayload,
  LOAD_INVOICE_DETAIL_SUCCESS,
  LOAD_INVOICE_DETAIL_FAILURE
} from "./types";

export const resetInvoiceDetail = () => ({
  type: RESET_INVOICE_DETAIL
});

export const loadInvoiceDetailRequest = (options: InvoiceDetailOptions) => ({
  type: LOAD_INVOICE_DETAIL_REQUEST,
  invoiceDetailOptions: options
});

export const loadInvoiceDetailSuccess = (
  payload: InvoiceDetailSuccessPayload
) => ({
  type: LOAD_INVOICE_DETAIL_SUCCESS,
  invoiceHeaderData: payload.invoiceHeaderData,
  receivedBillingItems: payload.receivedBillingItems,
  totalCount: payload.totalCount
});

export const loadInvoiceDetailFailure = (errorMessage: string) => ({
  type: LOAD_INVOICE_DETAIL_FAILURE,
  errorMessage: errorMessage
});
