import {
  InvoicesSummaryOptions,
  LoadInvoicesSummaryRequestAction,
  InvoicesSummarySuccessPayload,
  LOAD_INVOICES_SUMMARY_REQUEST,
  LOAD_INVOICES_SUMMARY_REQUEST_EXTRA
} from "./types";
import { API_ROUTE } from "common/constants";
import { BILLING_ENDPOINT } from "common/endpoints";
import axios from "axios";
import {
  loadInvoicesSummarySuccess,
  loadInvoicesSummaryFailure
} from "./actions";
import { put, call, takeLatest } from "redux-saga/effects";

const loadInvoicesApi = (options: InvoicesSummaryOptions) => {
  const skip = options.skip;
  const take = 50;
  return axios.post(`${API_ROUTE}${BILLING_ENDPOINT}/invoices`, {
    skip,
    take
  });
};

export function* loadInvoices(action: LoadInvoicesSummaryRequestAction) {
  try {
    const { data }: { data: InvoicesSummarySuccessPayload } = yield call(
      loadInvoicesApi,
      action.invoicesSummaryOptions
    );

    yield put(
      loadInvoicesSummarySuccess({
        receivedData: data ? data.receivedData : [],
        totalCount: data ? data.totalCount : 0
      })
    );
  } catch (e) {
    const message = e.response.data;
    yield put(
      loadInvoicesSummaryFailure(
        message.Message ?? "Something went wrong when trying to fetch Invoices"
      )
    );
  }
}

export function* watchLoadInvoicesSummaryRequest() {
  yield takeLatest(LOAD_INVOICES_SUMMARY_REQUEST, loadInvoices);
}

export function* watchLoadInvoicesSummaryRequestExtra() {
  yield takeLatest(LOAD_INVOICES_SUMMARY_REQUEST_EXTRA, loadInvoices);
}
