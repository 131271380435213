import { Feature } from "store/types";

export const featureToWkt = (wicket: any, feature: Feature) => {
  const str = JSON.stringify({
    type: feature.geometry.type,
    coordinates: feature.geometry.coordinatesArray
  });
  wicket.read(str);
  return wicket.write();
};

const featuresToWkt = (wicket: any, features: Feature[]) => {
  if (features.length === 0) {
    return null;
  }

  if (features.length > 1) {
    //loop through making a geometry collection
    const shapeWkts: string[] = [];
    features.forEach(shape => {
      shapeWkts.push(featureToWkt(wicket, shape));
    });
    const str = shapeWkts.join(", ");
    return `GEOMETRYCOLLECTION(${str})`;
  } else {
    return featureToWkt(wicket, features[0]);
  }
};

export default featuresToWkt;
