import { Action } from "redux";

export const LOGIN_USER_REQUEST = "LOGIN_USER_REQUEST";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILURE = "LOGIN_USER_FAILURE";

export const LOAD_DEFAULT_REGION_REQUEST = "LOAD_DEFAULT_REGION_REQUEST";
export const LOAD_DEFAULT_REGION_SUCCESS = "LOAD_DEFAULT_REGION_SUCCESS";
export const LOAD_DEFAULT_REGION_FAILURE = "LOAD_DEFAULT_REGION_FAILURE";

export const LOAD_SEARCHABLE_REGION_REQUEST = "LOAD_SEARCHABLE_REGION_REQUEST";
export const LOAD_SEARCHABLE_REGION_SUCCESS = "LOAD_SEARCHABLE_REGION_SUCCESS";
export const LOAD_SEARCHABLE_REGION_FAILURE = "LOAD_SEARCHABLE_REGION_FAILURE";

export const LOAD_CAN_VIEW_INVOICE_REQUEST = "LOAD_CAN_VIEW_INVOICE_REQUEST";
export const LOAD_CAN_VIEW_INVOICE_SUCCESS = "LOAD_CAN_VIEW_INVOICE_SUCCESS";
export const LOAD_CAN_VIEW_INVOICE_FAILURE = "LOAD_CAN_VIEW_INVOICE_FAILURE";

export const UPDATE_STORE_DEFAULT_REGION = "UPDATE_STORE_DEFAULT_REGION";

export const RESET_STORE_SEARCHABLE_REGION = "RESET_STORE_SEARCHABLE_REGION";

export const SETUSER = "SETUSER";
export const REFRESHTOKEN = "REFRESHTOKEN";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";

type RefreshTokenAction = Action<typeof REFRESHTOKEN> & {
  jwt: Jwt;
};

export type LoginUserRequestAction = Action<typeof LOGIN_USER_REQUEST>;

export type LoginUserSuccessAction = Action<typeof LOGIN_USER_SUCCESS> & {
  loginSuccessData: LoginSuccessData;
};

type LoadDefaultRegionSuccessAction = Action<
  typeof LOAD_DEFAULT_REGION_SUCCESS
> & {
  defaultRegion: string;
};

type UpdateStoreDefualtRegionAction = Action<
  typeof UPDATE_STORE_DEFAULT_REGION
> & {
  defaultRegion: string;
};

type LoadSearchableRegionSuccessAction = Action<
  typeof LOAD_SEARCHABLE_REGION_SUCCESS
> & {
  searchableRegion: string;
};

type ResetStoreSearchableRegionAction = Action<
  typeof RESET_STORE_SEARCHABLE_REGION
>;

type LoadCanViewInvoiceSuccessAction = Action<
  typeof LOAD_CAN_VIEW_INVOICE_SUCCESS
> & {
  canViewInvoice: boolean;
};

export type AuthStateActionTypes =
  | Action<typeof LOGOUT>
  | RefreshTokenAction
  | LoginUserSuccessAction
  | LoadDefaultRegionSuccessAction
  | UpdateStoreDefualtRegionAction
  | LoadSearchableRegionSuccessAction
  | ResetStoreSearchableRegionAction
  | LoadCanViewInvoiceSuccessAction;

export type AuthState = {
  isLoggedIn: boolean;
  isAuthenticated: boolean;
  isLoading: boolean;
  firstName: string;
  lastName: string;
  userId: number;
  organisationId: number;
  organisationName: string;
  isOrganisationManager: boolean;
  defaultRegion?: string;
  searchableRegion?: string;
  profileUpdateRequired: boolean;
  canViewInvoice?: boolean;
};

export type User = {
  id: number;
  username: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
};

export type Role = {
  id: number;
  description: string;
};

export type Jwt = {
  token: string;
  expires: Date;
  isValid: boolean;
};

export type LoginSuccessData = {
  userId: number;
  firstName: string;
  lastName: string;
  organisationId: number;
  organisationName: string;
  isOrganisationManager: boolean;
  profileUpdateRequired: boolean;
  isAuthenticated: boolean;
};
