import {
  LOAD_TTM_SITES_REQUEST,
  LOAD_TTM_SITES_SUCCESS,
  TTMSite,
  LOAD_TTM_SITES_FAILURE,
  SET_TTM_SITE_STORE_DATA,
  TTMDetour,
  SET_TTM_DETOUR_STORE_DATA,
  TTMClosure,
  SET_TTM_CLOSURE_STORE_DATA,
  SET_TTM_SITE_DB_REQUEST,
  SET_TTM_SITE_DB_SUCCESS,
  SET_TTM_SITE_DB_FAILURE,
  SET_TTM_DETOUR_DB_REQUEST,
  SET_TTM_DETOUR_DB_SUCCESS,
  SET_TTM_DETOUR_DB_FAILURE,
  SET_TTM_CLOSURE_DB_REQUEST,
  SET_TTM_CLOSURE_DB_SUCCESS,
  SET_TTM_CLOSURE_DB_FAILURE,
  DELETE_TTM_SITE_STORE,
  DELETE_TTM_SITE_DB_REQUEST,
  DELETE_TTM_SITE_DB_SUCCESS,
  DELETE_TTM_SITE_DB_FAILURE,
  DELETE_TTM_DETOUR_DB_FAILURE,
  DELETE_TTM_DETOUR_DB_SUCCESS,
  DELETE_TTM_DETOUR_DB_REQUEST,
  DELETE_TTM_DETOUR_STORE,
  DELETE_TTM_CLOSURE_STORE,
  DELETE_TTM_CLOSURE_DB_REQUEST,
  DELETE_TTM_CLOSURE_DB_SUCCESS,
  DELETE_TTM_CLOSURE_DB_FAILURE,
  DUPLICATE_TTM,
  RESET_TTM,
  RESET_ADDED_SITE_ID
} from "./types";

export const loadTTMSitesRequest = (applicationId?: number, guid?: string) => ({
  type: LOAD_TTM_SITES_REQUEST,
  applicationId: applicationId,
  guid: guid
});

export const loadTTMSitesSuccess = (payload: TTMSite[]) => ({
  type: LOAD_TTM_SITES_SUCCESS,
  sites: payload
});

export const loadTTMSitesFailure = (errorMessage: string) => ({
  type: LOAD_TTM_SITES_FAILURE,
  errorMessage: errorMessage
});

export const setTTMSiteStoreData = (newSite: TTMSite, isEditing: boolean) => ({
  type: SET_TTM_SITE_STORE_DATA,
  newSite: newSite,
  isEditing: isEditing
});

export const setTTMDetourStoreData = (
  newDetour: TTMDetour,
  isEditing: boolean
) => ({
  type: SET_TTM_DETOUR_STORE_DATA,
  newDetour: newDetour,
  isEditing: isEditing
});

export const setTTMClosureStoreData = (
  newClosure: TTMClosure,
  isEditing: boolean
) => ({
  type: SET_TTM_CLOSURE_STORE_DATA,
  newClosure: newClosure,
  isEditing: isEditing
});

export const setTTMSiteDbRequest = (
  newSite: TTMSite,
  isEditing: boolean,
  applicationId: number
) => ({
  type: SET_TTM_SITE_DB_REQUEST,
  newSite: newSite,
  isEditing: isEditing,
  applicationId: applicationId
});

export const setTTMSiteDbSuccess = (newSite: TTMSite, isEditing: boolean) => ({
  type: SET_TTM_SITE_DB_SUCCESS,
  newSite: newSite,
  isEditing: isEditing
});

export const setTTMSiteDbFailure = (errorMessage: string) => ({
  type: SET_TTM_SITE_DB_FAILURE,
  errorMessage: errorMessage
});

export const setTTMDetourDbRequest = (
  newDetour: TTMDetour,
  isEditing: boolean
) => ({
  type: SET_TTM_DETOUR_DB_REQUEST,
  newDetour: newDetour,
  isEditing: isEditing
});

export const setTTMDetourDbSuccess = (
  newDetour: TTMDetour,
  isEditing: boolean
) => ({
  type: SET_TTM_DETOUR_DB_SUCCESS,
  newDetour: newDetour,
  isEditing: isEditing
});

export const setTTMDetourDbFailure = (errorMessage: string) => ({
  type: SET_TTM_DETOUR_DB_FAILURE,
  errorMessage: errorMessage
});

export const setTTMClosureDbRequest = (
  newClosure: TTMClosure,
  isEditing: boolean
) => ({
  type: SET_TTM_CLOSURE_DB_REQUEST,
  newClosure: newClosure,
  isEditing: isEditing
});

export const setTTMClosureDbSuccess = (
  newClosure: TTMClosure,
  isEditing: boolean
) => ({
  type: SET_TTM_CLOSURE_DB_SUCCESS,
  newClosure: newClosure,
  isEditing: isEditing
});

export const setTTMClosureDbFailure = (errorMessage: string) => ({
  type: SET_TTM_CLOSURE_DB_FAILURE,
  errorMessage: errorMessage
});

export const deleteTTMSiteStore = (siteId: number) => ({
  type: DELETE_TTM_SITE_STORE,
  siteId: siteId
});

export const deleteTTMSiteDbRequest = (siteId: number) => ({
  type: DELETE_TTM_SITE_DB_REQUEST,
  siteId: siteId
});

export const deleteTTMSiteDbSuccess = (siteId: number) => ({
  type: DELETE_TTM_SITE_DB_SUCCESS,
  siteId: siteId
});

export const deleteTTMSiteDbFailure = (errorMessage: string) => ({
  type: DELETE_TTM_SITE_DB_FAILURE,
  errorMessage: errorMessage
});

export const deleteTTMDetourStore = (siteId: number, detourId: number) => ({
  type: DELETE_TTM_DETOUR_STORE,
  siteId: siteId,
  detourId: detourId
});

export const deleteTTMDetourDbRequest = (siteId: number, detourId: number) => ({
  type: DELETE_TTM_DETOUR_DB_REQUEST,
  siteId: siteId,
  detourId: detourId
});

export const deleteTTMDetourDbSuccess = (siteId: number, detourId: number) => ({
  type: DELETE_TTM_DETOUR_DB_SUCCESS,
  siteId: siteId,
  detourId: detourId
});

export const deleteTTMDetourDbFailure = (errorMessage: string) => ({
  type: DELETE_TTM_DETOUR_DB_FAILURE,
  errorMessage: errorMessage
});

export const deleteTTMClosureStore = (siteId: number, closureId: number) => ({
  type: DELETE_TTM_CLOSURE_STORE,
  siteId: siteId,
  closureId: closureId
});

export const deleteTTMClosureDbRequest = (
  siteId: number,
  closureId: number
) => ({
  type: DELETE_TTM_CLOSURE_DB_REQUEST,
  siteId: siteId,
  closureId: closureId
});

export const deleteTTMClosureDbSuccess = (
  siteId: number,
  closureId: number
) => ({
  type: DELETE_TTM_CLOSURE_DB_SUCCESS,
  siteId: siteId,
  closureId: closureId
});

export const deleteTTMClosureDbFailure = (errorMessage: string) => ({
  type: DELETE_TTM_CLOSURE_DB_FAILURE,
  errorMessage: errorMessage
});

export const duplicateTTM = (ttmSites: TTMSite[]) => ({
  type: DUPLICATE_TTM,
  ttmSites: ttmSites
});

export const resetTTM = () => ({
  type: RESET_TTM
});

export const resetAddedSiteId = () => ({
  type: RESET_ADDED_SITE_ID
});
