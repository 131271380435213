import { takeLatest, call, put } from "redux-saga/effects";
import {
  GET_FILTER_PREFERENCE_REQUEST,
  GET_FILTER_PREFERENCE_FAILURE,
  SET_FILTER_PREFERENCE_REQUEST,
  SetFilterPreferenceRequestAction,
  SET_FILTER_PREFERENCE_FAILURE,
  GetFilterPreferenceRequestAction
} from "./types";
import axios from "axios";
import { API_ROUTE } from "common/constants";
import { ADMINISTRATION_ENDPOINT } from "common/endpoints";
import { USERS_CONTROLLER } from "common/controllerConstants";
import {
  getFilterPreferenceSuccess,
  setFilterPreferenceSuccess,
  getFilterPreferenceFailure,
  setFilterPreferenceFailure
} from "./actions";
import { enqueueSnackbar } from "store/notistack/actions";
import { createNotification } from "common/addNotification";
import { FilterPreferences } from "store/types";

const setFilterPreferenceApi = (filterPreference: string) => {
  return axios.post(
    `${API_ROUTE}${ADMINISTRATION_ENDPOINT}/${USERS_CONTROLLER}/userfilterpreference`,
    { filterPreference: filterPreference }
  );
};

function* setFilterPreference(action: SetFilterPreferenceRequestAction) {
  try {
    const saveFilter = JSON.stringify(action.filterPreference, (key, value) =>
      typeof value === "undefined" ? null : value
    );
    yield call(setFilterPreferenceApi, saveFilter);
    yield put(setFilterPreferenceSuccess(action.filterPreference));
  } catch (e) {
    const message = e.response.data;

    if (message.Message) {
      enqueueSnackbar(
        createNotification(
          SET_FILTER_PREFERENCE_FAILURE,
          message.Message,
          message.MessageType
        )
      );
    } else {
      yield put(
        setFilterPreferenceFailure(
          "Something went wrong while saving your filter preferences"
        )
      );
    }
  }
}

export function* watchSetFilterPreferenceRequest() {
  yield takeLatest(SET_FILTER_PREFERENCE_REQUEST, setFilterPreference);
}

const getFilterPreferenceApi = () => {
  return axios.get(
    `${API_ROUTE}${ADMINISTRATION_ENDPOINT}/${USERS_CONTROLLER}/userfilterpreference`
  );
};

function* getFilterPreference(action: GetFilterPreferenceRequestAction) {
  try {
    const { data }: { data: FilterPreferences } = yield call(
      getFilterPreferenceApi
    );

    yield put(getFilterPreferenceSuccess(data, action.loggedInUserId));
  } catch (e) {
    const message = e.response.data;

    if (message.Message) {
      enqueueSnackbar(
        createNotification(
          GET_FILTER_PREFERENCE_FAILURE,
          message.Message,
          message.MessageType
        )
      );
    } else {
      yield put(
        getFilterPreferenceFailure(
          "Something went wrong while trying to get your filter preference"
        )
      );
    }
  }
}

export function* watchGetFilterPreferenceRequest() {
  yield takeLatest(GET_FILTER_PREFERENCE_REQUEST, getFilterPreference);
}
