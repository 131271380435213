import {
  LOAD_BILLPAYERS_SUMMARY_REQUEST,
  LoadBillPayersSummaryRequestAction,
  LOAD_BILLPAYERS_SUMMARY_REQUEST_EXTRA,
  BillPayersSummaryReceivedPayload,
  BillPayersSummaryFilter
} from "./types";
import { put, takeLatest, call } from "redux-saga/effects";
import {
  loadBillPayersSummaryFailure,
  loadBillPayersSummarySuccess
} from "./actions";
import axios from "axios";
import { API_ROUTE } from "common/constants";
import { BILLING_ENDPOINT } from "common/endpoints";

const getBillPayers = (filters: BillPayersSummaryFilter) => {
  const skip = filters.skip;
  const take = 50;
  return axios.post(`${API_ROUTE}${BILLING_ENDPOINT}/billpayers`, {
    skip,
    take
  });
};

export function* loadBillPayersSummaryRequest(
  action: LoadBillPayersSummaryRequestAction
) {
  try {
    const { data }: { data: BillPayersSummaryReceivedPayload } = yield call(
      getBillPayers,
      action.billPayersSummaryOptions
    );

    yield put(
      loadBillPayersSummarySuccess({
        receivedData: data.receivedData,
        totalCount: data.totalCount
      })
    );
  } catch (e) {
    const message = e.response.data;

    yield put(
      loadBillPayersSummaryFailure(
        message.Message ??
          "Something went wrong when trying to fetch the Bill Payers"
      )
    );
  }
}

export function* watchLoadBillPayersSummaryRequestExtra() {
  yield takeLatest(
    LOAD_BILLPAYERS_SUMMARY_REQUEST_EXTRA,
    loadBillPayersSummaryRequest
  );
}

export default function* watchLoadBillPayersSummaryRequest() {
  yield takeLatest(
    LOAD_BILLPAYERS_SUMMARY_REQUEST,
    loadBillPayersSummaryRequest
  );
}
