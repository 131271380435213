import produce from "immer";
import { Action } from "redux";
import { ErrorState, ErrorPayload, RESET_ERROR_MESSAGE } from "./types";

const initialState: ErrorState = new Map();

const errorReducer = produce(
  (draft: ErrorState, action: Action<string> & ErrorPayload) => {
    const matches = /.*_(REQUEST|FAILURE)/.exec(action.type);

    if (matches) {
      const requestName = action.type.replace(/_FAILURE/, "_REQUEST");
      const [, requestState] = matches;

      requestState === "FAILURE"
        ? draft.set(requestName, action.errorMessage)
        : draft.delete(requestName);
    } else if (action.type === RESET_ERROR_MESSAGE) {
      draft.clear();
    } else {
      return draft;
    }
  },
  initialState
);

export default errorReducer;
