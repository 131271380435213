import {
  LOAD_LOOKUP_REQUEST,
  LOAD_LOOKUP_SUCCESS,
  LOAD_LOOKUP_FAILURE,
  RESET_LOOKUP_DATA
} from "./types";
import { Lookup, SearchModel } from "store/types";
import { Endpoint } from "common/endpoints";

export const loadLookupRequest = (
  endpoint?: Endpoint,
  route?: string, //< This route is indicative of which lookup set is being requested and can be found as one of the constants in the ./types.ts file
  args?: string[] | SearchModel
) => ({
  type: LOAD_LOOKUP_REQUEST,
  endpoint: endpoint,
  route: route,
  args: args
});

export const loadLookupSuccess = (route: string, data: Lookup[]) => ({
  type: LOAD_LOOKUP_SUCCESS,
  route: route,
  data: data
});

export const loadLookupFailure = (errorMessage: string) => ({
  type: LOAD_LOOKUP_FAILURE,
  errorMessage: errorMessage
});

export const resetLookupData = (route: string) => ({
  type: RESET_LOOKUP_DATA,
  route: route
});
