import { ApplicationType } from "./enums";

export function GetValueFromEnum<T>(enumObject: T, searchStr: string): string {
  const filtered = Object.entries(enumObject).filter(t => t[0] === searchStr);
  return filtered.length === 0 ? null : filtered[0][1];
}

export function GetApplicationTypeString(
  applicationType: ApplicationType
): string {
  switch (applicationType) {
    case ApplicationType.Excavation:
      return "Excavation";
    case ApplicationType.NonExcavation:
      return "Non-Excavation";
    case ApplicationType.Preliminary:
      return "Preliminary";
    case ApplicationType.Event:
      return "Event";
    case ApplicationType.Retrospective:
      return "Emergency";
  }
}
