import produce from "immer";
import {
  BillPayersSummaryActionTypes,
  BillPayersSummaryState,
  LOAD_BILLPAYERS_SUMMARY_SUCCESS,
  RESET_BILLPAYERS_SUMMARY
} from "./types";

const initialState: BillPayersSummaryState = {
  billPayersSummaryData: [],
  totalSummaries: 0
};

const billPayersSummaryReducer = produce(
  (draft: BillPayersSummaryState, action: BillPayersSummaryActionTypes) => {
    switch (action.type) {
      case LOAD_BILLPAYERS_SUMMARY_SUCCESS: {
        draft.billPayersSummaryData.push(...action.receivedData);
        draft.totalSummaries = action.totalCount;
        return;
      }
      case RESET_BILLPAYERS_SUMMARY: {
        draft.billPayersSummaryData = [];
        return;
      }
    }
  },
  initialState
);

export default billPayersSummaryReducer;
