import {
  InvoiceDetailOptions,
  LOAD_INVOICE_DETAIL_REQUEST,
  LoadInvoiceDetailRequestAction,
  InvoiceDetailSuccessPayload
} from "./types";
import { API_ROUTE } from "common/constants";
import { BILLING_ENDPOINT } from "common/endpoints";
import axios from "axios";
import { put, call, takeLatest } from "redux-saga/effects";
import { loadInvoiceDetailFailure, loadInvoiceDetailSuccess } from "./actions";

const loadInvoiceApi = (options: InvoiceDetailOptions) => {
  const invoiceId = options.invoiceId;
  return axios.post(`${API_ROUTE}${BILLING_ENDPOINT}/invoice`, {
    invoiceId
  });
};

export function* loadInvoice(action: LoadInvoiceDetailRequestAction) {
  try {
    const { data }: { data: InvoiceDetailSuccessPayload } = yield call(
      loadInvoiceApi,
      action.invoiceDetailOptions
    );

    yield put(
      loadInvoiceDetailSuccess({
        receivedBillingItems: data.receivedBillingItems,
        totalCount: data.totalCount,
        invoiceHeaderData: data.invoiceHeaderData
      })
    );
  } catch (e) {
    const message = e.response.data;

    yield put(
      loadInvoiceDetailFailure(
        message.Message ??
          "Something went wrong when trying to fetch that Invoice"
      )
    );
  }
}

export function* watchLoadInvoiceDetailRequest() {
  yield takeLatest(LOAD_INVOICE_DETAIL_REQUEST, loadInvoice);
}
