import {
  SETDARKTHEME,
  SETLIGHTTHEME,
  SETPREFERENCES,
  PreferencesState,
  LOADPREFERENCES,
  SummariesViewMode,
  CHANGE_VIEW_MODE,
  SET_COLUMN_SORTING,
  GET_FILTER_PREFERENCE_REQUEST,
  GET_FILTER_PREFERENCE_FAILURE,
  SET_FILTER_PREFERENCE_REQUEST,
  SET_FILTER_PREFERENCE_FAILURE,
  SET_FILTER_PREFERENCE_SUCCESS,
  GET_FILTER_PREFERENCE_SUCCESS
} from "./types";
import { FilterPreferences, SortModel } from "store/types";

export const setDarkTheme = () => {
  return { type: SETDARKTHEME };
};

export const setLightTheme = () => {
  return { type: SETLIGHTTHEME };
};

export const loadPreferences = () => {
  return { type: LOADPREFERENCES };
};

export const setPreferences = (preferences: PreferencesState) => {
  return { type: SETPREFERENCES, preferences: preferences };
};

export const changeViewMode = (view: SummariesViewMode) => {
  return { type: CHANGE_VIEW_MODE, viewMode: view };
};

export const setColumnSorting = (columnSorting: SortModel[]) => {
  return { type: SET_COLUMN_SORTING, columnSorting: columnSorting };
};

export const getFilterPreferenceRequest = (loggedInUserId: number) => ({
  type: GET_FILTER_PREFERENCE_REQUEST,
  loggedInUserId: loggedInUserId
});

export const getFilterPreferenceSuccess = (
  filterPreference: FilterPreferences,
  loggedInUserId: number
) => ({
  type: GET_FILTER_PREFERENCE_SUCCESS,
  filterPreference: filterPreference,
  loggedInUserId: loggedInUserId
});

export const getFilterPreferenceFailure = (errorMessage: string) => ({
  type: GET_FILTER_PREFERENCE_FAILURE,
  errorMessage: errorMessage
});

export const setFilterPreferenceRequest = (
  filterPreference: FilterPreferences
) => ({
  type: SET_FILTER_PREFERENCE_REQUEST,
  filterPreference: filterPreference
});

export const setFilterPreferenceSuccess = (
  filterPreference: FilterPreferences
) => ({
  type: SET_FILTER_PREFERENCE_SUCCESS,
  filterPreference: filterPreference
});

export const setFilterPreferenceFailure = (errorMessage: string) => ({
  type: SET_FILTER_PREFERENCE_FAILURE,
  errorMessage: errorMessage
});
