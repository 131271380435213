import { Action } from "redux";
import { Lookup, SearchModel } from "store/types";
import { ErrorPayload } from "store/error/types";
import { Endpoint } from "common/endpoints";

export type LookupState = Map<string, Lookup[]>;

export const ROAD_LEVELS_LOOKUP = "roadlevels";
export const SCALE_OF_WORK_LOOKUP = "scaleofwork";
export const MAJOR_ACTIVITIES_LOOKUP = "majoractivities";
export const APPLICATION_STATUSES_LOOKUP = "applicationstatuses";
export const APPLICATION_STEPS_LOOKUP = "applicationsteps";
export const APPLICATION_TYPES_LOOKUP = "applicationtypes";
export const RCAS_LOOKUP = "rcas";
export const PARTICIPANT_CATEGORIES_LOOKUP = "participantcategories";
export const PARTICIPANT_ROLES_LOOKUP = "participantroles";
export const PRINCIPAL_ORGANISATIONS_LOOKUP = "principalorganisations";
export const PRINCIPAL_CONTACTS_LOOKUP = "principalcontacts";
export const PARTICIPANT_CERTIFICATION_TYPES_LOOKUP =
  "participantcertificatetypes";
export const ACTIVITIES_LOOKUP = "activities";
export const ROAD_CLASS_LOOKUP = "roadclass";
export const TTM_TYPES_LOOKUP = "ttmtypes";
export const TTM_IMPACT_LOOKUP = "ttmimpacts";
export const WORK_ACTIVITY_LOOKUP = "workactivities";
export const SPEED_LIMITS_LOOKUP = "speedlimits";
export const AFFECTED_ACCESS_LOOKUP = "affectedareas";
export const PRIMARY_APPLICANTS_LOOKUP = "primaryapplicants";
export const PRIMARY_APPLICANT_ROLES_LOOKUP = "primaryapplicantroles";
export const SEARCH_ORGANISATIONS_LOOKUP = "organisations";
export const SEARCH_USERS_LOOKUP = "users";
export const LOCATIONS_IN_ROAD_LOOKUP = "locationsinroad";
export const ATTACHMENT_TYPE_LOOKUP = "attachmenttypes";
export const REGION_LOOKUP = "regions";
export const AREA_LOOKUP = "areas";
export const NOTIFICATION_SUMMARY_TYPE_LOOKUP = "notificationsummarytypes";
export const FEEDBACK_LEVELS_LOOKUP = "feedbacklevels";
export const FILTER_USERS_LOOKUP = "filterusers";
export const COUNTRIES_LOOKUP = "countries";
export const RCA_MATCH_LOOKUP = "rcamatch";
export const ADDRESS_SEARCH_LOOKUP = "address";
export const QUICK_FILTER_LOOKUP = "quickfilters";

export const LOAD_LOOKUP_REQUEST = "LOAD_LOOKUP_REQUEST";
export const LOAD_LOOKUP_SUCCESS = "LOAD_LOOKUP_SUCCESS";
export const LOAD_LOOKUP_FAILURE = "LOAD_LOOKUP_FAILURE";
export const RESET_LOOKUP_DATA = "RESET_LOOKUP_DATA";

export type LoadLookupRequestAction = Action<typeof LOAD_LOOKUP_REQUEST> & {
  endpoint: Endpoint;
  route: string;
  args?: string[] | SearchModel;
};

export type LoadLookupSuccessAction = Action<typeof LOAD_LOOKUP_SUCCESS> & {
  route: string;
  data: Lookup[];
};

export type LoadLookupFailureAction = Action<typeof LOAD_LOOKUP_FAILURE> &
  ErrorPayload;

export type ResetLookupDataAction = Action<typeof RESET_LOOKUP_DATA> & {
  route: string;
};

export type LookupActionTypes =
  | LoadLookupRequestAction
  | LoadLookupSuccessAction
  | LoadLookupFailureAction
  | ResetLookupDataAction;
