import { Action } from "redux";
import { OptionsObject } from "notistack";

export const ENQUEUE_SNACKBAR = "ENQUEUE_SNACKERBAR";
export const REMOVE_SNACKBAR = "REMOVE_SNACKBAR";
export const REMOVE_ALL_SNACKBAR = "REMOVE_ALL_SNACKBAR";
export const DELETE_SNACKBAR = "DELETE_SNACKBAR";

type EnqueueSnackbarAction = Action<typeof ENQUEUE_SNACKBAR> & {
  notification: NotistackNotification;
};

type RemoveSnackbarAction = Action<typeof REMOVE_SNACKBAR> & {
  key: string;
};

type DeleteSnackbarAction = Action<typeof DELETE_SNACKBAR> & {
  key: string;
};

type RemoveAllSnackbarAction = Action<typeof REMOVE_ALL_SNACKBAR> & {
  key: string;
};

export type NotificationActionTypes =
  | EnqueueSnackbarAction
  | RemoveSnackbarAction
  | RemoveAllSnackbarAction
  | DeleteSnackbarAction;

export type NotistackNotification = {
  message: string;
  options: OptionsObject;
  dismissed: boolean;
};

export type NotistackState = {
  notifications: Map<string, NotistackNotification>;
};
