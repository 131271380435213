import {
  AuthState,
  AuthStateActionTypes,
  LOGIN_USER_SUCCESS,
  LOAD_DEFAULT_REGION_SUCCESS,
  UPDATE_STORE_DEFAULT_REGION,
  LOAD_SEARCHABLE_REGION_SUCCESS,
  RESET_STORE_SEARCHABLE_REGION,
  LOAD_CAN_VIEW_INVOICE_SUCCESS
} from "./types";
import produce from "immer";

const initialState: AuthState = {
  isLoggedIn: true,
  isLoading: true,
  isAuthenticated: false,
  firstName: "",
  lastName: "",
  userId: 0,
  organisationName: "",
  organisationId: 0,
  isOrganisationManager: false,
  profileUpdateRequired: false
};

const authReducer = produce(
  (draft: AuthState, action: AuthStateActionTypes) => {
    switch (action.type) {
      case LOGIN_USER_SUCCESS: {
        draft.userId = action.loginSuccessData.userId;
        draft.firstName = action.loginSuccessData.firstName;
        draft.lastName = action.loginSuccessData.lastName;
        draft.organisationName = action.loginSuccessData.organisationName;
        draft.organisationId = action.loginSuccessData.organisationId;
        draft.isOrganisationManager =
          action.loginSuccessData.isOrganisationManager;
        draft.profileUpdateRequired =
          action.loginSuccessData.profileUpdateRequired;
        draft.isAuthenticated = action.loginSuccessData.isAuthenticated;
        draft.isLoading = false;
        return;
      }
      case UPDATE_STORE_DEFAULT_REGION:
      case LOAD_DEFAULT_REGION_SUCCESS: {
        draft.defaultRegion = action.defaultRegion;
        return;
      }
      case LOAD_SEARCHABLE_REGION_SUCCESS: {
        draft.searchableRegion = action.searchableRegion;
        return;
      }
      case RESET_STORE_SEARCHABLE_REGION: {
        draft.searchableRegion = undefined;
        return;
      }
      case LOAD_CAN_VIEW_INVOICE_SUCCESS: {
        draft.canViewInvoice = action.canViewInvoice;
        return;
      }
    }
  },
  initialState
);

export default authReducer;
