/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { DispatchDetail, Feature, JsonGeometry } from "store/types";
import { LatLngExpression, LatLngTuple } from "leaflet";
import { Guid } from "guid-typescript";
import { CarInformation } from "store/map/types";

export const wktToFeatures = (
  wicket: any,
  wktString: string,
  carDetail?: CarInformation
) => {
  const features: Feature[] = [];

  if (wktString.startsWith("GEOMETRYCOLLECTION")) {
    // GEOMETRY COLLECTIONS

    wktString = wktString.replace(/^(GEOMETRYCOLLECTION\s*\()/, ""); //remove "GEOMETRYCOLLECTION("
    wktString = wktString.slice(0, -1); //remove ending ")"

    const singleShapes = wktString.split("),");

    singleShapes.forEach(shape => {
      // add ) if it was split off
      if (!shape.endsWith(")")) {
        shape = shape + ")";
      }

      const feature: Feature = getSingleFeatureFromWkt(
        wicket,
        shape,
        carDetail
      );

      features.push(feature);
    });

    return features;
  } else if (wktString.startsWith("MULTI")) {
    // MULTI POLYGONS/LINESTRINGS
    wicket.read(wktString);
    const geom: JsonGeometry = wicket.toJson();
    const type = geom.type.replace(/^Multi/, "");
    (geom.coordinates as LatLngExpression[][]).forEach(coords => {
      let geometry: JsonGeometry = {
        type: type,
        coordinates: coords
      };

      geometry = flipCoordinates(geometry);

      const newFeature: Feature = {
        type: "Feature",
        geometry: geometry,
        id: Guid.create(),
        carDetail: carDetail
      };

      features.push(newFeature);
    });
    return features;
  } else {
    // POLYGON/LINESTRING/POINT
    return [getSingleFeatureFromWkt(wicket, wktString, carDetail)];
  }
};

const getSingleFeatureFromWkt = (
  wicket: any,
  wktString: string,
  carDetail: CarInformation | undefined
) => {
  wicket.read(wktString);
  let geom: JsonGeometry = wicket.toJson();

  geom = flipCoordinates(geom);

  const feature: Feature = {
    type: "Feature",
    geometry: geom,
    id: Guid.create(),
    carDetail: carDetail
  };

  return feature;
};

export const flipCoordinates = (shape: JsonGeometry) => {
  shape.coordinatesArray = (<any>shape.coordinates).slice();

  if (shape.type === "Polygon") {
    shape.coordinates = (<LatLngExpression[][]>shape.coordinates).map(x => {
      return x.map(y => {
        return getLatLngTuple(y as LatLngTuple);
      });
    });
  } else if (shape.type === "Point") {
    shape.coordinates = <LatLngTuple>(
      getLatLngTuple(shape.coordinates as LatLngTuple)
    );
  } else if (shape.type === "LineString") {
    shape.coordinates = (<LatLngExpression[]>shape.coordinates).map(x => {
      return getLatLngTuple(x as LatLngTuple);
    });
  }
  return shape;
};

export const getLatLngTuple = (latLng: LatLngTuple) => {
  return <LatLngTuple>[latLng[1], latLng[0]];
};

export const getDispatchFeature = (dispatch: DispatchDetail) => {
  const geometries: JsonGeometry[] = [];
  if (dispatch.geometry.type.toUpperCase().startsWith("MULTI")) {
    if (dispatch.geometry.type !== "MultiPolygon") {
      return null;
    }

    (dispatch.geometry.coordinates as LatLngExpression[][]).forEach(coords => {
      const geometry: JsonGeometry = {
        type: "Polygon",
        coordinates: coords
      };
      geometries.push(flipCoordinates(geometry));
    });
  } else {
    const geometry: JsonGeometry = {
      type: dispatch.geometry.type,
      coordinates: dispatch.geometry.coordinates
    };
    geometries.push(flipCoordinates(geometry));
  }
  const features: Feature[] = [];
  geometries.forEach(geometry => {
    const item: Feature = {
      id: dispatch.properties.id,
      geometry: geometry,
      dispatchDetail: {
        id: dispatch.properties.dispatch_id,
        assetType: dispatch.properties.asset_type,
        status: dispatch.properties.call_status,
        callType: dispatch.properties.call_type,
        contractId: dispatch.properties.contract_id,
        fault: dispatch.properties.fault,
        priority: dispatch.properties.priority
      },
      type: dispatch.type
    };
    features.push(item);
  });

  return features;
};

export default wktToFeatures;
