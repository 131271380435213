import { all } from "redux-saga/effects";
import watchLoadSingleApplicationRequest, {
  watchFileUploadRequest,
  watchSetApplicationNotesRequest,
  watchSetWorkInformationRequest,
  watchDownloadCalloutReportRequest,
  watchSetParticipantRequest,
  watchSetNotifyWorkStatusRequest,
  watchSetApplicationFeedbackRequest,
  watchLoadExistingApplicationFeedbackRequest,
  watchReplaceParticipantRequest,
  watchLoadAuditLogsRequest,
  watchRemoveParticipantRequest,
  watchSetLocationRequest,
  watchDeleteAttachmentRequest,
  watchCancelApplicationRequest,
  watchSetMaintenanceCompletionRequest,
  watchDeclineApplicationParticipantRequest
} from "./applicationDetail/sagas";
import watchLoadApplicationsSummaryRequest, {
  watchGenerateApplicationGridListExportRequest,
  watchLoadApplicationExportColumnsRequest,
  watchLoadApplicationsSummaryRequestExtra,
  watchLoadRcaBoundariesRequest,
  watchLoadRcaLogosRequest
} from "./applicationsSummary/sagas";
import watchLoadLookupRequest from "./lookup/sagas";
import watchLoadTTMSitesRequest, {
  watchSetTTMSiteDbRequest,
  watchSetTTMDetourDbRequest,
  watchSetTTMClosureDbRequest,
  watchDeleteTTMSiteDbRequest,
  watchDeleteTTMDetourDbRequest,
  watchDeleteTTMClosureDbRequest
} from "./trafficManagement/sagas";
import watchSetUserDetailRequest, {
  watchLoadUserLinksRequest,
  watchSetUserLinkRequest,
  watchLoadUserDetailRequest,
  watchLoadDuplicateAccountsRequest,
  watchSetLinkSelectedDuplicatesRequest,
  watchSetGstNumberRequest,
  watchLoadNewOrganisationsRequest,
  watchSetNewOrganisationRequest,
  watchSetNotificationSettingsRequest,
  watchSetUserRegionsRequest,
  watchDeleteUserLinkRequest,
  watchLoadPermissionToViewUserRequest,
  watchLoadUserNotificationCountRequest,
  watchLoadUserNotificationsRequest,
  watchSetUserNotificationInactiveRequest,
  watchSetUserNotificationConfirmDeclineRequest,
  watchLoadScaleOfWorkQuestionnaireRequest,
  watchLoadUserRegionsRequest,
  watchLoadParticipantOrganisationRequest,
  watchConfirmUserLinkRequest,
  watchDeclineUserLinkRequest,
  watchGetContactRequest
} from "./administration/sagas";
import {
  watchTemporaryFileUploadRequest,
  watchTemporaryFileDeleteRequest,
  watchSubmitApplicationRequest,
  watchApplicationSettingsDataRequest,
  watchLoadBillingItemPreviewRequest,
  watchLoadRcaIdsFromShapesRequest,
  watchLoadRcaOptionsFromIdRequest,
  watchLoadPrimaryApplicantInfoRequest,
  watchSetTemporarySavedApplicationRequest,
  watchCheckRegisteredOrganisationRequest,
  watchLoadSavedApplicationsRequest,
  watchDeleteSavedApplicationRequest,
  watchLoadSavedApplicationRequest,
  watchChildApplicationSettingsDataRequest,
  watchGetDefaultBillPayerRequest
} from "./createApplication/sagas";
import watchLoadShapesRequest, {
  watchLoadApplicationClash,
  watchLoadDispatchRequest
} from "./map/sagas";
import {
  watchLoadBillPayerRequest,
  watchAddBillPayerRequest
} from "./billPayerDetail/sagas";
import watchLoadBillPayersSummaryRequest, {
  watchLoadBillPayersSummaryRequestExtra
} from "./billPayersSummary/sagas";
import {
  watchLoadInvoicesSummaryRequest,
  watchLoadInvoicesSummaryRequestExtra
} from "./invoiceSummary/sagas";
import { watchLoadInvoiceDetailRequest } from "./invoiceDetail/sagas";
import {
  watchDownloadAttachmentRequest,
  watchDownloadAttachmentsRequest,
  watchGetAttachmentValidation
} from "./media/sagas";
import {
  watchLoadCanViewInvoiceRequest,
  watchLoadDefaultRegionRequest,
  watchLoadSearchableRegionRequest
} from "./auth/sagas";
import {
  watchSetFilterPreferenceRequest,
  watchGetFilterPreferenceRequest
} from "./preferences/sagas";

export default function* rootSaga() {
  yield all([
    watchLoadApplicationsSummaryRequest(),
    watchLoadApplicationsSummaryRequestExtra(),
    watchLoadSingleApplicationRequest(),
    watchLoadUserLinksRequest(),
    watchLoadLookupRequest(),
    watchLoadTTMSitesRequest(),
    watchSetUserDetailRequest(),
    watchSetUserLinkRequest(),
    watchSetTTMSiteDbRequest(),
    watchSetTTMDetourDbRequest(),
    watchSetTTMClosureDbRequest(),
    watchSetGstNumberRequest(),
    watchLoadUserDetailRequest(),
    watchLoadDuplicateAccountsRequest(),
    watchSetLinkSelectedDuplicatesRequest(),
    watchTemporaryFileUploadRequest(),
    watchTemporaryFileDeleteRequest(),
    watchSubmitApplicationRequest(),
    watchApplicationSettingsDataRequest(),
    watchFileUploadRequest(),
    watchLoadBillingItemPreviewRequest(),
    watchLoadNewOrganisationsRequest(),
    watchSetNewOrganisationRequest(),
    watchLoadShapesRequest(),
    watchLoadRcaIdsFromShapesRequest(),
    watchLoadBillPayerRequest(),
    watchAddBillPayerRequest(),
    watchLoadBillPayersSummaryRequest(),
    watchLoadBillPayersSummaryRequestExtra(),
    watchSetNotificationSettingsRequest(),
    watchSetUserRegionsRequest(),
    watchDeleteUserLinkRequest(),
    watchLoadPermissionToViewUserRequest(),
    watchLoadInvoicesSummaryRequest(),
    watchLoadInvoicesSummaryRequestExtra(),
    watchLoadInvoiceDetailRequest(),
    watchLoadUserNotificationCountRequest(),
    watchLoadUserNotificationsRequest(),
    watchSetApplicationNotesRequest(),
    watchDeleteTTMSiteDbRequest(),
    watchDeleteTTMDetourDbRequest(),
    watchDeleteTTMClosureDbRequest(),
    watchSetUserNotificationInactiveRequest(),
    watchSetWorkInformationRequest(),
    watchDownloadAttachmentRequest(),
    watchDownloadAttachmentsRequest(),
    watchDownloadCalloutReportRequest(),
    watchSetNotifyWorkStatusRequest(),
    watchSetUserNotificationConfirmDeclineRequest(),
    watchSetApplicationFeedbackRequest(),
    watchLoadExistingApplicationFeedbackRequest(),
    watchSetParticipantRequest(),
    watchReplaceParticipantRequest(),
    watchLoadAuditLogsRequest(),
    watchRemoveParticipantRequest(),
    watchSetLocationRequest(),
    watchDeleteAttachmentRequest(),
    watchCancelApplicationRequest(),
    watchSetMaintenanceCompletionRequest(),
    watchLoadScaleOfWorkQuestionnaireRequest(),
    watchLoadSearchableRegionRequest(),
    watchLoadUserRegionsRequest(),
    watchLoadDefaultRegionRequest(),
    watchLoadRcaOptionsFromIdRequest(),
    watchLoadPrimaryApplicantInfoRequest(),
    watchLoadParticipantOrganisationRequest(),
    watchSetTemporarySavedApplicationRequest(),
    watchCheckRegisteredOrganisationRequest(),
    watchLoadSavedApplicationsRequest(),
    watchDeleteSavedApplicationRequest(),
    watchLoadSavedApplicationRequest(),
    watchConfirmUserLinkRequest(),
    watchDeclineUserLinkRequest(),
    watchGetContactRequest(),
    watchGetFilterPreferenceRequest(),
    watchSetFilterPreferenceRequest(),
    watchDeclineApplicationParticipantRequest(),
    watchLoadApplicationClash(),
    watchLoadRcaLogosRequest(),
    watchLoadCanViewInvoiceRequest(),
    watchLoadRcaBoundariesRequest(),
    watchLoadApplicationExportColumnsRequest(),
    watchGenerateApplicationGridListExportRequest(),
    watchChildApplicationSettingsDataRequest(),
    watchGetAttachmentValidation(),
    watchLoadDispatchRequest(),
    watchGetDefaultBillPayerRequest()
  ]);
}
