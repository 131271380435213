import { Action } from "redux";
import { ErrorPayload } from "store/error/types";
import { Lookup, Feature } from "store/types";
import { WorkInformationModel } from "containers/ApplicationDetail/EditWorkInformationModal";
import { CalloutType } from "common/enums";
import { AddParticipant } from "store/types";

/* saga action names start */
export const LOAD_SINGLE_APPLICATION_REQUEST =
  "LOAD_SINGLE_APPLICATION_REQUEST";
export const LOAD_SINGLE_APPLICATION_SUCCESS =
  "LOAD_SINGLE_APPLICATION_SUCCESS";
export const LOAD_SINGLE_APPLICATION_FAILURE =
  "LOAD_SINGLE_APPLICATION_FAILURE";
export const UPLOAD_DETAIL_FILE_REQUEST = "UPLOAD_DETAIL_FILE_REQUEST";
export const UPLOAD_DETAIL_FILE_SUCCESS = "UPLOAD_DETAIL_FILE_SUCCESS";
export const UPLOAD_DETAIL_FILE_FAILURE = "UPLOAD_DETAIL_FILE_FAILURE";

export const SET_WORK_INFORMATION_REQUEST = "SET_WORK_INFORMATION_REQUEST";
export const SET_WORK_INFORMATION_SUCCESS = "SET_WORK_INFORMATION_SUCCESS";
export const SET_WORK_INFORMATION_FAILURE = "SET_WORK_INFORMATION_FAILURE";

export const SET_APPLICATION_NOTES_REQUEST = "SET_APPLICATION_NOTES_REQUEST";
export const SET_APPLICATION_NOTES_SUCCESS = "SET_APPLICATION_NOTES_SUCCESS";
export const SET_APPLICATION_NOTES_FAILURE = "SET_APPLICATION_NOTES_FAILURE";

export const CANCEL_APPLICATION_REQUEST = "CANCEL_APPLICATION_REQUEST";
export const CANCEL_APPLICATION_SUCCESS = "CANCEL_APPLICATION_SUCCESS";
export const CANCEL_APPLICATION_FAILURE = "CANCEL_APPLICATION_FAILURE";

export const SET_NOTIFY_WORK_STATUS_REQUEST = "SET_NOTIFY_WORK_STATUS_REQUEST";
export const SET_NOTIFY_WORK_START_SUCCESS = "SET_NOTIFY_WORK_START_SUCCESS";
export const SET_NOTIFY_WORK_COMPLETION_SUCCESS =
  "SET_NOTIFY_WORK_COMPLETION_SUCCESS";
export const SET_NOTIFY_WORK_STATUS_FAILURE = "SET_NOTIFY_WORK_STATUS_FAILURE";

export const SET_APPLICATION_FEEDBACK_REQUEST =
  "SET_APPLICATION_FEEDBACK_REQUEST";
export const SET_APPLICATION_FEEDBACK_SUCCESS =
  "SET_APPLICATION_FEEDBACK_SUCCESS";
export const SET_APPLICATION_FEEDBACK_FAILURE =
  "SET_APPLICATION_FEEDBACK_FAILURE";

export const LOAD_EXISTING_APPLICATION_FEEDBACK_REQUEST =
  "LOAD_EXISTING_APPLICATION_FEEDBACK_REQUEST";
export const LOAD_EXISTING_APPLICATION_FEEDBACK_SUCCESS =
  "LOAD_EXISTING_APPLICATION_FEEDBACK_SUCCESS";
export const LOAD_EXISTING_APPLICATION_FEEDBACK_FAILURE =
  "LOAD_EXISTING_APPLICATION_FEEDBACK_FAILURE";

export const DOWNLOAD_CALLOUT_REPORT_REQUEST =
  "DOWNLOAD_CALLOUT_REPORT_REQUEST";
export const DOWNLOAD_INSPECTION_REPORT_SUCCESS =
  "DOWNLOAD_INSPECTION_REPORT_SUCCESS";
export const DOWNLOAD_DEFECT_REPORT_SUCCESS = "DOWNLOAD_DEFECT_REPORT_SUCCESS";
export const DOWNLOAD_CALLOUT_REPORT_FAILURE =
  "DOWNLOAD_CALLOUT_REPORT_FAILURE";

export const LOAD_AUDIT_LOGS_REQUEST = "LOAD_AUDIT_LOGS_REQUEST";
export const LOAD_AUDIT_LOGS_SUCCESS = "LOAD_AUDIT_LOGS_SUCCESS";
export const LOAD_AUDIT_LOGS_FAILURE = "LOAD_AUDIT_LOGS_FAILURE";

export const RESET_AUDIT_LOGS = "RESET_AUDIT_LOGS";
export const SET_PARTICIPANT_REQUEST = "SET_PARTICIPANT_REQUEST";
export const SET_PARTICIPANT_SUCCESS = "SET_PARTICIPANT_SUCCESS";
export const SET_PARTICIPANT_FAILURE = "SET_PARTICIPANT_FAILURE";
export const REPLACE_PARTICIPANT_REQUEST = "REPLACE_PARTICIPANT_REQUEST";
export const REPLACE_PARTICIPANT_SUCCESS = "REPLACE_PARTICIPANT_SUCCESS";
export const REPLACE_PARTICIPANT_FAILURE = "REPLACE_PARTICIPANT_FAILURE";
export const REMOVE_PARTICIPANT_REQUEST = "REMOVE_PARTICIPANT_REQUEST";
export const REMOVE_PARTICIPANT_SUCCESS = "REMOVE_PARTICIPANT_SUCCESS";
export const REMOVE_PARTICIPANT_FAILURE = "REMOVE_PARTICIPANT_FAILURE";

export const SET_LOCATION_REQUEST = "SET_LOCATION_REQUEST";
export const SET_LOCATION_SUCCESS = "SET_LOCATION_SUCCESS";
export const SET_LOCATION_FAILURE = "SET_LOCATION_FAILURE";
export const DELETE_ATTACHMENT_REQUEST = "DELETE_ATTACHMENT_REQUEST";
export const DELETE_ATTACHMENT_SUCCESS = "DELETE_ATTACHMENT_SUCCESS";
export const DELETE_ATTACHMENT_FAILURE = "DELETE_ATTACHMENT_FAILURE";

export const SET_MAINTENANCE_COMPLETION_REQUEST =
  "SET_MAINTENANCE_COMPLETION_REQUEST";
export const SET_MAINTENANCE_COMPLETION_SUCCESS =
  "SET_MAINTENANCE_COMPLETION_SUCCESS";
export const SET_MAINTENANCE_COMPLETION_FAILURE =
  "SET_MAINTENANCE_COMPLETION_FAILURE";

export const DECLINE_APPLICATION_PARTICIPANT_REQUEST =
  "DECLINE_APPLICATION_PARTICIPANT_REQUEST";
export const DECLINE_APPLICATION_PARTICIPANT_SUCCESS =
  "DECLINE_APPLICATION_PARTICIPANT_SUCCESS";
export const DECLINE_APPLICATION_PARTICIPANT_FAILURE =
  "DECLINE_APPLICATION_PARTICIPANT_FAILURE";
/* saga action names end */

/* redux action names start */
export const RESET_SINGLE_APPLICATION = "RESET_SINGLE_APPLICATION";
/* redux action names end */

export type LoadSingleApplicationRequestAction = Action<
  typeof LOAD_SINGLE_APPLICATION_REQUEST
> & {
  carId?: number;
  guid?: string;
};

type LoadSingleApplicationSuccessAction = Action<
  typeof LOAD_SINGLE_APPLICATION_SUCCESS
> & {
  applicationDetailData: ApplicationDetail;
};

type LoadSingleApplicationFailureAction = Action<
  typeof LOAD_SINGLE_APPLICATION_FAILURE
> &
  ErrorPayload;

type ResetSingleApplicationAction = Action<typeof RESET_SINGLE_APPLICATION>;

export type UploadFileRequestAction = Action<
  typeof UPLOAD_DETAIL_FILE_REQUEST
> & {
  applicationId: number;
  attachments: NewAttachment[];
  carStepId: number;
  permissionValue: number;
  childApplicationIds: number[];
  reference?: string;
};

type UploadFileSuccessAction = Action<typeof UPLOAD_DETAIL_FILE_SUCCESS> & {
  attachments: Attachment[];
};

type UploadFileFailureAction = Action<typeof UPLOAD_DETAIL_FILE_FAILURE> &
  ErrorPayload;

export type SetApplicationNotesRequestAction = Action<
  typeof SET_APPLICATION_NOTES_REQUEST
> & {
  notes: string;
  applicationId: number;
};

type SetApplicationNotesSuccessAction = Action<
  typeof SET_APPLICATION_NOTES_SUCCESS
> & {
  notes: string;
};

export type SetWorkInformationRequestAction = Action<
  typeof SET_WORK_INFORMATION_REQUEST
> & {
  workInfo: WorkInformationModel;
  applicationId: number;
};

type SetWorkInformationSuccessAction = Action<
  typeof SET_WORK_INFORMATION_SUCCESS
> & {
  workInfo: WorkInformationModel;
};

export type SetApplicationFeedbackRequestAction = Action<
  typeof SET_APPLICATION_FEEDBACK_REQUEST
> & {
  applicationId: number;
  rating: number;
  notes: string;
};

type SetApplicationFeedbackSuccessAction = Action<
  typeof SET_APPLICATION_FEEDBACK_SUCCESS
> & {
  feedback: ApplicationFeedback;
};

export type LoadExistingApplicationFeedbackRequestAction = Action<
  typeof LOAD_EXISTING_APPLICATION_FEEDBACK_REQUEST
> & {
  applicationId: number;
};

type LoadExistingApplicationFeedbackSuccessAction = Action<
  typeof LOAD_EXISTING_APPLICATION_FEEDBACK_SUCCESS
> & {
  feedback: ApplicationFeedback;
};
export type DownloadCalloutReportRequestAction = Action<
  typeof DOWNLOAD_CALLOUT_REPORT_REQUEST
> & {
  calloutId: number;
  calloutType: CalloutType;
};

type DownloadInspectionReportSuccessAction = Action<
  typeof DOWNLOAD_INSPECTION_REPORT_SUCCESS
> & {
  inspection: Inspection;
};

type DownloadDefectReportSuccessAction = Action<
  typeof DOWNLOAD_DEFECT_REPORT_SUCCESS
> & {
  defect: Defect;
};

export type SetParticiantRequestAction = Action<
  typeof SET_PARTICIPANT_REQUEST
> & {
  carId: number;
  rcaId: number;
  participant: AddParticipant;
  reference?: string;
};

type SetParticipantSuccessAction = Action<typeof SET_PARTICIPANT_SUCCESS> & {
  participant: Participant;
};

export type ReplaceParticipantRequestAction = Action<
  typeof REPLACE_PARTICIPANT_REQUEST
> & {
  carId: number;
  rcaId: number;
  participant: ReplaceParticipant;
};

type ReplaceParticipantSuccessAction = Action<
  typeof REPLACE_PARTICIPANT_SUCCESS
> & {
  participantResponse: ReplaceParticipantResponse;
};

export type SetNotifyWorkStatusRequestAction = Action<
  typeof SET_NOTIFY_WORK_STATUS_REQUEST
> & {
  applicationId: number;
  newDate: Date;
  isStart: boolean;
  reference?: string;
  offset?: number;
};

type SetNotifyWorkStartSuccessAction = Action<
  typeof SET_NOTIFY_WORK_START_SUCCESS
> & {
  updatedInfo: NotifyWorkStartResponse;
};

export type LoadAuditLogsRequestAction = Action<
  typeof LOAD_AUDIT_LOGS_REQUEST
> & {
  applicationId: number;
  sourceField: string;
};

type LoadAuditLogSuccessAction = Action<typeof LOAD_AUDIT_LOGS_SUCCESS> & {
  auditLog: AuditHistoryResponse;
};

type ResetAuditLogsAction = Action<typeof RESET_AUDIT_LOGS>;

type SetNotifyWorkCompletionSuccessAction = Action<
  typeof SET_NOTIFY_WORK_COMPLETION_SUCCESS
> & {
  updatedInfo: NotifyWorkCompletionResponse;
};

export type RemoveParticipantRequestAction = Action<
  typeof REMOVE_PARTICIPANT_REQUEST
> & {
  participantLinkId: number;
};

type RemoveParticipantSuccessAction = Action<
  typeof REMOVE_PARTICIPANT_SUCCESS
> & {
  participantLinkId: number;
};

export type SetLocationRequestAction = Action<typeof SET_LOCATION_REQUEST> & {
  locationInfo: LocationInformationModel;
  applicationId: number;
};

type SetLocationSuccessAction = Action<typeof SET_LOCATION_SUCCESS> & {
  locationInfo: LocationInformationModel;
};

export type DeleteAttachmentRequestAction = Action<
  typeof DELETE_ATTACHMENT_REQUEST
> & {
  attachmentReference: string;
};

type DeleteAttachmentSuccessAction = Action<
  typeof DELETE_ATTACHMENT_SUCCESS
> & {
  attachmentReference: string;
};

export type CancelApplicationRequestAction = Action<
  typeof CANCEL_APPLICATION_REQUEST
> & {
  applicationId: number;
};

type CancelApplicationSuccessAction = Action<typeof CANCEL_APPLICATION_SUCCESS>;

export type SetMaintenanceCompletionRequestAction = Action<
  typeof SET_MAINTENANCE_COMPLETION_REQUEST
> & {
  model: MaintenanceCompletionRequest;
};

type SetMaintenanceCompletionSuccessAction = Action<
  typeof SET_MAINTENANCE_COMPLETION_SUCCESS
> & {
  model: MaintenanceCompletionResponse;
};

export type DeclineApplicationParticipantRequestAction = Action<
  typeof DECLINE_APPLICATION_PARTICIPANT_REQUEST
> & {
  code: string;
};

type DeclineApplicationParticipantSuccessAction = Action<
  typeof DECLINE_APPLICATION_PARTICIPANT_SUCCESS
> & {
  participantDeclined: boolean;
};

export type SingleApplicationActionTypes =
  | LoadSingleApplicationSuccessAction
  | ResetSingleApplicationAction
  | UploadFileSuccessAction
  | SetApplicationNotesSuccessAction
  | SetWorkInformationSuccessAction
  | SetApplicationFeedbackSuccessAction
  | LoadExistingApplicationFeedbackSuccessAction
  | DownloadInspectionReportSuccessAction
  | DownloadDefectReportSuccessAction
  | SetNotifyWorkStartSuccessAction
  | LoadAuditLogSuccessAction
  | ResetAuditLogsAction
  | SetParticipantSuccessAction
  | ReplaceParticipantRequestAction
  | ReplaceParticipantSuccessAction
  | SetNotifyWorkCompletionSuccessAction
  | RemoveParticipantSuccessAction
  | SetLocationSuccessAction
  | DeleteAttachmentSuccessAction
  | CancelApplicationSuccessAction
  | SetMaintenanceCompletionSuccessAction
  | DeclineApplicationParticipantSuccessAction;

export type ApplicationDetail = {
  // info for editing
  permissionValue: number;
  canCancelApplication: boolean;
  isApplicationCancelled: boolean;
  isPONumberRequired: boolean;
  minDaysBeforeWorkStart: number;
  isRcaFeedbackEnabled: boolean;
  isActive: boolean;
  isWcnCheckEnabled: boolean;
  wcnDocuments: WorkCompletionDocument[];
  canDuplicateApplication: boolean;

  // header
  carId: number;
  carNumber: string;
  carType: Lookup;
  carStep: Lookup;
  carStatus: Lookup;
  // rcaLogoUrl?: string;

  // work information
  workType: Lookup;
  applicantRole: Lookup | null;
  activities: Lookup[];
  majorActivities: Lookup[];
  roadLevel: Lookup | null;
  globalCar: boolean;
  isRoadClosureRequired: boolean;
  estimatedDuration: number;
  estimatedStartDate: Date;
  estimatedCompletionDate: Date;
  utilityRefNumber?: string;
  consentNumber?: string;
  purchaseOrderNumber?: string;
  hoursOfWork?: string;
  description?: string;

  // notes
  notes: string;

  //location
  streetNumber: string;
  streetName: string;
  suburb: string;
  townCity: string;
  postcode: string;
  addressNotes?: string;
  shapes: string;
  features: Feature[];
  locationsInRoad: Lookup[];

  //road controlling authority
  rcaOrganisation: Lookup;
  rcaWebsite?: string;
  rcaCustomisations: number[];
  applicationSourceName: string;
  rcaBoundaries: string;
  rcaBoundaryFeatures: Feature[];

  //participants/people
  participants?: Participant[];

  //dates
  dateSubmitted?: Date;
  dateTmpLodged?: Date;
  dateReceived: Date;
  dateApproved?: Date;
  dateWorkSignedOff?: Date;
  dateWarrantyStarted?: Date;
  dateWarrantyCompletionNotified?: Date;
  dateWarrantyEnded?: Date;
  dateClosed?: Date;
  dateWorkStartedStatus: Lookup;
  dateWorkStarted?: Date;
  dateWorkCompletedStatus: Lookup;
  dateWorkCompleted?: Date;

  attachments?: Attachment[];
  masterAttachments?: AttachmentMasterGroup[];
  applicationFees?: ApplicationFee[];
  linkedApplications?: LinkedApplication[];
  inspectionsAndDefects?: Callout[];
  dateAuditLog?: AuditHistoryResponse | null;
  emailNotificationTypeId?: number;
  feedbackUrl?: string;
  auditHistory?: AuditHistory[];
};

export type AuditHistory = {
  auditLogID?: number;
  historyMessage: string;
  happenedOn: Date;
}

export type AttachmentMasterGroup = {
  attachmentMasterPurposeDescription: string;
  attachments: Attachment[];
};

export type Participant = {
  participantLinkId?: number;
  participantRole: Lookup;
  participantOrganisation?: Lookup;
  participantCategoryId?: number;
  user: Lookup;
  email?: string;
  mobileNumber?: string;
  workNumber?: string;
  address?: string;
  canDelete?: boolean;
  isActive?: boolean;
  hintText?: string;
  certificateDetails?: CertificateDetails;
  firstName?: string;
  feedbackUrl?: string;
};

export type ReplaceParticipantResponse = {
  participant: Participant;
  permissionValue: number;
};

export type TrafficManagementSite = {
  siteId: number;
  site: string;
  siteStatus: string;
};

export type ApplicationFee = {
  applicationFeeId: number;
  description: string;
  amount: number;
  status: Lookup;
  quantity: number;
  rate: number;
  unit: string;
  dateAdded: Date;
  isInvoiced: boolean;
};

export type LinkedApplication = {
  applicationId: number;
  carNumber: string;
  rca: Lookup;
  carStatus: Lookup;
  notes: string;
};

export type NewAttachment = {
  filename: string;
  attachmentType: Lookup;
  file?: File;
};

export type Attachment = NewAttachment & {
  attachmentReference: string;
  attachmentStatus: string;
  canDelete?: boolean;
};

export type NewAttachmentReturnType = {
  filename: string;
  attachmentTypeId: number;
  attachmentReference: string;
  canDelete?: boolean;
  attachmentStatus: string;
};

export type ApplicationDetailState = {
  applicationDetailData?: ApplicationDetail;
  feedback?: ApplicationFeedback;
  participantDeclined?: boolean;
};

//Inspection/Defect
export type Callout = {
  id: number;
  addedOn: Date;
  calloutType: CalloutType;
};

export type ApplicationFeedback = {
  applicationId?: number;
  rating: number | null;
  notes: string;
};

export type Inspection = {
  carNumber: string;
  inspectionId: number;
  date?: Date;
  auditor: string;
  type: string;
  category: string;
  rating: string;
  notes: string;
  remedialWorkRequired: string;
};

export type Defect = {
  defectId: number;
  carNumber: string;
  dateFound?: string;
  source: string;
  status: string;
  description: string;
  explanation: string;
  dateFixed?: Date;
  resolutionNote: string;
  rating: string;
};

export type NotifyWorkStartResponse = {
  carStatus: Lookup;
  duration: number;
  dateWorkStartedStatus: Lookup;
  dateWorkStarted?: Date;
  dateWorkCompleted?: Date;
};

export type ReplaceParticipant = AddParticipant & {
  participantLinkId: number;
};

export type AuditHistoryResponse = {
  firstName: string;
  lastName: string;
  addedOn: Date;
};

export type NotifyWorkCompletionResponse = {
  carStatus: Lookup;
  dateWorkCompleted: Date;
  carStep: Lookup;
  dateWorkCompletedStatus: Lookup;
  notes: string;
};

export type WorkCompletionDocument = {
  carEventId: number;
  attachmentType: Lookup;
};

export type LocationInformationModel = {
  features: Feature[];
  streetNumber: string;
  streetName: string;
  suburb: string;
  townCity: string;
  postCode: string;
  addressNotes?: string;
  locationsInRoad: Lookup[];
  shapeArea?: number;
  shapeLength?: number;
};

export type MaintenanceCompletionRequest = {
  carId: number;
  isSiteInspected: boolean;
  siteInspection?: SiteInspectionModel;
  reference?: string;
  offset: number;
};

export type SiteInspectionModel = {
  auditorName: string;
  auditorCompany: string;
  auditDate: Date;
};

export type MaintenanceCompletionResponse = {
  dateWarrantyCompletionNotified: Date;
  carStatus: Lookup;
  notes: string;
};

export type CertificateDetails = {
  userId: number;
  certificationType: string;
  certificateNumber: string;
  certificateExpiry?: Date;
};

export type EmailNotificationResponse = {
  applicationId: number;
  emailNotificationTypeId: number;
};
