import { Action } from "redux";
import { ErrorPayload } from "store/error/types";
import { SummariesViewMode } from "store/preferences/types";
import {
  FilterPreferences,
  Feature,
  BoundingBox,
  SortModel
} from "store/types";

/* saga action names start */
export const LOAD_APPLICATIONS_SUMMARY_REQUEST =
  "LOAD_APPLICATIONS_SUMMARY_REQUEST";
export const LOAD_APPLICATIONS_SUMMARY_REQUEST_EXTRA =
  "LOAD_APPLICATIONS_SUMMARY_REQUEST_EXTRA";
export const LOAD_APPLICATIONS_SUMMARY_SUCCESS =
  "LOAD_APPLICATIONS_SUMMARY_SUCCESS";
export const LOAD_APPLICATIONS_SUMMARY_FAILURE =
  "LOAD_APPLICATIONS_SUMMARY_FAILURE";

export const LOAD_MAP_APPLICATIONS_SUMMARY_REQUEST =
  "LOAD_MAP_APPLICATIONS_SUMMARY_REQUEST";
export const LOAD_MAP_APPLICATIONS_SUMMARY_SUCCESS =
  "LOAD_MAP_APPLICATIONS_SUMMARY_SUCCESS";

export const LOAD_RCA_LOGOS_REQUEST = "LOAD_RCA_LOGOS_REQUEST";
export const LOAD_RCA_LOGOS_SUCCESS = "LOAD_RCA_LOGOS_SUCCESS";

export const LOAD_RCA_BOUNDARIES_REQUEST = "LOAD_RCA_BOUDARIES_REQUEST";
export const LOAD_RCA_BOUNDARIES_SUCCESS = "LOAD_RCA_BOUNDARIES_SUCCESS";

export const RESET_APPLICATIONS_SUMMARY = "RESET_APPLICATIONS_SUMMARY";

export const LOAD_APPLICATION_EXPORT_COLUMNS_REQUEST =
  "LOAD_APPLICATION_EXPORT_COLUMNS_REQUEST";
export const LOAD_APPLICATION_EXPORT_COLUMNS_SUCCESS =
  "LOAD_APPLICATION_EXPORT_COLUMNS_SUCCESS";
export const LOAD_APPLICATION_EXPORT_COLUMNS_FAILURE =
  "LOAD_APPLICATION_EXPORT_COLUMNS_FAILURE";

export const GENERATE_APPLICATION_GRID_LIST_EXPORT_REQUEST =
  "GENERATE_APPLICATION_GRID_LIST_EXPORT_REQUEST";
export const GENERATE_APPLICATION_GRID_LIST_EXPORT_SUCCESS =
  "GENERATE_APPLICATION_GRID_LIST_EXPORT_SUCCESS";
export const GENERATE_APPLICATION_GRID_LIST_EXPORT_FAILURE =
  "GENERATE_APPLICATION_GRID_LIST_EXPORT_FAILURE";
/* saga action names end */

/* redux action names start */
/* redux action names end */

export type ApplicationsSummaryFilter = {
  filterPreferences?: FilterPreferences;
  skip: number;
  bounds?: BoundingBox;
  sorting?: SortModel[];
  loadCount?: boolean;
  viewMode: SummariesViewMode;
};

export type LoadApplicationsSummaryRequestAction = Action<
  typeof LOAD_APPLICATIONS_SUMMARY_REQUEST
> & {
  applicationsSummaryOptions: ApplicationsSummaryFilter;
};

export type LoadApplicationsSummaryRequestExtraAction = Action<
  typeof LOAD_APPLICATIONS_SUMMARY_REQUEST_EXTRA
> & {
  applicationsSummaryOptions: ApplicationsSummaryFilter;
};

type LoadApplicationsSummarySuccessAction = Action<
  typeof LOAD_APPLICATIONS_SUMMARY_SUCCESS
> & {
  receivedData: ApplicationSummary[];
  totalCount: number;
  isMapView: boolean;
};

type LoadApplicationsSummaryFailureAction = Action<
  typeof LOAD_APPLICATIONS_SUMMARY_FAILURE
> &
  ErrorPayload;

export type LoadMapApplicationsSummaryRequestAction = Action<
  typeof LOAD_MAP_APPLICATIONS_SUMMARY_REQUEST
> & {
  applicationsSummaryOptions: ApplicationsSummaryFilter;
  //bounds will go here
};

type LoadMapApplicationsSummarySuccessAction = Action<
  typeof LOAD_MAP_APPLICATIONS_SUMMARY_SUCCESS
> & {
  receivedData: ApplicationSummary[];
  totalCount: number;
};

type ResetApplicationsSummaryAction = Action<typeof RESET_APPLICATIONS_SUMMARY>;

export type LoadRcaLogosRequest = Action<typeof LOAD_RCA_LOGOS_REQUEST> & {
  rcaIds: number[];
};

type LoadRcaLogosSuccess = Action<typeof LOAD_RCA_LOGOS_SUCCESS> & {
  rcaLogos: RcaLogo[];
};

export type LoadRcaBoundariesRequestAction = Action<
  typeof LOAD_RCA_BOUNDARIES_REQUEST
> & {
  rcaIds: number[];
};

type LoadRcaBoundariesSuccessAction = Action<
  typeof LOAD_RCA_BOUNDARIES_SUCCESS
> & {
  rcaBoundaries: RcaBoundary[];
};

type LoadApplicationExportColumnsSuccessAction = Action<
  typeof LOAD_APPLICATION_EXPORT_COLUMNS_SUCCESS
> & {
  applicationExportColumns: ApplicationExportColumn[];
};

export type GenerateApplicationGridListExportRequestAction = Action<
  typeof GENERATE_APPLICATION_GRID_LIST_EXPORT_REQUEST
> & {
  filters: ApplicationsSummaryFilter;
  applicationExportColumns: ApplicationExportColumn[];
};

export type ApplicationsSummaryActionTypes =
  | LoadApplicationsSummaryRequestAction
  | LoadApplicationsSummarySuccessAction
  | LoadApplicationsSummaryFailureAction
  | LoadApplicationsSummaryRequestExtraAction
  | LoadMapApplicationsSummaryRequestAction
  | LoadMapApplicationsSummarySuccessAction
  | ResetApplicationsSummaryAction
  | LoadRcaLogosSuccess
  | LoadRcaBoundariesSuccessAction
  | LoadApplicationExportColumnsSuccessAction;

export type ApplicationSummary = {
  carId: number;
  carNumber: string;
  carType: string;
  streetNumber?: string;
  streetName: string;
  suburb: string;
  townCity: string;
  postcode: string;

  dateSubmitted: Date;
  estimatedCompletionDate: Date;
  estimatedStartDate: Date;

  applicantPrimaryContact: string;
  principalPrimaryContact: string;

  workType: string;
  applicantRole: string;
  activities: string;
  isRoadClosureRequired: boolean;

  carStatus: string;

  utilityOperator: string;
  utilityRefNumber: string;

  shapes: string;
  features: Feature[];
  isOverdueOrExpired: boolean;

  rcaOrganisationId: number;
};

export type ApplicationsSummaryState = {
  applicationsSummaryData: ApplicationSummary[];
  totalSummaries: number;
  rcaLogos: RcaLogo[];
  rcaBoundaries: RcaBoundary[];
  applicationExportColumns: ApplicationExportColumn[];
};

export type ApplicationsSummaryReceivedPayload = {
  receivedData: ApplicationSummary[];
  totalCount: number;
  isMapView: boolean;
};

export type RcaLogo = {
  rcaOrganisationId: number;
  imageBlob?: string;
};

export type RcaBoundary = {
  rcaOrganisationId: number;
  rcaBoundaryFeatures: Feature[];
  rcaBoundaryShape: string;
};

export type ApplicationExportColumn = {
  isChecked: boolean;
  columnName: string;
  description: string;
};

export type ApplicationsFilterRequest = {
  searchTerm?: string;
  rcaIds?: number[];
  dateFrom?: Date;
  dateTo?: Date;
  carStepIds?: number[];
  carStatusIds?: number[];
  carTypeIds?: number[];
  applicantRoleIds?: number[];
  userIds?: number[];
  bounds?: BoundingBox;
  quickFilterId?: number;
  skip: number;
  take: number;
  sorting?: SortModel[];
  loadCount: boolean;
  viewMode: SummariesViewMode;
};
