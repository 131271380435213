import {
  DOWNLOAD_ATTACHMENT_REQUEST,
  DOWNLOAD_ATTACHMENT_FAILURE,
  DOWNLOAD_ATTACHMENTS_REQUEST,
  DOWNLOAD_ATTACHMENTS_FAILURE,
  AttachmentModel,
  GET_ATTACHMENT_VALIDATION_REQUEST,
  GET_ATTACHMENT_VALIDATION_SUCCESS,
  AttachmentValidation
} from "./types";

export const downloadAttachmentRequest = (
  applicationId: number,
  attachmentReference: string,
  filename: string,
  isMasterAttachment: boolean
) => ({
  type: DOWNLOAD_ATTACHMENT_REQUEST,
  applicationId: applicationId,
  attachmentReference: attachmentReference,
  filename: filename,
  isMasterAttachment: isMasterAttachment
});

export const downloadAttachmentFailure = (errorMessage: string) => ({
  type: DOWNLOAD_ATTACHMENT_FAILURE,
  errorMessage: errorMessage
});

export const downloadAttachmentsRequest = (
  applicationId: number,
  attachments: AttachmentModel[],
  applicationNumber: string
) => ({
  type: DOWNLOAD_ATTACHMENTS_REQUEST,
  applicationId: applicationId,
  attachments: attachments,
  applicationNumber: applicationNumber
});

export const downloadAttachmentsFailure = (errorMessage: string) => ({
  type: DOWNLOAD_ATTACHMENTS_FAILURE,
  errorMessage: errorMessage
});

export const getAttachmentValidationRequest = () => ({
  type: GET_ATTACHMENT_VALIDATION_REQUEST
});

export const getAttachmentValidationSuccess = (
  attachmentValidation: AttachmentValidation
) => ({
  type: GET_ATTACHMENT_VALIDATION_SUCCESS,
  attachmentValidation: attachmentValidation
});
