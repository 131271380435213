import { NotistackNotification } from "store/notistack/types";
import { VariantType } from "notistack";

export const addNotification = (
  key: string,
  message: string,
  enqueueNotification: (notification: NotistackNotification) => void,
  variant: VariantType
) => {
  enqueueNotification(createNotification(key, message, variant));
};

export const createNotification = (
  key: string,
  message: string,
  variant: VariantType
) => {
  const notification: NotistackNotification = {
    message: message,
    dismissed: false,
    options: {
      key: key,
      variant: variant,
      autoHideDuration: 5000
    }
  };
  return notification;
};
