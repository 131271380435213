export const ADMINISTRATION_ENDPOINT = "administration";
export const APPLICATION_ENDPOINT = "applications";
export const BILLING_ENDPOINT = "billing";
export const MEDIA_ENDPOINT = "media";
export const TTM_ENDPOINT = "ttm";

export type Endpoint =
  | typeof ADMINISTRATION_ENDPOINT
  | typeof APPLICATION_ENDPOINT
  | typeof BILLING_ENDPOINT
  | typeof MEDIA_ENDPOINT
  | typeof TTM_ENDPOINT;
