import React, { ComponentType } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { connect } from "react-redux";
import { AppState } from "store/types";

type TAuthenticationProps = {
  isAuthenticated: boolean;
};

const ProtectedRoute = ({
  component: Comp,
  isAuthenticated,
  ...rest
}: RouteProps & TAuthenticationProps & { component: ComponentType<any> }) => (
  <Route
    {...rest}
    render={(props: any) =>
      isAuthenticated ? <Comp {...props} /> : <Redirect to="/login" />
    }
  />
);

const mapStateToProps = (state: AppState): TAuthenticationProps => {
  return { isAuthenticated: state.auth.isLoggedIn };
};

export default connect(mapStateToProps)(ProtectedRoute);
