export enum ApplicationType {
  Excavation = 1,
  Event = 2,
  NonExcavation = 3,
  Retrospective = 4,
  Preliminary = 5
}

export enum ApplicantRoleEnum {
  UtilityOperator = 1,
  Consultant = 2,
  Contractor = 3,
  Others = 4,
  TrafficManager = 5
}

export enum ParticipantRoleEnum {
  ApplicantPrimaryContact = 1,
  ApplicantAlternativeContact = 2,
  RCACorridorManager = 3,
  RCAFieldAuditor = 4,
  UtilityOperatorRepresentative = 5,
  UtilitySiteManager = 6,
  UtilityOperatorOffice = 7,
  BillPayer = 8,
  UtilityOperatorPrimaryContact = 9,
  CivilContractorSiteManager = 10,
  CivilContractorRepresentative = 11,
  CivilContractorProjectManager = 12,
  CivilContractorOfficeManager = 13,
  CivilContractorBillPayer = 14,
  CivilContractorPrimaryContact = 15,
  SiteTrafficManagementSupervisor = 16,
  TrafficManagementPlanDesigner = 17,
  TrafficManagementConsultant = 18,
  TrafficManagementCompanyRepresentative = 19,
  TrafficManagementCompanyOfficeManager = 20,
  TrafficManagementBillPayer = 21,
  TrafficManagementPrimaryContact = 22,
  OtherPartyConsultant = 23,
  OtherPartyAuthorisedAccess = 24,
  OtherPartyContact = 25,
  PrincipalPrimaryContact = 26,
  PrincipalAlternativeContact = 27,
  UtilityOperatorAlternativeContact = 28,
  OtherPartyNonConformanceNotices = 29
}

export enum ParticipantCategoryEnum {
  RoadControllingAuthority = 1,
  Applicant = 2,
  UtilityOperator = 3,
  CivilContractor = 4,
  TrafficManagement = 5,
  OtherParty = 6,
  BillPayer = 7,
  Principal = 8
}

export enum ContainerNamesEnum {
  ApplicationDetail = "applicationDetail",
  CreateApplication = "createApplication",
  HeaderContainer = "header",
  ManageAccount = "manageAccount",
  PlanningMap = "planningMap",
  ViewApplications = "viewApplications",
  BillPayerDetail = "billPayerDetail",
  ViewBillPayers = "viewBillPayers",
  ViewInvoices = "viewInvoices",
  InvoiceDetail = "invoiceDetail",
  Support = "support"
}

export enum SectionNamesEnum {
  AttachmentSection = "attachmentSection",
  FeesSection = "feesSection",
  DatesSection = "datesSection",
  LinkedApplicationSection = "linkedApplicationSection",
  LocationSection = "locationSection",
  NotesSection = "notesSection",
  ParticipantsSection = "participantsSection",
  RcaSection = "rcaSection",
  TtmSection = "ttmSection",
  WorkInformationSection = "workInformationSection",
  AccountModal = "AccountModal",
  WhoAddNewOrganisationModal = "whoAddNewOrganisationModal",
  AddParticipantToApplicationModal = "addParticipantToApplicationModal",
  SetupApplicationType = "setupApplicationType",
  TrafficClosureForm = "trafficClosureForm",
  SetupConflict = "setupConflict",
  CreateApplicationHeader = "createApplicationHeader",
  TrafficDetoursForm = "trafficDetoursForm",
  ExtraDetailSection = "extraDetailSection",
  PreviewReviewSection = "previewReviewSection",
  TrafficCancelForm = "trafficCancelForm",
  TrafficSection = "trafficSection",
  TrafficClosureDialogForm = "trafficClosureDialogForm",
  TrafficDetourDialogForm = "trafficDetourDialogForm",
  TrafficTtmForm = "trafficTtmForm",
  TrafficSiteDialogForm = "trafficSiteDialogForm",
  TrafficTtmSites = "trafficTtmSites",
  TrafficTtmSubTables = "trafficTtmSubTables",
  WhatDetailSection = "whatDetailSection",
  WhenDetailSection = "whenDetailSection",
  WhereMapSection = "whereMapSection",
  WhereAddressSection = "whereAddressSection",
  WhoParticipantInvolvedSection = "whoParticipantInvolvedSection",
  WhoBillPayerSection = "whoBillPayerSection",
  WhoAdditionalParticipantSection = "whoAdditionalParticipantSection",
  WhoOtherParticipants = "whoOtherParticipants",
  WhoPrincipalContactSection = "whoPrincipalContactSection",
  TopMenu = "topMenu",
  SideMenu = "sideMenu",
  AccountsDataPanel = "accountsDataPanel",
  ProfileTile = "profileTile",
  UserAccessModal = "userAccessModal",
  PlanningMapSection = "planningMapSection",
  SelectApplication = "selectApplication",
  FilterMenu = "filterMenu",
  ViewApplicationMainSection = "viewApplicationMainSection",
  ManageDuplicateAccountsModal = "manageDuplicateAccountsModal",
  ViewApplicationsToolbar = "viewApplicationToolbar",
  ViewApplicationsSpeedDial = "viewApplicationSpeedDial",
  Header = "header",
  SetupDocumentationSection = "setupDocumentationSection",
  MaintainGstNumberModal = "maintainGstNumberModal",
  TemporaryAttachmentList = "TemporaryAttachmentList",
  AddExtraAttachmentModal = "AddExtraAttachmentModal",
  LargeApplicationsTable = "LargeApplicationsTable",
  SetupPanel = "SetupPanel",
  WherePanel = "WherePanel",
  WhenPanel = "WhenPanel",
  WhoPanel = "WhoPanel",
  WhatPanel = "WhatPanel",
  TrafficPanel = "TrafficPanel",
  ExtraPanel = "ExtraPanel",
  PreviewPanel = "PreviewPanel",
  SubmissionPanel = "SubmissionPanel",
  ApplicationDetailPanel = "ApplicationDetailPanel",
  MaintainBillPayer = "MaintainBillPayer",
  BillPayersTable = "billPayersTable",
  ViewBillPayersMainSection = "viewBillPayersMainSection",
  BillPayersDetailedTable = "billPayersDetailedTable",
  UserRegionForm = "UserRegionForm",
  NotificationSettingsModal = "notificationSettingsModal",
  NotificationsList = "notificationsList",
  Grid = "grid",
  EditNotes = "editNotes",
  SelectedAttachmentModal = "selectedAttachmentModal",
  EditWorkInformation = "editWorkInformation",
  NotifyWorkStatus = "notifyWorkStatus",
  GiveFeedback = "giveFeedback",
  EditLocation = "editLocation",
  NotifyMaintenanceCompletion = "notifyMaintenanceCompletion",
  ImportShapes = "importShapes",
  MajorProcessingHistorySection = "MajorProcessingHistorySection"
}

export enum CreateApplicationStep {
  SetupPanel = 0,
  WherePanel = 1,
  WhenPanel = 2,
  WhoPanel = 3,
  WhatPanel = 4,
  TrafficPanel = 5,
  ExtraPanel = 6,
  PreviewPanel = 7,
  SubmissionPanel = 8
}

export enum AttachmentTypeEnum {
  TrafficManagementPlan = 9
}

export enum WorkType {
  Project = 1,
  Major = 2,
  Minor = 3,
  Emergency = 4,
  NotApplicable = 5
}

export enum CarDetailLabel {
  carNumber = "CAR Number",
  carStatus = "CAR Status",
  activity = "Activity",
  applicant = "Applicant",
  contact = "Contact",
  utility = "Utility",
  utilityContact = "Utility contact",
  duration = "Duration",
  startDate = "Start Date",
  endDate = "Complete Date",
  rca = "RCA"
}

export enum DispatchLabel {
  assetType = "Asset Type",
  status = "Call Status",
  callType = "Call Type",
  contractId = "Contract",
  fault = "Fault"
  // priority = "Priority" // Leave this one here, we may add it back
}

export enum UserLinkType {
  Duplicate = 1,
  Supervisor = 2,
  Authorised = 3
}

export enum CalloutType {
  Inspection = 1,
  Defect = 2
}

export enum UserNotificationTypes {
  UserLinkRequest = 3,
  ParticipantLinkRequest = 4,
  UserLinkResponse = 5,
  ParticipantLinkResponse = 6,
  ParticipantLinkReplaceResponse = 7,
  ParticipantLinkDeclineResponse = 8,
  UserLinkDeclineResponse = 9
}

export enum UserNotificationActionTypes {
  Confirm = "confirm",
  Decline = "decline",
  Hide = "hide",
  HideAll = "hide all",
  Duplicate = "duplicate"
}

export enum ApplicationStatus {
  New = 1,
  Pending = 2,
  Declined = 3,
  OnHold = 4,
  Approved = 5,
  WorkInProgress = 6,
  WorkOverdue = 7,
  WorkCompletionNotificationOverdue = 8,
  WorkCompletionNotified = 9,
  WorkCompletionInspectionOverdue = 10,
  WorkCompletionInspected = 11,
  WorkCompletionSignoffOverdue = 12,
  WorkInWarranty = 13,
  WarrantyRepairInProgress = 14,
  WarrantyInspectionOverdue = 15,
  WarrantyInspectionDone = 16,
  Closed = 17,
  Cancelled = 18,
  TmpOutstanding = 19,
  ReadyForApproval = 20,
  ApprovedInPrinciple = 21,
  CompletionNotified = 22,
  WarrantyExpiring = 23,
  WarrantyExpired = 24,
  CompletionOfMaintenanceNotified = 25,
  DefectOutstanding = 26
}

export enum ApplicationStep {
  CarApproval = 1,
  AuditWorks = 2,
  WorkCompletion = 3,
  WarrantyPeriod = 4,
  CarClosed = 5
}

export enum WorkDateStatus {
  Unknown = 1,
  Proposed = 2,
  Revised = 3,
  Actual = 4,
  Extended = 5
}

export enum ParticipantActionType {
  Add = "add",
  Replace = "replace"
}

export enum RcaCustomisationTypes {
  MajorWorkQuestionnaire = 1,
  TTMRoadLevelNotApplicable = 2,
  TTMImpactLookupLoadOrganisationOwnedOnly = 3
}

export enum SubmiticaPermission {
  None = 0,
  ViewApplications = 1,
  ViewDetails = 2,
  AttachTmp = 4,
  AttachOtherFiles = 8,
  NotifyWorkStart = 16,
  NotifyWorkCompletion = 32,
  EditCar = 64,
  AddParticipant = 128,
  CancelCar = 256,
  Full = 511
}

export enum BottomNavigationType {
  Applications = 0,
  Map = 1,
  BillPayers = 2,
  Account = 3
}

export enum WhoPanelSections {
  ParticipantsInvolved = 0,
  BillPayerDetails = 1,
  AdditionaParticipants = 2
}

export enum EmailNotificationTypes {
  WorkStartOverdue = 1,
  WorkCompletionOverdue = 2,
  TmpOutstanding = 6,
  ConfirmWorkStartReminder = 17,
  ConfirmWorkStartWarning = 18,
  WarrantyExpired = 20,
  BillPayerMissing = 25
}

export enum LinkActionResponse {
  ConfirmUserLink = "CONFIRMUSERLINK",
  DeclineUserLink = "DECLINEUSERLINK",
  DeclineParticipant = "DECLINEPARTICIPANT"
}

export enum MaxInputLenghts {
  NormalWorkingHours = 120,
  ConsentNumber = 50,
  UtilityReferenceNumber = 50,
  PurchaseOrderNumber = 100,
  Email = 100,
  FirstName = 50,
  LastName = 50,
  WorkPhone = 50,
  mobilePhone = 50,
  Fax = 50,
  JobPosition = 100,
  CertificationNumber = 100,
  PlaceOfIssue = 250,
  GSTNumber = 20,
  RCACustomerNumber = 100,
  OrganisationName = 255,
  StreetNumberStart = 50,
  StreetNumberEnd = 50
}

export enum GuidesGridAction {
  CreateApplication = "CreateApplication",
  Ttm = "Ttm",
  SubmiticaAccountOverview = "SubmiticaAccountOverview",
  SubmiticaAccountDetails = "SubmiticaAccountDetails",
  AdditionalInfo = "AdditionalInfo"
}
