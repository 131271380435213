import {
  Tooltip,
  TooltipProps,
  makeStyles,
  Theme,
  createStyles
} from "@material-ui/core";
import React from "react";

type SubmiticaTooltipProps = TooltipProps & SubmiticaTooltipStyleProps;

type SubmiticaTooltipStyleProps = {
  fontSize?: string;
  isCapitalised?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltipRoot: {
      fontSize: (props: SubmiticaTooltipStyleProps) =>
        props.fontSize ?? "0.8rem",
      textTransform: (props: SubmiticaTooltipStyleProps) =>
        props.isCapitalised ? "capitalize" : "none"
    }
  })
);

const SubmiticaTooltip = (props: SubmiticaTooltipProps) => {
  const classes = useStyles({
    fontSize: props.fontSize,
    isCapitalised: props.isCapitalised
  });

  return (
    <Tooltip
      placement={props.placement}
      title={props.title}
      classes={{ tooltip: props.classes?.tooltip ?? classes.tooltipRoot }}
    >
      {props.children}
    </Tooltip>
  );
};

export default SubmiticaTooltip;
