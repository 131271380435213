import { Action } from "redux";

export const DOWNLOAD_ATTACHMENT_REQUEST = "DOWNLOAD_ATTACHMENT_REQUEST";
export const DOWNLOAD_ATTACHMENT_FAILURE = "DOWNLOAD_ATTACHMENT_FAILURE";
export const DOWNLOAD_ATTACHMENTS_REQUEST = "DOWNLOAD_ATTACHMENTS_REQUEST";
export const DOWNLOAD_ATTACHMENTS_FAILURE = "DOWNLOAD_ATTACHMENTS_Failure";
export const GET_ATTACHMENT_VALIDATION_REQUEST =
  "GET_ATTACHMENT_VALIDATION_REQUEST";
export const GET_ATTACHMENT_VALIDATION_SUCCESS =
  "GET_ATTACHMENT_VALIDATION_SUCCESS";

type getAttachmentValidationSuccessAction = Action<
  typeof GET_ATTACHMENT_VALIDATION_SUCCESS
> & {
  attachmentValidation: AttachmentValidation;
};

export type MediaActionTypes = getAttachmentValidationSuccessAction;
export type DownloadAttachmentRequestAction = Action<
  typeof DOWNLOAD_ATTACHMENT_REQUEST
> & {
  applicationId: number;
  attachmentReference: string;
  filename: string;
  isMasterAttachment: boolean;
};

export type DownloadAttachmentsRequestAction = Action<
  typeof DOWNLOAD_ATTACHMENTS_REQUEST
> & {
  applicationId: number;
  attachments: AttachmentModel[];
  applicationNumber: string;
};

export type AttachmentModel = {
  attachmentReference: string;
  filename: string;
};

export type AttachmentValidation = {
  whiteList: string[];
  maxFileSize?: number;
};

export type MediaState = {
  attachmentValidation?: AttachmentValidation;
};
