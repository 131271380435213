export const APPLICANT_PRIMARY_CONTACT = "Applicant Primary Contact";
export const PRINCIPAL_PRIMARY_CONTACT = "Principal Primary Contact";
export const BILL_PAYER = "Bill Payer";

export const API_ROUTE = "/api/";

// Confirmation messages
export const MESSAGE_UNSAVED_CHANGES =
  "You have unsaved changes. Navigating away will cause those changes to be lost. Press OK to discard your changes or CLOSE to keep them.";
export const MESSAGE_LEAVE_APPLICATION_PROCESS =
  "Your application is not yet complete. If you change pages, your application will be saved for you to work on later. Click OK to continue";
export const MESSAGE_DELETE_TEMPORARY_ATTACHMENT =
  "Are you sure you want to remove this attachment?";
export const MESSAGE_FILTER_UNAPPLIED =
  "Navigating away from this panel will not apply the filter. Press OK to discard your filter changes. Press CLOSE to return to the filter.​";
export const MESSAGE_REMOVE_FILTER =
  "Are you sure you want to remove the currently applied filter?";

export const TTM_SITES_HELP_MODAL_TITLE = "Traffic Management Sites";
export const TTM_DETOURS_HELP_MODAL_TITLE = "Traffic Management Detours";
export const TTM_CLOSURES_HELP_MODAL_TITLE = "Traffic Management Closure Times";

export const REGEX_NAME_PATTERN = /^[a-zA-Z]+((['. -][a-zA-Z ])?[a-zA-Z]*)*\.?$/;

// For upload attachment error messages
export const INVALID_FILE_TYPE_MESSAGE =
  "only allows PDF attachments to be uploaded.";
export const FILE_LIMIT_EXCEEDED_MESSAGE =
  "$FILE_NAME exceeds the maximum file size limit of $FILE_SIZE MB";
export const FILE_NAME = "$FILE_NAME";
export const FILE_SIZE = "$FILE_SIZE";

export const INITIAL_ZOOM_VALUE = 13;

export const MAX_FILE_NUMBER_EXCEEDED_MESSAGE =
  "You can only upload a maximum of $MAX_FILES files";
export const MAX_FILES = "$MAX_FILES";
