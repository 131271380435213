import {
  LOAD_BILLPAYERS_SUMMARY_REQUEST,
  LOAD_BILLPAYERS_SUMMARY_SUCCESS,
  LOAD_BILLPAYERS_SUMMARY_FAILURE,
  RESET_BILLPAYERS_SUMMARY,
  BillPayersSummaryReceivedPayload,
  LOAD_BILLPAYERS_SUMMARY_REQUEST_EXTRA,
  BillPayersSummaryFilter
} from "./types";

export const loadBillPayersSummaryRequest = (
  payload: BillPayersSummaryFilter
) => ({
  type: LOAD_BILLPAYERS_SUMMARY_REQUEST,
  billPayersSummaryOptions: payload
});

export const loadBillPayersSummaryRequestExtra = (
  payload: BillPayersSummaryFilter
) => ({
  type: LOAD_BILLPAYERS_SUMMARY_REQUEST_EXTRA,
  billPayersSummaryOptions: payload
});

export const loadBillPayersSummarySuccess = (
  payload: BillPayersSummaryReceivedPayload
) => ({
  type: LOAD_BILLPAYERS_SUMMARY_SUCCESS,
  receivedData: payload.receivedData,
  totalCount: payload.totalCount
});

export const loadBillPayersSummaryFailure = (errorMessage: string) => ({
  type: LOAD_BILLPAYERS_SUMMARY_FAILURE,
  errorMessage: errorMessage
});

export const resetBillPayersSummary = () => ({
  type: RESET_BILLPAYERS_SUMMARY
});
