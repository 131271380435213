import { Action } from "redux";
import { ErrorPayload } from "store/error/types";

/* saga action names start */
export const LOAD_BILLPAYERS_SUMMARY_REQUEST =
  "LOAD_BILLPAYERS_SUMMARY_REQUEST";
export const LOAD_BILLPAYERS_SUMMARY_REQUEST_EXTRA =
  "LOAD_BILLPAYERS_SUMMARY_REQUEST_EXTRA";
export const LOAD_BILLPAYERS_SUMMARY_SUCCESS =
  "LOAD_BILLPAYERS_SUMMARY_SUCCESS";
export const LOAD_BILLPAYERS_SUMMARY_FAILURE =
  "LOAD_BILLPAYERS_SUMMARY_FAILURE";

export const RESET_BILLPAYERS_SUMMARY = "RESET_BILLPAYERS_SUMMARY";
/* saga action names end */

export type BillPayersSummaryFilter = {
  skip: number;
};

export type LoadBillPayersSummaryRequestAction = Action<
  typeof LOAD_BILLPAYERS_SUMMARY_REQUEST
> & {
  billPayersSummaryOptions: BillPayersSummaryFilter;
};

export type LoadBillPayersSummaryRequestExtraAction = Action<
  typeof LOAD_BILLPAYERS_SUMMARY_REQUEST_EXTRA
> & {
  billPayersSummaryOptions: BillPayersSummaryFilter;
};

type LoadBillPayersSummarySuccessAction = Action<
  typeof LOAD_BILLPAYERS_SUMMARY_SUCCESS
> & {
  receivedData: BillPayerSummary[];
  totalCount: number;
};

type LoadBillPayersSummaryFailureAction = Action<
  typeof LOAD_BILLPAYERS_SUMMARY_FAILURE
> &
  ErrorPayload;

type ResetBillPayersSummaryAction = Action<typeof RESET_BILLPAYERS_SUMMARY>;

export type BillPayersSummaryActionTypes =
  | LoadBillPayersSummaryRequestAction
  | LoadBillPayersSummarySuccessAction
  | LoadBillPayersSummaryFailureAction
  | LoadBillPayersSummaryRequestExtraAction
  | ResetBillPayersSummaryAction;

export type BillPayerSummary = {
  billPayerId: number;
  organisation: string;
  rca: string;
  billPayer: string;
  rcaCustomerNumber?: string;
  email: string;
  workPhone: string;
  mobilePhone: string;
  fax: string;
  address: string;
  rcaLogo?: string;
};

export type BillPayersSummaryState = {
  billPayersSummaryData: BillPayerSummary[];
  totalSummaries: number;
};

export type BillPayersSummaryReceivedPayload = {
  receivedData: BillPayerSummary[];
  totalCount: number;
};
