import {
  UserDetail,
  SET_NEW_ORGANISATION_REQUEST,
  RESET_CREATE_APPLICATION_NEW_USER_DATA,
  LOAD_USER_LINKS_REQUEST,
  UserAccount,
  LOAD_USER_LINKS_SUCCESS,
  RESET_ACCOUNT_MANAGEMENT,
  UserLinkButtonActionTypes,
  UserLinkDetail,
  LOAD_USER_DETAIL_REQUEST,
  LOAD_USER_DETAIL_SUCCESS,
  LOAD_DUPLICATE_ACCOUNT_FAILURE,
  DuplicateAccountModel,
  LOAD_DUPLICATE_ACCOUNT_SUCCESS,
  SET_LINK_DUPLICATES_REQUEST,
  SET_LINK_DUPLICATES_FAILURE,
  SET_LINK_DUPLICATES_SUCCESS,
  LOAD_DUPLICATE_ACCOUNT_REQUEST,
  RESET_SUCCESS_NOTIFICATION,
  SET_GST_NUMBER_REQUEST,
  SET_GST_NUMBER_FAILURE,
  SET_GST_NUMBER_SUCCESS,
  LOAD_USER_DETAIL_FAILURE,
  LOAD_USER_LINKS_FAILURE,
  LOAD_NEW_ORGANISATIONS_REQUEST,
  LOAD_NEW_ORGANISATIONS_SUCCESS,
  RegisteredOrganisationModel,
  LOAD_NEW_ORGANISATIONS_FAILURE,
  RESET_NEW_ORGANISATIONS,
  SET_NEW_ORGANISATION_SUCCESS,
  SET_NEW_ORGANISATION_FAILURE,
  SET_USER_DETAIL_REQUEST,
  SET_USER_DETAIL_SUCCESS,
  SET_USER_DETAIL_FAILURE,
  NotificationSettings,
  SET_NOTIFICATION_SETTINGS_REQUEST,
  SET_NOTIFICATION_SETTINGS_SUCCESS,
  SET_NOTIFICATION_SETTINGS_FAILURE,
  UserRegion,
  SET_USER_REGIONS_REQUEST,
  SET_USER_REGIONS_SUCCESS,
  SET_USER_REGIONS_FAILURE,
  SET_USER_LINK_REQUEST,
  SET_USER_LINK_FAILURE,
  SET_USER_LINK_SUCCESS,
  DELETE_USER_LINK_REQUEST,
  DELETE_USER_LINK_SUCCESS,
  DELETE_USER_LINK_FAILURE,
  LOAD_PERMISSION_TO_VIEW_USER_REQUEST,
  LOAD_PERMISSION_TO_VIEW_USER_FAILURE,
  LOAD_PERMISSION_TO_VIEW_USER_SUCCESS,
  LOAD_USER_NOTIFICATIONS_SUCCESS,
  LOAD_USER_NOTIFICATIONS_REQUEST,
  LOAD_USER_NOTIFICATIONS_FAILURE,
  UserNotifications,
  RESET_USER_NOTIFICATIONS,
  LOAD_USER_NOTIFICATIONS_COUNT_REQUEST,
  LOAD_USER_NOTIFICATIONS_COUNT_SUCCESS,
  LOAD_USER_NOTIFICATIONS_COUNT_FAILURE,
  SET_USER_NOTIFICATIONS_INACTIVE_REQUEST,
  SET_USER_NOTIFICATIONS_INACTIVE_FAILURE,
  SET_USER_NOTIFICATIONS_INACTIVE_SUCCESS,
  HideNotificationRequest,
  SET_USER_NOTIFICATION_CONFIRM_DECLINE_REQUEST,
  SET_USER_NOTIFICATION_CONFIRM_DECLINE_FAILURE,
  SET_USER_NOTIFICATION_CONFIRM_DECLINE_SUCCESS,
  LOAD_SCALE_OF_WORK_QUESTIONNAIRE_REQUEST,
  LOAD_SCALE_OF_WORK_QUESTIONNAIRE_SUCCESS,
  LOAD_SCALE_OF_WORK_QUESTIONNAIRE_FAILURE,
  LOAD_SCALE_OF_WORK_QUESTIONNAIRE_RESET,
  LOAD_USER_REGIONS_SUCCESS,
  LOAD_USER_REGIONS_REQUEST,
  LOAD_USER_REGIONS_FAILURE,
  LOAD_PARTICIPANT_ORGANISATION_REQUEST,
  LOAD_PARTICIPANT_ORGANISATION_SUCCESS,
  LOAD_PARTICIPANT_ORGANISATION_FAILURE,
  RESET_AUTOFILL_ORGANISATION,
  CONFIRM_USER_LINK_REQUEST,
  CONFIRM_USER_LINK_FAILURE,
  DECLINE_USER_LINK_REQUEST,
  USER_LINK_SUCCESS,
  RESET_USER_LINK_PROCESSED,
  UserNotificationPaginationResponse,
  GET_CONTACT_REQUEST,
  GET_CONTACT_SUCCESS
} from "./types";
import { Lookup, SearchModel } from "store/types";
import { Endpoint } from "common/endpoints";
import { UserLinkType, UserNotificationActionTypes } from "common/enums";

export const setNewOrganisationRequest = (
  newOrganisation: RegisteredOrganisationModel,
  parentId: string
) => ({
  type: SET_NEW_ORGANISATION_REQUEST,
  newOrganisation: newOrganisation,
  parentId: parentId
});

export const setNewOrganisationSuccess = (
  newOrg: Lookup,
  parentId: string
) => ({
  type: SET_NEW_ORGANISATION_SUCCESS,
  newOrg: newOrg,
  parentId: parentId
});

export const setNewOrganisationFailure = (errorMessage: string) => ({
  type: SET_NEW_ORGANISATION_FAILURE,
  errorMessage: errorMessage
});

export const getContactRequest = (
  organisationId: number,
  rcaIds: number[],
  forDefaultBillPayer: boolean,
  userId?: number | null
) => ({
  type: GET_CONTACT_REQUEST,
  organisationId: organisationId,
  rcaIds: rcaIds,
  forDefaultBillPayer: forDefaultBillPayer,
  userId: userId
});

export const getContactSuccess = (
  contact: Lookup | null
) => ({
  type: GET_CONTACT_SUCCESS,
  contact: contact
});

export const resetCreateApplicationNewUserData = () => ({
  type: RESET_CREATE_APPLICATION_NEW_USER_DATA
});

export const setUserDetailRequest = (
  parentId: string,
  newUserFormModel: UserDetail,
  isEditingUserAccount: boolean
) => ({
  type: SET_USER_DETAIL_REQUEST,
  parentId: parentId,
  newUserFormModel: newUserFormModel,
  isEditingUserAccount: isEditingUserAccount
});

export const setUserDetailFailure = (errorMessage: string) => ({
  type: SET_USER_DETAIL_FAILURE,
  errorMessage: errorMessage
});

export const setUserDetailSuccess = (
  parentId: string,
  newUser: Lookup,
  isEditingUserAccount: boolean,
  userDetail?: UserDetail
) => ({
  type: SET_USER_DETAIL_SUCCESS,
  newUser: newUser,
  parentId: parentId,
  isEditingUserAccount: isEditingUserAccount,
  userDetail: userDetail
});

export const setGstNumberRequest = (gstNumber: string) => ({
  type: SET_GST_NUMBER_REQUEST,
  gstNumber: gstNumber
});

export const setGstNumberFailure = (errorMessage: string) => ({
  type: SET_GST_NUMBER_FAILURE,
  errorMessage: errorMessage
});

export const setGstNumberSuccess = (gstNumber: string) => ({
  type: SET_GST_NUMBER_SUCCESS,
  gstNumber: gstNumber
});

export const loadAccountManagementRequest = (userId: number) => ({
  type: LOAD_USER_LINKS_REQUEST,
  userId: userId
});

export const loadAccountManagementFailure = (errorMessage: string) => ({
  type: LOAD_USER_LINKS_FAILURE,
  errorMessage: errorMessage
});

export const loadAccountManagementSuccess = (payload: UserAccount) => ({
  type: LOAD_USER_LINKS_SUCCESS,
  receivedData: payload
});

export const resetAccountManagement = () => ({
  type: RESET_ACCOUNT_MANAGEMENT
});

export const setUserLinkRequest = (
  user: Lookup,
  actionType: UserLinkButtonActionTypes,
  userLinkType: UserLinkType
) => ({
  type: SET_USER_LINK_REQUEST,
  user: user,
  actionType: actionType,
  userLinkType: userLinkType
});

export const setUserLinkFailure = (errorMessage: string) => ({
  type: SET_USER_LINK_FAILURE,
  errorMessage: errorMessage
});

export const setUserLinkSuccess = (
  userLink: UserLinkDetail,
  actionType: UserLinkButtonActionTypes
) => ({
  type: SET_USER_LINK_SUCCESS,
  userLink: userLink,
  actionType: actionType
});

export const deleteUserLinkRequest = (userLinkId: number) => ({
  type: DELETE_USER_LINK_REQUEST,
  userLinkId: userLinkId
});

export const deleteUserLinkSuccess = (userLinkId: number) => ({
  type: DELETE_USER_LINK_SUCCESS,
  userLinkId: userLinkId
});

export const deleteUserLinkFailure = (errorMessage: string) => ({
  type: DELETE_USER_LINK_FAILURE,
  errorMessage: errorMessage
});

export const loadUserDetailRequest = (userId: number) => ({
  type: LOAD_USER_DETAIL_REQUEST,
  userId: userId
});

export const loadUserDetailFailure = (errorMessage: string) => ({
  type: LOAD_USER_DETAIL_FAILURE,
  errorMessage: errorMessage
});

export const loadUserDetailSuccess = (userDetail: UserDetail) => ({
  type: LOAD_USER_DETAIL_SUCCESS,
  userDetail: userDetail
});

export const loadDuplicateAccountRequest = () => ({
  type: LOAD_DUPLICATE_ACCOUNT_REQUEST
});

export const loadDuplicateAccountFailure = (errorMessage: string) => ({
  type: LOAD_DUPLICATE_ACCOUNT_FAILURE,
  errorMessage: errorMessage
});

export const loadDuplicateAccountSuccess = (
  duplicateAccounts: DuplicateAccountModel[]
) => ({
  type: LOAD_DUPLICATE_ACCOUNT_SUCCESS,
  duplicateAccounts: duplicateAccounts
});

export const setLinkDuplicatesRequest = (
  selectedIds?: number[],
  emailToLink?: string
) => ({
  type: SET_LINK_DUPLICATES_REQUEST,
  selectedIds: selectedIds,
  emailToLink: emailToLink
});

export const setLinkDuplicatesFailure = (errorMessage: string) => ({
  type: SET_LINK_DUPLICATES_FAILURE,
  errorMessage: errorMessage
});

export const setLinkDuplicatesSuccess = (
  selectedIds?: number[],
  emailToLink?: string
) => ({
  type: SET_LINK_DUPLICATES_SUCCESS,
  selectedIds: selectedIds,
  emailToLink: emailToLink
});

export const resetSuccessNotification = (key: string) => ({
  type: RESET_SUCCESS_NOTIFICATION,
  key: key
});

export const loadNewOrganisationsRequest = (
  endpoint?: Endpoint,
  route?: string,
  args?: string[] | SearchModel
) => ({
  type: LOAD_NEW_ORGANISATIONS_REQUEST,
  endpoint: endpoint,
  route: route,
  args: args
});

export const loadNewOrganisationSuccess = (
  route: string,
  data: RegisteredOrganisationModel[]
) => ({
  type: LOAD_NEW_ORGANISATIONS_SUCCESS,
  route: route,
  data: data
});

export const loadNewOrganisationsFailure = (errorMessage: string) => ({
  type: LOAD_NEW_ORGANISATIONS_FAILURE,
  errorMessage: errorMessage
});

export const resetNewOrganisationsData = () => ({
  type: RESET_NEW_ORGANISATIONS
});

export const setNotificationSettingsRequest = (
  notificationSettings: NotificationSettings
) => ({
  type: SET_NOTIFICATION_SETTINGS_REQUEST,
  notificationSettings: notificationSettings
});

export const setNotificationSettingsSuccess = (
  notificationSettings: NotificationSettings
) => ({
  type: SET_NOTIFICATION_SETTINGS_SUCCESS,
  notificationSettings: notificationSettings
});

export const setNotificationSettingsFailure = (errorMessage: string) => ({
  type: SET_NOTIFICATION_SETTINGS_FAILURE,
  errorMessage: errorMessage
});

export const setUserRegionsRequest = (userRegions: UserRegion[]) => ({
  type: SET_USER_REGIONS_REQUEST,
  userRegions: userRegions
});

export const setUserRegionsSuccess = (userRegions: UserRegion[]) => ({
  type: SET_USER_REGIONS_SUCCESS,
  userRegions: userRegions
});

export const setUserRegionsFailure = (errorMessage: string) => ({
  type: SET_USER_REGIONS_FAILURE,
  errorMessage: errorMessage
});

export const loadPermissionToViewUserRequest = (userId: number) => ({
  type: LOAD_PERMISSION_TO_VIEW_USER_REQUEST,
  userId: userId
});

export const loadPermissionToViewUserSuccess = (hasPermission: boolean) => ({
  type: LOAD_PERMISSION_TO_VIEW_USER_SUCCESS,
  hasPermission: hasPermission
});

export const loadPermissionToViewUserFailure = (errorMessage: string) => ({
  type: LOAD_PERMISSION_TO_VIEW_USER_FAILURE,
  errorMessage: errorMessage
});

export const loadUserNotificationsRequest = (
  pageIndex: number
) => ({
  type: LOAD_USER_NOTIFICATIONS_REQUEST,
  pageIndex: pageIndex
});

export const loadUserNotificationsSuccess = (
  userNotificationPagination: UserNotificationPaginationResponse
) => ({
  type: LOAD_USER_NOTIFICATIONS_SUCCESS,
  userNotificationPagination: userNotificationPagination
});

export const loadUserNotificationsFailure = (errorMessage: string) => ({
  type: LOAD_USER_NOTIFICATIONS_FAILURE,
  errorMessage: errorMessage
});

export const resetUserNotifications = () => ({
  type: RESET_USER_NOTIFICATIONS
});

export const loadUserNotificationsCountRequest = () => ({
  type: LOAD_USER_NOTIFICATIONS_COUNT_REQUEST
});

export const loadUserNotificationsCountSuccess = (
  notificationCount: number
) => ({
  type: LOAD_USER_NOTIFICATIONS_COUNT_SUCCESS,
  notificationCount: notificationCount
});

export const loadUserNotificationsCountFailure = (errorMessage: string) => ({
  type: LOAD_USER_NOTIFICATIONS_COUNT_FAILURE,
  errorMessage: errorMessage
});

export const setUserNotificationInactiveRequest = (
  request: HideNotificationRequest
) => ({
  type: SET_USER_NOTIFICATIONS_INACTIVE_REQUEST,
  request: request
});

export const setUserNotificationInactiveFailure = (errorMessage: string) => ({
  type: SET_USER_NOTIFICATIONS_INACTIVE_FAILURE,
  errorMessage: errorMessage
});

export const setUserNotificationInactiveSuccess = (
  request: HideNotificationRequest
) => ({
  type: SET_USER_NOTIFICATIONS_INACTIVE_SUCCESS,
  request: request
});

export const setUserNotificationConfirmDeclineRequest = (
  notificationId: string,
  notificationActionType: UserNotificationActionTypes
) => ({
  type: SET_USER_NOTIFICATION_CONFIRM_DECLINE_REQUEST,
  notificationId: notificationId,
  notificationActionType: notificationActionType
});

export const setUserNotificationConfirmDeclineSuccess = (
  notificationId: string
) => ({
  type: SET_USER_NOTIFICATION_CONFIRM_DECLINE_SUCCESS,
  notificationId: notificationId
});

export const setUserNotificationConfirmDeclineFailure = (
  errorMessage: string
) => ({
  type: SET_USER_NOTIFICATION_CONFIRM_DECLINE_FAILURE,
  errorMessage: errorMessage
});

export const loadScaleOfWorkQuestionnaireRequest = (rcaId: number) => ({
  type: LOAD_SCALE_OF_WORK_QUESTIONNAIRE_REQUEST,
  rcaId: rcaId
});

export const loadScaleOfWorkQuestionnaireSuccess = (questions: string[]) => ({
  type: LOAD_SCALE_OF_WORK_QUESTIONNAIRE_SUCCESS,
  questions: questions
});

export const loadScaleOfWorkQuestionnaireFailure = (errorMessage: string) => ({
  type: LOAD_SCALE_OF_WORK_QUESTIONNAIRE_FAILURE,
  errorMessage: errorMessage
});

export const loadScaleOfWorkQuestionnaireReset = () => ({
  type: LOAD_SCALE_OF_WORK_QUESTIONNAIRE_RESET
});

export const loadUserRegionsRequest = () => ({
  type: LOAD_USER_REGIONS_REQUEST
});

export const loadUserRegionsSuccess = (userRegions: UserRegion[]) => ({
  type: LOAD_USER_REGIONS_SUCCESS,
  userRegions: userRegions
});

export const loadUserRegionsFailure = (errorMessage: string) => ({
  type: LOAD_USER_REGIONS_FAILURE,
  errorMessage: errorMessage
});

export const loadParticipantOrganisationRequest = (userId: number) => ({
  type: LOAD_PARTICIPANT_ORGANISATION_REQUEST,
  userId: userId
});

export const loadParticipantOrganisationSuccess = (organisation: Lookup) => ({
  type: LOAD_PARTICIPANT_ORGANISATION_SUCCESS,
  organisation: organisation
});

export const loadParticipantOrganisationFailure = (errorMessage: string) => ({
  type: LOAD_PARTICIPANT_ORGANISATION_FAILURE,
  errorMessage: errorMessage
});

export const resetAutofillOrganisation = () => ({
  type: RESET_AUTOFILL_ORGANISATION
});

export const confirmUserLinkRequest = (code: string) => ({
  type: CONFIRM_USER_LINK_REQUEST,
  code: code
});

export const userLinkSuccess = (userLinkAction: string) => ({
  type: USER_LINK_SUCCESS,
  userLinkAction: userLinkAction
});

export const confirmUserLinkFailure = (errorMessage: string) => ({
  type: CONFIRM_USER_LINK_FAILURE,
  errorMessage: errorMessage
});

export const declineUserLinkRequest = (code: string) => ({
  type: DECLINE_USER_LINK_REQUEST,
  code: code
});

export const declineUserLinkFailure = (errorMessage: string) => ({
  type: DELETE_USER_LINK_FAILURE,
  errorMessage: errorMessage
});

export const resetUserLinkProcessed = () => ({
  type: RESET_USER_LINK_PROCESSED
});
